import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import Breadcrumb from '../breadcrumb'
import { Link } from 'react-router-dom'
import CampoDePesquisa from '../campo-de-pesquisa'
import DicaDePesquisa from '../dica-de-pesquisa'
import Spinner from '../spinner'
import { Coluna, Tabela, LinhaExpandida, AgrupamentoDeColunas, ColunaFoto } from '../tabela'
import { LISTAR_PACIENTES } from '../../acoes/tipos'
import Permissao, { usePossuiAsPermissoes } from '../seguranca/permissao'
import moment from 'moment'
import { formatarCpf } from '../../bibliotecas/formatacao'
import * as permissoes from '../seguranca/permissoes'

export default function Lista(props) {
  const {
    fotos,
    paginaDeDados,
    pesquisar,
    recuperarFotoOriginalDoPacientePorIdentificador
  } = props

  const [ordenacao, setOrdenacao] = useState(props.ordenacao)
  const [pagina, setPagina] = useState(paginaDeDados.pagina)
  const [filtros, setFiltros] = useState(props.filtros)

  const permissaoDeIniciarAtendimento = usePossuiAsPermissoes([permissoes.INICIAR_ATENDIMENTO])
  const permissaoDeListarAtendimentos = usePossuiAsPermissoes([permissoes.LISTAR_ATENDIMENTOS_DO_PACIENTE])
  const exibirBotaoProntuario = permissaoDeIniciarAtendimento || permissaoDeListarAtendimentos

  useEffect(() => {
    (async () => await pesquisar(filtros, pagina, ordenacao))()
  }, [filtros, pesquisar, pagina, ordenacao])

  const listar = (numeroDaPagina, ordenacao) => {
    setPagina(numeroDaPagina)
    setOrdenacao(ordenacao)
  }

  const alterarFiltros = filtros => {
    if (filtros.obito.length) {
      setFiltros(filtros)
      setPagina(1)
    }
  }

  const alterarTermo = termo => alterarFiltros({ ...filtros, termo })

  const alterarFiltrosDaTabela = filtro => {
    alterarFiltros({
      ...filtros,
      [filtro.nome]: filtro.valor
    })
  }

  const recuperarTelefones = (telefones, identificador) => {
    const telefonesDoPaciente = telefones.filter(x => x.proprietario === 'Paciente')

    if (telefonesDoPaciente.length === 0) {
      return
    }

    if (identificador === 1) {
      return telefonesDoPaciente[0].numero
    } else if (identificador === 2 && telefonesDoPaciente.length > 1) {
      return telefonesDoPaciente[1].numero
    }
  }

  const renderizarCarteiras = (convenios, identificador) => {
    if (convenios.length === 0) return    

    return (
      <>
        <div
          data-tip
          data-for={`carteiras_${identificador}`}
        >
          <div className='table-items__mobile-value'>
            {convenios[0].numeroDaCarteira}
            {convenios.length > 1 && <small>{'  [+]'}</small>}
          </div>
        </div>
        <ReactTooltip
          id={`carteiras_${identificador}`}
          place='bottom'
          effect='solid'
          type='info'
          className='tooltip-nome'
          key={`carteiras_${identificador}`}
        >
          <div
            style={{
              marginBottom: 8,
              borderBottomStyle: 'solid',
              borderBottomWidth: '1px',
              borderBottomColor: 'rgb(232, 232, 232)'
            }}><strong>Carteirinhas Ativas</strong></div>
          {convenios.map((item, index) =>
            <React.Fragment key={index}><span>{'• '}{item.operadora}{' - '}{item.numeroDaCarteira}</span><br /></React.Fragment>
          )}
        </ReactTooltip>
      </>
    )
  }

  return (
    <div className='page-configuracoes-agendamento'>
      <div className='container'>
        <div className='main-content-form'>
          <div className='header-page-form'>
            <r-grid columns-md={6} columns-lg={12} class='w-100 align-items-start'>
              <r-cell span={4} span-md={2} span-lg={6}>
                <Breadcrumb />
              </r-cell>
              <r-cell span={4} span-md={2} span-lg={2}>
                <Permissao permissoes={[permissoes.ADICIONAR_PACIENTE]}>
                  <Link
                    to={`/cadastros/pacientes/novo`}
                    className='w-100 button --primary --plus'
                  >
                    Adicionar
                  </Link>
                </Permissao>
              </r-cell>
              <r-cell span={4} span-md={2} span-lg={4}>
                <DicaDePesquisa
                  className='form-search'
                  dica='A pesquisa é realizada em todas as colunas.'
                  exemplo='Maria 01/01/1940'
                >
                  <CampoDePesquisa
                    pesquisar={alterarTermo}
                    valor={filtros ? filtros.termo : ''}
                  />
                </DicaDePesquisa>
              </r-cell>
            </r-grid>
          </div>
          <Spinner operacoes={[LISTAR_PACIENTES]}>
            <Tabela
              acoes={[
                item => (
                  <Link
                    to={`/cadastros/pacientes/${item.identificador}`}
                    className='table-items-expanded__bt  --edit'
                    title='Editar'
                    key={item.identificador}
                  >
                    <i className='icon icon-pencil'></i>
                  </Link>
                ),
                item => (item.identificador && exibirBotaoProntuario &&
                  <Link
                    to={`/cadastros/pacientes/${item.identificador}/prontuario`}
                    className='table-items-expanded__bt  --edit-fixed'
                    title='Prontuário'
                    key={`${item.identificador}_prontuario`}
                  >
                    <i className='icon icon-doctor'></i>
                  </Link>
                )
              ]}
              alterarFiltros={alterarFiltrosDaTabela}
              chave='identificador'
              className='table-items mt-2 mb-4'
              expandida={true}
              filtros={filtros}
              listar={listar}
              ordenacao={ordenacao}
              paginaDeDados={paginaDeDados}
            >
              <Coluna
                className='col-xs-2 col-md-1'
                nome='foto'
                foto={true}
                fotos={fotos}
                identificadorDaFoto={item => item.foto}
                recuperarFotoOriginal={item => recuperarFotoOriginalDoPacientePorIdentificador(item.identificador)}
              />
              <Coluna
                campoDeDados='nome'
                className='col-xs-6 col-lg-3'
                exibirOrdenacao={true}
                principal={true}
                nome='Nome'
              />
              <Coluna
                className='col-lg-2'
                exibirOrdenacao={true}
                nome='CPF'
                campoDeDados='cpf'
                renderizar={item => item.cpf ? formatarCpf(item.cpf) : item.cpf}
              />
              <Coluna
                className='col-lg-2'
                exibirOrdenacao={true}
                nome='Data de Nascimento'
                campoDeDados='dataDeNascimento'
                renderizar={item => moment(item.dataDeNascimento).format("DD/MM/YYYY")}
              />
              <Coluna
                className='col-xs-4 col-lg-2'
                exibirOrdenacao={false}
                nome='Telefone'
                campoDeDados='telefone1'
                renderizar={item => recuperarTelefones(item.telefones, 1)}
              />
              <Coluna
                campoDeDados='obito'
                className='col-lg-1-5'
                filtros={['Ativo', 'Inativo']}
                nome='Status'
                renderizar={item => item.obito ? 'Inativo' : 'Ativo'}
              />
              <LinhaExpandida className='col-xs-10 col-lg-9'>
                <ColunaFoto
                  className='pr-0 col-xs-2 col-md-1'
                  nome='foto'
                  fotos={fotos}
                  identificadorDaFoto={item => item.foto}
                  recuperarFotoOriginal={item => recuperarFotoOriginalDoPacientePorIdentificador(item.identificador)}
                />
                <AgrupamentoDeColunas>
                  <Coluna
                    campoDeDados='nome'
                    icone='icon-name'
                    nome='NOME'
                  />
                  <Coluna
                    icone='icon-date'
                    nome='DATA DE NASCIMENTO'
                    renderizar={item => moment(item.dataDeNascimento).format("DD/MM/YYYY")}
                  />
                  <Coluna
                    icone='icon-card'
                    nome='CPF'
                    renderizar={item => item.cpf ? formatarCpf(item.cpf) : item.cpf}
                  />
                  <Coluna
                    icone='icon-phone'
                    nome='TELEFONE #1'
                    renderizar={item => recuperarTelefones(item.telefones, 1)}
                  />
                  <Coluna
                    icone='icon-phone'
                    nome='TELEFONE #2'
                    renderizar={item => recuperarTelefones(item.telefones, 2)}
                  />
                  <Coluna
                    icone='icon-hospital'
                    nome='ENFERMEIRO DE REFERÊNCIA'
                    renderizar={item => item.enfermeiroDeReferencia ? item.enfermeiroDeReferencia.nome : null}
                  />
                  <Coluna
                    icone='icon-doctor'
                    nome='MÉDICO DE REFERÊNCIA'
                    renderizar={item => item.medicoDeReferencia ? item.medicoDeReferencia.nome : null}
                  />
                  <Coluna
                    icone='icon-card'
                    nome='CARTEIRINHA'
                    renderizar={item => renderizarCarteiras(item.convenios, item.identificador)}
                  />
                </AgrupamentoDeColunas>
              </LinhaExpandida>
            </Tabela>
          </Spinner>
        </div>
      </div>
    </div>
  )
}