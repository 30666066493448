import React, { useEffect } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import RotaComAutenticacao from '../../seguranca/rota-com-autenticacao'
import Permissao from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import Passo1 from './passo-1'
import Passo2 from './passo-2'
import Passo3 from './passo-3'
import queryString from 'query-string'

export default function NovoAgendamento(props) {
  const {
    adicionarAgendamentoDoWizardDeAgendamento,
    adicionarPreCadastroDoPacienteDoWizardDeAgendamento,
    agendamentosRealizados,
    convenios,
    dataBaseHorariosLivres,
    excluirAgendamentoDoWizardDeAgendamento,
    filtros,
    fotosDosPacientes,
    fotosDosProfissionais,
    grausDeRelacoes,
    history,
    horariosLivres,
    limparDadosDoWizardDeAgendamento,
    limparDadosDoPacienteDoWizardDeAgendamento,
    limparHorariosLivresDoWizardDeAgendamento,
    listarGrausDeRelacoes,
    listarHorariosLivresDoWizardDeAgendamento,
    listarPacientesDoWizardDeAgendamento,
    listarProfissionaisDeSaudeDoWizardDeAgendamento,
    listarProfissoesDoWizardDeAgendamento,
    listarProprietariosDeTelefones,
    listarRecorrenciasDoAgendamento,
    listarSexos,
    listarTiposDeAgendamentoDoWizardDeAgendamento,
    listarUnidadesDaEmpresaDoWizardDeAgendamento,
    location,
    match,
    ordem,
    pacientes,
    paciente,
    pacienteSemPrograma,
    profissionaisDeSaude,
    profissoes,
    proprietariosDeTelefones,
    proximosAgendamentos,
    recorrenciasDoAgendamento,
    recuperarConveniosDoPacienteDoWizardDeAgendamento,
    recuperarFotoDoPacientePorIdentificador,
    recuperarFotoDoUsuarioPorIdentificador,
    removerImagemDaMemoriaPorIdentificador,
    selecionarConvenioDoPacienteDoWizardDeAgendamento,
    selecionarEspecialidadeDoProfissionalDoWizardDeAgendamento,
    selecionarPacienteDoWizardDeAgendamento,
    selecionarProfissaoDoWizardDeAgendamento,
    selecionarTipoDoAgendamentoDoWizardDeAgendamento,
    selecionarContatosDoPacienteDoWizardDeAgendamento,
    selecionarProfissionalDoWizardDeAgendamento,
    sexos,
    tiposDeAgendamento,
    unidades,
  } = props

  useEffect(() => { listarPacientesDoWizardDeAgendamento({ obito: ['Ativo'] }, 1, ordem, 50) }, [listarPacientesDoWizardDeAgendamento, ordem])
  useEffect(() => { listarSexos() }, [listarSexos])

  useEffect(() => {
    listarProfissoesDoWizardDeAgendamento()
    listarProfissionaisDeSaudeDoWizardDeAgendamento()
    listarTiposDeAgendamentoDoWizardDeAgendamento()
  }, [listarProfissoesDoWizardDeAgendamento, listarProfissionaisDeSaudeDoWizardDeAgendamento, listarTiposDeAgendamentoDoWizardDeAgendamento])

  useEffect(() => {
    if (location.search) {
      const parametros = queryString.parse(location.search)
      selecionarPacienteDoWizardDeAgendamento(parametros.paciente)
      selecionarTipoDoAgendamentoDoWizardDeAgendamento(parseInt(parametros.agendamento))
      if (!parametros.profissional) {
        history.push(`/agendamentos/novo-agendamento/passo-1`)
      } else {
        selecionarProfissionalDoWizardDeAgendamento([parseInt(parametros.profissional)])
      }
    }
  }, [location, selecionarPacienteDoWizardDeAgendamento, selecionarTipoDoAgendamentoDoWizardDeAgendamento, selecionarProfissionalDoWizardDeAgendamento, history])

  useEffect(() => {
    if (!paciente.identificador && !location.search) {
      history.push(`/agendamentos/novo-agendamento/passo-1`)
    }
  }, [paciente, history, location.search])

  const possuiParametros = location.search ?
    filtros.tipoDeAgendamentoSelecionado && filtros.profissionaisSelecionados.length > 0 && profissionaisDeSaude.length > 0 : filtros.tipoDeAgendamentoSelecionado

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}/passo-1`}
        render={
          props => (
            <Permissao permissoes={[
              permissoes.ADICIONAR_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE,
              permissoes.LISTAR_PACIENTES,
              permissoes.LISTAR_CONVENIOS_DO_PACIENTE]}
            >
              <Passo1
                {...props}
                adicionarPreCadastroDoPaciente={adicionarPreCadastroDoPacienteDoWizardDeAgendamento}
                convenios={convenios}
                grausDeRelacoes={grausDeRelacoes}
                limparDadosDoAgendamento={limparDadosDoWizardDeAgendamento}
                limparDadosDoPaciente={limparDadosDoPacienteDoWizardDeAgendamento}
                listarGrausDeRelacoes={listarGrausDeRelacoes}
                listarPacientesDoAgendamento={listarPacientesDoWizardDeAgendamento}
                listarProprietariosDeTelefones={listarProprietariosDeTelefones}
                ordem={ordem}
                paciente={paciente}
                pacientes={pacientes}
                proprietariosDeTelefones={proprietariosDeTelefones}
                recuperarConvenio={recuperarConveniosDoPacienteDoWizardDeAgendamento}
                selecionarConvenioDoAgendamento={selecionarConvenioDoPacienteDoWizardDeAgendamento}
                selecionarPacienteDoAgendamento={selecionarPacienteDoWizardDeAgendamento}
                selecionarContatos={selecionarContatosDoPacienteDoWizardDeAgendamento}
                sexos={sexos}
                email={paciente.email}
                contatos={paciente.contatos}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={`${match.path}/passo-2`}
        render={
          props => (
            <Permissao permissoes={[
              permissoes.ADICIONAR_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE,
              permissoes.LISTAR_PROFISSOES,
              permissoes.LISTAR_PROFISSIONAIS_DE_SAUDE,
              permissoes.LISTAR_ESPECIALIDADES,
              permissoes.LISTAR_TIPOS_DE_AGENDAMENTO]}
            >
              <Passo2
                {...props}
                convenios={convenios}
                convenioSelecionado={paciente.convenio}
                filtros={filtros}
                fotosDosPacientes={fotosDosPacientes}
                listarProfissionaisDeSaudeDoAgendamento={listarProfissionaisDeSaudeDoWizardDeAgendamento}
                listarProfissoesDoAgendamento={listarProfissoesDoWizardDeAgendamento}
                listarTiposDeAgendamentoDoAgendamento={listarTiposDeAgendamentoDoWizardDeAgendamento}
                paciente={paciente}
                profissionaisDeSaude={profissionaisDeSaude}
                profissoes={profissoes}
                recuperarFotoDoPacientePorIdentificador={recuperarFotoDoPacientePorIdentificador}
                removerImagemDaMemoriaPorIdentificador={removerImagemDaMemoriaPorIdentificador}
                selecionarPacienteDoAgendamento={selecionarPacienteDoWizardDeAgendamento}
                selecionarEspecialidadeDoProfissional={selecionarEspecialidadeDoProfissionalDoWizardDeAgendamento}
                selecionarProfissaoDoAgendamento={selecionarProfissaoDoWizardDeAgendamento}
                selecionarProfissionalDoAgendamento={selecionarProfissionalDoWizardDeAgendamento}
                selecionarTipoDoAgendamento={selecionarTipoDoAgendamentoDoWizardDeAgendamento}
                contatos={paciente.contatos}
                tiposDeAgendamento={tiposDeAgendamento}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={`${match.path}/passo-3`}
        render={
          props => (
            <Permissao permissoes={[
              permissoes.ADICIONAR_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE,
              permissoes.LISTAR_PROFISSOES,
              permissoes.LISTAR_PROFISSIONAIS_DE_SAUDE,
              permissoes.LISTAR_ESPECIALIDADES,
              permissoes.LISTAR_TIPOS_DE_AGENDAMENTO,
              permissoes.LISTAR_HORARIOS_LIVRES_DA_AGENDA]}
            >
              {possuiParametros &&
                <Passo3
                  {...props}
                  adicionarAgendamento={adicionarAgendamentoDoWizardDeAgendamento}
                  agendamentosRealizados={agendamentosRealizados}
                  convenios={convenios}
                  convenioSelecionado={paciente.convenio}
                  dataBaseHorariosLivres={dataBaseHorariosLivres}
                  excluirAgendamento={excluirAgendamentoDoWizardDeAgendamento}
                  filtros={filtros}
                  fotosDosPacientes={fotosDosPacientes}
                  fotosDosProfissionais={fotosDosProfissionais}
                  horarios={horariosLivres}
                  limparDadosDoAgendamento={limparDadosDoWizardDeAgendamento}
                  limparDadosDoPaciente={limparDadosDoPacienteDoWizardDeAgendamento}
                  limparHorariosLivresDoAgendamento={limparHorariosLivresDoWizardDeAgendamento}
                  listarHorariosLivresDoAgendamento={listarHorariosLivresDoWizardDeAgendamento}
                  listarPacientesDoWizardDeAgendamento={listarPacientesDoWizardDeAgendamento}
                  listarProfissionaisDeSaudeDoAgendamento={listarProfissionaisDeSaudeDoWizardDeAgendamento}
                  listarRecorrenciasDoAgendamento={listarRecorrenciasDoAgendamento}
                  listarUnidadesDaEmpresaDoAgendamento={listarUnidadesDaEmpresaDoWizardDeAgendamento}
                  ordem={ordem}
                  paciente={paciente}
                  pacienteSemPrograma={pacienteSemPrograma}
                  parametrosRota={location.search}
                  profissoes={profissoes}
                  profissionaisDeSaude={profissionaisDeSaude}
                  proximosAgendamentos={proximosAgendamentos}
                  recorrenciasDoAgendamento={recorrenciasDoAgendamento}
                  recuperarFotoDoPacientePorIdentificador={recuperarFotoDoPacientePorIdentificador}
                  recuperarFotoDoUsuarioPorIdentificador={recuperarFotoDoUsuarioPorIdentificador}
                  removerImagemDaMemoriaPorIdentificador={removerImagemDaMemoriaPorIdentificador}
                  selecionarPacienteDoAgendamento={selecionarPacienteDoWizardDeAgendamento}
                  sexos={sexos}
                  contatos={paciente.contatos}
                  tiposDeAgendamento={tiposDeAgendamento}
                  unidades={unidades}
                />
              }
            </Permissao>
          )
        }
      />
      <Redirect exact from={match.path} to={`${match.path}/passo-1`} />
    </Switch>
  )
}