import React from 'react'
import {Provider} from 'react-redux'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import 'react-confirm-alert/src/react-confirm-alert.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import '../design/fontello/css/fontello.css'
import '../design/css/principal.css'
import '../design/css/customizacoes.css'
import Notificacao from './notificacoes/com-redux'
import Principal from './principal/com-redux'
import Login from './login/com-redux'
import Unidades from './login/unidades/com-redux'
import Nps from './nps/com-redux'
import RecuperarSenha from './recuperar-senha/com-redux'
import PedidoDeContato from './pedido-de-contato/com-redux'
import ValidarAssinaturaDigital from './validar-assinatura-digital'
import ProvedorDeAutenticacao from './seguranca/provedor-de-autenticacao/com-redux'
import {ProvedorDoFirestore} from '../configuracoes/firestore/provedor'
import RotaComAutenticacao from './seguranca/rota-com-autenticacao'
import TeleconsultaAsclepius from './teleconsulta-asclepius'

export default function App({ store, firestore }) {
  return (
    <Provider store={store}>
      <ProvedorDeAutenticacao>
        <ProvedorDoFirestore firestore={firestore}>
          <Router>
            <Route component={Notificacao} />
            <Switch>
              <Route path='/login' exact component={Login} />
              <Route path='/login/unidades' component={Unidades} />
              <Route path='/recuperar-senha' component={RecuperarSenha} />
              <Route path='/validar-assinatura-digital' component={ValidarAssinaturaDigital} />
              <Route path='/nps/:identificadorDaEmpresa/:identificadorDaAvaliacao' component={Nps} />
              <Route path='/mais-60-saude/pedido-de-contato' component={PedidoDeContato} />
              <Route path='/empresas/:identificadorDaEmpresa/teleconsultas/:identificadorDaTeleconsulta/:sujeito' component={TeleconsultaAsclepius} />
              <RotaComAutenticacao path='/' component={Principal} />
            </Switch>
          </Router>
        </ProvedorDoFirestore>
      </ProvedorDeAutenticacao>
    </Provider>
  )
}