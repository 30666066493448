import React, { createContext, useContext, useState, useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { Form, Formik, Field, ErrorMessage } from 'formik'
import InputCor from './input-cor'
import calendario from '../../design/img/calendar.svg'
import relogio from '../../design/img/clock.svg'
import ReactDatePicker from 'react-datepicker'
import Modal from 'react-modal'
import noThumb from '../../design/img/no-thumb.png'
import moment from 'moment'
import { filtroPadrao } from '../../bibliotecas/texto'
import StylesSelect from './select'
import InputMension from './input-mension/index'
import { contemValor } from '../../bibliotecas/validacoes'
import NumberFormat from 'react-number-format'
import { formatarDataParaFormatoUniversal } from '../../bibliotecas/data'
import * as Mask from 'react-input-mask'

const ContextoDeFormulario = createContext()

const recuperarValor = (caminho, objeto) => {
  if (!contemValor(caminho)) return null
  if (!objeto) return null

  let valor = objeto

  for (const item of caminho.split('.')) {
    valor = valor[item]

    if (!valor) {
      break
    }
  }

  return valor
}

export function Formulario({ children, reinicializar, processando, valoresIniciais, acao, esquemaDeValidacoes, controlarDadosSalvos = null, id }) {
  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  return (
    <Formik
      enableReinitialize={reinicializar}
      initialValues={valoresIniciais}
      onSubmit={acao}
      validationSchema={esquemaDeValidacoes}
    >
      {props => {
        const { errors, touched, values, dirty, handleChange, handleBlur, setFieldValue } = props
        //console.log(JSON.stringify(props, null, 2))

        return (
          <ContextoDeFormulario.Provider value={{ erros: errors, tocados: touched, valores: values, handleChange, handleBlur, setFieldValue }}>
            <FormularioDoFormik
              processando={processando}
              onKeyDown={onKeyDown}
              children={children}
              controlarDadosSalvos={controlarDadosSalvos}
              dirty={dirty}
              argumentos={props}
              id={id}
            />
          </ContextoDeFormulario.Provider>
        )
      }}
    </Formik>
  )
}

function FormularioDoFormik(props) {
  const { processando, onKeyDown, children, controlarDadosSalvos, dirty, argumentos, id } = props

  useEffect(() => {
    if (controlarDadosSalvos) {
      controlarDadosSalvos(dirty)
    }
  }, [controlarDadosSalvos, dirty])

  return (
    <Form className={`form ${processando && 'is-loading'}`} onKeyDown={onKeyDown} id={id}>
      {typeof (children) === 'function' ? children(argumentos) : children}
    </Form>
  )
}

export function Input({ nome, titulo, margem, validar, classname, disabled = false, ...rest }) {
  const { erros, tocados, handleChange, handleBlur } = useContext(ContextoDeFormulario)
  const tocado = recuperarValor(nome, tocados)
  const erro = recuperarValor(nome, erros)

  const onChange = evento => {
    handleChange(evento)
    rest.onChange && rest.onChange(evento.target.value)
  }

  const onBlur = evento => {
    handleBlur(evento)
    rest.onBlur && rest.onBlur(evento)
  }

  return (
    <div className={`form-group ${(classname) ? classname : ''} ${(erro && tocado) && 'has-error'}`} style={{ marginBottom: margem }}>
      <label className='form-label'>{titulo}</label>
      <Field
        {...rest}
        name={nome}
        onChange={onChange}
        onKeyDown={evento => rest.as === 'textarea' && evento.stopPropagation()}
        validate={validar}
        onBlur={onBlur}
        autoComplete='off'
        autoCorrect='off'
        spellCheck='off'
        disabled={disabled}
      />
      <ErrorMessage name={nome} className='form-tip' component='span' />
    </div>
  )
}

export function InputMask({ nome, titulo, validar, mascara, ...rest }) {
  const { erros, tocados } = useContext(ContextoDeFormulario)
  const tocado = recuperarValor(nome, tocados)
  const erro = recuperarValor(nome, erros)

  return (
    <div className={`form-group ${(erro && tocado) && 'has-error'}`}>
      <label className='form-label' htmlFor={nome}>{titulo}</label>
      <Field
        {...rest}
        name={nome}
        mascara={mascara}
        component={InputMaskFormik}
        validate={validar}
      />
      <ErrorMessage name={nome} className='form-tip' component='span' />
    </div>
  )
}

export function InputMaskFormik(props) {
  const { field: { value, name }, form: { setFieldValue, handleChange, handleBlur }, titulo, validar, mascara, ehTelefone = false, ...rest } = props
  const [foco, setFoco] = useState(false)

  const filtrarNumeros = valor => valor.replace(/\D/g, '')

  const enviarMascara = valor => {
    if (ehTelefone) {
      if (!valor || valor.length === 0) return ''
      const numeros = filtrarNumeros(valor)
      return numeros.length < 11 ? mascara[1] : mascara[0]
    }
    return mascara[0]
  }

  const onChange = evento => {
    handleChange(evento)
    const valor = /\d/.test(evento.target.value) ? evento.target.value : ''
    rest.onChange && rest.onChange(valor)
    setFieldValue(name, filtrarNumeros(valor))
  }

  const onBlur = evento => {
    handleBlur(evento)
    rest.onBlur && rest.onBlur(evento)
    setFoco(false)
    setFieldValue(name, filtrarNumeros(evento.target.value))
  }

  const onFocus = evento => {
    setFoco(true)
    setFieldValue(name, filtrarNumeros(evento.target.value))
  }

  return (
    <Mask
      {...rest}
      value={value}
      name={name}
      mask={enviarMascara(value)}
      formatChars={{ 9: '[0-9]', '?': '[0-9]' }}
      maskChar={!foco ? '' : '_'}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      validate={validar}
      autoComplete='off'
      autoCorrect='off'
      spellCheck='off'
    />
  )
}

export function CampoNumerico({ nome, titulo, escala, className, outraEscala, numeroDeCasasDecimais = 0, disabled = false, margem, minW, validar, children, informacaoAdicional = null, ...rest }) {
  const { erros, tocados, handleChange, handleBlur } = useContext(ContextoDeFormulario)
  const tocado = recuperarValor(nome, tocados)
  const erro = recuperarValor(nome, erros)

  const onChange = evento => {
    handleChange(evento)
    rest.onChange && rest.onChange(evento.target.value)
  }

  const onBlur = evento => {
    handleBlur(evento)
    rest.onBlur && rest.onBlur(evento)
  }

  return (
    <div className={`form-group input-formulario ${className} ${(erro && tocado) && 'has-error'}`} style={{ marginBottom: margem }}>
      <label className='form-label'>{titulo}</label>
      <div className='form-input-group'>
        <Field
          {...rest}
          name={nome}
          decimalScale={numeroDeCasasDecimais}
          onChange={onChange}
          onBlur={onBlur}
          autoComplete='off'
          autoCorrect='off'
          spellCheck='off'
          validate={validar}
          isDisabled={disabled}
          component={NumeroFormatado}
        />
        {escala && <div className='form-input-group-append' style={minW && { minWidth: minW, textAlign: 'center' }}>{outraEscala ? <> {escala} ou <br /> {outraEscala} </> : escala}</div>}
        {children && <div className='form-input-group-append'>{children}</div>}
      </div>
      {informacaoAdicional &&
        <span className='dica-de-pesquisa'>
          <strong>{informacaoAdicional.label}</strong>: <i>{informacaoAdicional.valor}</i>
        </span>
      }
      <ErrorMessage name={nome} className='form-tip' component='span' />

    </div>
  )
}

export function Toglle({ nome, disabled, titulo, className = 'form-toggle', ...rest }) {
  const { handleChange } = useContext(ContextoDeFormulario)

  const onChange = evento => {
    handleChange(evento)
    rest.onChange && rest.onChange(evento.target.value !== 'true')
  }

  return (
    <div className={`${className} justify-content-between justify-content-md-start ${disabled && 'is-disabled'}`}>
      <span>{titulo}</span>
      <Field
        {...rest}
        id={`checkbox_${nome}`}
        name={nome}
        type='checkbox'
        onChange={onChange}
      />
      <label htmlFor={`checkbox_${nome}`}></label>
    </div>
  )
}

export function Cor(props) {
  return <Input component={InputCor} {...props} />
}

export function Checkbox({ nome, titulo, classname, onChangeDesabilitado, ...rest }) {
  const { handleChange } = useContext(ContextoDeFormulario)

  const onChange = evento => {
    handleChange(evento)
    rest.onChange && rest.onChange(evento.target.value !== 'true')
  }

  return (
    <div className={`checkbox ${(classname) ? classname : ''}`}>
      <Field
        {...rest}
        id={`checkbox_${nome}`}
        name={nome}
        type='checkbox'
        onChange={!onChangeDesabilitado ? onChange : rest.onChange}
      />
      <label htmlFor={`checkbox_${nome}`}><span>{titulo}</span></label>
    </div>
  )
}

export function Radio({ nome, titulo, valor, classname, onChange, disabled, ...rest }) {
  const { valores } = useContext(ContextoDeFormulario)
  const valorDoField = recuperarValor(nome, valores)

  return (
    <div className={`radio ${(classname) ? classname : ''}`}>
      <Field
        {...rest}
        id={`radio_${nome}_${valor}`}
        name={nome}
        value={valor}
        checked={valorDoField === valor}
        type='radio'
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={`radio_${nome}_${valor}`}><span>{titulo}</span></label>
    </div>
  )
}

export function Select({ nome, titulo, className, subTitulo = '', campoCodigo, campoDescricao, campoDesabilitado, itens = [], criador = false, options = null, validar, margem, children, ...rest }) {
  const { erros, tocados } = useContext(ContextoDeFormulario)
  const tocado = recuperarValor(nome, tocados)
  const erro = recuperarValor(nome, erros)

  const optionsSelect = options ? options : (
    campoCodigo ?
      itens.map(x => ({ label: x[campoDescricao], value: x[campoCodigo], isDisabled: campoDesabilitado ? x[campoDesabilitado] : false })) :
      itens.map(x => ({ label: x, value: x }))
  )

  return (
    <div className={`form-group ${className} ${(erro && tocado) && 'has-error'}`} style={{ marginBottom: margem }}>
      <label className={`form-label ${children && 'componente-select-botao'}`} htmlFor={nome}>{titulo}{children}</label>
      {subTitulo && <i className='form-label'>{subTitulo}</i>}
      <Field
        {...rest}
        options={optionsSelect}
        name={nome}
        component={SelectFormik}
        criador={criador}
        validate={validar}
      />
      <ErrorMessage name={nome} className='form-tip' component='span' />
    </div>
  )
}

function SelectFormik(props) {
  const { field: { value, name }, form: { setFieldValue, setFieldTouched, errors, touched }, titulo, options = [], isMulti, criador, placeholder = '', ...rest } = props

  const onBlur = evento => {
    setFieldTouched(name, true)
    rest.onBlur && rest.onBlur(evento)
  }

  const recuperarValorDoSelect = () => {
    if (isMulti) {
      return options.filter(x => value && value.some(item => item === x.value))
    } else if (rest.value) {
      return rest.value
    } else if (value) {
      if (rest.formatGroupLabel) {
        return options.map(x => x.options.find(y => y.value === value))
      }

      return options.find(x => x.value === value)
    }

    return ''
  }

  const valorPadrao = isMulti ? [] : ''
  const valor = recuperarValorDoSelect()

  const onChange = (option, evento) => {
    const valorAlterado = isMulti ? (option ? option.map(x => x.value) : valorPadrao) : (option ? option.value : valorPadrao)
    setFieldValue(name, valorAlterado)
    rest.onChange && rest.onChange(valorAlterado)
  }

  const erro = recuperarValor(name, errors)
  const tocado = recuperarValor(name, touched)

  const isValid = erro && tocado
  const filter = rest.filterOption ? rest.filterOption : (option, rawInput) => filtroPadrao(rawInput, option.label)

  return (
    <StylesSelect
      {...rest}
      {...props.field}
      criador={criador}
      filterOption={filter}
      key={`${name}_${valor}`}
      options={options}
      value={valor}
      onChange={onChange}
      isClearable={true}
      isMulti={isMulti}
      isValid={isValid}
      placeholder={placeholder}
      onBlur={onBlur}
      formatCreateLabel={userInput => `Criar opção para '${userInput}'`}
      noOptionsMessage={rest.noOptionsMessage}
      menuPortalTarget={document.body}
    />
  )
}

export function DatePicker({ margem, nome, titulo, isDisabled, validar, acoes = [], classnameAcao, ...rest }) {
  const { erros, tocados } = useContext(ContextoDeFormulario)
  const tocado = recuperarValor(nome, tocados)
  const erro = recuperarValor(nome, erros)

  const onClickAcao = acaoDoBotao => {
    acaoDoBotao()
  }

  return (
    <div className={`form-group ${(erro && tocado) && 'has-error'}`} style={{ marginBottom: margem }}>
      <div className='componente-select-acao'>
        <label className='form-label' htmlFor={nome}>{titulo}</label>
        {acoes.length > 0 && acoes.map((x, index) => <button type='button' disabled={isDisabled} key={index} className={classnameAcao ? classnameAcao : 'button button-light-select-acao'} title={x.tituloSecundario} onClick={() => onClickAcao(x.acao)}>{x.titulo}</button>)}
      </div>
      <button type='button' className='icon-calendar'>
        <img src={calendario} alt='calendario' />
      </button>
      <Field
        {...rest}
        name={nome}
        component={DatePickerFormik}
        isDisabled={isDisabled}
        validate={validar}
      />
      <ErrorMessage name={nome} className='form-tip' component='span' />
    </div>
  )
}

function NumeroFormatado(props) {
  const { field: { value, name }, form: { setFieldValue, setFieldTouched }, isDisabled, ...rest } = props

  const onBlur = evento => {
    setFieldTouched(name, true)
    rest.onBlur && rest.onBlur(evento)
  }

  const onChange = evento => {
    rest.onChange && rest.onChange(evento)

    const valor = evento.target.value
    const valorTratado = valor ? parseFloat(valor.replace(',', '.')) : null

    setFieldValue(name, valorTratado)
  }

  return (
    <NumberFormat
      {...rest}
      {...props.field}
      decimalSeparator=','
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      autoComplete='off'
      autoCorrect='off'
      spellCheck='off'
      disabled={isDisabled}
      allowNegative={false}
    />
  )
}

function DatePickerFormik(props) {
  const { field: { value, name }, form: { setFieldValue, setFieldTouched }, dateFormat = 'dd/MM/yyyy', isDisabled, ...rest } = props
  let valor

  if (value && value.toString().length >= 10) {
    const textoDaData = value.toString().substring(0, 10)

    if (/^\d{2}[/]\d{2}[/]\d{4}$/.test(textoDaData)) {
      valor = moment(value, 'dd/MM/yyyy').toDate()
    } else if (/^\d{4}[-]\d{2}[-]\d{2}$/.test(textoDaData)) {
      valor = moment(value).toDate()
    }
  }

  const onBlur = evento => {
    setFieldTouched(name, true)
    rest.onBlur && rest.onBlur(evento)
  }

  const onChange = data => {
    setFieldValue(name, formatarDataParaFormatoUniversal(data))
    rest.onChange && rest.onChange(data)
  }

  return (
    <ReactDatePicker
      {...rest}
      {...props.field}
      value={valor}
      selected={valor}
      onChange={onChange}
      onBlur={onBlur}
      showYearDropdown
      dateFormatCalendar='MMMM'
      dateFormat={dateFormat}
      autoComplete='off'
      autoCorrect='off'
      spellCheck='off'
      disabled={isDisabled}
      wrapperClassName='w-100'
    />
  )
}

export function MonthPicker({ nome, titulo, isDisabled, ...rest }) {
  const { erros, tocados } = useContext(ContextoDeFormulario)
  const tocado = recuperarValor(nome, tocados)
  const erro = recuperarValor(nome, erros)

  return (
    <div className={`form-group ${(erro && tocado) && 'has-error'}`}>
      <label className='form-label' htmlFor={nome}>{titulo}</label>

      <button type='button' className='icon-calendar'>
        <img src={calendario} alt='calendario' />
      </button>
      <Field
        {...rest}
        name={nome}
        component={MonthPickerFormik}
        isDisabled={isDisabled}
      />
      <ErrorMessage name={nome} className='form-tip' component='span' />
    </div>
  )
}

function MonthPickerFormik(props) {
  const { field: { value, name }, form: { setFieldValue, setFieldTouched }, dateFormat = 'MM/yyyy', isDisabled, ...rest } = props
  const valor = value ? moment(value).toDate() : null

  const onBlur = evento => {
    setFieldTouched(name, true)
    rest.onBlur && rest.onBlur(evento)
  }

  const onChange = data => {
    setFieldValue(name, formatarDataParaFormatoUniversal(data))
    rest.onChange && rest.onChange(data)
  }

  return (
    <ReactDatePicker
      {...rest}
      {...props.field}
      value={valor}
      selected={valor}
      onChange={onChange}
      onBlur={onBlur}
      showMonthYearPicker
      dateFormat={dateFormat}
      autoComplete='off'
      autoCorrect='off'
      spellCheck='off'
      disabled={isDisabled}
      wrapperClassName='w-100'
    />
  )
}

export function YearPicker({ nome, titulo, isDisabled, ...rest }) {
  const { erros, tocados } = useContext(ContextoDeFormulario)
  const tocado = recuperarValor(nome, tocados)
  const erro = recuperarValor(nome, erros)

  return (
    <div className={`form-group ${(erro && tocado) && 'has-error'}`}>
      <label className='form-label' htmlFor={nome}>{titulo}</label>

      <button className='icon-calendar'>
        <img src={calendario} alt='calendario' />
      </button>
      <Field
        {...rest}
        name={nome}
        component={YearPickerFormik}
        isDisabled={isDisabled}
      />
      <ErrorMessage name={nome} className='form-tip' component='span' />
    </div>
  )
}

function YearPickerFormik(props) {
  const { field: { value, name }, form: { setFieldValue, setFieldTouched }, dateFormat = 'yyyy', isDisabled, ...rest } = props
  const valor = value ? moment(value).toDate() : null

  const onBlur = evento => {
    setFieldTouched(name, true)
    rest.onBlur && rest.onBlur(evento)
  }

  const onChange = data => {
    setFieldValue(name, formatarDataParaFormatoUniversal(data))
    rest.onChange && rest.onChange(data)
  }

  return (
    <ReactDatePicker
      {...rest}
      {...props.field}
      selected={valor}
      value={valor}
      onChange={onChange}
      onBlur={onBlur}
      showYearPicker
      dateFormat={dateFormat}
      autoComplete='off'
      autoCorrect='off'
      spellCheck='off'
      disabled={isDisabled}
      yearItemNumber={8}
      wrapperClassName='w-100'
    />
  )
}

export function HourPicker({ nome, titulo, isDisabled, ...rest }) {
  const { erros, tocados } = useContext(ContextoDeFormulario)
  const tocado = recuperarValor(nome, tocados)
  const erro = recuperarValor(nome, erros)

  return (
    <div className={`form-group ${(erro && tocado) && 'has-error'}`}>
      <label className='form-label' htmlFor={nome}>{titulo}</label>

      <button className='icon-hour'>
        <img src={relogio} alt='relogio' />
      </button>
      <Field
        {...rest}
        name={nome}
        component={HourPickerFormik}
        isDisabled={isDisabled}
      />
      <ErrorMessage name={nome} className='form-tip' component='span' />
    </div>
  )
}

function HourPickerFormik(props) {
  const { field: { value, name }, form: { setFieldValue, setFieldTouched }, dateFormat = 'HH:mm', isDisabled, ...rest } = props
  const valor = value ? moment(value, 'HH:mm').toDate() : ''

  const onBlur = evento => {
    setFieldTouched(name, true)
    rest.onBlur && rest.onBlur(evento)
  }

  const onChange = data => {
    setFieldValue(name, data)
    rest.onChange && rest.onChange(data)
  }

  return (
    <ReactDatePicker
      {...rest}
      {...props.field}
      selected={valor}
      onChange={onChange}
      onBlur={onBlur}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={5}
      dateFormat={dateFormat}
      autoComplete='off'
      autoCorrect='off'
      spellCheck='off'
      timeCaption='Hora'
      className='form-select'
      disabled={isDisabled}
      wrapperClassName='w-100'
    />
  )
}

export function Opcoes({ titulo, opcoes, opcoesDesabilitadas, nome, classname, selecaoUnica, minimo, ...rest }) {
  return (
    <Field
      {...rest}
      component={OpcoesFormik}
      titulo={titulo}
      name={nome}
      opcoes={opcoes}
      opcoesDesabilitadas={opcoesDesabilitadas}
      classname={classname}
      selecaoUnica={selecaoUnica}
      minimo={minimo}
    />
  )
}

function OpcoesFormik(props) {
  const { titulo, opcoes = [], opcoesDesabilitadas = [], classname, selecaoUnica = false, minimo = 0, field: { value, name }, form: { setFieldValue, errors, setFieldTouched, touched, validateField }, ...rest } = props
  const [selecionados, setSelecionados] = useState(value || [])

  useEffect(() => setSelecionados(value), [value])

  const onChange = opcoes => {
    setFieldValue(name, opcoes)
    rest.onChange && rest.onChange(opcoes)
  }

  const aoClicar = opcao => {
    let opcoes = []

    if (selecaoUnica) {
      opcoes = [opcao]
    } else if (selecionados.includes(opcao)) {
      if (minimo && selecionados.length === minimo) return
      opcoes = selecionados.filter(x => x !== opcao)
    } else {
      opcoes = [...selecionados, opcao]
    }

    setSelecionados(opcoes)
    onChange(opcoes)
    setFieldTouched(name, true)
  }

  const tocado = recuperarValor(name, touched)
  const erro = recuperarValor(name, errors)

  useEffect(() => {
    erro && tocado === true && validateField(name)
  }, [selecionados, name, tocado, erro, validateField])

  return (
    <div className={`form-group ${(erro && tocado) && 'has-error'}`}>
      <label className='form-label'>{titulo}</label>
      <div className={`${classname} week-options`}>
        {opcoes.map((x, index) => (
          <div
            key={index}
            name={name}
            className={`${classname} week-options__item ${(opcoesDesabilitadas.includes(x) ? 'is-disabled' : '') || (selecionados.includes(x) ? 'is-selected' : '')}`}
            onClick={() => opcoes.includes(x) && aoClicar(x)}
            onChange={onChange}
          >
            {x}
          </div>
        ))}
      </div>
      <ErrorMessage name={name} className='form-tip' component='span' />
    </div>
  )
}

export function Contador({ titulo, nome, range = 1, minimo = 0, maximo = null, ...rest }) {
  return (
    <Field
      {...rest}
      component={ContadorFormik}
      titulo={titulo}
      name={nome}
      range={range}
      minimo={minimo}
      maximo={maximo}
    />
  )

}

function ContadorFormik(props) {
  const {
    titulo,
    range,
    minimo,
    maximo,
    field: { value, name },
    form: { setFieldValue, errors, touched },
    ...rest
  } = props

  const [valor, setValor] = useState(value)

  useEffect(() => setValor(value), [value])

  const aoClicar = sinal => {
    let novoValor = valor || 0

    if ("+" === sinal) {
      novoValor += range
    } else {
      novoValor -= range
    }

    novoValor = novoValor < minimo ? minimo : novoValor
    novoValor = maximo !== null && novoValor > maximo ? maximo : novoValor

    setValor(novoValor)
    setFieldValue(name, novoValor)
  }

  const tocado = recuperarValor(name, touched)
  const erro = recuperarValor(name, errors)

  return (
    <div className={`form-group ${(erro && tocado) && 'has-error'}`}>
      <label className='form-label'>{titulo}</label>
      <Field
        {...rest}
        name={name}
        autoComplete='off'
        autoCorrect='off'
        spellCheck='off'
        disabled
      />
      <div className="input-count">
        <a href='#/' onClick={() => aoClicar('-')}><i className="icon-minus"></i></a>
        <a href='#/' onClick={() => aoClicar('+')}><i className="icon-plus"></i></a>
      </div>
      <ErrorMessage name={name} className='form-tip' component='span' />
    </div>
  )
}

export function InputImagem({ nome, leitura, valor, ...rest }) {
  if (leitura) {
    return <InputImagemSomenteLeitura recuperar={rest.recuperar} valor={valor} />
  }

  return (
    <Field
      {...rest}
      name={nome}
      component={InputImagemFormik}
    />
  )
}

function InputImagemSomenteLeitura({ recuperar, valor }) {
  const [exibir, setExibir] = useState(false)
  const [url, setUrl] = useState(false)

  if (!valor) return <></>

  const abrirImagem = async () => {
    const response = await recuperar()

    setUrl(response)
    setExibir(true)
  }

  function fecharImagem() {
    setExibir(false)
  }

  function ExibirImagem({ fechar, url }) {
    return (
      <div className='modal-imagem' onClick={fechar}>
        {url && <img alt='imagem' src={url} />}
      </div>
    )
  }

  return (
    <>
      <Modal
        isOpen={exibir}
        className='estilo-do-modal-imagem'
      >
        <ExibirImagem
          url={url}
          fechar={fecharImagem}
        />
      </Modal>
      <div>
        <label
          htmlFor='anexo'
          className='formik__botao-adicionar-imagem'
          onClick={abrirImagem}
        >
          Visualizar Imagem
          <i className='icon icon-search ml-1'></i>
        </label>
      </div>
    </>
  )
}

function InputImagemFormik({ field: { name }, form: { setFieldValue }, recuperar, adicionar, tipo }) {
  const [exibir, setExibir] = useState(false)
  const [url, setUrl] = useState(false)

  const { valores } = useContext(ContextoDeFormulario)
  const guid = recuperarValor(name, valores)

  const adicionarImagem = evento => {
    if (!evento.target.files[0]) return

    const arquivo = evento.target.files[0]
    const leitor = new FileReader();

    leitor.onloadend = async () => {
      const imagem = await adicionar(arquivo, tipo)

      if (imagem) {
        setFieldValue(name, imagem.identificador)
      } else {
        setFieldValue(name, '')
      }
    }

    leitor.readAsDataURL(arquivo)
  }

  const confirmarERecuperar = () => {
    confirmAlert({
      title: 'Alerta',
      message: `Para visualizar a imagem é necessário salvar o registro.`,
      buttons: [{
        label: 'OK'
      }]
    })
  }

  const removerImagem = () => {
    setFieldValue(name, undefined)
  }

  const abrirImagem = async () => {
    if (recuperar) {
      const response = await recuperar()

      if (!response) {
        return
      }

      setUrl(response)
      setExibir(true)
      return
    }

    confirmarERecuperar()
  }

  function fecharImagem() {
    setExibir(false)
  }

  function ExibirImagem({ fechar, url }) {
    return (
      <div className='modal-imagem' onClick={fechar}>
        {url && <img alt='imagem' src={url} />}
      </div>
    )
  }

  const exibirAdicionar = !guid && adicionar

  return (
    <>
      <Modal
        isOpen={exibir}
        className='estilo-do-modal-imagem'
      >
        <ExibirImagem
          url={url}
          fechar={fecharImagem}
        />
      </Modal>
      <div>
        {exibirAdicionar ?
          <>
            <label
              htmlFor='anexo'
              className='formik__botao-adicionar-imagem'
            >
              Adicionar Imagem
              <i className='icon icon-doc ml-1'></i>
            </label>
            <input
              accept='image/*'
              type='file'
              name='anexo'
              id='anexo'
              onChange={(evento) => adicionarImagem(evento)}
              onClick={evento => { evento.target.value = null }}
            />
          </>
          :
          <div className='list-btn'>
            <label
              htmlFor='anexo'
              className='formik__botao-adicionar-imagem'
              onClick={abrirImagem}
            >
              Visualizar Imagem
              <i className='icon icon-search ml-1'></i>
            </label>
            <label
              htmlFor='anexo'
              className='formik__botao-adicionar-imagem is-danger'
              onClick={removerImagem}
            >
              Remover Imagem
              <i className='icon icon-remove ml-1'></i>
            </label>
          </div>
        }
      </div>
    </>
  )
}

export function InputFoto({ nome, titulo, ...rest }) {
  return (
    <Field
      {...rest}
      name={nome}
      component={InputFotoFormik}
    />
  )
}

function InputFotoFormik({ bytes, field: { name }, form: { setFieldValue }, selecionar, remover, recuperarFotoOriginal, habilitarFuncoes, carregandoFoto }) {
  const [foto, setFoto] = useState(null)
  const [exibir, setExibir] = useState(false)
  const [bytesDaFoto, setBytesDaFoto] = useState(null)

  useEffect(() => {
    setBytesDaFoto(bytes)
  }, [setBytesDaFoto, bytes])

  const selecionarImagem = evento => {
    if (!evento.target.files[0]) return

    const arquivo = evento.target.files[0]
    const leitor = new FileReader();

    leitor.onloadend = async () => {
      setFoto(leitor.result)
      const identificador = await selecionar(arquivo)

      if (identificador && !carregandoFoto) {
        setFieldValue(name, identificador)
        setBytesDaFoto(null)
      } else {
        setFoto(null)
        setFieldValue(name, '')
      }
    }

    leitor.readAsDataURL(arquivo)
  }

  const removerFoto = () => {
    setBytesDaFoto(null)
    setFoto(null)
    remover()
  }

  function abrirImagem() {
    setExibir(true)
  }

  function fecharImagem() {
    setExibir(false)
  }

  function ExibirImagem({ exibir, foto, fechar, recuperarFotoOriginal }) {
    const [fotoOriginal, setFotoOriginal] = useState(false)

    useEffect(() => {
      const recuperar = async () => {
        const response = await recuperarFotoOriginal()
        setFotoOriginal(response)
      }

      exibir && recuperarFotoOriginal && recuperar()

    }, [exibir, recuperarFotoOriginal])

    return (
      <div className='modal-foto'>
        <i className='icon icon-close modal-foto' onClick={fechar}></i>
        {!fotoOriginal && <img alt='foto' src={foto} />}
        {fotoOriginal && <img alt='foto' src={fotoOriginal} />}
      </div>
    )
  }

  return (
    <>
      <Modal
        isOpen={exibir}
        className='estilo-do-modal-foto'
      >
        <ExibirImagem
          exibir={exibir}
          foto={bytesDaFoto || foto}
          fechar={fecharImagem}
          recuperarFotoOriginal={recuperarFotoOriginal}
        />
      </Modal>
      <div>
        {!(bytesDaFoto || foto) ?
          <div className='avatar-upload'>
            <label
              htmlFor='avatar'
              className={habilitarFuncoes ? 'avatar-upload__thumb' : 'avatar-upload__thumb-none'}
              style={{ backgroundImage: `url(${noThumb})` }}>
            </label>
            {habilitarFuncoes &&
              <input
                accept='image/*'
                type='file'
                name={name}
                id='avatar'
                onChange={selecionarImagem}
              />
            }
          </div>
          :
          <div className='form-summary'>
            <div className='form-summary-images input-foto'>
              <div className='form-summary-image'>
                <img src={bytesDaFoto || foto} alt='foto' />
                <div className='form-summary-image-tools'>
                  <button className='form-summary-image-zoom' aria-label='Ampliar' type='button' onClick={abrirImagem}><i className='icon icon-search'></i></button>
                  {habilitarFuncoes && <button className='form-summary-image-remove' aria-label='Remover' type='button' onClick={removerFoto}><i className='icon icon-remove'></i></button>}
                </div>
              </div>
            </div>
          </div>
        }
        {carregandoFoto && 'Carregando...'}
      </div>
    </>
  )
}

export function Telefone(props) {
  return <InputMask placeholder='(99) 99999-9999' mascara={['(99) 99999-9999', '(99) 9999-9999?']} ehTelefone={true} {...props} />
}

export function Cpf(props) {
  return <InputMask placeholder='999.999.999-99' mascara={['999.999.999-99']} {...props} />
}

export function Cep(props) {
  return <InputMask placeholder='99999-999' mascara={['99999-999']} {...props} />
}

export function CampoPrescricao({ nome, titulo, dados = [], dadosUsuarios = [], validar, placeholder, children, ...rest }) {
  const { erros, tocados } = useContext(ContextoDeFormulario)
  const tocado = recuperarValor(nome, tocados)
  const erro = recuperarValor(nome, erros)

  return (
    <div className={`form-group form-prescricao ${(erro && tocado) && 'has-error'}`}>
      <div className='componente-react-mentions-prescricao'>
        <label htmlFor={nome}>{titulo}</label>
        {children}
      </div>
      <div className='form-prescricao-box'>
        <Field
          {...rest}
          name={nome}
          component={InputMensionFormik}
          validate={validar}
          dados={dados}
          dadosUsuarios={dadosUsuarios}
          placeholder={placeholder}
        />
        <div className='delimitador-da-prescricao-a5'></div>
        <span className='texto-delimitador-a5'>A5</span>
        <div className='delimitador-da-prescricao-a4'></div>
        <span className='texto-delimitador-a4'>A4</span>
      </div>
      <ErrorMessage name={nome} className='form-tip' component='span' />
    </div>
  )
}

export function InputSugestao({ nome, titulo, dados = [], dadosUsuarios = [], validar, placeholder, children, modal = false, ...rest }) {
  const { erros, tocados } = useContext(ContextoDeFormulario)
  const tocado = recuperarValor(nome, tocados)
  const erro = recuperarValor(nome, erros)

  return (
    <div className={`form-group ${(erro && tocado) && 'has-error'}`}>
      <div className={`componente-react-mentions${modal ? '-modal' : ''}`}>
        <label htmlFor={nome}>{titulo}</label>
        {children}
      </div>
      <Field
        {...rest}
        name={nome}
        component={InputMensionFormik}
        validate={validar}
        dados={dados}
        dadosUsuarios={dadosUsuarios}
        placeholder={placeholder}
      />
      <ErrorMessage name={nome} className='form-tip' component='span' />
    </div>
  )
}

function InputMensionFormik(props) {
  const {
    field: { name },
    form: { setFieldValue },
    dados,
    dadosUsuarios,
    titulo,
    tags = false,
    disabled = false,
    ...rest
  } = props

  const [selection, setSelection] = useState({ range: false, start: 0, end: 0 })
  const elemento = document.getElementById(name)

  const onKeyDown = keyEvent => {
    if (disabled) {
      keyEvent.preventDefault();
    }

    if ((keyEvent.charCode || keyEvent.keyCode) === 86 || // v
      (keyEvent.charCode || keyEvent.keyCode) === 88) { // x
      setSelection({ ...selection, range: true })
    }

    keyEvent.stopPropagation();
  }

  const onChange = (event, newValue, newPlainTextValue, mentions) => {
    const result = tags ? newValue : newPlainTextValue

    setFieldValue(name, result)
    rest.onChange && rest.onChange(event)

    if (selection.range) {
      setSelection({ ...selection, range: false })
      elemento.setSelectionRange(selection.start + 1, selection.start + 1)
    }
  }

  const onSelection = evento => {
    setSelection({
      start: evento.target.selectionStart,
      end: evento.target.selectionEnd
    });
  }

  return (
    <InputMension
      {...rest}
      {...props.field}
      handleChange={onChange}
      handleSelection={onSelection}
      handleKeyDown={onKeyDown}
      dados={dados}
      dadosUsuarios={dadosUsuarios}
      disabled={disabled}
    />
  )
}
export function MultiplaEscolha({ nome, pergunta, opcoes, renderizarTitulo, validar, leitura = false, disabled = false, ...rest }) {
  if (leitura) {
    return (
      <div className={rest.className}>
        <strong>{pergunta}</strong>
        <r-cell span={4} span-md={6} span-lg={12} class='respostas-leitura'>
          <>{opcoes.map(x => <div className='mtp-5' key={`${nome}_${x.codigo}`}>{x.codigo === rest.valor ? '(x)' : <>(&nbsp;&nbsp;)</>} {renderizarTitulo(x)} <br /></div>)}</>
        </r-cell>
      </div>
    )
  }

  return (
    <Field
      {...rest}
      component={MultiplaEscolhaFormik}
      name={nome}
      pergunta={pergunta}
      opcoes={opcoes}
      renderizarTitulo={renderizarTitulo}
      validate={validar}
      disabled={disabled}
    />
  )
}

function MultiplaEscolhaFormik(props) {
  const { pergunta, opcoes = [], renderizarTitulo, disabled, field: { name }, form: { setFieldValue, errors, touched }, ...rest } = props

  const tocado = recuperarValor(name, touched)
  const erro = recuperarValor(name, errors)

  const onChange = valor => {
    setFieldValue(name, valor)
    rest.onChange && rest.onChange(name, valor)
  }

  return (
    <div className={`form-group ${(erro && tocado) && 'has-error'} ${rest.className}`}>
      <div><strong>{pergunta}</strong></div>
      <r-cell span={4} span-md={6} span-lg={12} class='respostas'>
        {opcoes.map(x =>
          <div key={`${name}_${x.codigo}`}>
            <Radio
              nome={name}
              titulo={renderizarTitulo(x)}
              valor={x.codigo}
              onChange={() => onChange(x.codigo)}
              disabled={disabled}
            />
          </div>
        )}
      </r-cell>
      <ErrorMessage name={name} className='form-tip' component='span' />
    </div>
  )
}

export function MultiplaSelecao({ nome, pergunta, opcoes, renderizarTitulo, validar, leitura = false, disabled = false, valores = [], ...rest }) {
  if (leitura) {
    return (
      <div className={rest.className}>
        <strong>{pergunta}</strong>
        <r-cell span={4} span-md={6} span-lg={12} class='respostas-leitura'>
          <>{opcoes.map(x => <div className='mtp-5' key={`${nome}_${x.codigo}`}>{rest.valor?.includes(x.codigo) ? '(x)' : <>(&nbsp;&nbsp;)</>} {renderizarTitulo(x)} <br /></div>)}</>
        </r-cell>
      </div>
    )
  }

  return (
    <Field
      {...rest}
      component={MultiplaSelecaoFormik}
      name={nome}
      pergunta={pergunta}
      opcoes={opcoes}
      renderizarTitulo={renderizarTitulo}
      validate={validar}
      disabled={disabled}
      valores={valores}
    />
  )
}

function MultiplaSelecaoFormik(props) {
  const { pergunta, opcoes = [], renderizarTitulo, disabled, valores, field: { name, value }, form: { setFieldValue, errors, touched, setFieldTouched }, ...rest } = props

  const tocado = recuperarValor(name, touched)
  const erro = recuperarValor(name, errors)

  const onChange = opcoes => {
    setFieldValue(name, opcoes)
    rest.onChange && rest.onChange(opcoes)
  }

  const aoClicar = opcao => {
    let opcoes = []

    if (value.includes(opcao)) {
      opcoes = value.filter(x => x !== opcao)
    } else {
      opcoes = [...value, opcao]
    }

    onChange(opcoes)
    setFieldTouched(name, true)
  }

  return (
    <div className={`form-group ${(erro && tocado) && 'has-error'} ${rest.className}`}>
      <div><strong>{pergunta}</strong></div>
      <r-cell span={4} span-md={6} span-lg={12} class='respostas'>
        {opcoes.map(x =>
          <div className={`checkbox ${disabled && 'is-disabled'}`} key={x.codigo}>
            <input
              className='checkbox-input'
              type='checkbox'
              value={x.codigo}
              id={x.codigo}
              name={x.codigo}
              onChange={(evento) => aoClicar(evento.target.value)}
              disabled={disabled}
              checked={valores.includes(x.codigo)}
            />
            <label className='checkbox-label' htmlFor={x.codigo}><span>{x.nome}</span></label>
          </div>
        )}
      </r-cell>
      <ErrorMessage name={name} className='form-tip' component='span' />
    </div>
  )
}