import React, { useState, createContext, useContext, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
const ContextoDasAbas = createContext()

export function Abas({ children }) {
  const [indexAtivo, setIndexAtivo] = useState(0)
  
  return (
    <ContextoDasAbas.Provider
      value={{
        indexAtivo,
        selecionarIndexAtivo: setIndexAtivo
      }}
    >
      {children}
    </ContextoDasAbas.Provider>
  )
}

export function ListaDeAbas(props) {
  const { indexAtivo, selecionarIndexAtivo } = useContext(ContextoDasAbas)

  const children = React.Children.map(props.children, (child, index) =>
    child &&
    React.cloneElement(child, {
      estahAtiva: index === indexAtivo,
      aoSelecionar: () => selecionarIndexAtivo(index),
      statusDaSecao: props.statusDaSecao
    })
  )

  return (
    <>
      <div className={props.className}>
        {children}
      </div>
    </>
  )
}

export function Aba({ aoSelecionar, children, estahAtiva, statusDaSecao, title, aoClicar, index }) {
  const clicar = () => {
    aoClicar && aoClicar()
    aoSelecionar()
  }

  return (
    <div key='aba' >
      <button
        className={`pills-nav__item ${estahAtiva ? 'is-active' : (statusDaSecao && statusDaSecao.includes(children)) ? 'dados-nao-salvos' : ''}`}
        type='button'
        onClick={clicar}
        title={title}
        data-tip
        data-for={`info_${children}`}
        id={index === 0 && 'primeira-aba'}
      >
        {children}
      </button>
      {!estahAtiva && statusDaSecao && statusDaSecao.includes(children) &&
        <ReactTooltip
          id={`info_${children}`}
          place='right'
          effect='solid'
          type='error'
          key={children}
        >
          <span>{`A seção possui dados que não foram salvos.`}</span>
        </ReactTooltip>
      }
    </div>
  )
}

export function Paineis({ children }) {
  const { indexAtivo } = useContext(ContextoDasAbas)
  const [itensCarregados, setItensCarregados] = useState([])

  useEffect(() => {
    if (!itensCarregados.includes(indexAtivo)) {
      setItensCarregados([...itensCarregados, indexAtivo])
    }
  }, [itensCarregados, indexAtivo])

  return React.Children.map(children, (item, index) =>
    itensCarregados.includes(index) && <div style={{ display: index === indexAtivo ? 'block' : 'none' }}>{item}</div>
  )
}

export function Painel({ children }) {
  return children
}