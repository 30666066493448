import {
  RECUPEROU_CABECALHO_DO_PRONTUARIO,
  RECUPEROU_ATENDIMENTO,
  INICIOU_ATENDIMENTO,
  LISTOU_ATENDIMENTOS_FINALIZADOS,
  LISTOU_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE,
  CANCELOU_ATENDIMENTO,
  FINALIZOU_ATENDIMENTO,
  SALVOU_SECAO_DO_ATENDIMENTO,
  ADICIONOU_INFORMACOES,
  CARREGOU_DETALHES_DO_ATENDIMENTO_FINALIZADO,
  ADICIONOU_TAG_NO_PACIENTE_DO_PRONTUARIO,
  REMOVEU_TAG_DO_PACIENTE_DO_PRONTUARIO,
  SELECIONAR_ATENDIMENTO_DA_TIMELINE,
  ALTERAR_TERMO_DA_TIMELINE,
  LISTOU_VACINAS_DO_PACIENTE,
  LISTOU_QUEDAS_DO_PACIENTE,
  LISTOU_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE,
  LISTOU_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  LISTOU_INTERNACOES_DO_PACIENTE,
  LISTOU_IVCFS_DO_PACIENTE,
  LISTOU_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  LISTOU_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE,
  LISTOU_CASPS_16_DO_PACIENTE,
  LISTOU_AVALIACOES_DA_SOLIDAO_DO_PACIENTE,
  LISTOU_CONSULTAS_EXTERNAS_DO_PACIENTE,
  ASSINOU_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM,
  FINALIZOU_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO,
  LISTOU_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  MARCAR_ATENDIMENTO_COMO_PRIVADO,
  LISTOU_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE,
  LISTOU_MINI_MENTAIS,
  LISTOU_PHQS9,
  LISTOU_MEDICAMENTOS_DO_PACIENTE,
  ALTEROU_POSICAO_DOS_MEDICAMENTOS_DO_PACIENTE,
  LISTOU_DIAGNOSTICOS_DO_PACIENTE,
  ALTEROU_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE
} from '../../acoes/tipos'

const estadoInicial = {
  atendimento: null,
  cabecalho: {
    
    identificacao: {},
  },
  gestaoDeCondicoesClinicas: {
    consultasExternas: undefined,
    idasAoProntoAtendimento: undefined,
    infeccoesDoTratoUrinario: undefined,
    internacoes: undefined,
    quedas: undefined,
    registrosDocumentaisDoPsicologo: undefined,
    vacinas: undefined,
    medicamentos: undefined,
    diagnosticos: undefined,
    questionarios: {
      avaliacoesDaSobrecargaDoCuidador: undefined,
      avaliacoesFisicasEFuncionais: undefined,
      avaliacoesDaSolidao: undefined,
      casps16: undefined,
      escalasAmbientaisDeRiscoDeQuedas: undefined,
      ivcfs: undefined,
      miniMentais: undefined,
      phqs9: undefined
    }
  },
  timeline: { termo: '' },
  historicoDeCampos: {}
}

export default (state = {}, action) => {
  switch (action.type) {
    case RECUPEROU_CABECALHO_DO_PRONTUARIO: {
      const prontuario = state[action.identificadorDoPaciente] || estadoInicial

      return {
        ...state,
        [action.identificadorDoPaciente]: {
          ...prontuario,
          cabecalho: {
            ...prontuario.cabecalho,
            ...action.cabecalho,
            identificacao: {
              ...action.cabecalho.identificacao,
              foto: null,
              identificadorDaFoto: action.cabecalho.identificacao.foto
            },
            identificadorDoPaciente: action.identificadorDoPaciente,
          }
        },
      }
    }

    case LISTOU_ATENDIMENTOS_FINALIZADOS: {
      const { identificadorDoPaciente, atendimentos } = action
      const prontuario = state[action.identificadorDoPaciente] || estadoInicial

      return {
        ...state,
        [identificadorDoPaciente]: {
          ...prontuario,
          cabecalho: {
            ...prontuario.cabecalho,
            timeline: atendimentos.filter(x => x.status.toUpperCase() !== 'CANCELADO APÓS FINALIZADO')
          },
          timeline: {
            historico: atendimentos.map(atendimento => ({
              _status: 'nao_carregado',
              ...atendimento
            })),
            termo: prontuario.timeline.termo
          }
        }
      }
    }

    case LISTOU_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE: {
      const prontuario = state[action.identificadorDoPaciente] || estadoInicial

      const { prescricoes_da_memed, ...historicoDeCampos } = action.historicoDeCampos

      const historico = historicoDeCampos.prescricoes
        .concat(prescricoes_da_memed)
        .map(x => ({ ...x, origem: x.identificadorExterno ? 'Memed' : 'LifeCode' }))
        .sort((a, b) => {
          if (a.atendimento.inicio < b.atendimento.inicio) {
            return 1;
          }
          if (a.atendimento.inicio > b.atendimento.inicio) {
            return -1;
          }
          return 0
        })

      return {
        ...state,
        [action.identificadorDoPaciente]: {
          ...prontuario,
          historicoDeCampos: {
            ...historicoDeCampos,
            prescricoes: historico
          }
        }
      }
    }

    case RECUPEROU_ATENDIMENTO: {
      const prontuario = state[action.identificadorDoPaciente] || estadoInicial

      return {
        ...state,
        [action.identificadorDoPaciente]: {
          ...prontuario,
          atendimento: action.atendimento,
        }
      }
    }

    case INICIOU_ATENDIMENTO: {
      const { identificadorDoPaciente, atendimento } = action
      const prontuario = state[identificadorDoPaciente]

      if (!prontuario) {
        return state
      }

      return {
        ...state,
        [identificadorDoPaciente]: {
          ...prontuario,
          atendimento,
        },
      }
    }

    case CANCELOU_ATENDIMENTO: {
      const { identificadorDoPaciente, atendimento, status } = action
      const prontuario = state[identificadorDoPaciente]
      const atendimentosCarregados = prontuario.timeline.atendimentosCarregados
      const identificadorDoAtendimento = atendimento.identificador
      const index = prontuario.timeline.historico.findIndex(x => x.identificador === identificadorDoAtendimento)

      if (!prontuario) {
        return state
      }

      if (status === 'cancelado_antes_de_finalizar') {
        return {
          ...state,
          [identificadorDoPaciente]: {
            ...prontuario,
            atendimento: null,
          }
        }
      }

      return {
        ...state,
        [identificadorDoPaciente]: {
          ...prontuario,
          timeline: {
            ...prontuario.timeline,
            historico: prontuario.timeline.historico.fill(atendimento, index, index + 1),
            atendimentosCarregados: {
              ...atendimentosCarregados,
              [identificadorDoAtendimento]: {
                ...atendimentosCarregados[identificadorDoAtendimento],
                atendimento: {
                  ...atendimento
                }
              }
            }
          }
        }
      }
    }

    case FINALIZOU_ATENDIMENTO: {
      const { identificadorDoPaciente } = action
      const prontuario = state[identificadorDoPaciente]

      if (!prontuario) {
        return state
      }

      return {
        ...state,
        [identificadorDoPaciente]: {
          ...prontuario,
          atendimento: null,
        },
      }
    }

    case SALVOU_SECAO_DO_ATENDIMENTO: {
      const { identificadorDoPaciente } = action
      const prontuario = state[identificadorDoPaciente]

      if (!prontuario) {
        return state
      }

      return {
        ...state,
        [identificadorDoPaciente]: {
          ...prontuario,
          atendimento: {
            ...action.secaoDoAtendimento,
          }
        },
      }
    }

    case SELECIONAR_ATENDIMENTO_DA_TIMELINE: {
      const { atendimento } = action
      const identificadorDoPaciente = atendimento.paciente.identificador

      const prontuario = state[identificadorDoPaciente] || estadoInicial

      return {
        ...state,
        [identificadorDoPaciente]: {
          ...prontuario,
          timeline: {
            ...prontuario.timeline,
            atendimentoSelecionado: atendimento,
          }
        }
      }

    }

    case CARREGOU_DETALHES_DO_ATENDIMENTO_FINALIZADO: {
      const { identificadorDoPaciente, atendimento, secoes } = action
      const prontuario = state[identificadorDoPaciente] || estadoInicial

      const timeline = [...prontuario.timeline.historico]

      let atendimentoFinalizado = timeline.find(x => x.identificador === atendimento.identificador)

      if (atendimentoFinalizado) {
        atendimentoFinalizado._status = 'carregado'
      }

      return {
        ...state,
        [identificadorDoPaciente]: {
          ...prontuario,
          timeline: {
            ...prontuario.timeline,
            historico: timeline,
            atendimentoSelecionado: atendimento,
            atendimentosCarregados: {
              ...prontuario.timeline.atendimentosCarregados,
              [atendimento.identificador]: {
                atendimento: atendimento,
                secoes: secoes
              }
            }
          }
        }
      }
    }

    case MARCAR_ATENDIMENTO_COMO_PRIVADO: {
      const { identificadorDoPaciente, identificadorDoAtendimento, profissional } = action
      const atendimento = { identificador: identificadorDoAtendimento, paciente: { identificador: identificadorDoPaciente }, privado: true, profissional }
      const prontuario = state[identificadorDoPaciente] || estadoInicial
      const timeline = [...prontuario.timeline.historico]

      let atendimentoFinalizado = timeline.find(x => x.identificador === identificadorDoAtendimento)

      if (atendimentoFinalizado) {
        atendimentoFinalizado._status = 'carregado'
      }

      return {
        ...state,
        [identificadorDoPaciente]: {
          ...prontuario,
          historico: timeline,
          timeline: {
            ...prontuario.timeline,
            atendimentoSelecionado: atendimento,
            atendimentosCarregados: {
              ...prontuario.timeline.atendimentosCarregados,
              [identificadorDoAtendimento]: {
                atendimento: atendimento,
                secoes: [],
              }
            }
          }
        }
      }
    }

    case ADICIONOU_INFORMACOES: {
      const { identificadorDoAtendimento, informacoesAdicionais, identificadorDoPaciente } = action
      const prontuario = state[identificadorDoPaciente] || estadoInicial
      const atendimentosCarregados = prontuario.timeline.atendimentosCarregados

      return {
        ...state,
        [identificadorDoPaciente]: {
          ...prontuario,
          timeline: {
            ...prontuario.timeline,
            atendimentosCarregados: {
              ...atendimentosCarregados,
              [identificadorDoAtendimento]: {
                ...atendimentosCarregados[identificadorDoAtendimento],
                atendimento: {
                  ...atendimentosCarregados[identificadorDoAtendimento].atendimento,
                  informacoesAdicionais: [
                    ...atendimentosCarregados[identificadorDoAtendimento].atendimento.informacoesAdicionais,
                    informacoesAdicionais
                  ]
                }
              }
            }
          }
        }
      }
    }

    case REMOVEU_TAG_DO_PACIENTE_DO_PRONTUARIO: {
      const prontuario = state[action.identificadorDoPaciente] || estadoInicial

      return {
        ...state,
        [action.identificadorDoPaciente]: {
          ...prontuario,

          cabecalho: {
            ...prontuario.cabecalho,
            identificacao: {
              ...prontuario.cabecalho.identificacao,
              tags: prontuario.cabecalho.identificacao.tags.filter(x => x.tag !== action.tag)
            }
          }
        },
      }
    }

    case ADICIONOU_TAG_NO_PACIENTE_DO_PRONTUARIO: {
      const prontuario = state[action.identificadorDoPaciente] || estadoInicial

      return {
        ...state,
        [action.identificadorDoPaciente]: {
          ...prontuario,

          cabecalho: {
            ...prontuario.cabecalho,
            identificacao: {
              ...prontuario.cabecalho.identificacao,
              tags: [...prontuario.cabecalho.identificacao.tags, action.tag]
            }
          }
        },
      }
    }

    case ALTERAR_TERMO_DA_TIMELINE: {
      const { identificadorDoPaciente, termo } = action
      const prontuario = state[action.identificadorDoPaciente] || estadoInicial

      return {
        ...state,
        [identificadorDoPaciente]: {
          ...prontuario,
          timeline: {
            ...prontuario.timeline,
            termo: termo
          }
        }
      }
    }

    case LISTOU_VACINAS_DO_PACIENTE: {
      const prontuario = state[action.identificadorDoPaciente] || estadoInicial

      return {
        [action.identificadorDoPaciente]: {
          ...prontuario,
          gestaoDeCondicoesClinicas: {
            ...prontuario.gestaoDeCondicoesClinicas,
            vacinas: action.vacinas
          }
        }
      }
    }

    case LISTOU_CONSULTAS_EXTERNAS_DO_PACIENTE: {
      const prontuario = state[action.identificadorDoPaciente] || estadoInicial

      return {
        [action.identificadorDoPaciente]: {
          ...prontuario,
          gestaoDeCondicoesClinicas: {
            ...prontuario.gestaoDeCondicoesClinicas,
            consultasExternas: action.consultasExternas
          }
        }
      }
    }

    case LISTOU_QUEDAS_DO_PACIENTE: {
      const prontuario = state[action.identificadorDoPaciente] || estadoInicial

      return {
        [action.identificadorDoPaciente]: {
          ...prontuario,
          gestaoDeCondicoesClinicas: {
            ...prontuario.gestaoDeCondicoesClinicas,
            quedas: action.quedas
          }
        }
      }
    }

    case LISTOU_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE: {
      const prontuario = state[action.identificadorDoPaciente] || estadoInicial

      return {
        [action.identificadorDoPaciente]: {
          ...prontuario,
          gestaoDeCondicoesClinicas: {
            ...prontuario.gestaoDeCondicoesClinicas,
            registrosDocumentaisDoPsicologo: action.registrosDocumentais
          }
        }
      }
    }

    case LISTOU_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE: {
      const prontuario = state[action.identificadorDoPaciente] || estadoInicial

      return {
        [action.identificadorDoPaciente]: {
          ...prontuario,
          gestaoDeCondicoesClinicas: {
            ...prontuario.gestaoDeCondicoesClinicas,
            infeccoesDoTratoUrinario: action.infeccoes
          }
        }
      }
    }

    case LISTOU_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE: {
      const prontuario = state[action.identificadorDoPaciente] || estadoInicial

      return {
        [action.identificadorDoPaciente]: {
          ...prontuario,
          gestaoDeCondicoesClinicas: {
            ...prontuario.gestaoDeCondicoesClinicas,
            idasAoProntoAtendimento: action.idasAoPa
          }
        }
      }
    }

    case LISTOU_INTERNACOES_DO_PACIENTE: {
      const prontuario = state[action.identificadorDoPaciente] || estadoInicial

      return {
        [action.identificadorDoPaciente]: {
          ...prontuario,
          gestaoDeCondicoesClinicas: {
            ...prontuario.gestaoDeCondicoesClinicas,
            internacoes: action.internacoes
          }
        }
      }
    }

    case LISTOU_IVCFS_DO_PACIENTE: {
      const prontuario = state[action.identificadorDoPaciente] || estadoInicial

      return {
        [action.identificadorDoPaciente]: {
          ...prontuario,
          gestaoDeCondicoesClinicas: {
            ...prontuario.gestaoDeCondicoesClinicas,
            questionarios: {
              ...prontuario.gestaoDeCondicoesClinicas.questionarios,
              ivcfs: action.ivcfs
            }
          }
        }
      }
    }

    case LISTOU_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE: {
      const prontuario = state[action.identificadorDoPaciente] || estadoInicial

      return {
        [action.identificadorDoPaciente]: {
          ...prontuario,
          gestaoDeCondicoesClinicas: {
            ...prontuario.gestaoDeCondicoesClinicas,
            questionarios: {
              ...prontuario.gestaoDeCondicoesClinicas.questionarios,
              avaliacoesDaSobrecargaDoCuidador: action.avaliacoes
            }
          }
        }
      }
    }

    case LISTOU_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE: {
      const prontuario = state[action.identificadorDoPaciente] || estadoInicial

      return {
        [action.identificadorDoPaciente]: {
          ...prontuario,
          gestaoDeCondicoesClinicas: {
            ...prontuario.gestaoDeCondicoesClinicas,
            questionarios: {
              ...prontuario.gestaoDeCondicoesClinicas.questionarios,
              avaliacoesFisicasEFuncionais: action.avaliacoes
            }
          }
        }
      }
    }

    case LISTOU_CASPS_16_DO_PACIENTE: {
      const prontuario = state[action.identificadorDoPaciente] || estadoInicial

      return {
        [action.identificadorDoPaciente]: {
          ...prontuario,
          gestaoDeCondicoesClinicas: {
            ...prontuario.gestaoDeCondicoesClinicas,
            questionarios: {
              ...prontuario.gestaoDeCondicoesClinicas.questionarios,
              casps16: action.casps16
            }
          }
        }
      }
    }

    case LISTOU_AVALIACOES_DA_SOLIDAO_DO_PACIENTE: {
      const prontuario = state[action.identificadorDoPaciente] || estadoInicial

      return {
        [action.identificadorDoPaciente]: {
          ...prontuario,
          gestaoDeCondicoesClinicas: {
            ...prontuario.gestaoDeCondicoesClinicas,
            questionarios: {
              ...prontuario.gestaoDeCondicoesClinicas.questionarios,
              avaliacoesDaSolidao: action.avaliacoesDaSolidao
            }
          }
        }
      }
    }

    case LISTOU_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE: {
      const prontuario = state[action.identificadorDoPaciente] || estadoInicial

      return {
        [action.identificadorDoPaciente]: {
          ...prontuario,
          gestaoDeCondicoesClinicas: {
            ...prontuario.gestaoDeCondicoesClinicas,
            questionarios: {
              ...prontuario.gestaoDeCondicoesClinicas.questionarios,
              escalasAmbientaisDeRiscoDeQuedas: action.escalas
            }
          }
        }
      }
    }

    case LISTOU_MINI_MENTAIS: {
      const prontuario = state[action.identificadorDoPaciente] || estadoInicial

      return {
        [action.identificadorDoPaciente]: {
          ...prontuario,
          gestaoDeCondicoesClinicas: {
            ...prontuario.gestaoDeCondicoesClinicas,
            questionarios: {
              ...prontuario.gestaoDeCondicoesClinicas.questionarios,
              miniMentais: action.miniMentais
            }
          }
        }
      }
    }

    case LISTOU_PHQS9: {
      const prontuario = state[action.identificadorDoPaciente] || estadoInicial

      return {
        [action.identificadorDoPaciente]: {
          ...prontuario,
          gestaoDeCondicoesClinicas: {
            ...prontuario.gestaoDeCondicoesClinicas,
            questionarios: {
              ...prontuario.gestaoDeCondicoesClinicas.questionarios,
              phqs9: action.phqs9
            }
          }
        }
      }
    }

    case ASSINOU_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM:
    case FINALIZOU_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO: {
      const { atendimento } = action.dados
      const prontuario = state?.[atendimento?.paciente?.identificador];

      if (!prontuario) {
        return state
      }

      const timeline = [atendimento, ...prontuario.timeline.historico.filter(t => t.identificador !== atendimento.identificador)]

      const atendimentoSelecionado = {
        ...prontuario.timeline.atendimentoSelecionado,
        assinaturasDigitais: [{}]
      }

      return {
        ...state,
        [atendimento.paciente.identificador]: {
          ...prontuario,
          timeline: {
            ...prontuario.timeline,
            historico: timeline,
            atendimentoSelecionado,
            atendimentosCarregados: {
              ...prontuario.timeline.atendimentosCarregados,
              [atendimento.identificador]: {
                ...prontuario.timeline.atendimentosCarregados[atendimento.identificador],
                atendimento: atendimentoSelecionado,
              }
            }
          }
        }
      }

    }

    case LISTOU_MEDICAMENTOS_DO_PACIENTE: {
      const prontuario = state[action.identificadorDoPaciente] || estadoInicial

      return {
        [action.identificadorDoPaciente]: {
          ...prontuario,
          gestaoDeCondicoesClinicas: {
            ...prontuario.gestaoDeCondicoesClinicas,
            medicamentos: action.medicamentosDoPaciente
          }
        }
      }
    }

    case ALTEROU_POSICAO_DOS_MEDICAMENTOS_DO_PACIENTE: {
      const { identificadorDoPaciente, itensOrdenados } = action
      const prontuario = state[identificadorDoPaciente] || estadoInicial      
    
      itensOrdenados.forEach((medicamento, i) => { medicamento.posicao = i + 1 })
    
      return {
        ...state,
        [identificadorDoPaciente]: {
          ...prontuario,
          gestaoDeCondicoesClinicas: {
            ...prontuario.gestaoDeCondicoesClinicas,
            medicamentos: [...itensOrdenados]
          }
        }
      }
    }

    case LISTOU_DIAGNOSTICOS_DO_PACIENTE: {
      const prontuario = state[action.identificadorDoPaciente] || estadoInicial

      return {
        [action.identificadorDoPaciente]: {
          ...prontuario,
          gestaoDeCondicoesClinicas: {
            ...prontuario.gestaoDeCondicoesClinicas,
            diagnosticos: action.diagnosticosDoPaciente
          }
        }
      }
    }

    case ALTEROU_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE: {
      const { identificadorDoPaciente, itensOrdenados } = action
      const prontuario = state[identificadorDoPaciente] || estadoInicial      
    
      itensOrdenados.forEach((diagnostico, i) => { diagnostico.posicao = i + 1 })
    
      return {
        ...state,
        [identificadorDoPaciente]: {
          ...prontuario,
          gestaoDeCondicoesClinicas: {
            ...prontuario.gestaoDeCondicoesClinicas,
            diagnosticos: [...itensOrdenados]
          }
        }
      }
    }

    default: {
      return state
    }
  }
}