import { connect } from 'react-redux'
import { ProvedorDeContextoDoProntuario } from './index'
import acoes from '../../../acoes'

const mapStateToProps = (state, props) => {
  return {
    antibioticos: state.antibioticos,
    arquivosDaInternacao: state.arquivosDaInternacao,
    arquivosDoExame: state.arquivosDoExame,
    avaliacoesDaEscalaZarit: state.prontuario.zarit.avaliacoes,
    duracoesDasDores: state.duracoesDasDores,
    estabelecimentosDeSaude: state.estabelecimentosDeSaude.itens.filter(x => x.status === 'Aprovado'),
    especialidadesMedicas: state.profissoes.especialidadesMedicas,
    especialidadesMedicasInternas: state.encaminhamentos.especialidadesMedicasInternas,
    estadosDaMemoriaAtual: state.estadosDaMemoriaAtual,
    estadosDaMemoriaComparadoHaUmAnoAtras: state.estadosDaMemoriaComparadoHaUmAnoAtras,
    execucoesDoTesteDeFisioterapia: state.execucoesDoTesteDeFisioterapia,
    fabricantes: state.fabricantes.itens,
    frequenciasNaEscalaDaSolidao: state.prontuario.escalaDaSolidao,
    frequenciasNaEscalaZarit: state.prontuario.zarit.frequencias,
    gruposDeCuidado: state.gruposDeCuidado.itens,
    intensidadesDasDores: state.intensidadesDasDores,
    locaisDaAplicacao: state.locaisDaAplicacao,
    locaisDasDores: state.locaisDasDores,
    locaisDeQuedas: state.locaisDeQueda,
    microrganismos: state.microrganismos,
    motivosDeIdaAoProntoAtendimento: state.motivosDeIdaAoProntoAtendimento.itens.filter(x => x.status === 'Aprovado'),
    motivosDeInternacao: state.motivosDeInternacao.itens.filter(x => x.status === 'Aprovado'),
    motivosDePrioridade: state.motivosDePrioridade,
    niveisDeSaudeRelativa: state.niveisDeSaudeRelativa,
    orientacoes: state.orientacoes.paginaDeDados.dados,
    orientacoesDePagina: state.orientacoesDePagina,
    previsoesDeRetorno: state.previsoesDeRetorno,
    profissionaisExternos: state.profissionaisExternos.paginaDeDados.dados,
    profissionaisInternos: state.profissionaisDeSaude,
    profissoes: state.profissoes.itens,
    profissoesMultidisciplinares: state.profissoes.profissoesMultidisciplinares,
    respostasDoCasp16: state.prontuario.casp16,
    respostasDoPhq9: state.respostasDoPhq9,
    sorologias: state.sorologias,
    statusDeIdaAoProntoAtendimento: state.statusDeIdaAoProntoAtendimento,
    statusDeInternacao: state.statusDeInternacoes,
    tamanhosDePaginas: state.tamanhosDePaginas,
    tiposDeAtividadeFisicaDeLazer: state.tiposDeAtividadeFisicaDeLazer,
    tiposDeConsultaExterna: state.tiposDeConsultaExterna,
    tiposDeEncaminhamento: state.encaminhamentos.tipos.dados?.filter(t => t.habilitado),
    tiposDeExame: state.tiposDeExame,
    tiposDeInternacao: state.tiposDeInternacao,
    unidadesDeCalcioIonico: state.unidadesDeCalcioIonico,
    vacinas: state.vacinas,
  }
}

const acoesDoProntuario = {
  adicionarArquivo: acoes.adicionarArquivo,
  adicionarAvaliacaoDaSobrecargaDoCuidador: acoes.adicionarAvaliacaoDaSobrecargaDoCuidadorDoPaciente,
  adicionarAvaliacaoDaSolidao: acoes.adicionarAvaliacaoDaSolidaoDoPaciente,
  adicionarAvaliacaoFisicaEFuncional: acoes.adicionarAvaliacaoFisicaEFuncionalDoPaciente,
  adicionarCasp16: acoes.adicionarCasp16DoPaciente,
  adicionarConsultaExterna: acoes.adicionarConsultaExternaDoPaciente,
  adicionarDiagnosticoDoPaciente: acoes.adicionarDiagnosticoDoPaciente,
  adicionarEscalaAmbientalDeRiscoDeQuedas: acoes.adicionarEscalaAmbientalDeRiscoDeQuedasDoPaciente,
  adicionarIdaAoPa: acoes.adicionarIdaAoProntoAtendimentoDoPaciente,
  adicionarInfeccao: acoes.adicionarInfeccaoDoTratoUrinarioDoPaciente,
  adicionarInformacoes: acoes.adicionarInformacoes,
  adicionarInternacao: acoes.adicionarInternacaoDoPaciente,
  adicionarInterpretacaoMedicaDoMiniMental: acoes.adicionarInterpretacaoMedicaDoMiniMental,
  adicionarIvcf: acoes.adicionarIvcfDoPaciente,
  adicionarMedicamentoDoPaciente: acoes.adicionarMedicamentoDoPaciente,
  adicionarMiniMental: acoes.adicionarMiniMental,
  adicionarPhq9: acoes.adicionarPhq9,
  adicionarQueda: acoes.adicionarQuedaDoPaciente,
  adicionarRegistroDocumentalDoPsicologo: acoes.adicionarRegistroDocumentalDoPsicologoDoPaciente,
  adicionarSumarioDeAltaDaInternacao: acoes.adicionarSumarioDeAltaDaInternacao,
  adicionarTemplateDoProntuario: acoes.adicionarTemplateDoProntuario,
  adicionarVacina: acoes.adicionarVacinaDoPaciente,
  alterarAvaliacaoDaSobrecargaDoCuidador: acoes.alterarAvaliacaoDaSobrecargaDoCuidadorDoPaciente,
  alterarAvaliacaoDaSolidao: acoes.alterarAvaliacaoDaSolidaoDoPaciente,
  alterarAvaliacaoFisicaEFuncional: acoes.alterarAvaliacaoFisicaEFuncionalDoPaciente,
  alterarCasp16: acoes.alterarCasp16DoPaciente,
  alterarConsultaExterna: acoes.alterarConsultaExternaDoPaciente,
  alterarEscalaAmbientalDeRiscoDeQuedas: acoes.alterarEscalaAmbientalDeRiscoDeQuedasDoPaciente,
  alterarIdaAoPa: acoes.alterarIdaAoProntoAtendimentoDoPaciente,
  alterarInfeccao: acoes.alterarInfeccaoDoTratoUrinarioDoPaciente,
  alterarInternacao: acoes.alterarInternacaoDoPaciente,
  alterarIvcf: acoes.alterarIvcfDoPaciente,
  alterarMedicamentoDoPaciente: acoes.alterarMedicamentoDoPaciente,
  alterarMiniMental: acoes.alterarMiniMental,
  alterarPhq9: acoes.alterarPhq9,
  alterarPosicaoDosDiagnosticosDoPaciente: acoes.alterarPosicaoDosDiagnosticosDoPaciente,
  alterarPosicaoDosMedicamentosDoPaciente: acoes.alterarPosicaoDosMedicamentosDoPaciente,
  alterarQueda: acoes.alterarQuedaDoPaciente,
  alterarRegistroDocumentalDoPsicologo: acoes.alterarRegistroDocumentalDoPsicologoDoPaciente,
  alterarStatusDoDiagnosticoDoPaciente: acoes.alterarStatusDoDiagnosticoDoPaciente,
  alterarStatusDoMedicamentoDoPaciente: acoes.alterarStatusDoMedicamentoDoPaciente,
  alterarVacina: acoes.alterarVacinaDoPaciente,
  autenticarMedicoNaMemed: acoes.autenticarMedicoNaMemed,
  assinarAtendimentoFinalizado: acoes.assinarAtendimentoFinalizado,
  carregarDetalhesDoAtendimentoFinalizado: acoes.carregarDetalhesDoAtendimentoFinalizado,
  exibirPrescricaoMemedDoHistorico: acoes.exibirPrescricaoMemedDoHistorico,
  fazerDownloadDaPrescricao: acoes.fazerDownloadDaPrescricao,
  fazerDownloadDoAnexoDoAtendimento: acoes.fazerDownloadDoAnexoDoAtendimento,
  fazerDownloadDoArquivoAdicionalDoAtendimento: acoes.fazerDownloadDoArquivoAdicionalDoAtendimento,
  fazerDownloadDoArquivoDaOrientacao: acoes.fazerDownloadDoArquivoDaOrientacao,
  fazerDownloadDoArquivoDeAtendimento: acoes.fazerDownloadDoArquivoDeAtendimento,
  fazerDownloadDoArquivoDeVideoConsulta: acoes.fazerDownloadDoArquivoDeVideoConsulta,
  fazerDownloadDoEncaminhamento: acoes.fazerDownloadDoEncaminhamento,
  fazerDownloadDoExameDoAtendimento: acoes.fazerDownloadDoExameDoAtendimento,
  fazerDownloadDoSumarioDeAltaDaInternacao: acoes.fazerDownloadDoSumarioDeAltaDaInternacao,
  limparPrescricaoMemedNoFormulario: acoes.limparPrescricaoMemedNoFormulario,
  limparPrescricaoMemed: acoes.limparPrescricaoMemed,
  listarAntibioticos: acoes.listarAntibioticos,
  listarAvaliacoesDaSobrecargaDoCuidadorNaEscalaZarit: acoes.listarAvaliacoesDaSobrecargaDoCuidadorNaEscalaZarit,
  listarAvaliacoesDaSobrecargaDoCuidador: acoes.listarAvaliacoesDaSobrecargaDoCuidadorDoPaciente,
  listarAvaliacoesDaSolidao: acoes.listarAvaliacoesDaSolidaoDoPaciente,
  listarAvaliacoesFisicasEFuncionais: acoes.listarAvaliacoesFisicasEFuncionaisDoPaciente,
  listarCasps16DoPaciente: acoes.listarCasps16DoPaciente,
  listarConsultasExternas: acoes.listarConsultasExternasDoPaciente,
  listarDiagnosticosDoPaciente: acoes.listarDiagnosticosDoPaciente,
  listarDuracoesDasDores: acoes.listarDuracoesDasDores,
  listarEstabelecimentosDeSaude: acoes.listarEstabelecimentosDeSaude,
  listarEscalasAmbientaisDeRiscoDeQuedas: acoes.listarEscalasAmbientaisDeRiscoDeQuedasDoPaciente,
  listarEspecialidadesMedicasInternas: acoes.listarEspecialidadesMedicasInternas,
  listarEstadosDaMemoriaAtual: acoes.listarEstadosDaMemoriaAtual,
  listarEstadosDaMemoriaComparadoHaUmAnoAtras: acoes.listarEstadosDaMemoriaComparadoHaUmAnoAtras,
  listarExecucoesDoTesteDeFisioterapia: acoes.listarExecucoesDoTesteDeFisioterapia,
  listarFabricantes: acoes.listarFabricantes,
  listarFrequenciasNaEscalaDaSolidao: acoes.listarFrequenciasNaEscalaDaSolidao,
  listarFrequenciasNaEscalaZarit: acoes.listarFrequenciasNaEscalaZarit,
  listarGruposDeCuidado: acoes.listarGruposDeCuidado,
  listarIdasAoProntoAtendimentoDoPaciente: acoes.listarIdasAoProntoAtendimentoDoPaciente,
  listarInfeccoesDoTratoUrinarioDoPaciente: acoes.listarInfeccoesDoTratoUrinarioDoPaciente,
  listarIntensidadesDasDores: acoes.listarIntensidadesDasDores,
  listarInternacoesDoPaciente: acoes.listarInternacoesDoPaciente,
  listarIvcfsDoPaciente: acoes.listarIvcfsDoPaciente,
  listarLocaisDaAplicacao: acoes.listarLocaisDaAplicacao,
  listarLocaisDasDores: acoes.listarLocaisDasDores,
  listarLocaisDeQuedas: acoes.listarLocaisDeQuedas,
  listarMedicamentosDoPaciente: acoes.listarMedicamentoDoPaciente,
  listarMicrorganismos: acoes.listarMicrorganismos,
  listarMiniMentais: acoes.listarMiniMentais,
  listarMotivosDeIdaAoProntoAtendimento: acoes.listarMotivosDeIdaAoProntoAtendimento,
  listarMotivosDeInternacao: acoes.listarMotivosDeInternacao,
  listarMotivosDePrioridade: acoes.listarMotivosDePrioridade,
  listarNiveisDeSaudeRelativa: acoes.listarNiveisDeSaudeRelativa,
  listarOrientacoes: acoes.listarOrientacoes,
  listarOrientacoesDePaginas: acoes.listarOrientacoesDePaginas,
  listarPhqs9: acoes.listarPhqs9,
  listarPrevisoesDeRetorno: acoes.listarPrevisoesDeRetorno,
  listarProfissionaisExternos: acoes.listarProfissionaisExternos,
  listarProfissionaisInternos: acoes.listarProfissionaisDeSaude,
  listarProfissoes: acoes.listarProfissoes,
  listarProfissoesMultidisciplinares: acoes.listarProfissoesMultidisciplinares,
  listarQuedasDoPaciente: acoes.listarQuedasDoPaciente,
  listarRegistrosDocumentaisDoPsicologoDoPaciente: acoes.listarRegistrosDocumentaisDoPsicologoDoPaciente,
  listarRespostasDoCasp16: acoes.listarRespostasDoCasp16,
  listarRespostasDoPhq9: acoes.listarRespostasDoPhq9,
  listarResultadosAntiHbc: acoes.listarResultadosAntiHbc,
  listarResultadosAntiHcv: acoes.listarResultadosAntiHcv,
  listarResultadosAntiHiv: acoes.listarResultadosAntiHiv,
  listarResultadosHbsag: acoes.listarResultadosHbsag,
  listarStatusDeIdaAoProntoAtendimento: acoes.listarStatusDeIdaAoProntoAtendimento,
  listarStatusDeInternacao: acoes.listarStatusDeInternacoes,
  listarTiposDeAtividadeFisicaDeLazer: acoes.listarTiposDeAtividadeFisicaDeLazer,
  listarTiposDeConsultaExterna: acoes.listarTiposDeConsultaExterna,
  listarTiposDeEncaminhamento: acoes.listarTiposDeEncaminhamento,
  listarTiposDeExame: acoes.listarTiposDeExame,
  listarTiposDeInternacao: acoes.listarTiposDeInternacao,
  listarUnidadesDeCalcioIonico: acoes.listarUnidadesDeCalcioIonico,
  listarVacinas: acoes.listarVacinas,
  listarVacinasDoPaciente: acoes.listarVacinasDoPaciente,
  recuperarDesenhoDoMiniMental: acoes.recuperarDesenhoDoMiniMental,
  recuperarHistoricoPrescricaoNaMemed: acoes.recuperarHistoricoPrescricaoNaMemed,
  recuperarProfissaoMedica: acoes.recuperarProfissaoMedica,
  recuperarPrescricaoPdfNaMemed: acoes.recuperarPrescricaoPdfNaMemed,
  removerAvaliacaoDaSobrecargaDoCuidador: acoes.removerAvaliacaoDaSobrecargaDoCuidadorDoPaciente,
  removerAvaliacaoDaSolidao: acoes.removerAvaliacaoDaSolidaoDoPaciente,
  removerAvaliacaoFisicaEFuncional: acoes.removerAvaliacaoFisicaEFuncionalDoPaciente,
  removerCasp16: acoes.removerCasp16DoPaciente,
  removerConsultaExterna: acoes.removerConsultaExternaDoPaciente,
  removerEscalaAmbientalDeRiscoDeQuedas: acoes.removerEscalaAmbientalDeRiscoDeQuedasDoPaciente,
  removerIdaAoPa: acoes.removerIdaAoProntoAtendimentoDoPaciente,
  removerImagemDaMemoriaPorIdentificador: acoes.removerImagemDaMemoriaPorIdentificador,
  removerInfeccao: acoes.removerInfeccaoDoTratoUrinarioDoPaciente,
  removerInternacao: acoes.removerInternacaoDoPaciente,
  removerInterpretacaoMedicaDoMiniMental: acoes.removerInterpretacaoMedicaDoMiniMental,
  removerIvcf: acoes.removerIvcfDoPaciente,
  removerMiniMental: acoes.removerMiniMental,
  removerPhq9: acoes.removerPhq9,
  removerPrescricaoNaMemed: acoes.removerPrescricaoNaMemed,
  removerQueda: acoes.removerQuedaDoPaciente,
  removerRegistroDocumentalDoPsicologo: acoes.removerRegistroDocumentalDoPsicologoDoPaciente,
  removerVacina: acoes.removerVacinaDoPaciente,
}

export default connect(mapStateToProps, acoesDoProntuario)(ProvedorDeContextoDoProntuario)