import * as acoesDeAgenda from './agendamentos/agenda'
import * as acoesDeAgendamento from './agendamentos/agendamento'
import * as acoesDeAntibioticos from './antibioticos'
import * as acoesDeArquivos from './arquivos'
import * as acoesDeAssociacoes from './associacoes'
import * as acoesDeAtendimentos from './prontuario/atendimentos'
import * as acoesDeAtendimentosIniciados from './atendimentos-iniciados'
import * as acoesDeAtendimentosNaoAssinados from './atendimentos-nao-assinados'
import * as acoesDeAvaliacoesDaSobrecargaDoCuidadorDoPaciente
  from './prontuario/gestao-de-condicoes-clinicas/questionarios/avaliacao-da-sobrecarga-do-cuidador'
import * as acoesDeAvaliacoesDaSolidaoDoPaciente
  from './prontuario/gestao-de-condicoes-clinicas/questionarios/avaliacao-da-solidao'
import * as acoesDeAvaliacoesFisicasEFuncionaisDoPaciente
  from './prontuario/gestao-de-condicoes-clinicas/questionarios/avaliacao-fisica-e-funcional'
import * as acoesDeBloqueios from './usuarios/agenda/bloqueios'
import * as acoesDeBloqueiosDoAgendamento from './agendamentos/bloqueios'
import * as acoesDeCabecalhoDoProntuario from './prontuario/cabecalho/cabecalho'
import * as acoesDeCamposExternosDoPaciente from './pacientes/campos-externos'
import * as acoesDeCasps16DoPaciente from './prontuario/gestao-de-condicoes-clinicas/questionarios/casp-16'
import * as acoesDeCategoriasDaTarefa from './tarefas/categorias'
import * as acoesDeCelula from './celulas'
import * as acoesDeCidadesParaOPedidoDeContato from './pedidos-de-contato/cidades'
import * as acoesDeCids10 from './cids10'
import * as acoesDeComentariosDaTarefa from './tarefas/comentarios'
import * as acoesDeConfiguracoesDoUsuario from './usuarios/configuracoes'
import * as acoesDeConfirmarAgendamento from './agendamentos/confirmar-agendamento'
import * as acoesDeConsultasExternas from './prontuario/gestao-de-condicoes-clinicas/consultas-externas'
import * as acoesDeConsultoresDoPaciente from './pacientes/consultores'
import * as acoesDeConveniosDoPaciente from './pacientes/convenios'
import * as acoesDeDatasDaTarefa from './tarefas/data'
import * as acoesDeDesbloquearLoginDoUsuario from './usuarios/desbloquear-login-do-usuario'
import * as acoesDeDescricaoDaTarefa from './tarefas/descricao'
import * as acoesDeDiagnosticos from './diagnosticos'
import * as acoesDeDiagnosticosDoProntuario from './prontuario/gestao-de-condicoes-clinicas/diagnosticos'
import * as acoesDeDownloadDaArquivoAdicional from './prontuario/download-de-arquivos-adicionais-do-atendimento'
import * as acoesDeDownloadDaPrescricao from './download-da-prescricao'
import * as acoesDeDownloadDoEncaminhamento from './download-do-encaminhamento'
import * as acoesDeDuracoesDasDores from './duracoes-das-dores'
import * as acoesDeEmpresa from './empresa'
import * as acoesDeEncaminhamentos from './encaminhamentos'
import * as acoesDeEnfermeiros from './enfermeiros'
import * as acoesDeEnfermeirosDoPaciente from './pacientes/enfermeiros'
import * as acoesDeEscalasAmbientaisDeRiscoDeQuedasDoPaciente
  from './prontuario/gestao-de-condicoes-clinicas/questionarios/escala-ambiental-de-risco-de-quedas'
import * as acoesDeEspecialidadesMedicasInternas from './especialidades-medicas-internas'
import * as acoesDeEstabelecimentosDeSaude from './estabelecimentos-de-saude'
import * as acoesDeEstadosCivis from './estados-civis'
import * as acoesDeEstadosDaMemoriaAtual from './estados-da-memoria-atual'
import * as acoesDeEstadosDaMemoriaComparadoHaUmAnoAtras from './estados-da-memoria-comparado-ha-um-ano-atras'
import * as acoesDeExamesDoAtendimento from './exames-do-atendimento'
import * as acoesDeExecucoesDoTesteDeFisioterapia from './execucoes-do-teste-de-fisioterapia'
import * as acoesDeFabricantes from './fabricantes'
import * as acoesDeFeriados from './feriados'
import * as acoesDeFeriadosTrabalhados from './usuarios/agenda/feriados-trabalhados'
import * as acoesDeFiltrosDasTarefas from './tarefas/filtros'
import * as acoesDeFirestoreDeTarefas from './tarefas/firestore'
import * as acoesDeFotoDoUsuario from './usuarios/foto'
import * as acoesDeFotoDoPaciente from './pacientes/foto'
import * as acoesDeFotoDosProfissionaisDeSaude from './foto-dos-profissionais-de-saude'
import * as acoesDeFrequenciasNaEscalaZarit from './prontuario/zarit'
import * as acoesDeFusosHorarios from './fusos-horarios'
import * as acoesDeGrausDeRelacoes from './graus-de-relacoes'
import * as acoesDeGruposDeCuidados from './grupos-de-cuidado'
import * as acoesDeHorariosDaAgenda from './usuarios/agenda/horarios'
import * as acoesDeIdasAoProntoAtendimentoDoPaciente
  from './prontuario/gestao-de-condicoes-clinicas/idas-ao-pronto-atendimento'
import * as acoesDeImagens from './imagens'
import * as acoesDeIndicadoresDoPaciente from './pacientes/indicadores'
import * as acoesDeInfeccoesDoTratoUrinarioDoPaciente
  from './prontuario/gestao-de-condicoes-clinicas/infeccoes-do-trato-urinario'
import * as acoesDeInformarChegadaDoPaciente from './agendamentos/informar-chegada-do-paciente'
import * as acoesDeInstrucoes from './usuarios/agenda/instrucoes'
import * as acoesDeInstrucoesDoAgendamento from './agendamentos/instrucoes'
import * as acoesDeIntensidadesDasDores from './intensidades-das-dores'
import * as acoesDeInternacoesDoPaciente from './prontuario/gestao-de-condicoes-clinicas/internacoes'
import * as acoesDeIvcfsDoPaciente from './prontuario/gestao-de-condicoes-clinicas/questionarios/ivcf'
import * as acoesDeLacuna from './lacuna'
import * as acoesDeLinhaDoTempoDoProntuario from './prontuario/linha-do-tempo'
import * as acoesDeListaDeOpcoesMultiplas from './tarefas/lista-de-opcoes-multiplas'
import * as acoesDeLocaisDaAplicacao from './locais-da-aplicacao'
import * as acoesDeLocaisDasDores from './locais-das-dores'
import * as acoesDeLocaisDeQueda from './locais-de-queda'
import * as acoesDeMedicosDoPaciente from './pacientes/medicos'
import * as acoesDeMedicos from './medicos'
import * as acoesDeMedicamentos from './medicamentos'
import * as acoesDeMedicamentosDoProntuario from './prontuario/gestao-de-condicoes-clinicas/medicamentos'
import * as acoesDeMemed from './prontuario/memed'
import * as acoesDeMicrorganismos from './microrganismos'
import * as acoesDeMiniMentais from './prontuario/gestao-de-condicoes-clinicas/questionarios/mini-mental'
import * as acoesDeModalidadesDosTiposDeAgendamento from './modalidades-dos-tipos-de-agendamento'
import * as acoesDeMomentosDaTarefa from './tarefas/momento'
import * as acoesDeMotivosDeBloqueio from './usuarios/agenda/motivos-de-bloqueio'
import * as acoesDeMotivosDeBloqueioDoLogin from './motivos-de-bloqueio-do-login'
import * as acoesDeMotivosDeCancelamentoDaTarefa from './tarefas/motivos-do-cancelamento'
import * as acoesDeMotivosDeCancelamentoDoConvenio from './pacientes/motivos-de-cancelamento-do-convenio'
import * as acoesDeMotivosDeCancelamentoDosAgendamentos from './agendamentos/motivos-de-cancelamento'
import * as acoesDeMotivosDeIdaAoProntoAtendimento from './motivos-de-ida-ao-pronto-atendimento'
import * as acoesDeMotivosDeInternacao from './motivos-de-internacao'
import * as acoesDeMotivosDePrioridade from './motivos-de-prioridade'
import * as acoesDeMotivosParaNaoTerSidoAtendido from './agendamentos/motivos-para-nao-ter-sido-atendido'
import * as acoesDeNiveisDeSaudeRelativa from './niveis-de-saude-relativa'
import * as acoesDeNotificacoes from './notificacoes'
import * as acoesDeNps from './nps/index'
import * as acoesDeNumeroInteiroDaTarefa from './tarefas/numero-inteiro'
import * as acoesDeOperadoras from './operadoras'
import * as acoesDeMotivosDeSaidaDosProgramas from './pacientes/motivos-de-saida-dos-programas'
import * as acoesDeOperadorasDoPaciente from './pacientes/operadoras'
import * as acoesDeOrientacoes from './orientacoes'
import * as acoesDeOrientacoesDePaginas from './orientacoes-de-paginas'
import * as acoesDePacientes from './pacientes/index'
import * as acoesDePacientesDoAgendamento from './agendamentos/pacientes'
import * as acoesDePacientesDaTarefa from './tarefas/paciente'
import * as acoesDoPasso1DoWizardDeAgendamento from './agendamentos/wizard/passo1'
import * as acoesDoPasso2DoWizardDeAgendamento from './agendamentos/wizard/passo2'
import * as acoesDoPasso3DoWizardDeAgendamento from './agendamentos/wizard/passo3'
import * as acoesDePedidosDeContato from './pedidos-de-contato/index'
import * as acoesDePerfis from './usuarios/perfis'
import * as acoesDePesquisaDeAgendamentos from './agendamentos/pesquisa-de-agendamentos'
import * as acoesDePhqs9 from './prontuario/gestao-de-condicoes-clinicas/questionarios/phq9'
import * as acoesDePlanosDeCuidado from './planos-de-cuidado'
import * as acoesDePlanosDeCuidadoDoPaciente from './pacientes/planos-de-cuidado'
import * as acoesDePlanosDeSaudeParaOPedidoDeContato from './pedidos-de-contato/planos-de-saude'
import * as acoesDePowerBI from './integracoes/power-bi'
import * as acoesDePrazoDaTarefa from './tarefas/prazo'
import * as acoesDoPreCadastroDoPacienteDoWizardDeAgendamento from './agendamentos/wizard/pre-cadastro-do-paciente'
import * as acoesDeProfissionalResponsavelDaTarefa from './tarefas/profissional-responsavel'
import * as acoesDePrevisoesDeRetorno from './previsoes-de-retorno'
import * as acoesDeProfissionaisExternos from './profissionais-externos'
import * as acoesDeProfissionaisDeSaude from './profissionais-de-saude'
import * as acoesDeProfissoes from './profissoes'
import * as acoesDeProfissoesParaONps from './nps/profissoes'
import * as acoesDeProgramas from './programas'
import * as acoesDeProgramasDoPaciente from './pacientes/programas'
import * as acoesDeProprietariosDeTelefone from './proprietarios-de-telefones'
import * as acoesDeProximosAgendamentosMedicosDoPaciente from './agendamentos/proximos-agendamentos-medicos-do-paciente'
import * as acoesDePsicologosDoPaciente from './pacientes/psicologos'
import * as acoesDeQuedasDoPaciente from './prontuario/gestao-de-condicoes-clinicas/quedas'
import * as acoesDeRecorrenciasDoAgendamento from './agendamentos/recorrencias'
import * as acoesDeRelatoriosDeHorasDosProfissionais from './relatorios/horas-dos-profissionais'
import * as acoesDeRelatoriosDeIdasAoProntoAtendimento from './relatorios/idas-ao-pronto-atendimento'
import * as acoesDeRelatoriosDeInternacoes from './relatorios/internacoes'
import * as acoesDeRelatoriosDeReinternacoes from './relatorios/reinternacoes'
import * as acoesDeRelatorioDeAtendimentos from './relatorios/atendimentos'
import * as acoesDeRelatorioDePacientes from './relatorios/pacientes'
import * as acoesDeRelatorioDePacientesDoPrograma from './relatorios/pacientes-do-programa'
import * as acoesDeRegistrosDocumentaisDoPsicologoDoPaciente from './prontuario/gestao-de-condicoes-clinicas/registros-documentais-do-psicologo'
import * as acoesDeRespostasDoCasp16 from './prontuario/questionario-casp-16'
import * as acoesDeRespostasDoPhq9 from './respostas-do-phq9'
import * as acoesDeRespostasParaONps from './nps/respostas'
import * as acoesDeResumoDeInternacoes from './relatorios/resumo-de-internacoes'
import * as acoesDeSalaDeEspera from './agendamentos/sala-de-espera'
import * as acoesDeSetores from './setores'
import * as acoesDeSetorResponsavel from './tarefas/setor-responsavel'
import * as acoesDeSalas from '././empresa/salas'
import * as acoesDeSexos from './sexos'
import * as acoesDeSistema from './sistema'
import * as acoesDeSorologias from './sorologias'
import * as acoesDeSugestoes from './sugestoes'
import * as acoesDeSumarioDeAltaDaInternacao from './sumario-de-alta-da-internacao'
import * as acoesDeStatusDaTarefa from './tarefas/status'
import * as acoesDeStatusDeIdasAoProntoAtendimento from './status-de-idas-ao-pronto-atendimento'
import * as acoesDeStatusDeInternacoes from './status-de-internacoes'
import * as acoesDeStatusDosAgendamentos from './agendamentos/status-dos-agendamentos'
import * as acoesDeTagsDoPaciente from './pacientes/tags'
import * as acoesDeTagsDoProntuario from './prontuario/cabecalho/tags'
import * as acoesDeTamanhosDePaginas from './tamanhos-de-paginas'
import * as acoesDeTarefa from './tarefas/index'
import * as acoesDeTeleconsulta from './prontuario/teleconsulta'
import * as acoesDeTeleconsultaDoAgendamento from './teleconsulta'
import * as acoesDeTelefonesDeContatoDaTarefa from './tarefas/telefone-de-contato'
import * as acoesDeTextoDaTarefa from './tarefas/texto'
import * as acoesDeTextoLongoDaTarefa from './tarefas/texto-longo'
import * as acoesDeTiposDeAgendamentoDoAgendamento from './agendamentos/tipos-de-agendamento'
import * as acoesDeTiposDeAgendamento from './tipos-de-agendamento'
import * as acoesDeTiposDeAgendamentoDoUsuario from './usuarios/agenda/tipos-de-agendamento'
import * as acoesDeTiposDeAtendimento from './prontuario/tipos-de-atendimento'
import * as acoesDeTipoDeAtendimentoDoUsuario from './usuarios/tipos-de-atendimento'
import * as acoesDeTiposDeAtendimentoParaONps from './nps/tipos-de-atendimento'
import * as acoesDeTiposDeAtividadeFisicasDeLazer from './tipos-de-atividade-fisica-de-lazer'
import * as acoesDeTiposDeConsultaExterna from './tipos-de-consulta-externa'
import * as acoesDeTiposDeHorarios from './usuarios/agenda/tipos-de-horarios'
import * as acoesDeTiposDeInternacao from './tipos-de-internacao'
import * as acoesDeTiposDeRepeticaoDeSerieDaAgenda from './tipos-de-repeticao-de-serie-da-agenda'
import * as acoesDeTiposDeSala from './empresa/tipos-de-sala'
import * as acoesDeTiposDeTarefas from './tarefas/tipos'
import * as acoesDeTiposDeUnidade from './tipos-de-unidade'
import * as acoesDeTratamentos from './tratamentos'
import * as acoesDeUnidadesDeCalcioIonico from './unidades-de-calcio-ionico'
import * as acoesDeUnidadesFederativas from './unidades-federativas'
import * as acoesDeUnidadesDaEmpresa from './empresa/unidades'
import * as acoesDeUnidadesDoUsuarioDaConfiguracaoDaAgenda from './usuarios/agenda/unidades-do-usuario'
import * as acoesDeUnidadesDoUsuarioLogado from './usuarios/logado/unidades'
import * as acoesDeUnidadesDoUsuario from './usuarios/unidades'
import * as acoesDeUrgenciasDaTarefa from './tarefas/urgencias'
import * as acoesDeUsuario from './usuarios/usuario'
import * as acoesDeUsuariosBloqueados from './usuarios/usuarios-bloqueados'
import * as acoesDeVacina from './vacinas'
import * as acoesDeVacinasDoPaciente from './prontuario/gestao-de-condicoes-clinicas/vacinas'
import * as acoesDeVideoConsulta from './prontuario/video-consulta'

export default {
  ...acoesDeAgenda,
  ...acoesDeAgendamento,
  ...acoesDeAntibioticos,
  ...acoesDeArquivos,
  ...acoesDeAssociacoes,
  ...acoesDeAtendimentos,
  ...acoesDeAtendimentosIniciados,
  ...acoesDeAtendimentosNaoAssinados,
  ...acoesDeAvaliacoesDaSobrecargaDoCuidadorDoPaciente,
  ...acoesDeAvaliacoesDaSolidaoDoPaciente,
  ...acoesDeAvaliacoesFisicasEFuncionaisDoPaciente,
  ...acoesDeBloqueios,
  ...acoesDeBloqueiosDoAgendamento,
  ...acoesDeCabecalhoDoProntuario,
  ...acoesDeCamposExternosDoPaciente,
  ...acoesDeCasps16DoPaciente,
  ...acoesDeCategoriasDaTarefa,
  ...acoesDeCelula,
  ...acoesDeCidadesParaOPedidoDeContato,
  ...acoesDeCids10,
  ...acoesDeComentariosDaTarefa,
  ...acoesDeConfiguracoesDoUsuario,
  ...acoesDeConfirmarAgendamento,
  ...acoesDeConsultasExternas,
  ...acoesDeConsultoresDoPaciente,
  ...acoesDeConveniosDoPaciente,
  ...acoesDeDatasDaTarefa,
  ...acoesDeDesbloquearLoginDoUsuario,
  ...acoesDeDescricaoDaTarefa,
  ...acoesDeDiagnosticos,
  ...acoesDeDiagnosticosDoProntuario,
  ...acoesDeDownloadDaArquivoAdicional,
  ...acoesDeDownloadDaPrescricao,
  ...acoesDeDownloadDoEncaminhamento,
  ...acoesDeDuracoesDasDores,
  ...acoesDeEmpresa,
  ...acoesDeEncaminhamentos,
  ...acoesDeEnfermeiros,
  ...acoesDeEnfermeirosDoPaciente,
  ...acoesDeEscalasAmbientaisDeRiscoDeQuedasDoPaciente,
  ...acoesDeEspecialidadesMedicasInternas,
  ...acoesDeEstabelecimentosDeSaude,
  ...acoesDeEstadosCivis,
  ...acoesDeEstadosDaMemoriaAtual,
  ...acoesDeEstadosDaMemoriaComparadoHaUmAnoAtras,
  ...acoesDeExamesDoAtendimento,
  ...acoesDeExecucoesDoTesteDeFisioterapia,
  ...acoesDeFabricantes,
  ...acoesDeFeriados,
  ...acoesDeFeriadosTrabalhados,
  ...acoesDeFiltrosDasTarefas,
  ...acoesDeFirestoreDeTarefas,
  ...acoesDeFotoDoPaciente,
  ...acoesDeFotoDosProfissionaisDeSaude,
  ...acoesDeFotoDoUsuario,
  ...acoesDeFrequenciasNaEscalaZarit,
  ...acoesDeFusosHorarios,
  ...acoesDeGrausDeRelacoes,
  ...acoesDeGruposDeCuidados,
  ...acoesDeHorariosDaAgenda,
  ...acoesDeIdasAoProntoAtendimentoDoPaciente,
  ...acoesDeImagens,
  ...acoesDeIndicadoresDoPaciente,
  ...acoesDeInfeccoesDoTratoUrinarioDoPaciente,
  ...acoesDeInformarChegadaDoPaciente,
  ...acoesDeInstrucoes,
  ...acoesDeInstrucoesDoAgendamento,
  ...acoesDeIntensidadesDasDores,
  ...acoesDeInternacoesDoPaciente,
  ...acoesDeIvcfsDoPaciente,
  ...acoesDeLacuna,
  ...acoesDeLinhaDoTempoDoProntuario,
  ...acoesDeListaDeOpcoesMultiplas,
  ...acoesDeLocaisDaAplicacao,
  ...acoesDeLocaisDasDores,
  ...acoesDeLocaisDeQueda,
  ...acoesDeMedicamentos,
  ...acoesDeMedicamentosDoProntuario,
  ...acoesDeMedicos,
  ...acoesDeMedicosDoPaciente,
  ...acoesDeMemed,
  ...acoesDeMicrorganismos,
  ...acoesDeMiniMentais,
  ...acoesDeModalidadesDosTiposDeAgendamento,
  ...acoesDeMomentosDaTarefa,
  ...acoesDeMotivosDeBloqueio,
  ...acoesDeMotivosDeBloqueioDoLogin,
  ...acoesDeMotivosDeCancelamentoDaTarefa,
  ...acoesDeMotivosDeCancelamentoDoConvenio,
  ...acoesDeMotivosDeCancelamentoDosAgendamentos,
  ...acoesDeMotivosDeIdaAoProntoAtendimento,
  ...acoesDeMotivosDeInternacao,
  ...acoesDeMotivosDePrioridade,
  ...acoesDeMotivosDeSaidaDosProgramas,
  ...acoesDeMotivosParaNaoTerSidoAtendido,
  ...acoesDeNiveisDeSaudeRelativa,
  ...acoesDeNotificacoes,
  ...acoesDeNps,
  ...acoesDeNumeroInteiroDaTarefa,
  ...acoesDeOperadoras,
  ...acoesDeOperadorasDoPaciente,
  ...acoesDeOrientacoes,
  ...acoesDeOrientacoesDePaginas,
  ...acoesDePacientes,
  ...acoesDePacientesDaTarefa,
  ...acoesDePacientesDoAgendamento,
  ...acoesDePedidosDeContato,
  ...acoesDePerfis,
  ...acoesDePesquisaDeAgendamentos,
  ...acoesDePhqs9,
  ...acoesDePlanosDeCuidado,
  ...acoesDePlanosDeCuidadoDoPaciente,
  ...acoesDePlanosDeSaudeParaOPedidoDeContato,
  ...acoesDePowerBI,
  ...acoesDePrazoDaTarefa,
  ...acoesDePrevisoesDeRetorno,
  ...acoesDeProfissionalResponsavelDaTarefa,
  ...acoesDeProfissionaisDeSaude,
  ...acoesDeProfissionaisExternos,
  ...acoesDeProfissoes,
  ...acoesDeProfissoesParaONps,
  ...acoesDeProgramas,
  ...acoesDeProgramasDoPaciente,
  ...acoesDeProprietariosDeTelefone,
  ...acoesDeProximosAgendamentosMedicosDoPaciente,
  ...acoesDePsicologosDoPaciente,
  ...acoesDeQuedasDoPaciente,
  ...acoesDeRecorrenciasDoAgendamento,
  ...acoesDeRegistrosDocumentaisDoPsicologoDoPaciente,
  ...acoesDeRelatorioDeAtendimentos,
  ...acoesDeRelatorioDePacientes,
  ...acoesDeRelatorioDePacientesDoPrograma,
  ...acoesDeRelatoriosDeHorasDosProfissionais,
  ...acoesDeRelatoriosDeIdasAoProntoAtendimento,
  ...acoesDeRelatoriosDeInternacoes,
  ...acoesDeRelatoriosDeReinternacoes,
  ...acoesDeRespostasDoCasp16,
  ...acoesDeRespostasDoPhq9,
  ...acoesDeRespostasParaONps,
  ...acoesDeResumoDeInternacoes,
  ...acoesDeSalaDeEspera,
  ...acoesDeSalas,
  ...acoesDeSetores,
  ...acoesDeSetorResponsavel,
  ...acoesDeSexos,
  ...acoesDeSistema,
  ...acoesDeSorologias,
  ...acoesDeStatusDaTarefa,
  ...acoesDeStatusDeIdasAoProntoAtendimento,
  ...acoesDeStatusDeInternacoes,
  ...acoesDeStatusDosAgendamentos,
  ...acoesDeSugestoes,
  ...acoesDeSumarioDeAltaDaInternacao,
  ...acoesDeTagsDoPaciente,
  ...acoesDeTagsDoProntuario,
  ...acoesDeTamanhosDePaginas,
  ...acoesDeTarefa,
  ...acoesDeTeleconsulta,
  ...acoesDeTeleconsultaDoAgendamento,
  ...acoesDeTelefonesDeContatoDaTarefa,
  ...acoesDeTextoLongoDaTarefa,
  ...acoesDeTextoDaTarefa,
  ...acoesDeTipoDeAtendimentoDoUsuario,
  ...acoesDeTiposDeAgendamento,
  ...acoesDeTiposDeAgendamentoDoAgendamento,
  ...acoesDeTiposDeAgendamentoDoUsuario,
  ...acoesDeTiposDeAtendimento,
  ...acoesDeTiposDeAtendimentoParaONps,
  ...acoesDeTiposDeAtividadeFisicasDeLazer,
  ...acoesDeTiposDeConsultaExterna,
  ...acoesDeTiposDeHorarios,
  ...acoesDeTiposDeInternacao,
  ...acoesDeTiposDeRepeticaoDeSerieDaAgenda,
  ...acoesDeTiposDeTarefas,
  ...acoesDeTiposDeSala,
  ...acoesDeTiposDeUnidade,
  ...acoesDeTratamentos,
  ...acoesDeUnidadesDaEmpresa,
  ...acoesDeUnidadesDeCalcioIonico,
  ...acoesDeUnidadesDoUsuario,
  ...acoesDeUnidadesDoUsuarioDaConfiguracaoDaAgenda,
  ...acoesDeUnidadesDoUsuarioLogado,
  ...acoesDeUnidadesFederativas,
  ...acoesDeUrgenciasDaTarefa,
  ...acoesDeUsuario,
  ...acoesDeUsuariosBloqueados,
  ...acoesDeVacina,
  ...acoesDeVacinasDoPaciente,
  ...acoesDeVideoConsulta,
  ...acoesDoPasso1DoWizardDeAgendamento,
  ...acoesDoPasso2DoWizardDeAgendamento,
  ...acoesDoPasso3DoWizardDeAgendamento,
  ...acoesDoPreCadastroDoPacienteDoWizardDeAgendamento
}