import {
  ADICIONAR_AGENDAMENTO,
  ADICIONAR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  ADICIONAR_ARQUIVO,
  ADICIONAR_ASSOCIACAO,
  ADICIONAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  ADICIONAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  ADICIONAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  ADICIONAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ADICIONAR_CAMPO_EXTERNO_DO_PACIENTE,
  ADICIONAR_CASP_16_DO_PACIENTE,
  ADICIONAR_CELULA,
  ADICIONAR_COMENTARIO_DA_TAREFA,
  ADICIONAR_CONCENTRACAO_DO_MEDICAMENTO,
  ADICIONAR_CONSULTA_EXTERNA_DO_PACIENTE,
  ADICIONAR_CONVENIO_DO_PACIENTE,
  ADICIONAR_DIAGNOSTICO,
  ADICIONAR_DIAGNOSTICO_DO_PACIENTE,
  ADICIONAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  ADICIONAR_ESPECIALIDADE,
  ADICIONAR_ESPECIALIDADE_MEDICA_INTERNA,
  ADICIONAR_ESTABELECIMENTO_DE_SAUDE,
  ADICIONAR_FABRICANTE,
  ADICIONAR_FERIADO,
  ADICIONAR_FERIADO_TRABALHADO_DO_PROFISSIONAL,
  ADICIONAR_FOTO_DA_EMPRESA,
  ADICIONAR_FOTO_DO_PACIENTE,
  ADICIONAR_FOTO_DO_USUARIO,
  ADICIONAR_GRUPO_DE_CUIDADO,
  ADICIONAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ADICIONAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  ADICIONAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  ADICIONAR_INFORMACOES,
  ADICIONAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ADICIONAR_INTERNACAO_DO_PACIENTE,
  ADICIONAR_INTERPRETACAO_MEDICA_DO_MINI_MENTAL,
  ADICIONAR_IVCF_DO_PACIENTE,
  ADICIONAR_MEDICAMENTO,
  ADICIONAR_MEDICAMENTO_DO_PACIENTE,
  ADICIONAR_MINI_MENTAL,
  ADICIONAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  ADICIONAR_MOTIVO_DE_INTERNACAO,
  ADICIONAR_OPERADORA,
  ADICIONAR_ORIENTACAO,
  ADICIONAR_PACIENTE,
  ADICIONAR_PACIENTE_NA_LISTA_DE_ESPERA,
  ADICIONAR_PEDIDO_DE_CONTATO,
  ADICIONAR_PERFIL_DO_USUARIO,
  ADICIONAR_PHQ9,
  ADICIONAR_PLANO_DE_CUIDADO,
  ADICIONAR_PLANO_DE_CUIDADO_DO_PACIENTE,
  ADICIONAR_PRE_CADASTRO_DO_PACIENTE,
  ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DA_TAREFA,
  ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  ADICIONAR_PROFISSAO,
  ADICIONAR_PROFISSIONAL_EXTERNO,
  ADICIONAR_PROGRAMA,
  ADICIONAR_PROGRAMA_DO_PACIENTE,
  ADICIONAR_QUEDA_DO_PACIENTE,
  ADICIONAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
  ADICIONAR_SALA_NA_UNIDADE,
  ADICIONAR_SETOR,
  ADICIONAR_SUMARIO_DE_ALTA_DA_INTERNACAO,
  ADICIONAR_TAG_NO_PACIENTE,
  ADICIONAR_TAG_NO_PACIENTE_DO_PRONTUARIO,
  ADICIONAR_TEMPLATE,
  ADICIONAR_TEMPLATE_DO_PRONTUARIO,
  ADICIONAR_TIPO_DE_AGENDAMENTO,
  ADICIONAR_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL,
  ADICIONAR_UNIDADE_DA_EMPRESA,
  ADICIONAR_UNIDADE_DO_USUARIO,
  ADICIONAR_USUARIO,
  ADICIONAR_VACINA_DO_PACIENTE,
  ADICIONOU_AGENDAMENTO,
  ADICIONOU_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  ADICIONOU_ARQUIVO,
  ADICIONOU_ASSOCIACAO,
  ADICIONOU_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  ADICIONOU_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  ADICIONOU_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  ADICIONOU_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ADICIONOU_CAMPO_EXTERNO_DO_PACIENTE,
  ADICIONOU_CASP_16_DO_PACIENTE,
  ADICIONOU_CELULA,
  ADICIONOU_COMENTARIO_DA_TAREFA,
  ADICIONOU_CONCENTRACAO_DO_MEDICAMENTO,
  ADICIONOU_CONSULTA_EXTERNA_DO_PACIENTE,
  ADICIONOU_CONVENIO_DO_PACIENTE,
  ADICIONOU_DIAGNOSTICO,
  ADICIONOU_DIAGNOSTICO_DO_PACIENTE,
  ADICIONOU_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  ADICIONOU_ESPECIALIDADE,
  ADICIONOU_ESPECIALIDADE_MEDICA_INTERNA,
  ADICIONOU_ESTABELECIMENTO_DE_SAUDE,
  ADICIONOU_FABRICANTE,
  ADICIONOU_FERIADO,
  ADICIONOU_FERIADO_TRABALHADO_DO_PROFISSIONAL,
  ADICIONOU_FOTO_DA_EMPRESA,
  ADICIONOU_FOTO_DO_PACIENTE,
  ADICIONOU_FOTO_DO_USUARIO,
  ADICIONOU_GRUPO_DE_CUIDADO,
  ADICIONOU_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ADICIONOU_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  ADICIONOU_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  ADICIONOU_INFORMACOES,
  ADICIONOU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ADICIONOU_INTERNACAO_DO_PACIENTE,
  ADICIONOU_INTERPRETACAO_MEDICA_DO_MINI_MENTAL,
  ADICIONOU_IVCF_DO_PACIENTE,
  ADICIONOU_MEDICAMENTO,
  ADICIONOU_MEDICAMENTO_DO_PACIENTE,
  ADICIONOU_MINI_MENTAL,
  ADICIONOU_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  ADICIONOU_MOTIVO_DE_INTERNACAO,
  ADICIONOU_OPERADORA,
  ADICIONOU_ORIENTACAO,
  ADICIONOU_PACIENTE,
  ADICIONOU_PACIENTE_NA_LISTA_DE_ESPERA,
  ADICIONOU_PEDIDO_DE_CONTATO,
  ADICIONOU_PERFIL_DO_USUARIO,
  ADICIONOU_PHQ9,
  ADICIONOU_PLANO_DE_CUIDADO,
  ADICIONOU_PLANO_DE_CUIDADO_DO_PACIENTE,
  ADICIONOU_PRE_CADASTRO_DO_PACIENTE,
  ADICIONOU_PRE_CADASTRO_DO_PACIENTE_DA_TAREFA,
  ADICIONOU_PRE_CADASTRO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  ADICIONOU_PROFISSAO,
  ADICIONOU_PROFISSIONAL_EXTERNO,
  ADICIONOU_PROGRAMA,
  ADICIONOU_PROGRAMA_DO_PACIENTE,
  ADICIONOU_QUEDA_DO_PACIENTE,
  ADICIONOU_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
  ADICIONOU_SALA_NA_UNIDADE,
  ADICIONOU_SETOR,
  ADICIONOU_SUMARIO_DE_ALTA_DA_INTERNACAO,
  ADICIONOU_TAG_NO_PACIENTE,
  ADICIONOU_TAG_NO_PACIENTE_DO_PRONTUARIO,
  ADICIONOU_TEMPLATE,
  ADICIONOU_TEMPLATE_DO_PRONTUARIO,
  ADICIONOU_TIPO_DE_AGENDAMENTO,
  ADICIONOU_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL,
  ADICIONOU_UNIDADE_DA_EMPRESA,
  ADICIONOU_UNIDADE_DO_USUARIO,
  ADICIONOU_USUARIO,
  ADICIONOU_VACINA_DO_PACIENTE,
  ALTERAR_ASSOCIACAO,
  ALTERAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  ALTERAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  ALTERAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ALTERAR_CAMPO_EXTERNO_DO_PACIENTE,
  ALTERAR_CASP_16_DO_PACIENTE,
  ALTERAR_CELULA,
  ALTERAR_COMENTARIO_DA_TAREFA,
  ALTERAR_CONCENTRACAO_DO_MEDICAMENTO,
  ALTERAR_CONFIGURACAO_DO_USUARIO,
  ALTERAR_CONSULTA_EXTERNA_DO_PACIENTE,
  ALTERAR_CONVENIO_DO_PACIENTE,
  ALTERAR_DADOS_SELECIONADOS_DO_AGENDAMENTO_NA_LISTA_DE_ESPERA,
  ALTERAR_DIAGNOSTICO,
  ALTERAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  ALTERAR_ESPECIALIDADE,
  ALTERAR_ESTABELECIMENTO_DE_SAUDE,
  ALTERAR_FABRICANTE,
  ALTERAR_FILTROS_DAS_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_GRUPO_DE_CUIDADO,
  ALTERAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  ALTERAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ALTERAR_INTERNACAO_DO_PACIENTE,
  ALTERAR_IVCF_DO_PACIENTE,
  ALTERAR_MEDICAMENTO,
  ALTERAR_MEDICAMENTO_DO_PACIENTE,
  ALTERAR_MINI_MENTAL,
  ALTERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  ALTERAR_MOTIVO_DE_INTERNACAO,
  ALTERAR_OPERADORA,
  ALTERAR_ORIENTACAO,
  ALTERAR_PACIENTE_NA_LISTA_DE_ESPERA,
  ALTERAR_PEDIDO_DE_CONTATO,
  ALTERAR_PHQ9,
  ALTERAR_PLANO_DE_CUIDADO,
  ALTERAR_PLANO_DE_CUIDADO_DO_PACIENTE,
  ALTERAR_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE,
  ALTERAR_POSICAO_DOS_MEDICAMENTOS_DO_PACIENTE,
  ALTERAR_PROFISSAO,
  ALTERAR_PROFISSIONAL_EXTERNO,
  ALTERAR_PROGRAMA,
  ALTERAR_PROGRAMA_DO_PACIENTE,
  ALTERAR_QUEDA_DO_PACIENTE,
  ALTERAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
  ALTERAR_SALA_DA_UNIDADE,
  ALTERAR_SENHA_DO_USUARIO,
  ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ALTERAR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ALTERAR_SETOR,
  ALTERAR_STATUS_DA_TAREFA,
  ALTERAR_STATUS_DE_UMA_RESPOSTA,
  ALTERAR_STATUS_DO_DIAGNOSTICO_DO_PACIENTE,
  ALTERAR_STATUS_DO_ENCAMINHAMENTO,
  ALTERAR_STATUS_DO_GRUPO_DE_CUIDADO,
  ALTERAR_STATUS_DO_MEDICAMENTO_DO_PACIENTE,
  ALTERAR_STATUS_DO_USUARIO,
  ALTERAR_VACINA_DO_PACIENTE,
  ALTEROU_ASSOCIACAO,
  ALTEROU_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  ALTEROU_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  ALTEROU_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  ALTEROU_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTEROU_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ALTEROU_CAMPO_EXTERNO_DO_PACIENTE,
  ALTEROU_CASP_16_DO_PACIENTE,
  ALTEROU_CELULA,
  ALTEROU_COMENTARIO_DA_TAREFA,
  ALTEROU_CONCENTRACAO_DO_MEDICAMENTO,
  ALTEROU_CONFIGURACAO_DO_USUARIO,
  ALTEROU_CONSULTA_EXTERNA_DO_PACIENTE,
  ALTEROU_CONVENIO_DO_PACIENTE,
  ALTEROU_DADOS_SELECIONADOS_DO_AGENDAMENTO_NA_LISTA_DE_ESPERA,
  ALTEROU_DIAGNOSTICO,
  ALTEROU_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  ALTEROU_ESPECIALIDADE,
  ALTEROU_ESTABELECIMENTO_DE_SAUDE,
  ALTEROU_FABRICANTE,
  ALTEROU_GRUPO_DE_CUIDADO,
  ALTEROU_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTEROU_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  ALTEROU_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  ALTEROU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ALTEROU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ALTEROU_INTERNACAO_DO_PACIENTE,
  ALTEROU_IVCF_DO_PACIENTE,
  ALTEROU_MEDICAMENTO,
  ALTEROU_MEDICAMENTO_DO_PACIENTE,
  ALTEROU_MINI_MENTAL,
  ALTEROU_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  ALTEROU_MOTIVO_DE_INTERNACAO,
  ALTEROU_OPERADORA,
  ALTEROU_ORIENTACAO,
  ALTEROU_PACIENTE_NA_LISTA_DE_ESPERA,
  ALTEROU_PEDIDO_DE_CONTATO,
  ALTEROU_PHQ9,
  ALTEROU_PLANO_DE_CUIDADO,
  ALTEROU_PLANO_DE_CUIDADO_DO_PACIENTE,
  ALTEROU_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE,
  ALTEROU_POSICAO_DOS_MEDICAMENTOS_DO_PACIENTE,
  ALTEROU_PROFISSAO,
  ALTEROU_PROFISSIONAL_EXTERNO,
  ALTEROU_PROGRAMA,
  ALTEROU_PROGRAMA_DO_PACIENTE,
  ALTEROU_QUEDA_DO_PACIENTE,
  ALTEROU_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
  ALTEROU_SALA_DA_UNIDADE,
  ALTEROU_SENHA_DO_USUARIO,
  ALTEROU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTEROU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ALTEROU_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTEROU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ALTEROU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ALTEROU_SETOR,
  ALTEROU_STATUS_DA_TAREFA,
  ALTEROU_STATUS_DE_UMA_RESPOSTA,
  ALTEROU_STATUS_DO_DIAGNOSTICO_DO_PACIENTE,
  ALTEROU_STATUS_DO_ENCAMINHAMENTO,
  ALTEROU_STATUS_DO_GRUPO_DE_CUIDADO,
  ALTEROU_STATUS_DO_MEDICAMENTO_DO_PACIENTE,
  ALTEROU_STATUS_DO_USUARIO,
  ALTEROU_VACINA_DO_PACIENTE,
  ASSINAR_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM,
  ASSINOU_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM,
  ATIVAR_UNIDADE_DA_EMPRESA,
  ATIVOU_UNIDADE_DA_EMPRESA,
  AUTENTICAR_MEDICO_NA_MEMED,
  AUTENTICAR_USUARIO,
  AUTENTICOU_MEDICO_NA_MEMED,
  AUTENTICOU_USUARIO,
  CANCELAR_AGENDAMENTO,
  CANCELAR_ATENDIMENTO,
  CANCELOU_AGENDAMENTO,
  CANCELOU_ATENDIMENTO,
  CARREGAR_DETALHES_DO_ATENDIMENTO_FINALIZADO,
  CARREGOU_DETALHES_DO_ATENDIMENTO_FINALIZADO,
  CONFIRMAR_AGENDAMENTO,
  CONFIRMAR_CHEGADA_DO_PACIENTE,
  CONFIRMOU_AGENDAMENTO,
  CONFIRMOU_CHEGADA_DO_PACIENTE,
  CRIAR_TAREFA,
  CRIAR_TAREFA_DO_PACIENTE,
  CRIOU_TAREFA,
  CRIOU_TAREFA_DO_PACIENTE,
  DEFINIR_CATEGORIA_DA_TAREFA,
  DEFINIR_DATA_DA_TAREFA,
  DEFINIR_DESCRICAO_DA_TAREFA,
  DEFINIR_MOMENTO_DA_TAREFA,
  DEFINIR_MOTIVO_DO_CANCELAMENTO_DA_TAREFA,
  DEFINIR_NUMERO_INTEIRO_DA_TAREFA,
  DEFINIR_PACIENTE_DA_TAREFA,
  DEFINIR_PRAZO_DA_TAREFA,
  DEFINIR_PROFISSIONAL_RESPONSAVEL_DA_TAREFA,
  DEFINIR_SETOR_RESPONSAVEL_DA_TAREFA,
  DEFINIR_TELEFONE_DE_CONTATO_DA_TAREFA,
  DEFINIR_TEXTO_DA_TAREFA,
  DEFINIR_TEXTO_LONGO_DA_TAREFA,
  DEFINIR_URGENCIA_DA_TAREFA,
  DEFINIU_CATEGORIA_DA_TAREFA,
  DEFINIU_DATA_DA_TAREFA,
  DEFINIU_DESCRICAO_DA_TAREFA,
  DEFINIU_MOMENTO_DA_TAREFA,
  DEFINIU_MOTIVO_DO_CANCELAMENTO_DA_TAREFA,
  DEFINIU_NUMERO_INTEIRO_DA_TAREFA,
  DEFINIU_PACIENTE_DA_TAREFA,
  DEFINIU_PRAZO_DA_TAREFA,
  DEFINIU_PROFISSIONAL_RESPONSAVEL_DA_TAREFA,
  DEFINIU_SETOR_RESPONSAVEL_DA_TAREFA,
  DEFINIU_TELEFONE_DE_CONTATO_DA_TAREFA,
  DEFINIU_TEXTO_DA_TAREFA,
  DEFINIU_TEXTO_LONGO_DA_TAREFA,
  DEFINIU_URGENCIA_DA_TAREFA,
  DESABILITAR_PROFISSAO_PARA_O_NPS,
  DESABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO,
  DESABILITAR_TIPO_DE_ATENDIMENTO_PARA_O_NPS,
  DESABILITOU_PROFISSAO_PARA_O_NPS,
  DESABILITOU_TIPO_DE_ATENDIMENTO_DO_USUARIO,
  DESABILITOU_TIPO_DE_ATENDIMENTO_PARA_O_NPS,
  DESATIVAR_UNIDADE_DA_EMPRESA,
  DESATIVOU_UNIDADE_DA_EMPRESA,
  DESMARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA,
  DESMARCOU_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA,
  ERRO_AO_ADICIONAR_AGENDAMENTO,
  ERRO_AO_ADICIONAR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  ERRO_AO_ADICIONAR_ARQUIVO,
  ERRO_AO_ADICIONAR_ASSOCIACAO,
  ERRO_AO_ADICIONAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  ERRO_AO_ADICIONAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  ERRO_AO_ADICIONAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ADICIONAR_CAMPO_EXTERNO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_CASP_16_DO_PACIENTE,
  ERRO_AO_ADICIONAR_CELULA,
  ERRO_AO_ADICIONAR_COMENTARIO_DA_TAREFA,
  ERRO_AO_ADICIONAR_CONCENTRACAO_DO_MEDICAMENTO,
  ERRO_AO_ADICIONAR_CONSULTA_EXTERNA_DO_PACIENTE,
  ERRO_AO_ADICIONAR_CONVENIO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_DIAGNOSTICO,
  ERRO_AO_ADICIONAR_DIAGNOSTICO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  ERRO_AO_ADICIONAR_ESPECIALIDADE,
  ERRO_AO_ADICIONAR_ESPECIALIDADE_MEDICA_INTERNA,
  ERRO_AO_ADICIONAR_ESTABELECIMENTO_DE_SAUDE,
  ERRO_AO_ADICIONAR_FABRICANTE,
  ERRO_AO_ADICIONAR_FERIADO,
  ERRO_AO_ADICIONAR_FERIADO_TRABALHADO_DO_PROFISSIONAL,
  ERRO_AO_ADICIONAR_FOTO_DA_EMPRESA,
  ERRO_AO_ADICIONAR_FOTO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_FOTO_DO_USUARIO,
  ERRO_AO_ADICIONAR_GRUPO_DE_CUIDADO,
  ERRO_AO_ADICIONAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ADICIONAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_INFORMACOES,
  ERRO_AO_ADICIONAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ADICIONAR_INTERNACAO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_INTERPRETACAO_MEDICA_DO_MINI_MENTAL,
  ERRO_AO_ADICIONAR_IVCF_DO_PACIENTE,
  ERRO_AO_ADICIONAR_MEDICAMENTO,
  ERRO_AO_ADICIONAR_MEDICAMENTO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_MINI_MENTAL,
  ERRO_AO_ADICIONAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  ERRO_AO_ADICIONAR_MOTIVO_DE_INTERNACAO,
  ERRO_AO_ADICIONAR_OPERADORA,
  ERRO_AO_ADICIONAR_ORIENTACAO,
  ERRO_AO_ADICIONAR_PACIENTE,
  ERRO_AO_ADICIONAR_PACIENTE_NA_LISTA_DE_ESPERA,
  ERRO_AO_ADICIONAR_PEDIDO_DE_CONTATO,
  ERRO_AO_ADICIONAR_PERFIL_DO_USUARIO,
  ERRO_AO_ADICIONAR_PHQ9,
  ERRO_AO_ADICIONAR_PLANO_DE_CUIDADO,
  ERRO_AO_ADICIONAR_PLANO_DE_CUIDADO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_PRE_CADASTRO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DA_TAREFA,
  ERRO_AO_ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  ERRO_AO_ADICIONAR_PROFISSAO,
  ERRO_AO_ADICIONAR_PROFISSIONAL_EXTERNO,
  ERRO_AO_ADICIONAR_PROGRAMA,
  ERRO_AO_ADICIONAR_PROGRAMA_DO_PACIENTE,
  ERRO_AO_ADICIONAR_QUEDA_DO_PACIENTE,
  ERRO_AO_ADICIONAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_SALA_NA_UNIDADE,
  ERRO_AO_ADICIONAR_SETOR,
  ERRO_AO_ADICIONAR_SUMARIO_DE_ALTA_DA_INTERNACAO,
  ERRO_AO_ADICIONAR_TAG_NO_PACIENTE,
  ERRO_AO_ADICIONAR_TAG_NO_PACIENTE_DO_PRONTUARIO,
  ERRO_AO_ADICIONAR_TEMPLATE,
  ERRO_AO_ADICIONAR_TEMPLATE_DO_PRONTUARIO,
  ERRO_AO_ADICIONAR_TIPO_DE_AGENDAMENTO,
  ERRO_AO_ADICIONAR_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL,
  ERRO_AO_ADICIONAR_UNIDADE_DA_EMPRESA,
  ERRO_AO_ADICIONAR_UNIDADE_DO_USUARIO,
  ERRO_AO_ADICIONAR_USUARIO,
  ERRO_AO_ADICIONAR_VACINA_DO_PACIENTE,
  ERRO_AO_ALTERAR_ASSOCIACAO,
  ERRO_AO_ALTERAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  ERRO_AO_ALTERAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  ERRO_AO_ALTERAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  ERRO_AO_ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_ALTERAR_CAMPO_EXTERNO_DO_PACIENTE,
  ERRO_AO_ALTERAR_CASP_16_DO_PACIENTE,
  ERRO_AO_ALTERAR_CELULA,
  ERRO_AO_ALTERAR_COMENTARIO_DA_TAREFA,
  ERRO_AO_ALTERAR_CONCENTRACAO_DO_MEDICAMENTO,
  ERRO_AO_ALTERAR_CONFIGURACAO_DO_USUARIO,
  ERRO_AO_ALTERAR_CONSULTA_EXTERNA_DO_PACIENTE,
  ERRO_AO_ALTERAR_CONVENIO_DO_PACIENTE,
  ERRO_AO_ALTERAR_DADOS_SELECIONADOS_DO_AGENDAMENTO_NA_LISTA_DE_ESPERA,
  ERRO_AO_ALTERAR_DIAGNOSTICO,
  ERRO_AO_ALTERAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  ERRO_AO_ALTERAR_ESPECIALIDADE,
  ERRO_AO_ALTERAR_ESTABELECIMENTO_DE_SAUDE,
  ERRO_AO_ALTERAR_FABRICANTE,
  ERRO_AO_ALTERAR_GRUPO_DE_CUIDADO,
  ERRO_AO_ALTERAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ALTERAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  ERRO_AO_ALTERAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  ERRO_AO_ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_ALTERAR_INTERNACAO_DO_PACIENTE,
  ERRO_AO_ALTERAR_IVCF_DO_PACIENTE,
  ERRO_AO_ALTERAR_MEDICAMENTO,
  ERRO_AO_ALTERAR_MEDICAMENTO_DO_PACIENTE,
  ERRO_AO_ALTERAR_MINI_MENTAL,
  ERRO_AO_ALTERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  ERRO_AO_ALTERAR_MOTIVO_DE_INTERNACAO,
  ERRO_AO_ALTERAR_OPERADORA,
  ERRO_AO_ALTERAR_ORIENTACAO,
  ERRO_AO_ALTERAR_PACIENTE_NA_LISTA_DE_ESPERA,
  ERRO_AO_ALTERAR_PEDIDO_DE_CONTATO,
  ERRO_AO_ALTERAR_PHQ9,
  ERRO_AO_ALTERAR_PLANO_DE_CUIDADO,
  ERRO_AO_ALTERAR_PLANO_DE_CUIDADO_DO_PACIENTE,
  ERRO_AO_ALTERAR_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE,
  ERRO_AO_ALTERAR_POSICAO_DOS_MEDICAMENTOS_DO_PACIENTE,
  ERRO_AO_ALTERAR_PROFISSAO,
  ERRO_AO_ALTERAR_PROFISSIONAL_EXTERNO,
  ERRO_AO_ALTERAR_PROGRAMA,
  ERRO_AO_ALTERAR_PROGRAMA_DO_PACIENTE,
  ERRO_AO_ALTERAR_QUEDA_DO_PACIENTE,
  ERRO_AO_ALTERAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
  ERRO_AO_ALTERAR_SALA_DA_UNIDADE,
  ERRO_AO_ALTERAR_SENHA_DO_USUARIO,
  ERRO_AO_ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_ALTERAR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_ALTERAR_SETOR,
  ERRO_AO_ALTERAR_STATUS_DA_TAREFA,
  ERRO_AO_ALTERAR_STATUS_DE_UMA_RESPOSTA,
  ERRO_AO_ALTERAR_STATUS_DO_DIAGNOSTICO_DO_PACIENTE,
  ERRO_AO_ALTERAR_STATUS_DO_ENCAMINHAMENTO,
  ERRO_AO_ALTERAR_STATUS_DO_GRUPO_DE_CUIDADO,
  ERRO_AO_ALTERAR_STATUS_DO_MEDICAMENTO_DO_PACIENTE,
  ERRO_AO_ALTERAR_STATUS_DO_USUARIO,
  ERRO_AO_ALTERAR_VACINA_DO_PACIENTE,
  ERRO_AO_ASSINAR_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM,
  ERRO_AO_ATIVAR_UNIDADE_DA_EMPRESA,
  ERRO_AO_AUTENTICAR_MEDICO_NA_MEMED,
  ERRO_AO_CANCELAR_AGENDAMENTO,
  ERRO_AO_CANCELAR_ATENDIMENTO,
  ERRO_AO_CARREGAR_DETALHES_DO_ATENDIMENTO_FINALIZADO,
  ERRO_AO_CONFIRMAR_AGENDAMENTO,
  ERRO_AO_CONFIRMAR_CHEGADA_DO_PACIENTE,
  ERRO_AO_CRIAR_TAREFA,
  ERRO_AO_CRIAR_TAREFA_DO_PACIENTE,
  ERRO_AO_DEFINIR_CATEGORIA_DA_TAREFA,
  ERRO_AO_DEFINIR_DATA_DA_TAREFA,
  ERRO_AO_DEFINIR_DESCRICAO_DA_TAREFA,
  ERRO_AO_DEFINIR_MOMENTO_DA_TAREFA,
  ERRO_AO_DEFINIR_MOTIVO_DO_CANCELAMENTO_DA_TAREFA,
  ERRO_AO_DEFINIR_NUMERO_INTEIRO_DA_TAREFA,
  ERRO_AO_DEFINIR_PACIENTE_DA_TAREFA,
  ERRO_AO_DEFINIR_PRAZO_DA_TAREFA,
  ERRO_AO_DEFINIR_PROFISSIONAL_RESPONSAVEL_DA_TAREFA,
  ERRO_AO_DEFINIR_SETOR_RESPONSAVEL_DA_TAREFA,
  ERRO_AO_DEFINIR_TELEFONE_DE_CONTATO_DA_TAREFA,
  ERRO_AO_DEFINIR_TEXTO_DA_TAREFA,
  ERRO_AO_DEFINIR_TEXTO_LONGO_DA_TAREFA,
  ERRO_AO_DEFINIR_URGENCIA_DA_TAREFA,
  ERRO_AO_DESABILITAR_PROFISSAO_PARA_O_NPS,
  ERRO_AO_DESABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO,
  ERRO_AO_DESABILITAR_TIPO_DE_ATENDIMENTO_PARA_O_NPS,
  ERRO_AO_DESATIVAR_UNIDADE_DA_EMPRESA,
  ERRO_AO_DESMARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA,
  ERRO_AO_EXCLUIR_AGENDAMENTO,
  ERRO_AO_EXCLUIR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  ERRO_AO_EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_EXCLUIR_FERIADO,
  ERRO_AO_EXCLUIR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_EXCLUIR_SERIE_DE_FERIADOS,
  ERRO_AO_EXCLUIR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_EXCLUIR_TEMPLATE,
  ERRO_AO_FAZER_DOWNLOAD_DA_PRESCRICAO,
  ERRO_AO_FAZER_DOWNLOAD_DE_ATENDIMENTOS,
  ERRO_AO_FAZER_DOWNLOAD_DE_IDAS_AO_PRONTO_ATENDIMENTO,
  ERRO_AO_FAZER_DOWNLOAD_DE_INTERNACOES,
  ERRO_AO_FAZER_DOWNLOAD_DE_INTERNACOES_POR_FRATURA_DE_FEMUR,
  ERRO_AO_FAZER_DOWNLOAD_DE_PACIENTES,
  ERRO_AO_FAZER_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA,
  ERRO_AO_FAZER_DOWNLOAD_DE_REINTERNACOES,
  ERRO_AO_FAZER_DOWNLOAD_DO_ANEXO_DO_ATENDIMENTO,
  ERRO_AO_FAZER_DOWNLOAD_DO_ARQUIVO_ADICIONAL_DO_ATENDIMENTO,
  ERRO_AO_FAZER_DOWNLOAD_DO_ARQUIVO_DA_ORIENTACAO,
  ERRO_AO_FAZER_DOWNLOAD_DO_ARQUIVO_DE_ATENDIMENTO,
  ERRO_AO_FAZER_DOWNLOAD_DO_ARQUIVO_DE_VIDEO_CONSULTA,
  ERRO_AO_FAZER_DOWNLOAD_DO_ENCAMINHAMENTO,
  ERRO_AO_FAZER_DOWNLOAD_DO_EXAME_DO_ATENDIMENTO,
  ERRO_AO_FAZER_DOWNLOAD_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS,
  ERRO_AO_FILTRAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA,
  ERRO_AO_FINALIZAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO,
  ERRO_AO_FINALIZAR_ATENDIMENTO,
  ERRO_AO_GERAR_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO,
  ERRO_AO_GERAR_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO,
  ERRO_AO_HABILITAR_PROFISSOES_PARA_O_NPS,
  ERRO_AO_HABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO,
  ERRO_AO_HABILITAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS,
  ERRO_AO_IMPORTAR_PACIENTES,
  ERRO_AO_INFORMAR_SAIDA_DO_PACIENTE,
  ERRO_AO_INICIAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO,
  ERRO_AO_INICIAR_ATENDIMENTO,
  ERRO_AO_INICIAR_SESSAO_COM_CERTIFICADO_EM_NUVEM,
  ERRO_AO_LER_CERTIFICADOS_DIGITAIS_FISICOS,
  ERRO_AO_LISTAR_AGENDAMENTOS,
  ERRO_AO_LISTAR_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO,
  ERRO_AO_LISTAR_AGENDAMENTOS_DO_DIA,
  ERRO_AO_LISTAR_AGENDAMENTOS_PARA_CONFIRMACAO_DE_AGENDAMENTO,
  ERRO_AO_LISTAR_ANTIBIOTICOS,
  ERRO_AO_LISTAR_ASSOCIACOES,
  ERRO_AO_LISTAR_ASSOCIACOES_DO_CADASTRO,
  ERRO_AO_LISTAR_ATENDIMENTOS_FINALIZADOS,
  ERRO_AO_LISTAR_ATENDIMENTOS_NAO_ASSINADOS,
  ERRO_AO_LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  ERRO_AO_LISTAR_AVALIACOES_DA_SOLIDAO_DO_PACIENTE,
  ERRO_AO_LISTAR_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE,
  ERRO_AO_LISTAR_AVALIACOES_RESPONDIDAS,
  ERRO_AO_LISTAR_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_LISTAR_CAMPOS_EXTERNOS_DO_PACIENTE,
  ERRO_AO_LISTAR_CASPS_16_DO_PACIENTE,
  ERRO_AO_LISTAR_CATEGORIAS_DO_TAREFAS,
  ERRO_AO_LISTAR_CELULAS,
  ERRO_AO_LISTAR_CELULAS_DO_CADASTRO,
  ERRO_AO_LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES,
  ERRO_AO_LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  ERRO_AO_LISTAR_CIDADES_PARA_O_PEDIDO_DE_CONTATO,
  ERRO_AO_LISTAR_CIDS10,
  ERRO_AO_LISTAR_COMPONENTES_COM_TEMPLATE,
  ERRO_AO_LISTAR_CONCENTRACOES_DO_MEDICAMENTO,
  ERRO_AO_LISTAR_CONSULTAS_EXTERNAS_DO_PACIENTE,
  ERRO_AO_LISTAR_CONSULTORES_DO_PACIENTE,
  ERRO_AO_LISTAR_CONVENIOS_DO_PACIENTE,
  ERRO_AO_LISTAR_DIAGNOSTICOS,
  ERRO_AO_LISTAR_DIAGNOSTICOS_DO_PACIENTE,
  ERRO_AO_LISTAR_DIAGNOSTICOS_PARA_O_PRONTUARIO,
  ERRO_AO_LISTAR_DURACOES_DAS_DORES,
  ERRO_AO_LISTAR_EMPRESAS_HABILITADAS_PARA_O_LOGIN,
  ERRO_AO_LISTAR_ENCAMINHAMENTOS,
  ERRO_AO_LISTAR_ENFERMEIROS,
  ERRO_AO_LISTAR_ENFERMEIROS_DO_PACIENTE,
  ERRO_AO_LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES,
  ERRO_AO_LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  ERRO_AO_LISTAR_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  ERRO_AO_LISTAR_ESPECIALIDADES,
  ERRO_AO_LISTAR_ESPECIALIDADES_MEDICAS_INTERNAS,
  ERRO_AO_LISTAR_ESTABELECIMENTOS_DE_SAUDE,
  ERRO_AO_LISTAR_ESTABELECIMENTOS_DE_SAUDE_DO_CADASTRO,
  ERRO_AO_LISTAR_ESTADOS_DA_MEMORIA_ATUAL,
  ERRO_AO_LISTAR_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS,
  ERRO_AO_LISTAR_FABRICANTES,
  ERRO_AO_LISTAR_FABRICANTES_DO_CADASTRO,
  ERRO_AO_LISTAR_FERIADOS,
  ERRO_AO_LISTAR_FERIADOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_LISTAR_FERIADOS_TRABALHADOS_DO_PROFISSIONAL,
  ERRO_AO_LISTAR_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO,
  ERRO_AO_LISTAR_FREQUENCIAS_NA_ESCALA_ZARIT,
  ERRO_AO_LISTAR_GRAUS_DE_RELACOES,
  ERRO_AO_LISTAR_GRUPOS_DE_CUIDADO,
  ERRO_AO_LISTAR_GRUPOS_DE_CUIDADO_DO_CADASTRO,
  ERRO_AO_LISTAR_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE,
  ERRO_AO_LISTAR_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_LISTAR_HORARIOS_LIVRES,
  ERRO_AO_LISTAR_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO,
  ERRO_AO_LISTAR_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  ERRO_AO_LISTAR_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE,
  ERRO_AO_LISTAR_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_LISTAR_INTENSIDADES_DAS_DORES,
  ERRO_AO_LISTAR_INTERNACOES_DO_PACIENTE,
  ERRO_AO_LISTAR_IVCFS_DO_PACIENTE,
  ERRO_AO_LISTAR_LOCAIS_DA_APLICACAO,
  ERRO_AO_LISTAR_LOCAIS_DAS_DORES,
  ERRO_AO_LISTAR_LOCAIS_DE_QUEDAS,
  ERRO_AO_LISTAR_MEDICAMENTOS,
  ERRO_AO_LISTAR_MEDICAMENTOS_DO_PACIENTE,
  ERRO_AO_LISTAR_MEDICAMENTOS_DO_PRONTUARIO,
  ERRO_AO_LISTAR_MEDICOS,
  ERRO_AO_LISTAR_MEDICOS_DO_PACIENTE,
  ERRO_AO_LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES,
  ERRO_AO_LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  ERRO_AO_LISTAR_MICRORGANISMOS,
  ERRO_AO_LISTAR_MINI_MENTAIS,
  ERRO_AO_LISTAR_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO,
  ERRO_AO_LISTAR_MOTIVOS_DE_BLOQUEIO_DO_LOGIN,
  ERRO_AO_LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_CONVENIO,
  ERRO_AO_LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_TAREFAS,
  ERRO_AO_LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO,
  ERRO_AO_LISTAR_MOTIVOS_DE_INTERNACAO,
  ERRO_AO_LISTAR_MOTIVOS_DE_INTERNACAO_DO_CADASTRO,
  ERRO_AO_LISTAR_NIVEIS_DE_SAUDE_RELATIVA,
  ERRO_AO_LISTAR_OPERADORAS,
  ERRO_AO_LISTAR_OPERADORAS_DO_CADASTRO,
  ERRO_AO_LISTAR_OPERADORAS_DO_PACIENTE,
  ERRO_AO_LISTAR_OPERADORAS_DO_RELATORIO_DE_PACIENTES,
  ERRO_AO_LISTAR_ORIENTACOES,
  ERRO_AO_LISTAR_ORIENTACOES_DE_PAGINAS,
  ERRO_AO_LISTAR_PACIENTES,
  ERRO_AO_LISTAR_PACIENTES_DO_AGENDAMENTO,
  ERRO_AO_LISTAR_PACIENTES_DO_NPS,
  ERRO_AO_LISTAR_PACIENTES_DO_TAREFAS,
  ERRO_AO_LISTAR_PACIENTES_DO_WIZARD_DE_AGENDAMENTO,
  ERRO_AO_LISTAR_PACIENTES_DOS_FILTROS_DO_TAREFAS,
  ERRO_AO_LISTAR_PEDIDOS_DE_CONTATO,
  ERRO_AO_LISTAR_PERFIS,
  ERRO_AO_LISTAR_PHQS9,
  ERRO_AO_LISTAR_PLANOS_DE_CUIDADO,
  ERRO_AO_LISTAR_PLANOS_DE_CUIDADO_DO_PACIENTE,
  ERRO_AO_LISTAR_PLANOS_DE_SAUDE_PARA_O_PEDIDO_DE_CONTATO,
  ERRO_AO_LISTAR_POSOLOGIAS,
  ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE,
  ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA,
  ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE_DE_SUGESTAO,
  ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE_DO_WIZARD_DE_AGENDAMENTO,
  ERRO_AO_LISTAR_PROFISSIONAIS_DO_NPS,
  ERRO_AO_LISTAR_PROFISSIONAIS_EXTERNOS,
  ERRO_AO_LISTAR_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS,
  ERRO_AO_LISTAR_PROFISSOES,
  ERRO_AO_LISTAR_PROFISSOES_DO_CADASTRO,
  ERRO_AO_LISTAR_PROFISSOES_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS,
  ERRO_AO_LISTAR_PROFISSOES_DO_WIZARD_DE_AGENDAMENTO,
  ERRO_AO_LISTAR_PROFISSOES_PARA_O_NPS,
  ERRO_AO_LISTAR_PROGRAMAS,
  ERRO_AO_LISTAR_PROGRAMAS_DO_PACIENTE,
  ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_ATENDIMENTOS,
  ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_IDAS_AO_PRONTO_ATENDIMENTO,
  ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_INTERNACOES,
  ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES,
  ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_REINTERNACOES,
  ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_RESUMO_DE_INTERNACOES,
  ERRO_AO_LISTAR_PROXIMOS_AGENDAMENTOS_MEDICOS_DO_PACIENTE,
  ERRO_AO_LISTAR_PSICOLOGOS_DO_PACIENTE,
  ERRO_AO_LISTAR_QUEDAS_DO_PACIENTE,
  ERRO_AO_LISTAR_RECORRENCIAS_DO_AGENDAMENTO,
  ERRO_AO_LISTAR_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE,
  ERRO_AO_LISTAR_RESPOSTAS_DO_CASP16,
  ERRO_AO_LISTAR_RESPOSTAS_DO_PHQ9,
  ERRO_AO_LISTAR_SALAS_DA_UNIDADE,
  ERRO_AO_LISTAR_SETORES,
  ERRO_AO_LISTAR_SETORES_RESPONSAVEIS_DO_TAREFAS,
  ERRO_AO_LISTAR_SEXOS,
  ERRO_AO_LISTAR_STATUS_DE_ENCAMINHAMENTO,
  ERRO_AO_LISTAR_STATUS_DOS_AGENDAMENTOS,
  ERRO_AO_LISTAR_SUGESTOES_DE_PACIENTE,
  ERRO_AO_LISTAR_TAGS_DE_PACIENTES,
  ERRO_AO_LISTAR_TAMANHOS_DE_PAGINAS,
  ERRO_AO_LISTAR_TEMPLATES,
  ERRO_AO_LISTAR_TEMPLATES_DO_PRONTUARIO,
  ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO,
  ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DA_PESQUISA,
  ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DAS_CONFIGURACOES_DA_AGENDA,
  ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DO_AGENDAMENTO,
  ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA,
  ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO_DO_PRONTUARIO,
  ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO,
  ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS,
  ERRO_AO_LISTAR_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER,
  ERRO_AO_LISTAR_TIPOS_DE_CONSULTA_EXTERNA,
  ERRO_AO_LISTAR_TIPOS_DE_ENCAMINHAMENTO,
  ERRO_AO_LISTAR_TIPOS_DE_EXAME,
  ERRO_AO_LISTAR_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA,
  ERRO_AO_LISTAR_TIPOS_DE_TAREFA,
  ERRO_AO_LISTAR_TIPOS_DE_UNIDADE,
  ERRO_AO_LISTAR_TOKENS_DE_EXEMPLO,
  ERRO_AO_LISTAR_TOKENS_DE_SUGESTAO,
  ERRO_AO_LISTAR_TRATAMENTOS,
  ERRO_AO_LISTAR_UNIDADES_DA_EMPRESA,
  ERRO_AO_LISTAR_UNIDADES_DA_EMPRESA_DO_USUARIO,
  ERRO_AO_LISTAR_UNIDADES_DO_USUARIO,
  ERRO_AO_LISTAR_UNIDADES_DO_USUARIO_LOGADO,
  ERRO_AO_LISTAR_URGENCIAS_DO_TAREFAS,
  ERRO_AO_LISTAR_USUARIOS,
  ERRO_AO_LISTAR_USUARIOS_BLOQUEADOS,
  ERRO_AO_LISTAR_VACINAS,
  ERRO_AO_LISTAR_VACINAS_DO_PACIENTE,
  ERRO_AO_LISTAR_VIAS_DE_ADMINISTRACAO_DE_MEDICAMENTOS,
  ERRO_AO_MARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA,
  ERRO_AO_RECUPERAR_AGENDA_SEMANAL_DO_PROFISSIONAL,
  ERRO_AO_RECUPERAR_AGENDAMENTO_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_ASSOCIACAO_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_ATENDIMENTO,
  ERRO_AO_RECUPERAR_ATENDIMENTO_INICIADO,
  ERRO_AO_RECUPERAR_AVALIACAO,
  ERRO_AO_RECUPERAR_AVALIACAO_RESPONDIDA,
  ERRO_AO_RECUPERAR_CABECALHO_DO_PRONTUARIO,
  ERRO_AO_RECUPERAR_CAMPO_EXTERNO_DO_PACIENTE,
  ERRO_AO_RECUPERAR_CELULA_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_CERTIFICADOS_EM_NUVEM_DO_USUARIO,
  ERRO_AO_RECUPERAR_CONFIGURACOES_DO_USUARIO,
  ERRO_AO_RECUPERAR_CONFIGURACOES_DO_USUARIO_LOGADO,
  ERRO_AO_RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO,
  ERRO_AO_RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO_LOGADO,
  ERRO_AO_RECUPERAR_CONVENIO_DO_PACIENTE,
  ERRO_AO_RECUPERAR_CONVENIOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  ERRO_AO_RECUPERAR_CONVENIOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_RECUPERAR_DESENHO_DO_MINI_MENTAL,
  ERRO_AO_RECUPERAR_DIAGNOSTICO_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_EMPRESA,
  ERRO_AO_RECUPERAR_ENDERECO_DA_UNIDADE_POR_CEP,
  ERRO_AO_RECUPERAR_ENDERECO_DO_PACIENTE_POR_CEP,
  ERRO_AO_RECUPERAR_ESPECIALIDADE_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_ESTABELECIMENTO_DE_SAUDE_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_FABRICANTE_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_FERIADO_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_FOTO_DA_EMPRESA_LOGADA,
  ERRO_AO_RECUPERAR_FOTO_DA_EMPRESA_POR_IDENTIFICADOR_PUBLICO,
  ERRO_AO_RECUPERAR_FOTO_DO_PACIENTE_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_FOTO_DO_USUARIO_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_GRUPO_DE_CUIDADO_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_HISTORICO_PRESCRICAO_NA_MEMED,
  ERRO_AO_RECUPERAR_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_RECUPERAR_LOGIN,
  ERRO_AO_RECUPERAR_MEDICAMENTO_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_MOTIVO_DE_INTERNACAO_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_OPERADORA_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_ORIENTACAO_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_PACIENTE_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_PEDIDO_DE_CONTATO_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_PLANO_DE_CUIDADO_DO_PACIENTE,
  ERRO_AO_RECUPERAR_PLANO_DE_CUIDADO_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_PRESCRICAO_PDF_NA_MEMED,
  ERRO_AO_RECUPERAR_PROFISSAO_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_PROFISSIONAL_EXTERNO_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_PROGRAMA_DO_PACIENTE,
  ERRO_AO_RECUPERAR_PROGRAMA_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_SALA_DA_UNIDADE_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_SENHA,
  ERRO_AO_RECUPERAR_SESSAO_COM_CERTIFICADO_EM_NUVEM,
  ERRO_AO_RECUPERAR_SETOR_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_TELECONSULTA_DO_AGENDAMENTO,
  ERRO_AO_RECUPERAR_TELECONSULTA_DO_ATENDIMENTO,
  ERRO_AO_RECUPERAR_TEMPLATE_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_TIPO_DE_AGENDAMENTO_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_TOKEN_DOS_RELATORIOS_DO_POWERBI,
  ERRO_AO_RECUPERAR_URL_AUTENTICACAO_DO_CERTIFICADO_EM_NUVEM,
  ERRO_AO_RECUPERAR_USUARIO_POR_IDENTIFICADOR,
  ERRO_AO_REDEFINIR_SENHA,
  ERRO_AO_REMOVER_ASSOCIACAO,
  ERRO_AO_REMOVER_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  ERRO_AO_REMOVER_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  ERRO_AO_REMOVER_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  ERRO_AO_REMOVER_CAMPO_EXTERNO_DO_PACIENTE,
  ERRO_AO_REMOVER_CASP_16_DO_PACIENTE,
  ERRO_AO_REMOVER_CELULA,
  ERRO_AO_REMOVER_COMENTARIO_DA_TAREFA,
  ERRO_AO_REMOVER_CONCENTRACAO_DO_MEDICAMENTO,
  ERRO_AO_REMOVER_CONSULTA_EXTERNA_DO_PACIENTE,
  ERRO_AO_REMOVER_CONVENIO_DO_PACIENTE,
  ERRO_AO_REMOVER_DIAGNOSTICO,
  ERRO_AO_REMOVER_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  ERRO_AO_REMOVER_ESPECIALIDADE,
  ERRO_AO_REMOVER_ESPECIALIDADE_MEDICA_INTERNA,
  ERRO_AO_REMOVER_ESTABELECIMENTO_DE_SAUDE,
  ERRO_AO_REMOVER_FABRICANTE,
  ERRO_AO_REMOVER_FERIADO_TRABALHADO_DO_PROFISSIONAL,
  ERRO_AO_REMOVER_GRUPO_DE_CUIDADO,
  ERRO_AO_REMOVER_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  ERRO_AO_REMOVER_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  ERRO_AO_REMOVER_INTERNACAO_DO_PACIENTE,
  ERRO_AO_REMOVER_INTERPRETACAO_MEDICA_DO_MINI_MENTAL,
  ERRO_AO_REMOVER_IVCF_DO_PACIENTE,
  ERRO_AO_REMOVER_MEDICAMENTO,
  ERRO_AO_REMOVER_MINI_MENTAL,
  ERRO_AO_REMOVER_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  ERRO_AO_REMOVER_MOTIVO_DE_INTERNACAO,
  ERRO_AO_REMOVER_OPERADORA,
  ERRO_AO_REMOVER_ORIENTACAO,
  ERRO_AO_REMOVER_PACIENTE_NA_LISTA_DE_ESPERA,
  ERRO_AO_REMOVER_PEDIDO_DE_CONTATO,
  ERRO_AO_REMOVER_PERFIL_DO_USUARIO,
  ERRO_AO_REMOVER_PHQ9,
  ERRO_AO_REMOVER_PLANO_DE_CUIDADO,
  ERRO_AO_REMOVER_PLANO_DE_CUIDADO_DO_PACIENTE,
  ERRO_AO_REMOVER_PRESCRICAO_NA_MEMED,
  ERRO_AO_REMOVER_PROFISSAO,
  ERRO_AO_REMOVER_PROFISSIONAL_EXTERNO,
  ERRO_AO_REMOVER_PROGRAMA,
  ERRO_AO_REMOVER_PROGRAMA_DO_PACIENTE,
  ERRO_AO_REMOVER_QUEDA_DO_PACIENTE,
  ERRO_AO_REMOVER_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
  ERRO_AO_REMOVER_SALA_DA_UNIDADE,
  ERRO_AO_REMOVER_SETOR,
  ERRO_AO_REMOVER_TAG_DO_PACIENTE,
  ERRO_AO_REMOVER_TAG_DO_PACIENTE_DO_PRONTUARIO,
  ERRO_AO_REMOVER_TIPO_DE_AGENDAMENTO,
  ERRO_AO_REMOVER_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL,
  ERRO_AO_REMOVER_UNIDADE_DA_EMPRESA,
  ERRO_AO_REMOVER_UNIDADE_DO_USUARIO,
  ERRO_AO_REMOVER_VACINA_DO_PACIENTE,
  ERRO_AO_RESPONDER_AVALIACAO,
  ERRO_AO_SALVAR_AGENDAMENTO,
  ERRO_AO_SALVAR_EMPRESA,
  ERRO_AO_SALVAR_FERIADO,
  ERRO_AO_SALVAR_PACIENTE,
  ERRO_AO_SALVAR_SECAO_DO_ATENDIMENTO,
  ERRO_AO_SALVAR_SERIE_DE_FERIADOS,
  ERRO_AO_SALVAR_TEMPLATE,
  ERRO_AO_SALVAR_TIPO_DE_AGENDAMENTO,
  ERRO_AO_SALVAR_UNIDADE_DA_EMPRESA,
  ERRO_AO_SALVAR_USUARIO,
  ERRO_AO_SELECIONAR_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  EXCLUIR_AGENDAMENTO,
  EXCLUIR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  EXCLUIR_FERIADO,
  EXCLUIR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  EXCLUIR_SERIE_DE_FERIADOS,
  EXCLUIR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  EXCLUIR_TEMPLATE,
  EXCLUIU_AGENDAMENTO,
  EXCLUIU_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  EXCLUIU_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIU_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  EXCLUIU_FERIADO,
  EXCLUIU_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  EXCLUIU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  EXCLUIU_SERIE_DE_FERIADOS,
  EXCLUIU_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  EXCLUIU_TEMPLATE,
  FAZER_DOWNLOAD_DA_PRESCRICAO,
  FAZER_DOWNLOAD_DE_ATENDIMENTOS,
  FAZER_DOWNLOAD_DE_IDAS_AO_PRONTO_ATENDIMENTO,
  FAZER_DOWNLOAD_DE_INTERNACOES,
  FAZER_DOWNLOAD_DE_INTERNACOES_POR_FRATURA_DE_FEMUR,
  FAZER_DOWNLOAD_DE_PACIENTES,
  FAZER_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA,
  FAZER_DOWNLOAD_DE_REINTERNACOES,
  FAZER_DOWNLOAD_DO_ANEXO_DO_ATENDIMENTO,
  FAZER_DOWNLOAD_DO_ARQUIVO_ADICIONAL_DO_ATENDIMENTO,
  FAZER_DOWNLOAD_DO_ARQUIVO_DA_ORIENTACAO,
  FAZER_DOWNLOAD_DO_ARQUIVO_DE_ATENDIMENTO,
  FAZER_DOWNLOAD_DO_ARQUIVO_DE_VIDEO_CONSULTA,
  FAZER_DOWNLOAD_DO_ENCAMINHAMENTO,
  FAZER_DOWNLOAD_DO_EXAME_DO_ATENDIMENTO,
  FAZER_DOWNLOAD_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS,
  FEZ_DOWNLOAD_DA_PRESCRICAO,
  FEZ_DOWNLOAD_DE_ATENDIMENTOS,
  FEZ_DOWNLOAD_DE_IDAS_AO_PRONTO_ATENDIMENTO,
  FEZ_DOWNLOAD_DE_INTERNACOES,
  FEZ_DOWNLOAD_DE_INTERNACOES_POR_FRATURA_DE_FEMUR,
  FEZ_DOWNLOAD_DE_PACIENTES,
  FEZ_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA,
  FEZ_DOWNLOAD_DE_REINTERNACOES,
  FEZ_DOWNLOAD_DO_ANEXO_DO_ATENDIMENTO,
  FEZ_DOWNLOAD_DO_ARQUIVO_ADICIONAL_DO_ATENDIMENTO,
  FEZ_DOWNLOAD_DO_ARQUIVO_DA_ORIENTACAO,
  FEZ_DOWNLOAD_DO_ARQUIVO_DE_ATENDIMENTO,
  FEZ_DOWNLOAD_DO_ARQUIVO_DE_VIDEO_CONSULTA,
  FEZ_DOWNLOAD_DO_ENCAMINHAMENTO,
  FEZ_DOWNLOAD_DO_EXAME_DO_ATENDIMENTO,
  FEZ_DOWNLOAD_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS,
  FILTRAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA,
  FILTROU_PROFISSIONAIS_DE_SAUDE_DA_AGENDA,
  FINALIZAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO,
  FINALIZAR_ATENDIMENTO,
  FINALIZOU_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO,
  FINALIZOU_ATENDIMENTO,
  GERAR_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO,
  GERAR_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO,
  GEROU_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO,
  GEROU_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO,
  HABILITAR_PROFISSOES_PARA_O_NPS,
  HABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO,
  HABILITAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS,
  HABILITOU_PROFISSOES_PARA_O_NPS,
  HABILITOU_TIPO_DE_ATENDIMENTO_DO_USUARIO,
  HABILITOU_TIPOS_DE_ATENDIMENTO_PARA_O_NPS,
  IMPORTAR_PACIENTES,
  IMPORTOU_PACIENTES,
  INFORMAR_SAIDA_DO_PACIENTE,
  INFORMOU_SAIDA_DO_PAICENTE,
  INICIAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO,
  INICIAR_ATENDIMENTO,
  INICIAR_SESSAO_COM_CERTIFICADO_EM_NUVEM,
  INICIOU_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO,
  INICIOU_ATENDIMENTO,
  INICIOU_SESSAO_COM_CERTIFICADO_EM_NUVEM,
  INTEGRAR_NA_MEMED,
  INTEGROU_NA_MEMED,
  LER_CERTIFICADOS_DIGITAIS_FISICOS,
  LEU_CERTIFICADOS_DIGITAIS_FISICOS,
  LIMPAR_HORARIOS_LIVRES,
  LISTAR_AGENDAMENTOS,
  LISTAR_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO,
  LISTAR_AGENDAMENTOS_DO_DIA,
  LISTAR_AGENDAMENTOS_PARA_CONFIRMACAO_DE_AGENDAMENTO,
  LISTAR_ANTIBIOTICOS,
  LISTAR_ASSOCIACOES,
  LISTAR_ASSOCIACOES_DO_CADASTRO,
  LISTAR_ATENDIMENTOS_FINALIZADOS,
  LISTAR_ATENDIMENTOS_NAO_ASSINADOS,
  LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  LISTAR_AVALIACOES_DA_SOLIDAO_DO_PACIENTE,
  LISTAR_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE,
  LISTAR_AVALIACOES_RESPONDIDAS,
  LISTAR_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  LISTAR_CAMPOS_EXTERNOS_DO_PACIENTE,
  LISTAR_CASPS_16_DO_PACIENTE,
  LISTAR_CATEGORIAS_DO_TAREFAS,
  LISTAR_CELULAS,
  LISTAR_CELULAS_DO_CADASTRO,
  LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES,
  LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  LISTAR_CIDADES_PARA_O_PEDIDO_DE_CONTATO,
  LISTAR_CIDS10,
  LISTAR_COMPONENTES_COM_TEMPLATE,
  LISTAR_CONCENTRACOES_DO_MEDICAMENTO,
  LISTAR_CONSULTAS_EXTERNAS_DO_PACIENTE,
  LISTAR_CONSULTORES_DO_PACIENTE,
  LISTAR_CONVENIOS_DO_PACIENTE,
  LISTAR_DIAGNOSTICOS,
  LISTAR_DIAGNOSTICOS_DO_PACIENTE,
  LISTAR_DIAGNOSTICOS_PARA_O_PRONTUARIO,
  LISTAR_DURACOES_DAS_DORES,
  LISTAR_EMPRESAS_HABILITADAS_PARA_O_LOGIN,
  LISTAR_ENCAMINHAMENTOS,
  LISTAR_ENFERMEIROS,
  LISTAR_ENFERMEIROS_DO_PACIENTE,
  LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES,
  LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  LISTAR_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  LISTAR_ESPECIALIDADES,
  LISTAR_ESPECIALIDADES_MEDICAS_INTERNAS,
  LISTAR_ESTABELECIMENTOS_DE_SAUDE,
  LISTAR_ESTABELECIMENTOS_DE_SAUDE_DO_CADASTRO,
  LISTAR_ESTADOS_DA_MEMORIA_ATUAL,
  LISTAR_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS,
  LISTAR_FABRICANTES,
  LISTAR_FABRICANTES_DO_CADASTRO,
  LISTAR_FERIADOS,
  LISTAR_FERIADOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  LISTAR_FERIADOS_TRABALHADOS_DO_PROFISSIONAL,
  LISTAR_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO,
  LISTAR_FREQUENCIAS_NA_ESCALA_ZARIT,
  LISTAR_GRAUS_DE_RELACOES,
  LISTAR_GRUPOS_DE_CUIDADO,
  LISTAR_GRUPOS_DE_CUIDADO_DO_CADASTRO,
  LISTAR_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE,
  LISTAR_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  LISTAR_HORARIOS_LIVRES,
  LISTAR_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO,
  LISTAR_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  LISTAR_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE,
  LISTAR_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE,
  LISTAR_INTENSIDADES_DAS_DORES,
  LISTAR_INTERNACOES_DO_PACIENTE,
  LISTAR_IVCFS_DO_PACIENTE,
  LISTAR_LOCAIS_DA_APLICACAO,
  LISTAR_LOCAIS_DAS_DORES,
  LISTAR_LOCAIS_DE_QUEDAS,
  LISTAR_MEDICAMENTOS,
  LISTAR_MEDICAMENTOS_DO_PACIENTE,
  LISTAR_MEDICAMENTOS_DO_PRONTUARIO,
  LISTAR_MEDICOS,
  LISTAR_MEDICOS_DO_PACIENTE,
  LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES,
  LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  LISTAR_MICRORGANISMOS,
  LISTAR_MINI_MENTAIS,
  LISTAR_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO,
  LISTAR_MOTIVOS_DE_BLOQUEIO_DO_LOGIN,
  LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_CONVENIO,
  LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_TAREFAS,
  LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO,
  LISTAR_MOTIVOS_DE_INTERNACAO,
  LISTAR_MOTIVOS_DE_INTERNACAO_DO_CADASTRO,
  LISTAR_NIVEIS_DE_SAUDE_RELATIVA,
  LISTAR_OPERADORAS,
  LISTAR_OPERADORAS_DO_CADASTRO,
  LISTAR_OPERADORAS_DO_PACIENTE,
  LISTAR_OPERADORAS_DO_RELATORIO_DE_PACIENTES,
  LISTAR_ORIENTACOES,
  LISTAR_ORIENTACOES_DE_PAGINAS,
  LISTAR_PACIENTES,
  LISTAR_PACIENTES_DO_AGENDAMENTO,
  LISTAR_PACIENTES_DO_NPS,
  LISTAR_PACIENTES_DO_TAREFAS,
  LISTAR_PACIENTES_DO_WIZARD_DE_AGENDAMENTO,
  LISTAR_PACIENTES_DOS_FILTROS_DO_TAREFAS,
  LISTAR_PEDIDOS_DE_CONTATO,
  LISTAR_PERFIS,
  LISTAR_PHQS9,
  LISTAR_PLANOS_DE_CUIDADO,
  LISTAR_PLANOS_DE_CUIDADO_DO_PACIENTE,
  LISTAR_PLANOS_DE_SAUDE_PARA_O_PEDIDO_DE_CONTATO,
  LISTAR_POSOLOGIAS,
  LISTAR_PROFISSIONAIS_DE_SAUDE,
  LISTAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA,
  LISTAR_PROFISSIONAIS_DE_SAUDE_DE_SUGESTAO,
  LISTAR_PROFISSIONAIS_DE_SAUDE_DO_WIZARD_DE_AGENDAMENTO,
  LISTAR_PROFISSIONAIS_DO_NPS,
  LISTAR_PROFISSIONAIS_EXTERNOS,
  LISTAR_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS,
  LISTAR_PROFISSOES,
  LISTAR_PROFISSOES_DO_CADASTRO,
  LISTAR_PROFISSOES_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS,
  LISTAR_PROFISSOES_DO_WIZARD_DE_AGENDAMENTO,
  LISTAR_PROFISSOES_PARA_O_NPS,
  LISTAR_PROGRAMAS,
  LISTAR_PROGRAMAS_DO_PACIENTE,
  LISTAR_PROGRAMAS_DO_RELATORIO_DE_ATENDIMENTOS,
  LISTAR_PROGRAMAS_DO_RELATORIO_DE_IDAS_AO_PRONTO_ATENDIMENTO,
  LISTAR_PROGRAMAS_DO_RELATORIO_DE_INTERNACOES,
  LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES,
  LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  LISTAR_PROGRAMAS_DO_RELATORIO_DE_REINTERNACOES,
  LISTAR_PROGRAMAS_DO_RELATORIO_RESUMO_DE_INTERNACOES,
  LISTAR_PROXIMOS_AGENDAMENTOS_MEDICOS_DO_PACIENTE,
  LISTAR_PSICOLOGOS_DO_PACIENTE,
  LISTAR_QUEDAS_DO_PACIENTE,
  LISTAR_RECORRENCIAS_DO_AGENDAMENTO,
  LISTAR_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE,
  LISTAR_RESPOSTAS_DO_CASP16,
  LISTAR_RESPOSTAS_DO_PHQ9,
  LISTAR_SALAS_DA_UNIDADE,
  LISTAR_SETORES,
  LISTAR_SETORES_RESPONSAVEIS_DO_TAREFAS,
  LISTAR_SEXOS,
  LISTAR_STATUS_DE_ENCAMINHAMENTO,
  LISTAR_STATUS_DOS_AGENDAMENTOS,
  LISTAR_SUGESTOES_DE_PACIENTE,
  LISTAR_TAGS_DE_PACIENTES,
  LISTAR_TAMANHOS_DE_PAGINAS,
  LISTAR_TEMPLATES,
  LISTAR_TEMPLATES_DO_PRONTUARIO,
  LISTAR_TIPOS_DE_AGENDAMENTO,
  LISTAR_TIPOS_DE_AGENDAMENTO_DA_PESQUISA,
  LISTAR_TIPOS_DE_AGENDAMENTO_DO_AGENDAMENTO,
  LISTAR_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA,
  LISTAR_TIPOS_DE_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  LISTAR_TIPOS_DE_ATENDIMENTO_DO_PRONTUARIO,
  LISTAR_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO,
  LISTAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS,
  LISTAR_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER,
  LISTAR_TIPOS_DE_CONSULTA_EXTERNA,
  LISTAR_TIPOS_DE_ENCAMINHAMENTO,
  LISTAR_TIPOS_DE_EXAME,
  LISTAR_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA,
  LISTAR_TIPOS_DE_TAREFA,
  LISTAR_TIPOS_DE_UNIDADE,
  LISTAR_TOKENS_DE_EXEMPLO,
  LISTAR_TOKENS_DE_SUGESTAO,
  LISTAR_TRATAMENTOS,
  LISTAR_UNIDADES_DA_EMPRESA,
  LISTAR_UNIDADES_DA_EMPRESA_DO_USUARIO,
  LISTAR_UNIDADES_DO_USUARIO,
  LISTAR_UNIDADES_DO_USUARIO_LOGADO,
  LISTAR_URGENCIAS_DO_TAREFAS,
  LISTAR_USUARIOS,
  LISTAR_USUARIOS_BLOQUEADOS,
  LISTAR_VACINAS,
  LISTAR_VACINAS_DO_PACIENTE,
  LISTAR_VIAS_DE_ADMINISTRACAO_DE_MEDICAMENTOS,
  LISTOU_AGENDAMENTOS,
  LISTOU_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO,
  LISTOU_AGENDAMENTOS_DO_DIA,
  LISTOU_AGENDAMENTOS_PARA_CONFIRMACAO_DE_AGENDAMENTO,
  LISTOU_ANTIBIOTICOS,
  LISTOU_ASSOCIACOES,
  LISTOU_ASSOCIACOES_DO_CADASTRO,
  LISTOU_ATENDIMENTOS_FINALIZADOS,
  LISTOU_ATENDIMENTOS_NAO_ASSINADOS,
  LISTOU_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  LISTOU_AVALIACOES_DA_SOLIDAO_DO_PACIENTE,
  LISTOU_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE,
  LISTOU_AVALIACOES_RESPONDIDAS,
  LISTOU_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  LISTOU_CAMPOS_EXTERNOS_DO_PACIENTE,
  LISTOU_CASPS_16_DO_PACIENTE,
  LISTOU_CATEGORIAS_DO_TAREFAS,
  LISTOU_CELULAS,
  LISTOU_CELULAS_DO_CADASTRO,
  LISTOU_CELULAS_DO_RELATORIO_DE_PACIENTES,
  LISTOU_CELULAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  LISTOU_CIDADES_PARA_O_PEDIDO_DE_CONTATO,
  LISTOU_CIDS10,
  LISTOU_COMPONENTES_COM_TEMPLATE,
  LISTOU_CONCENTRACOES_DO_MEDICAMENTO,
  LISTOU_CONSULTAS_EXTERNAS_DO_PACIENTE,
  LISTOU_CONSULTORES_DO_PACIENTE,
  LISTOU_CONVENIOS_DO_PACIENTE,
  LISTOU_DIAGNOSTICOS,
  LISTOU_DIAGNOSTICOS_DO_PACIENTE,
  LISTOU_DIAGNOSTICOS_PARA_O_PRONTUARIO,
  LISTOU_DURACOES_DAS_DORES,
  LISTOU_EMPRESAS_HABILITADAS_PARA_O_LOGIN,
  LISTOU_ENCAMINHAMENTOS,
  LISTOU_ENFERMEIROS,
  LISTOU_ENFERMEIROS_DO_PACIENTE,
  LISTOU_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES,
  LISTOU_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  LISTOU_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  LISTOU_ESPECIALIDADES,
  LISTOU_ESPECIALIDADES_MEDICAS_INTERNAS,
  LISTOU_ESTABELECIMENTOS_DE_SAUDE,
  LISTOU_ESTABELECIMENTOS_DE_SAUDE_DO_CADASTRO,
  LISTOU_ESTADOS_DA_MEMORIA_ATUAL,
  LISTOU_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS,
  LISTOU_FABRICANTES,
  LISTOU_FABRICANTES_DO_CADASTRO,
  LISTOU_FERIADOS,
  LISTOU_FERIADOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  LISTOU_FERIADOS_TRABALHADOS_DO_PROFISSIONAL,
  LISTOU_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO,
  LISTOU_FREQUENCIAS_NA_ESCALA_ZARIT,
  LISTOU_GRAUS_DE_RELACOES,
  LISTOU_GRUPOS_DE_CUIDADO,
  LISTOU_GRUPOS_DE_CUIDADO_DO_CADASTRO,
  LISTOU_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE,
  LISTOU_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  LISTOU_HORARIOS_LIVRES,
  LISTOU_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO,
  LISTOU_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  LISTOU_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE,
  LISTOU_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE,
  LISTOU_INTENSIDADES_DAS_DORES,
  LISTOU_INTERNACOES_DO_PACIENTE,
  LISTOU_IVCFS_DO_PACIENTE,
  LISTOU_LOCAIS_DA_APLICACAO,
  LISTOU_LOCAIS_DAS_DORES,
  LISTOU_LOCAIS_DE_QUEDAS,
  LISTOU_MEDICAMENTOS,
  LISTOU_MEDICAMENTOS_DO_PACIENTE,
  LISTOU_MEDICAMENTOS_DO_PRONTUARIO,
  LISTOU_MEDICOS,
  LISTOU_MEDICOS_DO_PACIENTE,
  LISTOU_MEDICOS_DO_RELATORIO_DE_PACIENTES,
  LISTOU_MEDICOS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  LISTOU_MICRORGANISMOS,
  LISTOU_MINI_MENTAIS,
  LISTOU_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO,
  LISTOU_MOTIVOS_DE_BLOQUEIO_DO_LOGIN,
  LISTOU_MOTIVOS_DE_CANCELAMENTO_DO_CONVENIO,
  LISTOU_MOTIVOS_DE_CANCELAMENTO_DO_TAREFAS,
  LISTOU_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO,
  LISTOU_MOTIVOS_DE_INTERNACAO,
  LISTOU_MOTIVOS_DE_INTERNACAO_DO_CADASTRO,
  LISTOU_NIVEIS_DE_SAUDE_RELATIVA,
  LISTOU_OPERADORAS,
  LISTOU_OPERADORAS_DO_CADASTRO,
  LISTOU_OPERADORAS_DO_PACIENTE,
  LISTOU_OPERADORAS_DO_RELATORIO_DE_PACIENTES,
  LISTOU_ORIENTACOES,
  LISTOU_ORIENTACOES_DE_PAGINAS,
  LISTOU_PACIENTES,
  LISTOU_PACIENTES_DO_AGENDAMENTO,
  LISTOU_PACIENTES_DO_NPS,
  LISTOU_PACIENTES_DO_TAREFAS,
  LISTOU_PACIENTES_DO_WIZARD_DE_AGENDAMENTO,
  LISTOU_PACIENTES_DOS_FILTROS_DO_TAREFAS,
  LISTOU_PEDIDOS_DE_CONTATO,
  LISTOU_PERFIS,
  LISTOU_PHQS9,
  LISTOU_PLANOS_DE_CUIDADO,
  LISTOU_PLANOS_DE_CUIDADO_DO_PACIENTE,
  LISTOU_PLANOS_DE_SAUDE_PARA_O_PEDIDO_DE_CONTATO,
  LISTOU_POSOLOGIAS,
  LISTOU_PROFISSIONAIS_DE_SAUDE,
  LISTOU_PROFISSIONAIS_DE_SAUDE_DA_AGENDA,
  LISTOU_PROFISSIONAIS_DE_SAUDE_DE_SUGESTAO,
  LISTOU_PROFISSIONAIS_DE_SAUDE_DO_WIZARD_DE_AGENDAMENTO,
  LISTOU_PROFISSIONAIS_DO_NPS,
  LISTOU_PROFISSIONAIS_EXTERNOS,
  LISTOU_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS,
  LISTOU_PROFISSOES,
  LISTOU_PROFISSOES_DO_CADASTRO,
  LISTOU_PROFISSOES_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS,
  LISTOU_PROFISSOES_DO_WIZARD_DE_AGENDAMENTO,
  LISTOU_PROFISSOES_PARA_O_NPS,
  LISTOU_PROGRAMAS,
  LISTOU_PROGRAMAS_DO_PACIENTE,
  LISTOU_PROGRAMAS_DO_RELATORIO_DE_ATENDIMENTOS,
  LISTOU_PROGRAMAS_DO_RELATORIO_DE_IDAS_AO_PRONTO_ATENDIMENTO,
  LISTOU_PROGRAMAS_DO_RELATORIO_DE_INTERNACOES,
  LISTOU_PROGRAMAS_DO_RELATORIO_DE_PACIENTES,
  LISTOU_PROGRAMAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  LISTOU_PROGRAMAS_DO_RELATORIO_DE_REINTERNACOES,
  LISTOU_PROGRAMAS_DO_RELATORIO_RESUMO_DE_INTERNACOES,
  LISTOU_PROXIMOS_AGENDAMENTOS_MEDICOS_DO_PACIENTE,
  LISTOU_PSICOLOGOS_DO_PACIENTE,
  LISTOU_QUEDAS_DO_PACIENTE,
  LISTOU_RECORRENCIAS_DO_AGENDAMENTO,
  LISTOU_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE,
  LISTOU_RESPOSTAS_DO_CASP16,
  LISTOU_RESPOSTAS_DO_PHQ9,
  LISTOU_SALAS_DA_UNIDADE,
  LISTOU_SETORES,
  LISTOU_SETORES_RESPONSAVEIS_DO_TAREFAS,
  LISTOU_SEXOS,
  LISTOU_STATUS_DE_ENCAMINHAMENTO,
  LISTOU_STATUS_DOS_AGENDAMENTOS,
  LISTOU_SUGESTOES_DE_PACIENTE,
  LISTOU_TAGS_DE_PACIENTES,
  LISTOU_TAMANHOS_DE_PAGINAS,
  LISTOU_TEMPLATES,
  LISTOU_TEMPLATES_DO_PRONTUARIO,
  LISTOU_TIPOS_DE_AGENDAMENTO,
  LISTOU_TIPOS_DE_AGENDAMENTO_DA_PESQUISA,
  LISTOU_TIPOS_DE_AGENDAMENTO_DAS_CONFIGURACOES_DA_AGENDA,
  LISTOU_TIPOS_DE_AGENDAMENTO_DO_AGENDAMENTO,
  LISTOU_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA,
  LISTOU_TIPOS_DE_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  LISTOU_TIPOS_DE_ATENDIMENTO_DO_PRONTUARIO,
  LISTOU_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO,
  LISTOU_TIPOS_DE_ATENDIMENTO_PARA_O_NPS,
  LISTOU_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER,
  LISTOU_TIPOS_DE_CONSULTA_EXTERNA,
  LISTOU_TIPOS_DE_ENCAMINHAMENTO,
  LISTOU_TIPOS_DE_EXAME,
  LISTOU_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA,
  LISTOU_TIPOS_DE_TAREFA,
  LISTOU_TIPOS_DE_UNIDADE,
  LISTOU_TOKENS_DE_EXEMPLO,
  LISTOU_TOKENS_DE_SUGESTAO,
  LISTOU_TRATAMENTOS,
  LISTOU_UNIDADES_DA_EMPRESA,
  LISTOU_UNIDADES_DA_EMPRESA_DO_USUARIO,
  LISTOU_UNIDADES_DO_USUARIO,
  LISTOU_UNIDADES_DO_USUARIO_LOGADO,
  LISTOU_URGENCIAS_DO_TAREFAS,
  LISTOU_USUARIOS,
  LISTOU_USUARIOS_BLOQUEADOS,
  LISTOU_VACINAS,
  LISTOU_VACINAS_DO_PACIENTE,
  LISTOU_VIAS_DE_ADMINISTRACAO_DE_MEDICAMENTOS,
  MARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA,
  MARCOU_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA,
  NAO_AUTENTICOU_USUARIO,
  RECUPERAR_AGENDA_SEMANAL_DO_PROFISSIONAL,
  RECUPERAR_AGENDAMENTO_PELO_IDENTIFICADOR,
  RECUPERAR_ASSOCIACAO_PELO_IDENTIFICADOR,
  RECUPERAR_ATENDIMENTO,
  RECUPERAR_ATENDIMENTO_INICIADO,
  RECUPERAR_AVALIACAO,
  RECUPERAR_AVALIACAO_RESPONDIDA,
  RECUPERAR_CABECALHO_DO_PRONTUARIO,
  RECUPERAR_CAMPO_EXTERNO_DO_PACIENTE,
  RECUPERAR_CELULA_PELO_IDENTIFICADOR,
  RECUPERAR_CERTIFICADOS_EM_NUVEM_DO_USUARIO,
  RECUPERAR_CONFIGURACOES_DO_USUARIO,
  RECUPERAR_CONFIGURACOES_DO_USUARIO_LOGADO,
  RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO,
  RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO_LOGADO,
  RECUPERAR_CONVENIO_DO_PACIENTE,
  RECUPERAR_CONVENIOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  RECUPERAR_CONVENIOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO,
  RECUPERAR_DESENHO_DO_MINI_MENTAL,
  RECUPERAR_DIAGNOSTICO_PELO_IDENTIFICADOR,
  RECUPERAR_EMPRESA,
  RECUPERAR_ENDERECO_DA_UNIDADE_POR_CEP,
  RECUPERAR_ENDERECO_DO_PACIENTE_POR_CEP,
  RECUPERAR_ESPECIALIDADE_POR_IDENTIFICADOR,
  RECUPERAR_ESTABELECIMENTO_DE_SAUDE_PELO_IDENTIFICADOR,
  RECUPERAR_FABRICANTE_PELO_IDENTIFICADOR,
  RECUPERAR_FERIADO_POR_IDENTIFICADOR,
  RECUPERAR_FOTO_DA_EMPRESA_LOGADA,
  RECUPERAR_FOTO_DA_EMPRESA_POR_IDENTIFICADOR_PUBLICO,
  RECUPERAR_FOTO_DO_PACIENTE_POR_IDENTIFICADOR,
  RECUPERAR_FOTO_DO_USUARIO_POR_IDENTIFICADOR,
  RECUPERAR_GRUPO_DE_CUIDADO_PELO_IDENTIFICADOR,
  RECUPERAR_HISTORICO_PRESCRICAO_NA_MEMED,
  RECUPERAR_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE,
  RECUPERAR_LOGIN,
  RECUPERAR_MEDICAMENTO_POR_IDENTIFICADOR,
  RECUPERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO_PELO_IDENTIFICADOR,
  RECUPERAR_MOTIVO_DE_INTERNACAO_PELO_IDENTIFICADOR,
  RECUPERAR_OPERADORA_PELO_IDENTIFICADOR,
  RECUPERAR_ORIENTACAO_PELO_IDENTIFICADOR,
  RECUPERAR_PACIENTE_POR_IDENTIFICADOR,
  RECUPERAR_PEDIDO_DE_CONTATO_PELO_IDENTIFICADOR,
  RECUPERAR_PLANO_DE_CUIDADO_DO_PACIENTE,
  RECUPERAR_PLANO_DE_CUIDADO_PELO_IDENTIFICADOR,
  RECUPERAR_PRESCRICAO_PDF_NA_MEMED,
  RECUPERAR_PROFISSAO_POR_IDENTIFICADOR,
  RECUPERAR_PROFISSIONAL_EXTERNO_PELO_IDENTIFICADOR,
  RECUPERAR_PROGRAMA_DO_PACIENTE,
  RECUPERAR_PROGRAMA_PELO_IDENTIFICADOR,
  RECUPERAR_SALA_DA_UNIDADE_PELO_IDENTIFICADOR,
  RECUPERAR_SENHA,
  RECUPERAR_SESSAO_COM_CERTIFICADO_EM_NUVEM,
  RECUPERAR_SETOR_PELO_IDENTIFICADOR,
  RECUPERAR_TELECONSULTA_DO_AGENDAMENTO,
  RECUPERAR_TELECONSULTA_DO_ATENDIMENTO,
  RECUPERAR_TEMPLATE_POR_IDENTIFICADOR,
  RECUPERAR_TIPO_DE_AGENDAMENTO_POR_IDENTIFICADOR,
  RECUPERAR_TOKEN_DOS_RELATORIOS_DO_POWERBI,
  RECUPERAR_URL_AUTENTICACAO_DO_CERTIFICADO_EM_NUVEM,
  RECUPERAR_USUARIO_POR_IDENTIFICADOR,
  RECUPEROU_AGENDA_SEMANAL_DO_PROFISSIONAL,
  RECUPEROU_AGENDAMENTO_PELO_IDENTIFICADOR,
  RECUPEROU_ASSOCIACAO_PELO_IDENTIFICADOR,
  RECUPEROU_ATENDIMENTO,
  RECUPEROU_ATENDIMENTO_INICIADO,
  RECUPEROU_AVALIACAO,
  RECUPEROU_AVALIACAO_RESPONDIDA,
  RECUPEROU_CABECALHO_DO_PRONTUARIO,
  RECUPEROU_CAMPO_EXTERNO_DO_PACIENTE,
  RECUPEROU_CELULA_PELO_IDENTIFICADOR,
  RECUPEROU_CERTIFICADOS_EM_NUVEM_DO_USUARIO,
  RECUPEROU_CONFIGURACOES_DO_USUARIO,
  RECUPEROU_CONFIGURACOES_DO_USUARIO_LOGADO,
  RECUPEROU_CONFIGURACOES_VIGENTES_DO_USUARIO,
  RECUPEROU_CONFIGURACOES_VIGENTES_DO_USUARIO_LOGADO,
  RECUPEROU_CONVENIO_DO_PACIENTE,
  RECUPEROU_CONVENIOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  RECUPEROU_CONVENIOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO,
  RECUPEROU_DESENHO_DO_MINI_MENTAL,
  RECUPEROU_DIAGNOSTICO_PELO_IDENTIFICADOR,
  RECUPEROU_EMPRESA,
  RECUPEROU_ENDERECO_DA_UNIDADE_POR_CEP,
  RECUPEROU_ENDERECO_DO_PACIENTE_POR_CEP,
  RECUPEROU_ESPECIALIDADE_POR_IDENTIFICADOR,
  RECUPEROU_ESTABELECIMENTO_DE_SAUDE_PELO_IDENTIFICADOR,
  RECUPEROU_FABRICANTE_PELO_IDENTIFICADOR,
  RECUPEROU_FERIADO_POR_IDENTIFICADOR,
  RECUPEROU_FOTO_DA_EMPRESA_LOGADA,
  RECUPEROU_FOTO_DA_EMPRESA_POR_IDENTIFICADOR_PUBLICO,
  RECUPEROU_FOTO_DO_PACIENTE_POR_IDENTIFICADOR,
  RECUPEROU_FOTO_DO_USUARIO_POR_IDENTIFICADOR,
  RECUPEROU_GRUPO_DE_CUIDADO_PELO_IDENTIFICADOR,
  RECUPEROU_HISTORICO_PRESCRICAO_NA_MEMED,
  RECUPEROU_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE,
  RECUPEROU_LOGIN,
  RECUPEROU_MEDICAMENTO_POR_IDENTIFICADOR,
  RECUPEROU_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO_PELO_IDENTIFICADOR,
  RECUPEROU_MOTIVO_DE_INTERNACAO_PELO_IDENTIFICADOR,
  RECUPEROU_OPERADORA_PELO_IDENTIFICADOR,
  RECUPEROU_ORIENTACAO_PELO_IDENTIFICADOR,
  RECUPEROU_PACIENTE_POR_IDENTIFICADOR,
  RECUPEROU_PEDIDO_DE_CONTATO_PELO_IDENTIFICADOR,
  RECUPEROU_PLANO_DE_CUIDADO_DO_PACIENTE,
  RECUPEROU_PLANO_DE_CUIDADO_PELO_IDENTIFICADOR,
  RECUPEROU_PRESCRICAO_PDF_NA_MEMED,
  RECUPEROU_PROFISSAO_POR_IDENTIFICADOR,
  RECUPEROU_PROFISSIONAL_EXTERNO_PELO_IDENTIFICADOR,
  RECUPEROU_PROGRAMA_DO_PACIENTE,
  RECUPEROU_PROGRAMA_PELO_IDENTIFICADOR,
  RECUPEROU_SALA_DA_UNIDADE_PELO_IDENTIFICADOR,
  RECUPEROU_SENHA,
  RECUPEROU_SESSAO_COM_CERTIFICADO_EM_NUVEM,
  RECUPEROU_SETOR_PELO_IDENTIFICADOR,
  RECUPEROU_TELECONSULTA_DO_AGENDAMENTO,
  RECUPEROU_TELECONSULTA_DO_ATENDIMENTO,
  RECUPEROU_TEMPLATE_POR_IDENTIFICADOR,
  RECUPEROU_TIPO_DE_AGENDAMENTO_POR_IDENTIFICADOR,
  RECUPEROU_TOKEN_DOS_RELATORIOS_DO_POWERBI,
  RECUPEROU_URL_AUTENTICACAO_DO_CERTIFICADO_EM_NUVEM,
  RECUPEROU_USUARIO_POR_IDENTIFICADOR,
  REDEFINIR_SENHA,
  REDEFINIU_SENHA,
  REMOVER_ASSOCIACAO,
  REMOVER_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  REMOVER_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  REMOVER_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  REMOVER_CAMPO_EXTERNO_DO_PACIENTE,
  REMOVER_CASP_16_DO_PACIENTE,
  REMOVER_CELULA,
  REMOVER_COMENTARIO_DA_TAREFA,
  REMOVER_CONCENTRACAO_DO_MEDICAMENTO,
  REMOVER_CONSULTA_EXTERNA_DO_PACIENTE,
  REMOVER_CONVENIO_DO_PACIENTE,
  REMOVER_DIAGNOSTICO,
  REMOVER_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  REMOVER_ESPECIALIDADE,
  REMOVER_ESPECIALIDADE_MEDICA_INTERNA,
  REMOVER_ESTABELECIMENTO_DE_SAUDE,
  REMOVER_FABRICANTE,
  REMOVER_FERIADO_TRABALHADO_DO_PROFISSIONAL,
  REMOVER_GRUPO_DE_CUIDADO,
  REMOVER_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  REMOVER_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  REMOVER_INTERNACAO_DO_PACIENTE,
  REMOVER_INTERPRETACAO_MEDICA_DO_MINI_MENTAL,
  REMOVER_IVCF_DO_PACIENTE,
  REMOVER_MEDICAMENTO,
  REMOVER_MINI_MENTAL,
  REMOVER_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  REMOVER_MOTIVO_DE_INTERNACAO,
  REMOVER_OPERADORA,
  REMOVER_ORIENTACAO,
  REMOVER_PACIENTE_NA_LISTA_DE_ESPERA,
  REMOVER_PEDIDO_DE_CONTATO,
  REMOVER_PERFIL_DO_USUARIO,
  REMOVER_PHQ9,
  REMOVER_PLANO_DE_CUIDADO,
  REMOVER_PLANO_DE_CUIDADO_DO_PACIENTE,
  REMOVER_PRESCRICAO_NA_MEMED,
  REMOVER_PROFISSAO,
  REMOVER_PROFISSIONAL_EXTERNO,
  REMOVER_PROGRAMA,
  REMOVER_PROGRAMA_DO_PACIENTE,
  REMOVER_QUEDA_DO_PACIENTE,
  REMOVER_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
  REMOVER_SALA_DA_UNIDADE,
  REMOVER_SETOR,
  REMOVER_TAG_DO_PACIENTE,
  REMOVER_TAG_DO_PACIENTE_DO_PRONTUARIO,
  REMOVER_TIPO_DE_AGENDAMENTO,
  REMOVER_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL,
  REMOVER_UNIDADE_DA_EMPRESA,
  REMOVER_UNIDADE_DO_USUARIO,
  REMOVER_VACINA_DO_PACIENTE,
  REMOVEU_ASSOCIACAO,
  REMOVEU_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  REMOVEU_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  REMOVEU_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  REMOVEU_CAMPO_EXTERNO_DO_PACIENTE,
  REMOVEU_CASP_16_DO_PACIENTE,
  REMOVEU_CELULA,
  REMOVEU_COMENTARIO_DA_TAREFA,
  REMOVEU_CONCENTRACAO_DO_MEDICAMENTO,
  REMOVEU_CONSULTA_EXTERNA_DO_PACIENTE,
  REMOVEU_CONVENIO_DO_PACIENTE,
  REMOVEU_DIAGNOSTICO,
  REMOVEU_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  REMOVEU_ESPECIALIDADE,
  REMOVEU_ESPECIALIDADE_MEDICA_INTERNA,
  REMOVEU_ESTABELECIMENTO_DE_SAUDE,
  REMOVEU_FABRICANTE,
  REMOVEU_FERIADO_TRABALHADO_DO_PROFISSIONAL,
  REMOVEU_GRUPO_DE_CUIDADO,
  REMOVEU_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  REMOVEU_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  REMOVEU_INTERNACAO_DO_PACIENTE,
  REMOVEU_INTERPRETACAO_MEDICA_DO_MINI_MENTAL,
  REMOVEU_IVCF_DO_PACIENTE,
  REMOVEU_MEDICAMENTO,
  REMOVEU_MINI_MENTAL,
  REMOVEU_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  REMOVEU_MOTIVO_DE_INTERNACAO,
  REMOVEU_OPERADORA,
  REMOVEU_ORIENTACAO,
  REMOVEU_PACIENTE_NA_LISTA_DE_ESPERA,
  REMOVEU_PEDIDO_DE_CONTATO,
  REMOVEU_PERFIL_DO_USUARIO,
  REMOVEU_PHQ9,
  REMOVEU_PLANO_DE_CUIDADO,
  REMOVEU_PLANO_DE_CUIDADO_DO_PACIENTE,
  REMOVEU_PRESCRICAO_NA_MEMED,
  REMOVEU_PROFISSAO,
  REMOVEU_PROFISSIONAL_EXTERNO,
  REMOVEU_PROGRAMA,
  REMOVEU_PROGRAMA_DO_PACIENTE,
  REMOVEU_QUEDA_DO_PACIENTE,
  REMOVEU_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
  REMOVEU_SALA_DA_UNIDADE,
  REMOVEU_SETOR,
  REMOVEU_TAG_DO_PACIENTE,
  REMOVEU_TAG_DO_PACIENTE_DO_PRONTUARIO,
  REMOVEU_TIPO_DE_AGENDAMENTO,
  REMOVEU_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL,
  REMOVEU_UNIDADE_DA_EMPRESA,
  REMOVEU_UNIDADE_DO_USUARIO,
  REMOVEU_VACINA_DO_PACIENTE,
  RESPONDER_AVALIACAO,
  RESPONDEU_AVALIACAO,
  SALVAR_AGENDAMENTO,
  SALVAR_EMPRESA,
  SALVAR_FERIADO,
  SALVAR_PACIENTE,
  SALVAR_SECAO_DO_ATENDIMENTO,
  SALVAR_SERIE_DE_FERIADOS,
  SALVAR_TEMPLATE,
  SALVAR_TIPO_DE_AGENDAMENTO,
  SALVAR_UNIDADE_DA_EMPRESA,
  SALVAR_USUARIO,
  SALVOU_AGENDAMENTO,
  SALVOU_EMPRESA,
  SALVOU_FERIADO,
  SALVOU_PACIENTE,
  SALVOU_SECAO_DO_ATENDIMENTO,
  SALVOU_SERIE_DE_FERIADOS,
  SALVOU_TEMPLATE,
  SALVOU_TIPO_DE_AGENDAMENTO,
  SALVOU_UNIDADE_DA_EMPRESA,
  SALVOU_USUARIO,
  SELECIONAR_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  SELECIONOU_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
} from '../acoes/tipos'

let estadoInicial = {
}

const iniciarOperacao = operacao => {
  return {
    descricao: operacao,
    inicio: new Date(),
  }
}

const acoesDeInicio = [
  INICIAR_ATENDIMENTO,
  LISTAR_SEXOS,
  LISTAR_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA,
  LISTAR_TRATAMENTOS,
  CANCELAR_ATENDIMENTO,
  FINALIZAR_ATENDIMENTO,
  SALVAR_SECAO_DO_ATENDIMENTO,
  FAZER_DOWNLOAD_DA_PRESCRICAO,
  FAZER_DOWNLOAD_DO_ENCAMINHAMENTO,
  LISTAR_ATENDIMENTOS_FINALIZADOS,
  ADICIONAR_INFORMACOES,
  LISTAR_TIPOS_DE_EXAME,
  AUTENTICAR_USUARIO,
  LISTAR_USUARIOS,
  ADICIONAR_USUARIO,
  SALVAR_USUARIO,
  ADICIONAR_PERFIL_DO_USUARIO,
  REMOVER_PERFIL_DO_USUARIO,
  ALTERAR_SENHA_DO_USUARIO,
  RECUPERAR_USUARIO_POR_IDENTIFICADOR,
  LISTAR_TIPOS_DE_AGENDAMENTO,
  ADICIONAR_TIPO_DE_AGENDAMENTO,
  SALVAR_TIPO_DE_AGENDAMENTO,
  REMOVER_TIPO_DE_AGENDAMENTO,
  RECUPERAR_TIPO_DE_AGENDAMENTO_POR_IDENTIFICADOR,
  LISTAR_PACIENTES,
  LISTAR_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_FILTROS_DAS_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ADICIONAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  IMPORTAR_PACIENTES,
  RECUPERAR_PACIENTE_POR_IDENTIFICADOR,
  ADICIONAR_PACIENTE,
  SALVAR_PACIENTE,
  ADICIONAR_PROGRAMA_DO_PACIENTE,
  ALTERAR_PROGRAMA_DO_PACIENTE,
  REMOVER_PROGRAMA_DO_PACIENTE,
  RECUPERAR_PROGRAMA_DO_PACIENTE,
  RECUPERAR_AGENDA_SEMANAL_DO_PROFISSIONAL,
  LISTAR_PROFISSIONAIS_DE_SAUDE,
  CANCELAR_AGENDAMENTO,
  EXCLUIR_AGENDAMENTO,
  CONFIRMAR_AGENDAMENTO,
  ADICIONAR_AGENDAMENTO,
  SALVAR_AGENDAMENTO,
  LISTAR_PROGRAMAS_DO_PACIENTE,
  LISTAR_RECORRENCIAS_DO_AGENDAMENTO,
  LISTAR_TIPOS_DE_AGENDAMENTO_DO_AGENDAMENTO,
  LISTAR_HORARIOS_LIVRES,
  LISTAR_AGENDAMENTOS_PARA_CONFIRMACAO_DE_AGENDAMENTO,
  LISTAR_AGENDAMENTOS,
  LISTAR_AGENDAMENTOS_DO_DIA,
  CONFIRMAR_CHEGADA_DO_PACIENTE,
  INFORMAR_SAIDA_DO_PACIENTE,
  SALVAR_SERIE_DE_FERIADOS,
  EXCLUIR_SERIE_DE_FERIADOS,
  EXCLUIR_FERIADO,
  ADICIONAR_PRE_CADASTRO_DO_PACIENTE,
  RECUPERAR_CONVENIOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO,
  LISTAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA,
  FILTRAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA,
  LISTAR_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO,
  LISTAR_DIAGNOSTICOS,
  ADICIONAR_DIAGNOSTICO,
  RECUPERAR_DIAGNOSTICO_PELO_IDENTIFICADOR,
  ALTERAR_DIAGNOSTICO,
  REMOVER_DIAGNOSTICO,
  LISTAR_MEDICAMENTOS_DO_PRONTUARIO,
  LISTAR_POSOLOGIAS,
  LISTAR_TIPOS_DE_ATENDIMENTO_DO_PRONTUARIO,
  LISTAR_OPERADORAS,
  LISTAR_PROFISSOES,
  LISTAR_PERFIS,
  LISTAR_MEDICOS,
  LISTAR_GRAUS_DE_RELACOES,
  LISTAR_TAMANHOS_DE_PAGINAS,
  LISTAR_ENFERMEIROS,
  LISTAR_FERIADOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  RECUPERAR_TELECONSULTA_DO_AGENDAMENTO,
  RECUPERAR_TELECONSULTA_DO_ATENDIMENTO,
  RECUPERAR_ATENDIMENTO,
  RECUPERAR_ATENDIMENTO_INICIADO,
  LISTAR_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ADICIONAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  LISTAR_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE,
  ADICIONAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  LISTAR_MICRORGANISMOS,
  ADICIONAR_SUMARIO_DE_ALTA_DA_INTERNACAO,
  LISTAR_ORIENTACOES_DE_PAGINAS,
  FAZER_DOWNLOAD_DO_ARQUIVO_DE_ATENDIMENTO,
  LISTAR_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA,
  ADICIONAR_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL,
  REMOVER_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL,
  RECUPERAR_EMPRESA,
  SALVAR_EMPRESA,
  ADICIONAR_UNIDADE_DA_EMPRESA,
  SALVAR_UNIDADE_DA_EMPRESA,
  LISTAR_UNIDADES_DA_EMPRESA,
  REMOVER_UNIDADE_DA_EMPRESA,
  ATIVAR_UNIDADE_DA_EMPRESA,
  DESATIVAR_UNIDADE_DA_EMPRESA,
  LISTAR_EMPRESAS_HABILITADAS_PARA_O_LOGIN,
  LISTAR_UNIDADES_DO_USUARIO,
  ADICIONAR_UNIDADE_DO_USUARIO,
  REMOVEU_UNIDADE_DO_USUARIO,
  LISTAR_UNIDADES_DA_EMPRESA_DO_USUARIO,
  RECUPERAR_LOGIN,
  HABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO,
  LISTAR_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO,
  DESABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO,
  LISTAR_PROGRAMAS_DO_RELATORIO_RESUMO_DE_INTERNACOES,
  GERAR_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO,
  GERAR_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO,
  FAZER_DOWNLOAD_DE_IDAS_AO_PRONTO_ATENDIMENTO,
  FAZER_DOWNLOAD_DE_INTERNACOES,
  FAZER_DOWNLOAD_DE_INTERNACOES_POR_FRATURA_DE_FEMUR,
  FAZER_DOWNLOAD_DE_REINTERNACOES,
  LISTAR_PROGRAMAS_DO_RELATORIO_DE_IDAS_AO_PRONTO_ATENDIMENTO,
  LISTAR_PROGRAMAS_DO_RELATORIO_DE_INTERNACOES,
  LISTAR_PROGRAMAS_DO_RELATORIO_DE_REINTERNACOES,
  LISTAR_OPERADORAS_DO_RELATORIO_DE_PACIENTES,
  LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES,
  LISTAR_PROGRAMAS_DO_RELATORIO_DE_ATENDIMENTOS,
  FAZER_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA,
  LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  FAZER_DOWNLOAD_DE_PACIENTES,
  LISTAR_VIAS_DE_ADMINISTRACAO_DE_MEDICAMENTOS,
  FAZER_DOWNLOAD_DE_ATENDIMENTOS,
  ADICIONAR_ARQUIVO,
  FAZER_DOWNLOAD_DO_ARQUIVO_ADICIONAL_DO_ATENDIMENTO,
  LISTAR_FERIADOS,
  ADICIONAR_FERIADO,
  RECUPERAR_FERIADO_POR_IDENTIFICADOR,
  SALVAR_FERIADO,
  LISTAR_UNIDADES_DO_USUARIO_LOGADO,
  FAZER_DOWNLOAD_DO_ARQUIVO_DE_VIDEO_CONSULTA,
  ADICIONAR_TAG_NO_PACIENTE,
  REMOVER_TAG_DO_PACIENTE,
  LISTAR_TAGS_DE_PACIENTES,
  REMOVER_TIPO_DE_AGENDAMENTO,
  LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES,
  LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES,
  LISTAR_DIAGNOSTICOS_PARA_O_PRONTUARIO,
  LISTAR_MEDICAMENTOS,
  ADICIONAR_MEDICAMENTO,
  REMOVER_MEDICAMENTO,
  ALTERAR_MEDICAMENTO,
  LISTAR_CONCENTRACOES_DO_MEDICAMENTO,
  ADICIONAR_CONCENTRACAO_DO_MEDICAMENTO,
  REMOVER_CONCENTRACAO_DO_MEDICAMENTO,
  ALTERAR_CONCENTRACAO_DO_MEDICAMENTO,
  RECUPERAR_MEDICAMENTO_POR_IDENTIFICADOR,
  RECUPERAR_ENDERECO_DO_PACIENTE_POR_CEP,
  LISTAR_PACIENTES_DO_AGENDAMENTO,
  LISTAR_PACIENTES_DO_WIZARD_DE_AGENDAMENTO,
  RECUPERAR_CONVENIOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  SELECIONAR_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  EXCLUIR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  LISTAR_PROFISSOES_DO_WIZARD_DE_AGENDAMENTO,
  LISTAR_PROFISSIONAIS_DE_SAUDE_DO_WIZARD_DE_AGENDAMENTO,
  LISTAR_TIPOS_DE_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  LISTAR_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO,
  ADICIONAR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  RECUPERAR_FOTO_DO_USUARIO_POR_IDENTIFICADOR,
  RECUPERAR_FOTO_DO_PACIENTE_POR_IDENTIFICADOR,
  RECUPERAR_FOTO_DA_EMPRESA_LOGADA,
  RECUPERAR_FOTO_DA_EMPRESA_POR_IDENTIFICADOR_PUBLICO,
  RECUPERAR_DESENHO_DO_MINI_MENTAL,
  ADICIONAR_FOTO_DO_USUARIO,
  ADICIONAR_FOTO_DO_PACIENTE,
  ADICIONAR_FOTO_DA_EMPRESA,
  LISTAR_MOTIVOS_DE_INTERNACAO,
  LISTAR_MOTIVOS_DE_INTERNACAO_DO_CADASTRO,
  ADICIONAR_MOTIVO_DE_INTERNACAO,
  RECUPERAR_MOTIVO_DE_INTERNACAO_PELO_IDENTIFICADOR,
  ALTERAR_MOTIVO_DE_INTERNACAO,
  REMOVER_MOTIVO_DE_INTERNACAO,
  REMOVER_PROFISSAO,
  ADICIONAR_PROFISSAO,
  ALTERAR_PROFISSAO,
  EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  RECUPERAR_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE,
  ADICIONAR_PACIENTE_NA_LISTA_DE_ESPERA,
  ALTERAR_PACIENTE_NA_LISTA_DE_ESPERA,
  REMOVER_PACIENTE_NA_LISTA_DE_ESPERA,
  ALTERAR_DADOS_SELECIONADOS_DO_AGENDAMENTO_NA_LISTA_DE_ESPERA,
  RECUPERAR_MEDICAMENTO_POR_IDENTIFICADOR,
  ADICIONAR_ESPECIALIDADE,
  REMOVER_ESPECIALIDADE,
  ALTERAR_ESPECIALIDADE,
  RECUPERAR_ESPECIALIDADE_POR_IDENTIFICADOR,
  LISTAR_ESPECIALIDADES,
  LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO,
  ADICIONAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  RECUPERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO_PELO_IDENTIFICADOR,
  ALTERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  REMOVER_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  LISTAR_ESTABELECIMENTOS_DE_SAUDE,
  LISTAR_ESTABELECIMENTOS_DE_SAUDE_DO_CADASTRO,
  ADICIONAR_ESTABELECIMENTO_DE_SAUDE,
  RECUPERAR_ESTABELECIMENTO_DE_SAUDE_PELO_IDENTIFICADOR,
  ALTERAR_ESTABELECIMENTO_DE_SAUDE,
  REMOVER_ESTABELECIMENTO_DE_SAUDE,
  LISTAR_OPERADORAS_DO_CADASTRO,
  ADICIONAR_OPERADORA,
  RECUPERAR_OPERADORA_PELO_IDENTIFICADOR,
  REMOVER_OPERADORA,
  ALTERAR_OPERADORA,
  ADICIONAR_PROGRAMA,
  RECUPERAR_PROGRAMA_PELO_IDENTIFICADOR,
  ALTERAR_PROGRAMA,
  LISTAR_PROGRAMAS,
  REMOVER_PROGRAMA,
  LISTAR_ENFERMEIROS_DO_PACIENTE,
  LISTAR_MEDICOS_DO_PACIENTE,
  LISTAR_OPERADORAS_DO_PACIENTE,
  LISTAR_PROGRAMAS,
  LISTAR_PSICOLOGOS_DO_PACIENTE,
  LISTAR_CONSULTORES_DO_PACIENTE,
  LISTAR_OPERADORAS_DO_PACIENTE,
  RECUPERAR_SENHA,
  REDEFINIR_SENHA,
  LISTAR_STATUS_DOS_AGENDAMENTOS,
  RECUPERAR_AGENDAMENTO_PELO_IDENTIFICADOR,
  LISTAR_ASSOCIACOES,
  LISTAR_ASSOCIACOES_DO_CADASTRO,
  ADICIONAR_ASSOCIACAO,
  RECUPERAR_ASSOCIACAO_PELO_IDENTIFICADOR,
  REMOVER_ASSOCIACAO,
  ALTERAR_ASSOCIACAO,
  LISTAR_PROFISSOES_DO_CADASTRO,
  RECUPERAR_PROFISSAO_POR_IDENTIFICADOR,
  RECUPERAR_CABECALHO_DO_PRONTUARIO,
  ALTERAR_STATUS_DO_USUARIO,
  LISTAR_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE,
  LISTAR_PROFISSIONAIS_EXTERNOS,
  ADICIONAR_PROFISSIONAL_EXTERNO,
  RECUPERAR_PROFISSIONAL_EXTERNO_PELO_IDENTIFICADOR,
  REMOVER_PROFISSIONAL_EXTERNO,
  ALTERAR_PROFISSIONAL_EXTERNO,
  LISTAR_ENCAMINHAMENTOS,
  LISTAR_STATUS_DE_ENCAMINHAMENTO,
  LISTAR_TIPOS_DE_AGENDAMENTO,
  ALTERAR_STATUS_DO_ENCAMINHAMENTO,
  ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  LISTAR_GRUPOS_DE_CUIDADO,
  LISTAR_GRUPOS_DE_CUIDADO_DO_CADASTRO,
  ADICIONAR_GRUPO_DE_CUIDADO,
  RECUPERAR_GRUPO_DE_CUIDADO_PELO_IDENTIFICADOR,
  REMOVER_GRUPO_DE_CUIDADO,
  ALTERAR_GRUPO_DE_CUIDADO,
  LISTAR_FREQUENCIAS_NA_ESCALA_ZARIT,
  CARREGAR_DETALHES_DO_ATENDIMENTO_FINALIZADO,
  LISTAR_RESPOSTAS_DO_CASP16,
  LISTAR_PLANOS_DE_CUIDADO,
  ADICIONAR_PLANO_DE_CUIDADO,
  ALTERAR_PLANO_DE_CUIDADO,
  REMOVER_PLANO_DE_CUIDADO,
  RECUPERAR_PLANO_DE_CUIDADO_PELO_IDENTIFICADOR,
  LISTAR_PLANOS_DE_CUIDADO_DO_PACIENTE,
  ADICIONAR_PLANO_DE_CUIDADO_DO_PACIENTE,
  ALTERAR_PLANO_DE_CUIDADO_DO_PACIENTE,
  REMOVER_PLANO_DE_CUIDADO_DO_PACIENTE,
  RECUPERAR_PLANO_DE_CUIDADO_DO_PACIENTE,
  LISTAR_ORIENTACOES,
  ADICIONAR_ORIENTACAO,
  RECUPERAR_ORIENTACAO_PELO_IDENTIFICADOR,
  ALTERAR_ORIENTACAO,
  REMOVER_ORIENTACAO,
  FAZER_DOWNLOAD_DO_ARQUIVO_DA_ORIENTACAO,
  ADICIONAR_TAG_NO_PACIENTE_DO_PRONTUARIO,
  REMOVER_TAG_DO_PACIENTE_DO_PRONTUARIO,
  RECUPERAR_TOKEN_DOS_RELATORIOS_DO_POWERBI,
  LISTAR_TIPOS_DE_AGENDAMENTO_DA_PESQUISA,
  LISTAR_PROFISSIONAIS_DE_SAUDE_DE_SUGESTAO,
  LISTAR_SUGESTOES_DE_PACIENTE,
  LISTAR_TOKENS_DE_SUGESTAO,
  LISTAR_TOKENS_DE_EXEMPLO,
  LISTAR_COMPONENTES_COM_TEMPLATE,
  ADICIONAR_TEMPLATE,
  RECUPERAR_TEMPLATE_POR_IDENTIFICADOR,
  SALVAR_TEMPLATE,
  LISTAR_TEMPLATES,
  EXCLUIR_TEMPLATE,
  LISTAR_TEMPLATES_DO_PRONTUARIO,
  ADICIONAR_TEMPLATE_DO_PRONTUARIO,
  ALTERAR_STATUS_DO_GRUPO_DE_CUIDADO,
  DEFINIR_TELEFONE_DE_CONTATO_DA_TAREFA,
  DEFINIR_DESCRICAO_DA_TAREFA,
  LISTAR_PACIENTES_DO_TAREFAS,
  DEFINIR_PACIENTE_DA_TAREFA,
  LISTAR_CATEGORIAS_DO_TAREFAS,
  DEFINIR_CATEGORIA_DA_TAREFA,
  LISTAR_URGENCIAS_DO_TAREFAS,
  DEFINIR_URGENCIA_DA_TAREFA,
  LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_TAREFAS,
  DEFINIR_MOTIVO_DO_CANCELAMENTO_DA_TAREFA,
  LISTAR_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS,
  DEFINIR_PROFISSIONAL_RESPONSAVEL_DA_TAREFA,
  ADICIONAR_COMENTARIO_DA_TAREFA,
  ALTERAR_COMENTARIO_DA_TAREFA,
  REMOVER_COMENTARIO_DA_TAREFA,
  ALTERAR_STATUS_DA_TAREFA,
  CRIAR_TAREFA,
  CRIAR_TAREFA_DO_PACIENTE,
  LISTAR_TIPOS_DE_TAREFA,
  LISTAR_VACINAS,
  LISTAR_VACINAS_DO_PACIENTE,
  ADICIONAR_VACINA_DO_PACIENTE,
  ALTERAR_VACINA_DO_PACIENTE,
  REMOVER_VACINA_DO_PACIENTE,
  LISTAR_LOCAIS_DE_QUEDAS,
  LISTAR_QUEDAS_DO_PACIENTE,
  ADICIONAR_QUEDA_DO_PACIENTE,
  ALTERAR_QUEDA_DO_PACIENTE,
  REMOVER_QUEDA_DO_PACIENTE,
  LISTAR_ANTIBIOTICOS,
  LISTAR_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE,
  ADICIONAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  ALTERAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  REMOVER_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  LISTAR_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  ADICIONAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  ALTERAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  REMOVER_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  LISTAR_INTERNACOES_DO_PACIENTE,
  ADICIONAR_INTERNACAO_DO_PACIENTE,
  ALTERAR_INTERNACAO_DO_PACIENTE,
  REMOVER_INTERNACAO_DO_PACIENTE,
  LISTAR_IVCFS_DO_PACIENTE,
  ADICIONAR_IVCF_DO_PACIENTE,
  ALTERAR_IVCF_DO_PACIENTE,
  REMOVER_IVCF_DO_PACIENTE,
  LISTAR_NIVEIS_DE_SAUDE_RELATIVA,
  REMOVER_IVCF_DO_PACIENTE,
  LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  ADICIONAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  ALTERAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  REMOVER_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  LISTAR_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE,
  ADICIONAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  ALTERAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  REMOVER_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  LISTAR_CASPS_16_DO_PACIENTE,
  ADICIONAR_CASP_16_DO_PACIENTE,
  ALTERAR_CASP_16_DO_PACIENTE,
  REMOVER_CASP_16_DO_PACIENTE,
  LISTAR_AVALIACOES_DA_SOLIDAO_DO_PACIENTE,
  ADICIONAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  ALTERAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  REMOVER_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  LISTAR_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO,
  RECUPERAR_AVALIACAO,
  RESPONDER_AVALIACAO,
  AUTENTICAR_MEDICO_NA_MEMED,
  INTEGRAR_NA_MEMED,
  RECUPERAR_PRESCRICAO_PDF_NA_MEMED,
  RECUPERAR_HISTORICO_PRESCRICAO_NA_MEMED,
  REMOVER_PRESCRICAO_NA_MEMED,
  LER_CERTIFICADOS_DIGITAIS_FISICOS,
  LISTAR_ATENDIMENTOS_NAO_ASSINADOS,
  INICIAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO,
  RECUPERAR_CERTIFICADOS_EM_NUVEM_DO_USUARIO,
  RECUPERAR_URL_AUTENTICACAO_DO_CERTIFICADO_EM_NUVEM,
  FINALIZAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO,
  ASSINAR_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM,
  INICIAR_SESSAO_COM_CERTIFICADO_EM_NUVEM,
  RECUPERAR_SESSAO_COM_CERTIFICADO_EM_NUVEM,
  LISTAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS,
  LISTAR_PROFISSOES_PARA_O_NPS,
  HABILITAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS,
  DESABILITAR_TIPO_DE_ATENDIMENTO_PARA_O_NPS,
  HABILITAR_PROFISSOES_PARA_O_NPS,
  DESABILITAR_PROFISSAO_PARA_O_NPS,
  LISTAR_AVALIACOES_RESPONDIDAS,
  ALTERAR_STATUS_DE_UMA_RESPOSTA,
  RECUPERAR_AVALIACAO_RESPONDIDA,
  LISTAR_PACIENTES_DO_NPS,
  LISTAR_PROFISSIONAIS_DO_NPS,
  RECUPERAR_ENDERECO_DA_UNIDADE_POR_CEP,
  ADICIONAR_CONSULTA_EXTERNA_DO_PACIENTE,
  ALTERAR_CONSULTA_EXTERNA_DO_PACIENTE,
  LISTAR_CONSULTAS_EXTERNAS_DO_PACIENTE,
  REMOVER_CONSULTA_EXTERNA_DO_PACIENTE,
  LISTAR_TIPOS_DE_CONSULTA_EXTERNA,
  MARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA,
  DESMARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA,
  DEFINIR_TEXTO_DA_TAREFA,
  ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DA_TAREFA,
  LISTAR_PROFISSOES_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS,
  FAZER_DOWNLOAD_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS,
  DEFINIR_TEXTO_LONGO_DA_TAREFA,
  DEFINIR_NUMERO_INTEIRO_DA_TAREFA,
  DEFINIR_MOMENTO_DA_TAREFA,
  LISTAR_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  ADICIONAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  ALTERAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  REMOVER_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  LISTAR_MOTIVOS_DE_BLOQUEIO_DO_LOGIN,
  LISTAR_USUARIOS_BLOQUEADOS,
  ADICIONAR_CONVENIO_DO_PACIENTE,
  ALTERAR_CONVENIO_DO_PACIENTE,
  LISTAR_CONVENIOS_DO_PACIENTE,
  REMOVER_CONVENIO_DO_PACIENTE,
  RECUPERAR_CONVENIO_DO_PACIENTE,
  LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_CONVENIO,
  FAZER_DOWNLOAD_DO_EXAME_DO_ATENDIMENTO,
  FAZER_DOWNLOAD_DO_ANEXO_DO_ATENDIMENTO,
  DEFINIR_DATA_DA_TAREFA,
  DEFINIR_PRAZO_DA_TAREFA,
  ALTERAR_CONFIGURACAO_DO_USUARIO,
  RECUPERAR_CONFIGURACOES_DO_USUARIO_LOGADO,
  RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO_LOGADO,
  RECUPERAR_CONFIGURACOES_DO_USUARIO,
  RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO,
  LISTAR_DURACOES_DAS_DORES,
  LISTAR_INTENSIDADES_DAS_DORES,
  LISTAR_LOCAIS_DAS_DORES,
  LISTAR_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER,
  LISTAR_PACIENTES_DOS_FILTROS_DO_TAREFAS,
  ADICIONAR_ESPECIALIDADE_MEDICA_INTERNA,
  REMOVER_ESPECIALIDADE_MEDICA_INTERNA,
  LISTAR_ESPECIALIDADES_MEDICAS_INTERNAS,
  LISTAR_CAMPOS_EXTERNOS_DO_PACIENTE,
  ADICIONAR_CAMPO_EXTERNO_DO_PACIENTE,
  ALTERAR_CAMPO_EXTERNO_DO_PACIENTE,
  REMOVER_CAMPO_EXTERNO_DO_PACIENTE,
  RECUPERAR_CAMPO_EXTERNO_DO_PACIENTE,
  LISTAR_TIPOS_DE_UNIDADE,
  ADICIONAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
  ALTERAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
  REMOVER_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
  LISTAR_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE,
  LISTAR_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO,
  ADICIONAR_CELULA,
  ALTERAR_CELULA,
  LISTAR_CELULAS,
  LISTAR_CELULAS_DO_CADASTRO,
  RECUPERAR_CELULA_PELO_IDENTIFICADOR,
  REMOVER_CELULA,
  LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES,
  LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  LISTAR_FABRICANTES,
  LISTAR_FABRICANTES_DO_CADASTRO,
  ADICIONAR_FABRICANTE,
  RECUPERAR_FABRICANTE_PELO_IDENTIFICADOR,
  ALTERAR_FABRICANTE,
  REMOVER_FABRICANTE,
  LISTAR_LOCAIS_DA_APLICACAO,
  LISTAR_ESTADOS_DA_MEMORIA_ATUAL,
  LISTAR_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS,
  LISTAR_MINI_MENTAIS,
  ADICIONAR_MINI_MENTAL,
  ALTERAR_MINI_MENTAL,
  REMOVER_MINI_MENTAL,
  LISTAR_LOCAIS_DA_APLICACAO,
  LISTAR_PROXIMOS_AGENDAMENTOS_MEDICOS_DO_PACIENTE,
  ADICIONAR_INTERPRETACAO_MEDICA_DO_MINI_MENTAL,
  REMOVER_INTERPRETACAO_MEDICA_DO_MINI_MENTAL,
  LISTAR_CIDS10,
  LISTAR_RESPOSTAS_DO_PHQ9,
  LISTAR_PHQS9,
  ADICIONAR_PHQ9,
  ALTERAR_PHQ9,
  REMOVER_PHQ9,
  LISTAR_SALAS_DA_UNIDADE,
  ADICIONAR_SALA_NA_UNIDADE,
  ALTERAR_SALA_DA_UNIDADE,
  REMOVER_SALA_DA_UNIDADE,
  RECUPERAR_SALA_DA_UNIDADE_PELO_IDENTIFICADOR,
  ADICIONAR_MEDICAMENTO_DO_PACIENTE,
  ALTERAR_MEDICAMENTO_DO_PACIENTE,
  ALTERAR_STATUS_DO_MEDICAMENTO_DO_PACIENTE,
  LISTAR_MEDICAMENTOS_DO_PACIENTE,
  LISTAR_SETORES,
  ADICIONAR_SETOR,
  RECUPERAR_SETOR_PELO_IDENTIFICADOR,
  ALTERAR_SETOR,
  REMOVER_SETOR,
  LISTAR_SETORES_RESPONSAVEIS_DO_TAREFAS,
  DEFINIR_SETOR_RESPONSAVEL_DA_TAREFA,
  ALTERAR_POSICAO_DOS_MEDICAMENTOS_DO_PACIENTE,
  ADICIONAR_DIAGNOSTICO_DO_PACIENTE,
  ALTERAR_STATUS_DO_DIAGNOSTICO_DO_PACIENTE,
  LISTAR_DIAGNOSTICOS_DO_PACIENTE,
  ALTERAR_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE,
  ADICIONAR_FERIADO_TRABALHADO_DO_PROFISSIONAL,
  REMOVER_FERIADO_TRABALHADO_DO_PROFISSIONAL,
  LISTAR_FERIADOS_TRABALHADOS_DO_PROFISSIONAL,
  LISTAR_PEDIDOS_DE_CONTATO,
  ADICIONAR_PEDIDO_DE_CONTATO,
  RECUPERAR_PEDIDO_DE_CONTATO_PELO_IDENTIFICADOR,
  ALTERAR_PEDIDO_DE_CONTATO,
  REMOVER_PEDIDO_DE_CONTATO,
  LISTAR_CIDADES_PARA_O_PEDIDO_DE_CONTATO,
  LISTAR_PLANOS_DE_SAUDE_PARA_O_PEDIDO_DE_CONTATO,
]

const acoesDeTermino = {
  [INICIOU_ATENDIMENTO]: [INICIAR_ATENDIMENTO],
  [ERRO_AO_INICIAR_ATENDIMENTO]: [INICIAR_ATENDIMENTO],
  [LISTOU_SEXOS]: [LISTAR_SEXOS],
  [ERRO_AO_LISTAR_SEXOS]: [LISTAR_SEXOS],
  [LISTOU_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA]: [LISTAR_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA],
  [ERRO_AO_LISTAR_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA]: [LISTAR_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA],
  [LISTOU_TRATAMENTOS]: [LISTAR_TRATAMENTOS],
  [ERRO_AO_LISTAR_TRATAMENTOS]: [LISTAR_TRATAMENTOS],
  [CANCELOU_ATENDIMENTO]: [CANCELAR_ATENDIMENTO],
  [ERRO_AO_CANCELAR_ATENDIMENTO]: [CANCELAR_ATENDIMENTO],
  [FINALIZOU_ATENDIMENTO]: [FINALIZAR_ATENDIMENTO],
  [ERRO_AO_FINALIZAR_ATENDIMENTO]: [FINALIZAR_ATENDIMENTO],
  [SALVOU_SECAO_DO_ATENDIMENTO]: [SALVAR_SECAO_DO_ATENDIMENTO],
  [ERRO_AO_SALVAR_SECAO_DO_ATENDIMENTO]: [SALVAR_SECAO_DO_ATENDIMENTO],
  [FEZ_DOWNLOAD_DA_PRESCRICAO]: [FAZER_DOWNLOAD_DA_PRESCRICAO],
  [ERRO_AO_FAZER_DOWNLOAD_DA_PRESCRICAO]: [FAZER_DOWNLOAD_DA_PRESCRICAO],
  [FEZ_DOWNLOAD_DO_ENCAMINHAMENTO]: [FAZER_DOWNLOAD_DO_ENCAMINHAMENTO],
  [ERRO_AO_FAZER_DOWNLOAD_DO_ENCAMINHAMENTO]: [FAZER_DOWNLOAD_DO_ENCAMINHAMENTO],
  [LISTOU_ATENDIMENTOS_FINALIZADOS]: [LISTAR_ATENDIMENTOS_FINALIZADOS],
  [ERRO_AO_LISTAR_ATENDIMENTOS_FINALIZADOS]: [LISTAR_ATENDIMENTOS_FINALIZADOS],
  [ADICIONOU_INFORMACOES]: [ADICIONAR_INFORMACOES],
  [ERRO_AO_ADICIONAR_INFORMACOES]: [ADICIONAR_INFORMACOES],
  [LISTOU_TIPOS_DE_EXAME]: [LISTAR_TIPOS_DE_EXAME],
  [ERRO_AO_LISTAR_TIPOS_DE_EXAME]: [LISTAR_TIPOS_DE_EXAME],
  [AUTENTICOU_USUARIO]: [AUTENTICAR_USUARIO],
  [NAO_AUTENTICOU_USUARIO]: [AUTENTICAR_USUARIO],
  [LISTOU_USUARIOS]: [LISTAR_USUARIOS],
  [ERRO_AO_LISTAR_USUARIOS]: [LISTAR_USUARIOS],
  [ADICIONOU_USUARIO]: [ADICIONAR_USUARIO],
  [ERRO_AO_ADICIONAR_USUARIO]: [ADICIONAR_USUARIO],
  [SALVOU_USUARIO]: [SALVAR_USUARIO],
  [ERRO_AO_SALVAR_USUARIO]: [SALVAR_USUARIO],
  [ADICIONOU_PERFIL_DO_USUARIO]: [ADICIONAR_PERFIL_DO_USUARIO],
  [ERRO_AO_ADICIONAR_PERFIL_DO_USUARIO]: [ADICIONAR_PERFIL_DO_USUARIO],
  [REMOVEU_PERFIL_DO_USUARIO]: [REMOVER_PERFIL_DO_USUARIO],
  [ERRO_AO_REMOVER_PERFIL_DO_USUARIO]: [REMOVER_PERFIL_DO_USUARIO],
  [ALTEROU_SENHA_DO_USUARIO]: [ALTERAR_SENHA_DO_USUARIO],
  [ERRO_AO_ALTERAR_SENHA_DO_USUARIO]: [ALTERAR_SENHA_DO_USUARIO],
  [RECUPEROU_TELECONSULTA_DO_AGENDAMENTO]: [RECUPERAR_TELECONSULTA_DO_AGENDAMENTO],
  [ERRO_AO_RECUPERAR_TELECONSULTA_DO_AGENDAMENTO]: [RECUPERAR_TELECONSULTA_DO_AGENDAMENTO],
  [RECUPEROU_TELECONSULTA_DO_ATENDIMENTO]: [RECUPERAR_TELECONSULTA_DO_ATENDIMENTO],
  [ERRO_AO_RECUPERAR_TELECONSULTA_DO_ATENDIMENTO]: [RECUPERAR_TELECONSULTA_DO_ATENDIMENTO],
  [RECUPEROU_USUARIO_POR_IDENTIFICADOR]: [RECUPERAR_USUARIO_POR_IDENTIFICADOR],
  [ERRO_AO_RECUPERAR_USUARIO_POR_IDENTIFICADOR]: [RECUPERAR_USUARIO_POR_IDENTIFICADOR],
  [LISTOU_TIPOS_DE_AGENDAMENTO]: [LISTAR_TIPOS_DE_AGENDAMENTO],
  [ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO]: [LISTAR_TIPOS_DE_AGENDAMENTO],
  [ADICIONOU_TIPO_DE_AGENDAMENTO]: [ADICIONAR_TIPO_DE_AGENDAMENTO],
  [ERRO_AO_ADICIONAR_TIPO_DE_AGENDAMENTO]: [ADICIONAR_TIPO_DE_AGENDAMENTO],
  [SALVOU_TIPO_DE_AGENDAMENTO]: [SALVAR_TIPO_DE_AGENDAMENTO],
  [ERRO_AO_SALVAR_TIPO_DE_AGENDAMENTO]: [SALVAR_TIPO_DE_AGENDAMENTO],
  [RECUPEROU_TIPO_DE_AGENDAMENTO_POR_IDENTIFICADOR]: [RECUPERAR_TIPO_DE_AGENDAMENTO_POR_IDENTIFICADOR],
  [ERRO_AO_RECUPERAR_TIPO_DE_AGENDAMENTO_POR_IDENTIFICADOR]: [RECUPERAR_TIPO_DE_AGENDAMENTO_POR_IDENTIFICADOR],
  [LISTOU_PACIENTES]: [LISTAR_PACIENTES],
  [ERRO_AO_LISTAR_PACIENTES]: [LISTAR_PACIENTES],
  [RECUPEROU_PACIENTE_POR_IDENTIFICADOR]: [RECUPERAR_PACIENTE_POR_IDENTIFICADOR],
  [ERRO_AO_RECUPERAR_PACIENTE_POR_IDENTIFICADOR]: [RECUPERAR_PACIENTE_POR_IDENTIFICADOR],
  [ADICIONOU_PACIENTE]: [ADICIONAR_PACIENTE],
  [ERRO_AO_ADICIONAR_PACIENTE]: [ADICIONAR_PACIENTE],
  [SALVOU_PACIENTE]: [SALVAR_PACIENTE],
  [ERRO_AO_SALVAR_PACIENTE]: [SALVAR_PACIENTE],
  [ADICIONOU_PROGRAMA_DO_PACIENTE]: [ADICIONAR_PROGRAMA_DO_PACIENTE],
  [ERRO_AO_ADICIONAR_PROGRAMA_DO_PACIENTE]: [ADICIONAR_PROGRAMA_DO_PACIENTE],
  [ALTEROU_PROGRAMA_DO_PACIENTE]: [ALTERAR_PROGRAMA_DO_PACIENTE],
  [ERRO_AO_ALTERAR_PROGRAMA_DO_PACIENTE]: [ALTERAR_PROGRAMA_DO_PACIENTE],
  [REMOVEU_PROGRAMA_DO_PACIENTE]: [REMOVER_PROGRAMA_DO_PACIENTE],
  [ERRO_AO_REMOVER_PROGRAMA_DO_PACIENTE]: [REMOVER_PROGRAMA_DO_PACIENTE],
  [RECUPEROU_PROGRAMA_DO_PACIENTE]: [RECUPERAR_PROGRAMA_DO_PACIENTE],
  [ERRO_AO_RECUPERAR_PROGRAMA_DO_PACIENTE]: [RECUPERAR_PROGRAMA_DO_PACIENTE],
  [RECUPEROU_AGENDA_SEMANAL_DO_PROFISSIONAL]: [RECUPERAR_AGENDA_SEMANAL_DO_PROFISSIONAL],
  [ERRO_AO_RECUPERAR_AGENDA_SEMANAL_DO_PROFISSIONAL]: [RECUPERAR_AGENDA_SEMANAL_DO_PROFISSIONAL],
  [LISTOU_PROFISSIONAIS_DE_SAUDE]: [LISTAR_PROFISSIONAIS_DE_SAUDE],
  [ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE]: [LISTAR_PROFISSIONAIS_DE_SAUDE],
  [CANCELOU_AGENDAMENTO]: [CANCELAR_AGENDAMENTO],
  [ERRO_AO_CANCELAR_AGENDAMENTO]: [CANCELAR_AGENDAMENTO],
  [EXCLUIU_AGENDAMENTO]: [EXCLUIR_AGENDAMENTO],
  [ERRO_AO_EXCLUIR_AGENDAMENTO]: [EXCLUIR_AGENDAMENTO],
  [CONFIRMOU_AGENDAMENTO]: [CONFIRMAR_AGENDAMENTO],
  [ERRO_AO_CONFIRMAR_AGENDAMENTO]: [CONFIRMAR_AGENDAMENTO],
  [ADICIONOU_AGENDAMENTO]: [ADICIONAR_AGENDAMENTO],
  [ERRO_AO_ADICIONAR_AGENDAMENTO]: [ADICIONAR_AGENDAMENTO],
  [SALVOU_AGENDAMENTO]: [SALVAR_AGENDAMENTO],
  [ERRO_AO_SALVAR_AGENDAMENTO]: [SALVAR_AGENDAMENTO],
  [LISTOU_PROGRAMAS_DO_PACIENTE]: [LISTAR_PROGRAMAS_DO_PACIENTE],
  [ERRO_AO_LISTAR_PROGRAMAS_DO_PACIENTE]: [LISTAR_PROGRAMAS_DO_PACIENTE],
  [LISTOU_RECORRENCIAS_DO_AGENDAMENTO]: [LISTAR_RECORRENCIAS_DO_AGENDAMENTO],
  [ERRO_AO_LISTAR_RECORRENCIAS_DO_AGENDAMENTO]: [LISTAR_RECORRENCIAS_DO_AGENDAMENTO],
  [LISTOU_TIPOS_DE_AGENDAMENTO_DO_AGENDAMENTO]: [LISTAR_TIPOS_DE_AGENDAMENTO_DO_AGENDAMENTO],
  [ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DO_AGENDAMENTO]: [LISTAR_TIPOS_DE_AGENDAMENTO_DO_AGENDAMENTO],
  [LISTOU_HORARIOS_LIVRES]: [LISTAR_HORARIOS_LIVRES],
  [ERRO_AO_LISTAR_HORARIOS_LIVRES]: [LISTAR_HORARIOS_LIVRES],
  [LIMPAR_HORARIOS_LIVRES]: [LISTAR_HORARIOS_LIVRES],
  [LISTOU_AGENDAMENTOS_PARA_CONFIRMACAO_DE_AGENDAMENTO]: [LISTAR_AGENDAMENTOS_PARA_CONFIRMACAO_DE_AGENDAMENTO],
  [ERRO_AO_LISTAR_AGENDAMENTOS_PARA_CONFIRMACAO_DE_AGENDAMENTO]: [LISTAR_AGENDAMENTOS_PARA_CONFIRMACAO_DE_AGENDAMENTO],
  [LISTOU_AGENDAMENTOS]: [LISTAR_AGENDAMENTOS],
  [ERRO_AO_LISTAR_AGENDAMENTOS]: [LISTAR_AGENDAMENTOS],
  [LISTOU_AGENDAMENTOS_DO_DIA]: [LISTAR_AGENDAMENTOS_DO_DIA],
  [ERRO_AO_LISTAR_AGENDAMENTOS_DO_DIA]: [LISTAR_AGENDAMENTOS_DO_DIA],
  [CONFIRMOU_CHEGADA_DO_PACIENTE]: [CONFIRMAR_CHEGADA_DO_PACIENTE],
  [ERRO_AO_CONFIRMAR_CHEGADA_DO_PACIENTE]: [CONFIRMAR_CHEGADA_DO_PACIENTE],
  [INFORMOU_SAIDA_DO_PAICENTE]: [INFORMAR_SAIDA_DO_PACIENTE],
  [ERRO_AO_INFORMAR_SAIDA_DO_PACIENTE]: [INFORMAR_SAIDA_DO_PACIENTE],
  [ADICIONOU_PRE_CADASTRO_DO_PACIENTE]: [ADICIONAR_PRE_CADASTRO_DO_PACIENTE],
  [ERRO_AO_ADICIONAR_PRE_CADASTRO_DO_PACIENTE]: [ADICIONAR_PRE_CADASTRO_DO_PACIENTE],
  [RECUPEROU_CONVENIOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO]: [RECUPERAR_CONVENIOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO],
  [ERRO_AO_RECUPERAR_CONVENIOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO]: [RECUPERAR_CONVENIOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO],
  [LISTOU_PROFISSIONAIS_DE_SAUDE_DA_AGENDA]: [LISTAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA],
  [ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA]: [LISTAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA],
  [FILTROU_PROFISSIONAIS_DE_SAUDE_DA_AGENDA]: [FILTRAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA],
  [ERRO_AO_FILTRAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA]: [FILTRAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA],
  [LISTOU_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO]: [LISTAR_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO],
  [ERRO_AO_LISTAR_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO]: [LISTAR_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO],
  [LISTOU_DIAGNOSTICOS]: [LISTAR_DIAGNOSTICOS],
  [ERRO_AO_LISTAR_DIAGNOSTICOS]: [LISTAR_DIAGNOSTICOS],
  [LISTOU_DIAGNOSTICOS_PARA_O_PRONTUARIO]: [LISTAR_DIAGNOSTICOS_PARA_O_PRONTUARIO],
  [ERRO_AO_LISTAR_DIAGNOSTICOS_PARA_O_PRONTUARIO]: [LISTAR_DIAGNOSTICOS_PARA_O_PRONTUARIO],
  [ADICIONOU_DIAGNOSTICO]: [ADICIONAR_DIAGNOSTICO],
  [ERRO_AO_ADICIONAR_DIAGNOSTICO]: [ADICIONAR_DIAGNOSTICO],
  [RECUPEROU_DIAGNOSTICO_PELO_IDENTIFICADOR]: [RECUPERAR_DIAGNOSTICO_PELO_IDENTIFICADOR],
  [ERRO_AO_RECUPERAR_DIAGNOSTICO_PELO_IDENTIFICADOR]: [RECUPERAR_DIAGNOSTICO_PELO_IDENTIFICADOR],
  [ALTEROU_DIAGNOSTICO]: [ALTERAR_DIAGNOSTICO],
  [ERRO_AO_ALTERAR_DIAGNOSTICO]: [ALTERAR_DIAGNOSTICO],
  [REMOVEU_DIAGNOSTICO]: [REMOVER_DIAGNOSTICO],
  [ERRO_AO_REMOVER_DIAGNOSTICO]: [REMOVER_DIAGNOSTICO],
  [LISTOU_MEDICAMENTOS_DO_PRONTUARIO]: [LISTAR_MEDICAMENTOS_DO_PRONTUARIO],
  [ERRO_AO_LISTAR_MEDICAMENTOS_DO_PRONTUARIO]: [LISTAR_MEDICAMENTOS_DO_PRONTUARIO],
  [LISTOU_POSOLOGIAS]: [LISTAR_POSOLOGIAS],
  [ERRO_AO_LISTAR_POSOLOGIAS]: [LISTAR_POSOLOGIAS],
  [LISTOU_TIPOS_DE_ATENDIMENTO_DO_PRONTUARIO]: [LISTAR_TIPOS_DE_ATENDIMENTO_DO_PRONTUARIO],
  [ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO_DO_PRONTUARIO]: [LISTAR_TIPOS_DE_ATENDIMENTO_DO_PRONTUARIO],
  [LISTOU_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO]: [LISTAR_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO],
  [ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO]: [LISTAR_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO],
  [LISTOU_OPERADORAS]: [LISTAR_OPERADORAS],
  [ERRO_AO_LISTAR_OPERADORAS]: [LISTAR_OPERADORAS],
  [LISTOU_PROFISSOES]: [LISTAR_PROFISSOES],
  [ERRO_AO_LISTAR_PROFISSOES]: [LISTAR_PROFISSOES],
  [LISTOU_PERFIS]: [LISTAR_PERFIS],
  [ERRO_AO_LISTAR_PERFIS]: [LISTAR_PERFIS],
  [RECUPEROU_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE]: [RECUPERAR_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE],
  [ERRO_AO_RECUPERAR_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE]: [RECUPERAR_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE],
  [ADICIONOU_PACIENTE_NA_LISTA_DE_ESPERA]: [ADICIONAR_PACIENTE_NA_LISTA_DE_ESPERA],
  [ERRO_AO_ADICIONAR_PACIENTE_NA_LISTA_DE_ESPERA]: [ADICIONAR_PACIENTE_NA_LISTA_DE_ESPERA],
  [ALTEROU_PACIENTE_NA_LISTA_DE_ESPERA]: [ALTERAR_PACIENTE_NA_LISTA_DE_ESPERA],
  [ERRO_AO_ALTERAR_PACIENTE_NA_LISTA_DE_ESPERA]: [ALTERAR_PACIENTE_NA_LISTA_DE_ESPERA],
  [REMOVEU_PACIENTE_NA_LISTA_DE_ESPERA]: [REMOVER_PACIENTE_NA_LISTA_DE_ESPERA],
  [ERRO_AO_REMOVER_PACIENTE_NA_LISTA_DE_ESPERA]: [REMOVER_PACIENTE_NA_LISTA_DE_ESPERA],
  [ALTEROU_DADOS_SELECIONADOS_DO_AGENDAMENTO_NA_LISTA_DE_ESPERA]: [ALTERAR_DADOS_SELECIONADOS_DO_AGENDAMENTO_NA_LISTA_DE_ESPERA],
  [ERRO_AO_ALTERAR_DADOS_SELECIONADOS_DO_AGENDAMENTO_NA_LISTA_DE_ESPERA]: [ALTERAR_DADOS_SELECIONADOS_DO_AGENDAMENTO_NA_LISTA_DE_ESPERA],
  [LISTOU_MEDICOS]: [LISTAR_MEDICOS],
  [ERRO_AO_LISTAR_MEDICOS]: [LISTAR_MEDICOS],
  [LISTOU_GRAUS_DE_RELACOES]: [LISTAR_GRAUS_DE_RELACOES],
  [ERRO_AO_LISTAR_GRAUS_DE_RELACOES]: [LISTAR_GRAUS_DE_RELACOES],
  [LISTOU_TAMANHOS_DE_PAGINAS]: [LISTAR_TAMANHOS_DE_PAGINAS],
  [ERRO_AO_LISTAR_TAMANHOS_DE_PAGINAS]: [LISTAR_TAMANHOS_DE_PAGINAS],
  [LISTOU_ENFERMEIROS]: [LISTAR_ENFERMEIROS],
  [ERRO_AO_LISTAR_ENFERMEIROS]: [LISTAR_ENFERMEIROS],
  [LISTOU_FERIADOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]: [LISTAR_FERIADOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE],
  [ERRO_AO_LISTAR_FERIADOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]: [LISTAR_FERIADOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE],
  [RECUPEROU_ATENDIMENTO]: [RECUPERAR_ATENDIMENTO],
  [ERRO_AO_RECUPERAR_ATENDIMENTO]: [RECUPERAR_ATENDIMENTO],
  [RECUPEROU_ATENDIMENTO_INICIADO]: [RECUPERAR_ATENDIMENTO_INICIADO],
  [ERRO_AO_RECUPERAR_ATENDIMENTO_INICIADO]: [RECUPERAR_ATENDIMENTO_INICIADO],
  [LISTOU_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]: [LISTAR_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE],
  [ERRO_AO_LISTAR_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]: [LISTAR_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE],
  [ADICIONOU_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]: [ADICIONAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE],
  [ERRO_AO_ADICIONAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]: [ADICIONAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE],
  [ALTEROU_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]: [ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE],
  [ERRO_AO_ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]: [ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE],
  [ALTEROU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE]: [ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE],
  [ERRO_AO_ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE]: [ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE],
  [EXCLUIU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE]: [EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE],
  [ERRO_AO_EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE]: [EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE],
  [ALTEROU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]: [ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE],
  [ERRO_AO_ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]: [ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE],
  [EXCLUIU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]: [EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE],
  [ERRO_AO_EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]: [EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE],
  [EXCLUIU_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]: [EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE],
  [ERRO_AO_EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]: [EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE],
  [EXCLUIU_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO]: [EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO],
  [ERRO_AO_EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO]: [EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO],
  [EXCLUIU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO]: [EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO],
  [ERRO_AO_EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO]: [EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO],
  [EXCLUIU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO]: [EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO],
  [ERRO_AO_EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO]: [EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO],
  [ERRO_AO_EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE]: [EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE],
  [EXCLUIU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE]: [EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE],
  [EXCLUIU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO]: [EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO],
  [ERRO_AO_EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO]: [EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO],
  [LISTOU_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE]: [LISTAR_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE],
  [ERRO_AO_LISTAR_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE]: [LISTAR_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE],
  [ADICIONOU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE]: [ADICIONAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE],
  [ERRO_AO_ADICIONAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE]: [ADICIONAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE],
  [ALTEROU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE]: [ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE],
  [ERRO_AO_ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE]: [ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE],
  [LISTOU_MICRORGANISMOS]: [LISTAR_MICRORGANISMOS],
  [ERRO_AO_LISTAR_MICRORGANISMOS]: [LISTAR_MICRORGANISMOS],
  [ADICIONOU_SUMARIO_DE_ALTA_DA_INTERNACAO]: [ADICIONAR_SUMARIO_DE_ALTA_DA_INTERNACAO],
  [ERRO_AO_ADICIONAR_SUMARIO_DE_ALTA_DA_INTERNACAO]: [ADICIONAR_SUMARIO_DE_ALTA_DA_INTERNACAO],
  [LISTOU_ORIENTACOES_DE_PAGINAS]: [LISTAR_ORIENTACOES_DE_PAGINAS],
  [ERRO_AO_LISTAR_ORIENTACOES_DE_PAGINAS]: [LISTAR_ORIENTACOES_DE_PAGINAS],
  [FEZ_DOWNLOAD_DO_ARQUIVO_DE_ATENDIMENTO]: [FAZER_DOWNLOAD_DO_ARQUIVO_DE_ATENDIMENTO],
  [ERRO_AO_FAZER_DOWNLOAD_DO_ARQUIVO_DE_ATENDIMENTO]: [FAZER_DOWNLOAD_DO_ARQUIVO_DE_ATENDIMENTO],
  [LISTOU_TIPOS_DE_AGENDAMENTO_DAS_CONFIGURACOES_DA_AGENDA]: [LISTAR_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA],
  [ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA]: [LISTAR_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA],
  [LISTOU_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA]: [LISTAR_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA],
  [ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DAS_CONFIGURACOES_DA_AGENDA]: [LISTAR_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA],
  [ADICIONOU_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL]: [ADICIONAR_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL],
  [ERRO_AO_ADICIONAR_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL]: [ADICIONAR_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL],
  [REMOVEU_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL]: [REMOVER_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL],
  [ERRO_AO_REMOVER_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL]: [REMOVER_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL],
  [RECUPEROU_EMPRESA]: [RECUPERAR_EMPRESA],
  [ERRO_AO_RECUPERAR_EMPRESA]: [RECUPERAR_EMPRESA],
  [SALVOU_EMPRESA]: [SALVAR_EMPRESA],
  [ERRO_AO_SALVAR_EMPRESA]: [SALVAR_EMPRESA],
  [ADICIONOU_UNIDADE_DA_EMPRESA]: [ADICIONAR_UNIDADE_DA_EMPRESA],
  [ERRO_AO_ADICIONAR_UNIDADE_DA_EMPRESA]: [ADICIONAR_UNIDADE_DA_EMPRESA],
  [SALVOU_UNIDADE_DA_EMPRESA]: [SALVAR_UNIDADE_DA_EMPRESA],
  [ERRO_AO_SALVAR_UNIDADE_DA_EMPRESA]: [SALVAR_UNIDADE_DA_EMPRESA],
  [LISTOU_UNIDADES_DA_EMPRESA]: [LISTAR_UNIDADES_DA_EMPRESA],
  [ERRO_AO_LISTAR_UNIDADES_DA_EMPRESA]: [LISTAR_UNIDADES_DA_EMPRESA],
  [REMOVEU_UNIDADE_DA_EMPRESA]: [REMOVER_UNIDADE_DA_EMPRESA],
  [ERRO_AO_REMOVER_UNIDADE_DA_EMPRESA]: [REMOVER_UNIDADE_DA_EMPRESA],
  [ATIVOU_UNIDADE_DA_EMPRESA]: [ATIVAR_UNIDADE_DA_EMPRESA],
  [ERRO_AO_ATIVAR_UNIDADE_DA_EMPRESA]: [ATIVAR_UNIDADE_DA_EMPRESA],
  [DESATIVOU_UNIDADE_DA_EMPRESA]: [DESATIVAR_UNIDADE_DA_EMPRESA],
  [ERRO_AO_DESATIVAR_UNIDADE_DA_EMPRESA]: [DESATIVAR_UNIDADE_DA_EMPRESA],
  [LISTOU_EMPRESAS_HABILITADAS_PARA_O_LOGIN]: [LISTAR_EMPRESAS_HABILITADAS_PARA_O_LOGIN],
  [ERRO_AO_LISTAR_EMPRESAS_HABILITADAS_PARA_O_LOGIN]: [LISTAR_EMPRESAS_HABILITADAS_PARA_O_LOGIN],
  [LISTOU_UNIDADES_DO_USUARIO]: [LISTAR_UNIDADES_DO_USUARIO],
  [ERRO_AO_LISTAR_UNIDADES_DO_USUARIO]: [LISTAR_UNIDADES_DO_USUARIO],
  [ADICIONOU_UNIDADE_DO_USUARIO]: [ADICIONAR_UNIDADE_DO_USUARIO],
  [ERRO_AO_ADICIONAR_UNIDADE_DO_USUARIO]: [ADICIONAR_UNIDADE_DO_USUARIO],
  [REMOVEU_UNIDADE_DO_USUARIO]: [REMOVER_UNIDADE_DO_USUARIO],
  [ERRO_AO_REMOVER_UNIDADE_DO_USUARIO]: [REMOVER_UNIDADE_DO_USUARIO],
  [LISTOU_UNIDADES_DA_EMPRESA_DO_USUARIO]: [LISTAR_UNIDADES_DA_EMPRESA_DO_USUARIO],
  [ERRO_AO_LISTAR_UNIDADES_DA_EMPRESA_DO_USUARIO]: [LISTAR_UNIDADES_DA_EMPRESA_DO_USUARIO],
  [RECUPEROU_LOGIN]: [RECUPERAR_LOGIN],
  [ERRO_AO_RECUPERAR_LOGIN]: [RECUPERAR_LOGIN],
  [HABILITOU_TIPO_DE_ATENDIMENTO_DO_USUARIO]: [HABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO],
  [ERRO_AO_HABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO]: [HABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO],
  [DESABILITOU_TIPO_DE_ATENDIMENTO_DO_USUARIO]: [DESABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO],
  [ERRO_AO_DESABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO]: [DESABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO],
  [GEROU_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO]: [GERAR_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO],
  [ERRO_AO_GERAR_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO]: [GERAR_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO],
  [GEROU_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO]: [GERAR_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO],
  [ERRO_AO_GERAR_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO]: [GERAR_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO],
  [LISTOU_PROGRAMAS_DO_RELATORIO_RESUMO_DE_INTERNACOES]: [LISTAR_PROGRAMAS_DO_RELATORIO_RESUMO_DE_INTERNACOES],
  [ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_RESUMO_DE_INTERNACOES]: [LISTAR_PROGRAMAS_DO_RELATORIO_RESUMO_DE_INTERNACOES],
  [FEZ_DOWNLOAD_DE_IDAS_AO_PRONTO_ATENDIMENTO]: [FAZER_DOWNLOAD_DE_IDAS_AO_PRONTO_ATENDIMENTO],
  [ERRO_AO_FAZER_DOWNLOAD_DE_IDAS_AO_PRONTO_ATENDIMENTO]: [FAZER_DOWNLOAD_DE_IDAS_AO_PRONTO_ATENDIMENTO],
  [FEZ_DOWNLOAD_DE_INTERNACOES]: [FAZER_DOWNLOAD_DE_INTERNACOES],
  [ERRO_AO_FAZER_DOWNLOAD_DE_INTERNACOES]: [FAZER_DOWNLOAD_DE_INTERNACOES],
  [FEZ_DOWNLOAD_DE_INTERNACOES_POR_FRATURA_DE_FEMUR]: [FAZER_DOWNLOAD_DE_INTERNACOES_POR_FRATURA_DE_FEMUR],
  [ERRO_AO_FAZER_DOWNLOAD_DE_INTERNACOES_POR_FRATURA_DE_FEMUR]: [FAZER_DOWNLOAD_DE_INTERNACOES_POR_FRATURA_DE_FEMUR],
  [FEZ_DOWNLOAD_DE_REINTERNACOES]: [FAZER_DOWNLOAD_DE_REINTERNACOES],
  [ERRO_AO_FAZER_DOWNLOAD_DE_REINTERNACOES]: [FAZER_DOWNLOAD_DE_REINTERNACOES],
  [LISTOU_PROGRAMAS_DO_RELATORIO_DE_IDAS_AO_PRONTO_ATENDIMENTO]: [LISTAR_PROGRAMAS_DO_RELATORIO_DE_IDAS_AO_PRONTO_ATENDIMENTO],
  [ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_IDAS_AO_PRONTO_ATENDIMENTO]: [LISTAR_PROGRAMAS_DO_RELATORIO_DE_IDAS_AO_PRONTO_ATENDIMENTO],
  [LISTOU_PROGRAMAS_DO_RELATORIO_DE_INTERNACOES]: [LISTAR_PROGRAMAS_DO_RELATORIO_DE_INTERNACOES],
  [ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_INTERNACOES]: [LISTAR_PROGRAMAS_DO_RELATORIO_DE_INTERNACOES],
  [LISTOU_PROGRAMAS_DO_RELATORIO_DE_REINTERNACOES]: [LISTAR_PROGRAMAS_DO_RELATORIO_DE_REINTERNACOES],
  [ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_REINTERNACOES]: [LISTAR_PROGRAMAS_DO_RELATORIO_DE_REINTERNACOES],
  [LISTOU_OPERADORAS_DO_RELATORIO_DE_PACIENTES]: [LISTAR_OPERADORAS_DO_RELATORIO_DE_PACIENTES],
  [ERRO_AO_LISTAR_OPERADORAS_DO_RELATORIO_DE_PACIENTES]: [LISTAR_OPERADORAS_DO_RELATORIO_DE_PACIENTES],
  [LISTOU_PROGRAMAS_DO_RELATORIO_DE_PACIENTES]: [LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES],
  [ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES]: [LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES],
  [FEZ_DOWNLOAD_DE_PACIENTES]: [FAZER_DOWNLOAD_DE_PACIENTES],
  [ERRO_AO_FAZER_DOWNLOAD_DE_PACIENTES]: [FAZER_DOWNLOAD_DE_PACIENTES],
  [FEZ_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA]: [FAZER_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA],
  [ERRO_AO_FAZER_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA]: [FAZER_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA],
  [LISTOU_MEDICOS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA]: [LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA],
  [ERRO_AO_LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA]: [LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA],
  [LISTOU_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA]: [LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA],
  [ERRO_AO_LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA]: [LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA],
  [LISTOU_PROGRAMAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA]: [LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA],
  [ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA]: [LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA],
  [LISTOU_VIAS_DE_ADMINISTRACAO_DE_MEDICAMENTOS]: [LISTAR_VIAS_DE_ADMINISTRACAO_DE_MEDICAMENTOS],
  [ERRO_AO_LISTAR_VIAS_DE_ADMINISTRACAO_DE_MEDICAMENTOS]: [LISTAR_VIAS_DE_ADMINISTRACAO_DE_MEDICAMENTOS],
  [LISTOU_PROGRAMAS_DO_RELATORIO_DE_ATENDIMENTOS]: [LISTAR_PROGRAMAS_DO_RELATORIO_DE_ATENDIMENTOS],
  [ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_ATENDIMENTOS]: [LISTAR_PROGRAMAS_DO_RELATORIO_DE_ATENDIMENTOS],
  [FEZ_DOWNLOAD_DE_ATENDIMENTOS]: [FAZER_DOWNLOAD_DE_ATENDIMENTOS],
  [ERRO_AO_FAZER_DOWNLOAD_DE_ATENDIMENTOS]: [FAZER_DOWNLOAD_DE_ATENDIMENTOS],
  [ADICIONOU_ARQUIVO]: [ADICIONAR_ARQUIVO],
  [ERRO_AO_ADICIONAR_ARQUIVO]: [ADICIONAR_ARQUIVO],
  [FEZ_DOWNLOAD_DO_ARQUIVO_ADICIONAL_DO_ATENDIMENTO]: [FAZER_DOWNLOAD_DO_ARQUIVO_ADICIONAL_DO_ATENDIMENTO],
  [ERRO_AO_FAZER_DOWNLOAD_DO_ARQUIVO_ADICIONAL_DO_ATENDIMENTO]: [FAZER_DOWNLOAD_DO_ARQUIVO_ADICIONAL_DO_ATENDIMENTO],
  [LISTOU_FERIADOS]: [LISTAR_FERIADOS],
  [ERRO_AO_LISTAR_FERIADOS]: [LISTAR_FERIADOS],
  [ADICIONOU_FERIADO]: [ADICIONAR_FERIADO],
  [ERRO_AO_ADICIONAR_FERIADO]: [ADICIONAR_FERIADO],
  [RECUPEROU_FERIADO_POR_IDENTIFICADOR]: [RECUPERAR_FERIADO_POR_IDENTIFICADOR],
  [ERRO_AO_RECUPERAR_FERIADO_POR_IDENTIFICADOR]: [RECUPERAR_FERIADO_POR_IDENTIFICADOR],
  [SALVOU_FERIADO]: [SALVAR_FERIADO],
  [ERRO_AO_SALVAR_FERIADO]: [SALVAR_FERIADO],
  [LISTOU_UNIDADES_DO_USUARIO_LOGADO]: [LISTAR_UNIDADES_DO_USUARIO_LOGADO],
  [ERRO_AO_LISTAR_UNIDADES_DO_USUARIO_LOGADO]: [LISTAR_UNIDADES_DO_USUARIO_LOGADO],
  [FEZ_DOWNLOAD_DO_ARQUIVO_DE_VIDEO_CONSULTA]: [FAZER_DOWNLOAD_DO_ARQUIVO_DE_VIDEO_CONSULTA],
  [ERRO_AO_FAZER_DOWNLOAD_DO_ARQUIVO_DE_VIDEO_CONSULTA]: [FAZER_DOWNLOAD_DO_ARQUIVO_DE_VIDEO_CONSULTA],
  [ADICIONOU_TAG_NO_PACIENTE]: [ADICIONAR_TAG_NO_PACIENTE],
  [ERRO_AO_ADICIONAR_TAG_NO_PACIENTE]: [ADICIONAR_TAG_NO_PACIENTE],
  [REMOVEU_TAG_DO_PACIENTE]: [REMOVER_TAG_DO_PACIENTE],
  [ERRO_AO_REMOVER_TAG_DO_PACIENTE]: [REMOVER_TAG_DO_PACIENTE],
  [LISTOU_TAGS_DE_PACIENTES]: [LISTAR_TAGS_DE_PACIENTES],
  [ERRO_AO_LISTAR_TAGS_DE_PACIENTES]: [LISTAR_TAGS_DE_PACIENTES],
  [REMOVEU_TIPO_DE_AGENDAMENTO]: [REMOVER_TIPO_DE_AGENDAMENTO],
  [ERRO_AO_REMOVER_TIPO_DE_AGENDAMENTO]: [REMOVER_TIPO_DE_AGENDAMENTO],
  [LISTOU_MEDICOS_DO_RELATORIO_DE_PACIENTES]: [LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES],
  [ERRO_AO_LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES]: [LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES],
  [LISTOU_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES]: [LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES],
  [ERRO_AO_LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES]: [LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES],
  [LISTOU_MEDICAMENTOS]: [LISTAR_MEDICAMENTOS],
  [ERRO_AO_LISTAR_MEDICAMENTOS]: [LISTAR_MEDICAMENTOS],
  [ADICIONOU_MEDICAMENTO]: [ADICIONAR_MEDICAMENTO],
  [ERRO_AO_ADICIONAR_MEDICAMENTO]: [ADICIONAR_MEDICAMENTO],
  [REMOVEU_MEDICAMENTO]: [REMOVER_MEDICAMENTO],
  [ERRO_AO_REMOVER_MEDICAMENTO]: [REMOVER_MEDICAMENTO],
  [ALTEROU_MEDICAMENTO]: [ALTERAR_MEDICAMENTO],
  [ERRO_AO_ALTERAR_MEDICAMENTO]: [ALTERAR_MEDICAMENTO],
  [LISTOU_CONCENTRACOES_DO_MEDICAMENTO]: [LISTAR_CONCENTRACOES_DO_MEDICAMENTO],
  [ERRO_AO_LISTAR_CONCENTRACOES_DO_MEDICAMENTO]: [LISTAR_CONCENTRACOES_DO_MEDICAMENTO],
  [ADICIONOU_CONCENTRACAO_DO_MEDICAMENTO]: [ADICIONAR_CONCENTRACAO_DO_MEDICAMENTO],
  [ERRO_AO_ADICIONAR_CONCENTRACAO_DO_MEDICAMENTO]: [ADICIONAR_CONCENTRACAO_DO_MEDICAMENTO],
  [REMOVEU_CONCENTRACAO_DO_MEDICAMENTO]: [REMOVER_CONCENTRACAO_DO_MEDICAMENTO],
  [ERRO_AO_REMOVER_CONCENTRACAO_DO_MEDICAMENTO]: [REMOVER_CONCENTRACAO_DO_MEDICAMENTO],
  [ALTEROU_CONCENTRACAO_DO_MEDICAMENTO]: [ALTERAR_CONCENTRACAO_DO_MEDICAMENTO],
  [ERRO_AO_ALTERAR_CONCENTRACAO_DO_MEDICAMENTO]: [ALTERAR_CONCENTRACAO_DO_MEDICAMENTO],
  [RECUPEROU_MEDICAMENTO_POR_IDENTIFICADOR]: [RECUPERAR_MEDICAMENTO_POR_IDENTIFICADOR],
  [ERRO_AO_RECUPERAR_MEDICAMENTO_POR_IDENTIFICADOR]: [RECUPERAR_MEDICAMENTO_POR_IDENTIFICADOR],
  [RECUPEROU_ENDERECO_DO_PACIENTE_POR_CEP]: [RECUPERAR_ENDERECO_DO_PACIENTE_POR_CEP],
  [ERRO_AO_RECUPERAR_ENDERECO_DO_PACIENTE_POR_CEP]: [RECUPERAR_ENDERECO_DO_PACIENTE_POR_CEP],
  [LISTOU_PACIENTES_DO_AGENDAMENTO]: [LISTAR_PACIENTES_DO_AGENDAMENTO],
  [ERRO_AO_LISTAR_PACIENTES_DO_AGENDAMENTO]: [LISTAR_PACIENTES_DO_AGENDAMENTO],
  [LISTOU_PACIENTES_DO_WIZARD_DE_AGENDAMENTO]: [LISTAR_PACIENTES_DO_WIZARD_DE_AGENDAMENTO],
  [ERRO_AO_LISTAR_PACIENTES_DO_WIZARD_DE_AGENDAMENTO]: [LISTAR_PACIENTES_DO_WIZARD_DE_AGENDAMENTO],
  [RECUPEROU_CONVENIOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO]: [RECUPERAR_CONVENIOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO],
  [ERRO_AO_RECUPERAR_CONVENIOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO]: [RECUPERAR_CONVENIOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO],
  [SELECIONOU_PACIENTE_DO_WIZARD_DE_AGENDAMENTO]: [SELECIONAR_PACIENTE_DO_WIZARD_DE_AGENDAMENTO],
  [ERRO_AO_SELECIONAR_PACIENTE_DO_WIZARD_DE_AGENDAMENTO]: [SELECIONAR_PACIENTE_DO_WIZARD_DE_AGENDAMENTO],
  [EXCLUIU_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO]: [EXCLUIR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO],
  [ERRO_AO_EXCLUIR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO]: [EXCLUIR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO],
  [LISTOU_PROFISSOES_DO_WIZARD_DE_AGENDAMENTO]: [LISTAR_PROFISSOES_DO_WIZARD_DE_AGENDAMENTO],
  [ERRO_AO_LISTAR_PROFISSOES_DO_WIZARD_DE_AGENDAMENTO]: [LISTAR_PROFISSOES_DO_WIZARD_DE_AGENDAMENTO],
  [LISTOU_PROFISSIONAIS_DE_SAUDE_DO_WIZARD_DE_AGENDAMENTO]: [LISTAR_PROFISSIONAIS_DE_SAUDE_DO_WIZARD_DE_AGENDAMENTO],
  [ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE_DO_WIZARD_DE_AGENDAMENTO]: [LISTAR_PROFISSIONAIS_DE_SAUDE_DO_WIZARD_DE_AGENDAMENTO],
  [LISTOU_TIPOS_DE_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO]: [LISTAR_TIPOS_DE_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO],
  [ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO]: [LISTAR_TIPOS_DE_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO],
  [LISTOU_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO]: [LISTAR_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO],
  [ERRO_AO_LISTAR_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO]: [LISTAR_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO],
  [ADICIONOU_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO]: [ADICIONAR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO],
  [ERRO_AO_ADICIONAR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO]: [ADICIONAR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO],
  [ADICIONOU_PRE_CADASTRO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO]: [ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO],
  [ERRO_AO_ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO]: [ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO],
  [RECUPEROU_FOTO_DO_USUARIO_POR_IDENTIFICADOR]: [RECUPERAR_FOTO_DO_USUARIO_POR_IDENTIFICADOR],
  [ERRO_AO_RECUPERAR_FOTO_DO_USUARIO_POR_IDENTIFICADOR]: [RECUPERAR_FOTO_DO_USUARIO_POR_IDENTIFICADOR],
  [RECUPEROU_FOTO_DO_PACIENTE_POR_IDENTIFICADOR]: [RECUPERAR_FOTO_DO_PACIENTE_POR_IDENTIFICADOR],
  [ERRO_AO_RECUPERAR_FOTO_DO_PACIENTE_POR_IDENTIFICADOR]: [RECUPERAR_FOTO_DO_PACIENTE_POR_IDENTIFICADOR],
  [RECUPEROU_FOTO_DA_EMPRESA_LOGADA]: [RECUPERAR_FOTO_DA_EMPRESA_LOGADA],
  [ERRO_AO_RECUPERAR_FOTO_DA_EMPRESA_LOGADA]: [RECUPERAR_FOTO_DA_EMPRESA_LOGADA],
  [RECUPEROU_FOTO_DA_EMPRESA_POR_IDENTIFICADOR_PUBLICO]: [RECUPERAR_FOTO_DA_EMPRESA_POR_IDENTIFICADOR_PUBLICO],
  [ERRO_AO_RECUPERAR_FOTO_DA_EMPRESA_POR_IDENTIFICADOR_PUBLICO]: [RECUPERAR_FOTO_DA_EMPRESA_POR_IDENTIFICADOR_PUBLICO],
  [RECUPEROU_DESENHO_DO_MINI_MENTAL]: [RECUPERAR_DESENHO_DO_MINI_MENTAL],
  [ERRO_AO_RECUPERAR_DESENHO_DO_MINI_MENTAL]: [RECUPERAR_DESENHO_DO_MINI_MENTAL],
  [ADICIONOU_FOTO_DO_USUARIO]: [ADICIONAR_FOTO_DO_USUARIO],
  [ERRO_AO_ADICIONAR_FOTO_DO_USUARIO]: [ADICIONAR_FOTO_DO_USUARIO],
  [ADICIONOU_FOTO_DO_PACIENTE]: [ADICIONAR_FOTO_DO_PACIENTE],
  [ERRO_AO_ADICIONAR_FOTO_DO_PACIENTE]: [ADICIONAR_FOTO_DO_PACIENTE],
  [ADICIONOU_FOTO_DA_EMPRESA]: [ADICIONAR_FOTO_DA_EMPRESA],
  [ERRO_AO_ADICIONAR_FOTO_DA_EMPRESA]: [ADICIONAR_FOTO_DA_EMPRESA],
  [LISTOU_MOTIVOS_DE_INTERNACAO]: [LISTAR_MOTIVOS_DE_INTERNACAO],
  [ERRO_AO_LISTAR_MOTIVOS_DE_INTERNACAO]: [LISTAR_MOTIVOS_DE_INTERNACAO],
  [LISTOU_MOTIVOS_DE_INTERNACAO_DO_CADASTRO]: [LISTAR_MOTIVOS_DE_INTERNACAO_DO_CADASTRO],
  [ERRO_AO_LISTAR_MOTIVOS_DE_INTERNACAO_DO_CADASTRO]: [LISTAR_MOTIVOS_DE_INTERNACAO_DO_CADASTRO],
  [RECUPEROU_MOTIVO_DE_INTERNACAO_PELO_IDENTIFICADOR]: [RECUPERAR_MOTIVO_DE_INTERNACAO_PELO_IDENTIFICADOR],
  [ERRO_AO_RECUPERAR_MOTIVO_DE_INTERNACAO_PELO_IDENTIFICADOR]: [RECUPERAR_MOTIVO_DE_INTERNACAO_PELO_IDENTIFICADOR],
  [ALTEROU_MOTIVO_DE_INTERNACAO]: [ALTERAR_MOTIVO_DE_INTERNACAO],
  [ERRO_AO_ALTERAR_MOTIVO_DE_INTERNACAO]: [ALTERAR_MOTIVO_DE_INTERNACAO],
  [ADICIONOU_MOTIVO_DE_INTERNACAO]: [ADICIONAR_MOTIVO_DE_INTERNACAO],
  [ERRO_AO_ADICIONAR_MOTIVO_DE_INTERNACAO]: [ADICIONAR_MOTIVO_DE_INTERNACAO],
  [REMOVEU_MOTIVO_DE_INTERNACAO]: [REMOVER_MOTIVO_DE_INTERNACAO],
  [ERRO_AO_REMOVER_MOTIVO_DE_INTERNACAO]: [REMOVER_MOTIVO_DE_INTERNACAO],
  [REMOVEU_PROFISSAO]: [REMOVER_PROFISSAO],
  [ERRO_AO_REMOVER_PROFISSAO]: [REMOVER_PROFISSAO],
  [ADICIONOU_PROFISSAO]: [ADICIONAR_PROFISSAO],
  [ERRO_AO_ADICIONAR_PROFISSAO]: [ADICIONAR_PROFISSAO],
  [ALTEROU_PROFISSAO]: [ALTERAR_PROFISSAO],
  [ERRO_AO_ALTERAR_PROFISSAO]: [ALTERAR_PROFISSAO],
  [RECUPEROU_PROFISSAO_POR_IDENTIFICADOR]: [RECUPERAR_PROFISSAO_POR_IDENTIFICADOR],
  [ERRO_AO_RECUPERAR_PROFISSAO_POR_IDENTIFICADOR]: [RECUPERAR_PROFISSAO_POR_IDENTIFICADOR],
  [ADICIONOU_ESPECIALIDADE]: [ADICIONAR_ESPECIALIDADE],
  [ERRO_AO_ADICIONAR_ESPECIALIDADE]: [ADICIONAR_ESPECIALIDADE],
  [REMOVEU_ESPECIALIDADE]: [REMOVER_ESPECIALIDADE],
  [ERRO_AO_REMOVER_ESPECIALIDADE]: [REMOVER_ESPECIALIDADE],
  [ALTEROU_ESPECIALIDADE]: [ALTERAR_ESPECIALIDADE],
  [ERRO_AO_ALTERAR_ESPECIALIDADE]: [ALTERAR_ESPECIALIDADE],
  [RECUPEROU_ESPECIALIDADE_POR_IDENTIFICADOR]: [RECUPERAR_ESPECIALIDADE_POR_IDENTIFICADOR],
  [ERRO_AO_RECUPERAR_ESPECIALIDADE_POR_IDENTIFICADOR]: [RECUPERAR_ESPECIALIDADE_POR_IDENTIFICADOR],
  [LISTOU_ESPECIALIDADES]: [LISTAR_ESPECIALIDADES],
  [ERRO_AO_LISTAR_ESPECIALIDADES]: [LISTAR_ESPECIALIDADES],
  [LISTOU_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO]: [LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO],
  [ERRO_AO_LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO]: [LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO],
  [RECUPEROU_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO_PELO_IDENTIFICADOR]: [RECUPERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO_PELO_IDENTIFICADOR],
  [ERRO_AO_RECUPERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO_PELO_IDENTIFICADOR]: [RECUPERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO_PELO_IDENTIFICADOR],
  [ALTEROU_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO]: [ALTERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO],
  [ERRO_AO_ALTERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO]: [ALTERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO],
  [ADICIONOU_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO]: [ADICIONAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO],
  [ERRO_AO_ADICIONAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO]: [ADICIONAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO],
  [REMOVEU_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO]: [REMOVER_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO],
  [ERRO_AO_REMOVER_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO]: [REMOVER_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO],
  [LISTOU_ESTABELECIMENTOS_DE_SAUDE]: [LISTAR_ESTABELECIMENTOS_DE_SAUDE],
  [ERRO_AO_LISTAR_ESTABELECIMENTOS_DE_SAUDE]: [LISTAR_ESTABELECIMENTOS_DE_SAUDE],
  [LISTOU_ESTABELECIMENTOS_DE_SAUDE_DO_CADASTRO]: [LISTAR_ESTABELECIMENTOS_DE_SAUDE_DO_CADASTRO],
  [ERRO_AO_LISTAR_ESTABELECIMENTOS_DE_SAUDE_DO_CADASTRO]: [LISTAR_ESTABELECIMENTOS_DE_SAUDE_DO_CADASTRO],
  [RECUPEROU_ESTABELECIMENTO_DE_SAUDE_PELO_IDENTIFICADOR]: [RECUPERAR_ESTABELECIMENTO_DE_SAUDE_PELO_IDENTIFICADOR],
  [ERRO_AO_RECUPERAR_ESTABELECIMENTO_DE_SAUDE_PELO_IDENTIFICADOR]: [RECUPERAR_ESTABELECIMENTO_DE_SAUDE_PELO_IDENTIFICADOR],
  [ALTEROU_ESTABELECIMENTO_DE_SAUDE]: [ALTERAR_ESTABELECIMENTO_DE_SAUDE],
  [ERRO_AO_ALTERAR_ESTABELECIMENTO_DE_SAUDE]: [ALTERAR_ESTABELECIMENTO_DE_SAUDE],
  [ADICIONOU_ESTABELECIMENTO_DE_SAUDE]: [ADICIONAR_ESTABELECIMENTO_DE_SAUDE],
  [ERRO_AO_ADICIONAR_ESTABELECIMENTO_DE_SAUDE]: [ADICIONAR_ESTABELECIMENTO_DE_SAUDE],
  [REMOVEU_ESTABELECIMENTO_DE_SAUDE]: [REMOVER_ESTABELECIMENTO_DE_SAUDE],
  [ERRO_AO_REMOVER_ESTABELECIMENTO_DE_SAUDE]: [REMOVER_ESTABELECIMENTO_DE_SAUDE],
  [LISTOU_OPERADORAS_DO_CADASTRO]: [LISTAR_OPERADORAS_DO_CADASTRO],
  [ERRO_AO_LISTAR_OPERADORAS_DO_CADASTRO]: [LISTAR_OPERADORAS_DO_CADASTRO],
  [ADICIONOU_OPERADORA]: [ADICIONAR_OPERADORA],
  [ERRO_AO_ADICIONAR_OPERADORA]: [ADICIONAR_OPERADORA],
  [ALTEROU_OPERADORA]: [ALTERAR_OPERADORA],
  [ERRO_AO_ALTERAR_OPERADORA]: [ALTERAR_OPERADORA],
  [RECUPEROU_OPERADORA_PELO_IDENTIFICADOR]: [RECUPERAR_OPERADORA_PELO_IDENTIFICADOR],
  [ERRO_AO_RECUPERAR_OPERADORA_PELO_IDENTIFICADOR]: [RECUPERAR_OPERADORA_PELO_IDENTIFICADOR],
  [REMOVEU_OPERADORA]: [REMOVER_OPERADORA],
  [ERRO_AO_REMOVER_OPERADORA]: [REMOVER_OPERADORA],
  [ERRO_AO_RECUPERAR_PROGRAMA_PELO_IDENTIFICADOR]: [RECUPERAR_PROGRAMA_PELO_IDENTIFICADOR],
  [ERRO_AO_ALTERAR_PROGRAMA]: [ALTERAR_PROGRAMA],
  [ERRO_AO_LISTAR_PROGRAMAS]: [LISTAR_PROGRAMAS],
  [ERRO_AO_ADICIONAR_PROGRAMA]: [ADICIONAR_PROGRAMA],
  [ERRO_AO_REMOVER_PROGRAMA]: [REMOVER_PROGRAMA],
  [ADICIONOU_PROGRAMA]: [ADICIONAR_PROGRAMA],
  [RECUPEROU_PROGRAMA_PELO_IDENTIFICADOR]: [RECUPERAR_PROGRAMA_PELO_IDENTIFICADOR],
  [ALTEROU_PROGRAMA]: [ALTERAR_PROGRAMA],
  [REMOVEU_PROGRAMA]: [REMOVER_PROGRAMA],
  [LISTOU_PROGRAMAS]: [LISTAR_PROGRAMAS],
  [LISTOU_ENFERMEIROS_DO_PACIENTE]: [LISTAR_ENFERMEIROS_DO_PACIENTE],
  [ERRO_AO_LISTAR_ENFERMEIROS_DO_PACIENTE]: [LISTAR_ENFERMEIROS_DO_PACIENTE],
  [LISTOU_PSICOLOGOS_DO_PACIENTE]: [LISTAR_PSICOLOGOS_DO_PACIENTE],
  [ERRO_AO_LISTAR_PSICOLOGOS_DO_PACIENTE]: [LISTAR_PSICOLOGOS_DO_PACIENTE],
  [LISTOU_MEDICOS_DO_PACIENTE]: [LISTAR_MEDICOS_DO_PACIENTE],
  [ERRO_AO_LISTAR_MEDICOS_DO_PACIENTE]: [LISTAR_MEDICOS_DO_PACIENTE],
  [LISTOU_CONSULTORES_DO_PACIENTE]: [LISTAR_CONSULTORES_DO_PACIENTE],
  [ERRO_AO_LISTAR_CONSULTORES_DO_PACIENTE]: [LISTAR_CONSULTORES_DO_PACIENTE],
  [LISTOU_OPERADORAS_DO_PACIENTE]: [LISTAR_OPERADORAS_DO_PACIENTE],
  [ERRO_AO_LISTAR_OPERADORAS_DO_PACIENTE]: [LISTAR_OPERADORAS_DO_PACIENTE],
  [LISTOU_PROGRAMAS]: [LISTAR_PROGRAMAS],
  [ERRO_AO_LISTAR_PROGRAMAS]: [LISTAR_PROGRAMAS],
  [RECUPEROU_SENHA]: [RECUPERAR_SENHA],
  [ERRO_AO_RECUPERAR_SENHA]: [RECUPERAR_SENHA],
  [REDEFINIU_SENHA]: [REDEFINIR_SENHA],
  [ERRO_AO_REDEFINIR_SENHA]: [REDEFINIR_SENHA],
  [LISTOU_STATUS_DOS_AGENDAMENTOS]: [LISTAR_STATUS_DOS_AGENDAMENTOS],
  [ERRO_AO_LISTAR_STATUS_DOS_AGENDAMENTOS]: [LISTAR_STATUS_DOS_AGENDAMENTOS],
  [RECUPEROU_AGENDAMENTO_PELO_IDENTIFICADOR]: [RECUPERAR_AGENDAMENTO_PELO_IDENTIFICADOR],
  [ERRO_AO_RECUPERAR_AGENDAMENTO_PELO_IDENTIFICADOR]: [RECUPERAR_AGENDAMENTO_PELO_IDENTIFICADOR],
  [LISTOU_ASSOCIACOES]: [LISTAR_ASSOCIACOES],
  [ERRO_AO_LISTAR_ASSOCIACOES]: [LISTAR_ASSOCIACOES],
  [LISTOU_ASSOCIACOES_DO_CADASTRO]: [LISTAR_ASSOCIACOES_DO_CADASTRO],
  [ERRO_AO_LISTAR_ASSOCIACOES_DO_CADASTRO]: [LISTAR_ASSOCIACOES_DO_CADASTRO],
  [ADICIONOU_ASSOCIACAO]: [ADICIONAR_ASSOCIACAO],
  [ERRO_AO_ADICIONAR_ASSOCIACAO]: [ADICIONAR_ASSOCIACAO],
  [ALTEROU_ASSOCIACAO]: [ALTERAR_ASSOCIACAO],
  [ERRO_AO_ALTERAR_ASSOCIACAO]: [ALTERAR_ASSOCIACAO],
  [RECUPEROU_ASSOCIACAO_PELO_IDENTIFICADOR]: [RECUPERAR_ASSOCIACAO_PELO_IDENTIFICADOR],
  [ERRO_AO_RECUPERAR_ASSOCIACAO_PELO_IDENTIFICADOR]: [RECUPERAR_ASSOCIACAO_PELO_IDENTIFICADOR],
  [REMOVEU_ASSOCIACAO]: [REMOVER_ASSOCIACAO],
  [ERRO_AO_REMOVER_ASSOCIACAO]: [REMOVER_ASSOCIACAO],
  [LISTOU_PROFISSOES_DO_CADASTRO]: [LISTAR_PROFISSOES_DO_CADASTRO],
  [ERRO_AO_LISTAR_PROFISSOES_DO_CADASTRO]: [LISTAR_PROFISSOES_DO_CADASTRO],
  [RECUPEROU_CABECALHO_DO_PRONTUARIO]: [RECUPERAR_CABECALHO_DO_PRONTUARIO],
  [ERRO_AO_RECUPERAR_CABECALHO_DO_PRONTUARIO]: [RECUPERAR_CABECALHO_DO_PRONTUARIO],
  [ALTEROU_STATUS_DO_USUARIO]: [ALTERAR_STATUS_DO_USUARIO],
  [ERRO_AO_ALTERAR_STATUS_DO_USUARIO]: [ALTERAR_STATUS_DO_USUARIO],
  [LISTOU_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE]: [LISTAR_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE],
  [ERRO_AO_LISTAR_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE]: [LISTAR_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE],
  [LISTOU_PROFISSIONAIS_EXTERNOS]: [LISTAR_PROFISSIONAIS_EXTERNOS],
  [ERRO_AO_LISTAR_PROFISSIONAIS_EXTERNOS]: [LISTAR_PROFISSIONAIS_EXTERNOS],
  [ADICIONOU_PROFISSIONAL_EXTERNO]: [ADICIONAR_PROFISSIONAL_EXTERNO],
  [ERRO_AO_ADICIONAR_PROFISSIONAL_EXTERNO]: [ADICIONAR_PROFISSIONAL_EXTERNO],
  [ALTEROU_PROFISSIONAL_EXTERNO]: [ALTERAR_PROFISSIONAL_EXTERNO],
  [ERRO_AO_ALTERAR_PROFISSIONAL_EXTERNO]: [ALTERAR_PROFISSIONAL_EXTERNO],
  [RECUPEROU_PROFISSIONAL_EXTERNO_PELO_IDENTIFICADOR]: [RECUPERAR_PROFISSIONAL_EXTERNO_PELO_IDENTIFICADOR],
  [ERRO_AO_RECUPERAR_PROFISSIONAL_EXTERNO_PELO_IDENTIFICADOR]: [RECUPERAR_PROFISSIONAL_EXTERNO_PELO_IDENTIFICADOR],
  [REMOVEU_PROFISSIONAL_EXTERNO]: [REMOVER_PROFISSIONAL_EXTERNO],
  [ERRO_AO_REMOVER_PROFISSIONAL_EXTERNO]: [REMOVER_PROFISSIONAL_EXTERNO],
  [LISTOU_ENCAMINHAMENTOS]: [LISTAR_ENCAMINHAMENTOS],
  [ERRO_AO_LISTAR_ENCAMINHAMENTOS]: [LISTAR_ENCAMINHAMENTOS],
  [LISTOU_STATUS_DE_ENCAMINHAMENTO]: [LISTAR_STATUS_DE_ENCAMINHAMENTO],
  [ERRO_AO_LISTAR_STATUS_DE_ENCAMINHAMENTO]: [LISTAR_STATUS_DE_ENCAMINHAMENTO],
  [LISTOU_TIPOS_DE_ENCAMINHAMENTO]: [LISTAR_TIPOS_DE_ENCAMINHAMENTO],
  [ERRO_AO_LISTAR_TIPOS_DE_ENCAMINHAMENTO]: [LISTAR_TIPOS_DE_ENCAMINHAMENTO],
  [ALTEROU_STATUS_DO_ENCAMINHAMENTO]: [ALTERAR_STATUS_DO_ENCAMINHAMENTO],
  [ERRO_AO_ALTERAR_STATUS_DO_ENCAMINHAMENTO]: [ALTERAR_STATUS_DO_ENCAMINHAMENTO],
  [ALTEROU_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO]: [ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO],
  [ERRO_AO_ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO]: [ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO],
  [ALTEROU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO]: [ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO],
  [ERRO_AO_ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO]: [ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO],
  [ALTEROU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO]: [ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO],
  [ERRO_AO_ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO]: [ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO],
  [ALTEROU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO]: [ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO],
  [ERRO_AO_ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO]: [ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO],
  [LISTOU_GRUPOS_DE_CUIDADO]: [LISTAR_GRUPOS_DE_CUIDADO],
  [ERRO_AO_LISTAR_GRUPOS_DE_CUIDADO]: [LISTAR_GRUPOS_DE_CUIDADO],
  [LISTOU_GRUPOS_DE_CUIDADO_DO_CADASTRO]: [LISTAR_GRUPOS_DE_CUIDADO_DO_CADASTRO],
  [ERRO_AO_LISTAR_GRUPOS_DE_CUIDADO_DO_CADASTRO]: [LISTAR_GRUPOS_DE_CUIDADO_DO_CADASTRO],
  [ADICIONOU_GRUPO_DE_CUIDADO]: [ADICIONAR_GRUPO_DE_CUIDADO],
  [ERRO_AO_ADICIONAR_GRUPO_DE_CUIDADO]: [ADICIONAR_GRUPO_DE_CUIDADO],
  [ALTEROU_GRUPO_DE_CUIDADO]: [ALTERAR_GRUPO_DE_CUIDADO],
  [ERRO_AO_ALTERAR_GRUPO_DE_CUIDADO]: [ALTERAR_GRUPO_DE_CUIDADO],
  [RECUPEROU_GRUPO_DE_CUIDADO_PELO_IDENTIFICADOR]: [RECUPERAR_GRUPO_DE_CUIDADO_PELO_IDENTIFICADOR],
  [ERRO_AO_RECUPERAR_GRUPO_DE_CUIDADO_PELO_IDENTIFICADOR]: [RECUPERAR_GRUPO_DE_CUIDADO_PELO_IDENTIFICADOR],
  [REMOVEU_GRUPO_DE_CUIDADO]: [REMOVER_GRUPO_DE_CUIDADO],
  [ERRO_AO_REMOVER_GRUPO_DE_CUIDADO]: [REMOVER_GRUPO_DE_CUIDADO],
  [SALVOU_SERIE_DE_FERIADOS]: [SALVAR_SERIE_DE_FERIADOS],
  [ERRO_AO_SALVAR_SERIE_DE_FERIADOS]: [SALVAR_SERIE_DE_FERIADOS],
  [EXCLUIU_SERIE_DE_FERIADOS]: [EXCLUIR_SERIE_DE_FERIADOS],
  [ERRO_AO_EXCLUIR_SERIE_DE_FERIADOS]: [EXCLUIR_SERIE_DE_FERIADOS],
  [EXCLUIU_FERIADO]: [EXCLUIR_FERIADO],
  [ERRO_AO_EXCLUIR_FERIADO]: [EXCLUIR_FERIADO],
  [ERRO_AO_LISTAR_FREQUENCIAS_NA_ESCALA_ZARIT]: [LISTAR_FREQUENCIAS_NA_ESCALA_ZARIT],
  [LISTOU_FREQUENCIAS_NA_ESCALA_ZARIT]: [LISTAR_FREQUENCIAS_NA_ESCALA_ZARIT],
  [ERRO_AO_CARREGAR_DETALHES_DO_ATENDIMENTO_FINALIZADO]: [CARREGAR_DETALHES_DO_ATENDIMENTO_FINALIZADO],
  [CARREGOU_DETALHES_DO_ATENDIMENTO_FINALIZADO]: [CARREGAR_DETALHES_DO_ATENDIMENTO_FINALIZADO],
  [LISTOU_RESPOSTAS_DO_CASP16]: [LISTAR_RESPOSTAS_DO_CASP16],
  [ERRO_AO_LISTAR_RESPOSTAS_DO_CASP16]: [LISTAR_RESPOSTAS_DO_CASP16],
  [LISTOU_PLANOS_DE_CUIDADO]: [LISTAR_PLANOS_DE_CUIDADO],
  [ERRO_AO_LISTAR_PLANOS_DE_CUIDADO]: [LISTAR_PLANOS_DE_CUIDADO],
  [ADICIONOU_PLANO_DE_CUIDADO]: [ADICIONAR_PLANO_DE_CUIDADO],
  [ERRO_AO_ADICIONAR_PLANO_DE_CUIDADO]: [ADICIONAR_PLANO_DE_CUIDADO],
  [RECUPEROU_PLANO_DE_CUIDADO_PELO_IDENTIFICADOR]: [RECUPERAR_PLANO_DE_CUIDADO_PELO_IDENTIFICADOR],
  [ERRO_AO_RECUPERAR_PLANO_DE_CUIDADO_PELO_IDENTIFICADOR]: [RECUPERAR_PLANO_DE_CUIDADO_PELO_IDENTIFICADOR],
  [ALTEROU_PLANO_DE_CUIDADO]: [ALTERAR_PLANO_DE_CUIDADO],
  [ERRO_AO_ALTERAR_PLANO_DE_CUIDADO]: [ALTERAR_PLANO_DE_CUIDADO],
  [REMOVEU_PLANO_DE_CUIDADO]: [REMOVER_PLANO_DE_CUIDADO],
  [ERRO_AO_REMOVER_PLANO_DE_CUIDADO]: [REMOVER_PLANO_DE_CUIDADO],
  [LISTOU_PLANOS_DE_CUIDADO_DO_PACIENTE]: [LISTAR_PLANOS_DE_CUIDADO_DO_PACIENTE],
  [ERRO_AO_LISTAR_PLANOS_DE_CUIDADO_DO_PACIENTE]: [LISTAR_PLANOS_DE_CUIDADO_DO_PACIENTE],
  [ADICIONOU_PLANO_DE_CUIDADO_DO_PACIENTE]: [ADICIONAR_PLANO_DE_CUIDADO_DO_PACIENTE],
  [ERRO_AO_ADICIONAR_PLANO_DE_CUIDADO_DO_PACIENTE]: [ADICIONAR_PLANO_DE_CUIDADO_DO_PACIENTE],
  [RECUPEROU_PLANO_DE_CUIDADO_DO_PACIENTE]: [RECUPERAR_PLANO_DE_CUIDADO_DO_PACIENTE],
  [ERRO_AO_RECUPERAR_PLANO_DE_CUIDADO_DO_PACIENTE]: [RECUPERAR_PLANO_DE_CUIDADO_DO_PACIENTE],
  [ALTEROU_PLANO_DE_CUIDADO_DO_PACIENTE]: [ALTERAR_PLANO_DE_CUIDADO_DO_PACIENTE],
  [ERRO_AO_ALTERAR_PLANO_DE_CUIDADO_DO_PACIENTE]: [ALTERAR_PLANO_DE_CUIDADO_DO_PACIENTE],
  [REMOVEU_PLANO_DE_CUIDADO_DO_PACIENTE]: [REMOVER_PLANO_DE_CUIDADO_DO_PACIENTE],
  [ERRO_AO_REMOVER_PLANO_DE_CUIDADO_DO_PACIENTE]: [REMOVER_PLANO_DE_CUIDADO_DO_PACIENTE],
  [LISTOU_ORIENTACOES]: [LISTAR_ORIENTACOES],
  [ERRO_AO_LISTAR_ORIENTACOES]: [LISTAR_ORIENTACOES],
  [ADICIONOU_ORIENTACAO]: [ADICIONAR_ORIENTACAO],
  [ERRO_AO_ADICIONAR_ORIENTACAO]: [ADICIONAR_ORIENTACAO],
  [ALTEROU_ORIENTACAO]: [ALTERAR_ORIENTACAO],
  [ERRO_AO_ALTERAR_ORIENTACAO]: [ALTERAR_ORIENTACAO],
  [RECUPEROU_ORIENTACAO_PELO_IDENTIFICADOR]: [RECUPERAR_ORIENTACAO_PELO_IDENTIFICADOR],
  [ERRO_AO_RECUPERAR_ORIENTACAO_PELO_IDENTIFICADOR]: [RECUPERAR_ORIENTACAO_PELO_IDENTIFICADOR],
  [REMOVEU_ORIENTACAO]: [REMOVER_ORIENTACAO],
  [ERRO_AO_REMOVER_ORIENTACAO]: [REMOVER_ORIENTACAO],
  [FEZ_DOWNLOAD_DO_ARQUIVO_DA_ORIENTACAO]: [FAZER_DOWNLOAD_DO_ARQUIVO_DA_ORIENTACAO],
  [ERRO_AO_FAZER_DOWNLOAD_DO_ARQUIVO_DA_ORIENTACAO]: [FAZER_DOWNLOAD_DO_ARQUIVO_DA_ORIENTACAO],
  [ADICIONOU_TAG_NO_PACIENTE_DO_PRONTUARIO]: [ADICIONAR_TAG_NO_PACIENTE_DO_PRONTUARIO],
  [ERRO_AO_ADICIONAR_TAG_NO_PACIENTE_DO_PRONTUARIO]: [ADICIONAR_TAG_NO_PACIENTE_DO_PRONTUARIO],
  [REMOVEU_TAG_DO_PACIENTE_DO_PRONTUARIO]: [REMOVER_TAG_DO_PACIENTE_DO_PRONTUARIO],
  [ERRO_AO_REMOVER_TAG_DO_PACIENTE_DO_PRONTUARIO]: [REMOVER_TAG_DO_PACIENTE_DO_PRONTUARIO],
  [RECUPEROU_TOKEN_DOS_RELATORIOS_DO_POWERBI]: [RECUPERAR_TOKEN_DOS_RELATORIOS_DO_POWERBI],
  [ERRO_AO_RECUPERAR_TOKEN_DOS_RELATORIOS_DO_POWERBI]: [RECUPERAR_TOKEN_DOS_RELATORIOS_DO_POWERBI],
  [LISTOU_TIPOS_DE_AGENDAMENTO_DA_PESQUISA]: [LISTAR_TIPOS_DE_AGENDAMENTO_DA_PESQUISA],
  [ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DA_PESQUISA]: [LISTAR_TIPOS_DE_AGENDAMENTO_DA_PESQUISA],
  [ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE_DE_SUGESTAO]: [LISTAR_PROFISSIONAIS_DE_SAUDE_DE_SUGESTAO],
  [LISTOU_PROFISSIONAIS_DE_SAUDE_DE_SUGESTAO]: [LISTAR_PROFISSIONAIS_DE_SAUDE_DE_SUGESTAO],
  [ERRO_AO_LISTAR_SUGESTOES_DE_PACIENTE]: [LISTAR_SUGESTOES_DE_PACIENTE],
  [LISTOU_SUGESTOES_DE_PACIENTE]: [LISTAR_SUGESTOES_DE_PACIENTE],
  [ERRO_AO_LISTAR_TOKENS_DE_SUGESTAO]: [LISTAR_TOKENS_DE_SUGESTAO],
  [LISTOU_TOKENS_DE_SUGESTAO]: [LISTAR_TOKENS_DE_SUGESTAO],
  [ERRO_AO_LISTAR_TOKENS_DE_EXEMPLO]: [LISTAR_TOKENS_DE_EXEMPLO],
  [LISTOU_TOKENS_DE_EXEMPLO]: [LISTAR_TOKENS_DE_EXEMPLO],
  [ERRO_AO_LISTAR_COMPONENTES_COM_TEMPLATE]: [LISTAR_COMPONENTES_COM_TEMPLATE],
  [LISTOU_COMPONENTES_COM_TEMPLATE]: [LISTAR_COMPONENTES_COM_TEMPLATE],
  [ADICIONOU_TEMPLATE]: [ADICIONAR_TEMPLATE],
  [ERRO_AO_ADICIONAR_TEMPLATE]: [ADICIONAR_TEMPLATE],
  [RECUPEROU_TEMPLATE_POR_IDENTIFICADOR]: [RECUPERAR_TEMPLATE_POR_IDENTIFICADOR],
  [ERRO_AO_RECUPERAR_TEMPLATE_POR_IDENTIFICADOR]: [RECUPERAR_TEMPLATE_POR_IDENTIFICADOR],
  [SALVOU_TEMPLATE]: [SALVAR_TEMPLATE],
  [ERRO_AO_SALVAR_TEMPLATE]: [SALVAR_TEMPLATE],
  [LISTOU_TEMPLATES]: [LISTAR_TEMPLATES],
  [ERRO_AO_LISTAR_TEMPLATES]: [LISTAR_TEMPLATES],
  [EXCLUIU_TEMPLATE]: [EXCLUIR_TEMPLATE],
  [ERRO_AO_EXCLUIR_TEMPLATE]: [EXCLUIR_TEMPLATE],
  [LISTOU_TEMPLATES_DO_PRONTUARIO]: [LISTAR_TEMPLATES_DO_PRONTUARIO],
  [ERRO_AO_LISTAR_TEMPLATES_DO_PRONTUARIO]: [LISTAR_TEMPLATES_DO_PRONTUARIO],
  [ADICIONOU_TEMPLATE_DO_PRONTUARIO]: [ADICIONAR_TEMPLATE_DO_PRONTUARIO],
  [ERRO_AO_ADICIONAR_TEMPLATE_DO_PRONTUARIO]: [ADICIONAR_TEMPLATE_DO_PRONTUARIO],
  [ALTEROU_STATUS_DO_GRUPO_DE_CUIDADO]: [ALTERAR_STATUS_DO_GRUPO_DE_CUIDADO],
  [ERRO_AO_ALTERAR_STATUS_DO_GRUPO_DE_CUIDADO]: [ALTERAR_STATUS_DO_GRUPO_DE_CUIDADO],
  [DEFINIU_TELEFONE_DE_CONTATO_DA_TAREFA]: [DEFINIR_TELEFONE_DE_CONTATO_DA_TAREFA],
  [ERRO_AO_DEFINIR_TELEFONE_DE_CONTATO_DA_TAREFA]: [DEFINIR_TELEFONE_DE_CONTATO_DA_TAREFA],
  [DEFINIU_DESCRICAO_DA_TAREFA]: [DEFINIR_DESCRICAO_DA_TAREFA],
  [ERRO_AO_DEFINIR_DESCRICAO_DA_TAREFA]: [DEFINIR_DESCRICAO_DA_TAREFA],
  [LISTOU_PACIENTES_DO_TAREFAS]: [LISTAR_PACIENTES_DO_TAREFAS],
  [ERRO_AO_LISTAR_PACIENTES_DO_TAREFAS]: [LISTAR_PACIENTES_DO_TAREFAS],
  [DEFINIU_PACIENTE_DA_TAREFA]: [DEFINIR_PACIENTE_DA_TAREFA],
  [ERRO_AO_DEFINIR_PACIENTE_DA_TAREFA]: [DEFINIR_PACIENTE_DA_TAREFA],
  [LISTOU_CATEGORIAS_DO_TAREFAS]: [LISTAR_CATEGORIAS_DO_TAREFAS],
  [ERRO_AO_LISTAR_CATEGORIAS_DO_TAREFAS]: [LISTAR_CATEGORIAS_DO_TAREFAS],
  [DEFINIU_CATEGORIA_DA_TAREFA]: [DEFINIR_CATEGORIA_DA_TAREFA],
  [ERRO_AO_DEFINIR_CATEGORIA_DA_TAREFA]: [DEFINIR_CATEGORIA_DA_TAREFA],
  [LISTOU_URGENCIAS_DO_TAREFAS]: [LISTAR_URGENCIAS_DO_TAREFAS],
  [ERRO_AO_LISTAR_URGENCIAS_DO_TAREFAS]: [LISTAR_URGENCIAS_DO_TAREFAS],
  [DEFINIU_URGENCIA_DA_TAREFA]: [DEFINIR_URGENCIA_DA_TAREFA],
  [ERRO_AO_DEFINIR_URGENCIA_DA_TAREFA]: [DEFINIR_URGENCIA_DA_TAREFA],
  [LISTOU_MOTIVOS_DE_CANCELAMENTO_DO_TAREFAS]: [LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_TAREFAS],
  [ERRO_AO_LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_TAREFAS]: [LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_TAREFAS],
  [DEFINIU_MOTIVO_DO_CANCELAMENTO_DA_TAREFA]: [DEFINIR_MOTIVO_DO_CANCELAMENTO_DA_TAREFA],
  [ERRO_AO_DEFINIR_MOTIVO_DO_CANCELAMENTO_DA_TAREFA]: [DEFINIR_MOTIVO_DO_CANCELAMENTO_DA_TAREFA],
  [LISTOU_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS]: [LISTAR_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS],
  [ERRO_AO_LISTAR_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS]: [LISTAR_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS],
  [DEFINIU_PROFISSIONAL_RESPONSAVEL_DA_TAREFA]: [DEFINIR_PROFISSIONAL_RESPONSAVEL_DA_TAREFA],
  [ERRO_AO_DEFINIR_PROFISSIONAL_RESPONSAVEL_DA_TAREFA]: [DEFINIR_PROFISSIONAL_RESPONSAVEL_DA_TAREFA],
  [ERRO_AO_DEFINIR_URGENCIA_DA_TAREFA]: [DEFINIR_URGENCIA_DA_TAREFA],
  [ADICIONOU_COMENTARIO_DA_TAREFA]: [ADICIONAR_COMENTARIO_DA_TAREFA],
  [ERRO_AO_ADICIONAR_COMENTARIO_DA_TAREFA]: [ADICIONAR_COMENTARIO_DA_TAREFA],
  [ALTEROU_COMENTARIO_DA_TAREFA]: [ALTERAR_COMENTARIO_DA_TAREFA],
  [ERRO_AO_ALTERAR_COMENTARIO_DA_TAREFA]: [ALTERAR_COMENTARIO_DA_TAREFA],
  [REMOVEU_COMENTARIO_DA_TAREFA]: [REMOVER_COMENTARIO_DA_TAREFA],
  [ERRO_AO_REMOVER_COMENTARIO_DA_TAREFA]: [REMOVER_COMENTARIO_DA_TAREFA],
  [ALTEROU_STATUS_DA_TAREFA]: [ALTERAR_STATUS_DA_TAREFA],
  [ERRO_AO_ALTERAR_STATUS_DA_TAREFA]: [ALTERAR_STATUS_DA_TAREFA],
  [CRIOU_TAREFA]: [CRIAR_TAREFA],
  [ERRO_AO_CRIAR_TAREFA]: [CRIAR_TAREFA],
  [CRIOU_TAREFA_DO_PACIENTE]: [CRIAR_TAREFA_DO_PACIENTE],
  [ERRO_AO_CRIAR_TAREFA_DO_PACIENTE]: [CRIAR_TAREFA_DO_PACIENTE],
  [LISTOU_TIPOS_DE_TAREFA]: [LISTAR_TIPOS_DE_TAREFA],
  [ERRO_AO_LISTAR_TIPOS_DE_TAREFA]: [LISTAR_TIPOS_DE_TAREFA],
  [LISTOU_VACINAS]: [LISTAR_VACINAS],
  [ERRO_AO_LISTAR_VACINAS]: [LISTAR_VACINAS],
  [LISTOU_VACINAS_DO_PACIENTE]: [LISTAR_VACINAS_DO_PACIENTE],
  [ERRO_AO_LISTAR_VACINAS_DO_PACIENTE]: [LISTAR_VACINAS_DO_PACIENTE],
  [ADICIONOU_VACINA_DO_PACIENTE]: [ADICIONAR_VACINA_DO_PACIENTE],
  [ERRO_AO_ADICIONAR_VACINA_DO_PACIENTE]: [ADICIONAR_VACINA_DO_PACIENTE],
  [ALTEROU_VACINA_DO_PACIENTE]: [ALTERAR_VACINA_DO_PACIENTE],
  [ERRO_AO_ALTERAR_VACINA_DO_PACIENTE]: [ALTERAR_VACINA_DO_PACIENTE],
  [REMOVEU_VACINA_DO_PACIENTE]: [REMOVER_VACINA_DO_PACIENTE],
  [ERRO_AO_REMOVER_VACINA_DO_PACIENTE]: [REMOVER_VACINA_DO_PACIENTE],
  [LISTOU_LOCAIS_DE_QUEDAS]: [LISTAR_LOCAIS_DE_QUEDAS],
  [ERRO_AO_LISTAR_LOCAIS_DE_QUEDAS]: [LISTAR_LOCAIS_DE_QUEDAS],
  [LISTOU_QUEDAS_DO_PACIENTE]: [LISTAR_QUEDAS_DO_PACIENTE],
  [ERRO_AO_LISTAR_QUEDAS_DO_PACIENTE]: [LISTAR_QUEDAS_DO_PACIENTE],
  [ADICIONOU_QUEDA_DO_PACIENTE]: [ADICIONAR_QUEDA_DO_PACIENTE],
  [ERRO_AO_ADICIONAR_QUEDA_DO_PACIENTE]: [ADICIONAR_QUEDA_DO_PACIENTE],
  [ALTEROU_QUEDA_DO_PACIENTE]: [ALTERAR_QUEDA_DO_PACIENTE],
  [ERRO_AO_ALTERAR_QUEDA_DO_PACIENTE]: [ALTERAR_QUEDA_DO_PACIENTE],
  [REMOVEU_QUEDA_DO_PACIENTE]: [REMOVER_QUEDA_DO_PACIENTE],
  [ERRO_AO_REMOVER_QUEDA_DO_PACIENTE]: [REMOVER_QUEDA_DO_PACIENTE],
  [LISTOU_ANTIBIOTICOS]: [LISTAR_ANTIBIOTICOS],
  [ERRO_AO_LISTAR_ANTIBIOTICOS]: [LISTAR_ANTIBIOTICOS],
  [LISTOU_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE]: [LISTAR_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE],
  [ERRO_AO_LISTAR_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE]: [LISTAR_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE],
  [ADICIONOU_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE]: [ADICIONAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE],
  [ERRO_AO_ADICIONAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE]: [ADICIONAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE],
  [ALTEROU_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE]: [ALTERAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE],
  [ERRO_AO_ALTERAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE]: [ALTERAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE],
  [REMOVEU_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE]: [REMOVER_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE],
  [ERRO_AO_REMOVER_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE]: [REMOVER_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE],
  [LISTOU_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE]: [LISTAR_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE],
  [ERRO_AO_LISTAR_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE]: [LISTAR_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE],
  [ADICIONOU_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE]: [ADICIONAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE],
  [ERRO_AO_ADICIONAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE]: [ADICIONAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE],
  [ALTEROU_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE]: [ALTERAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE],
  [ERRO_AO_ALTERAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE]: [ALTERAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE],
  [REMOVEU_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE]: [REMOVER_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE],
  [ERRO_AO_REMOVER_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE]: [REMOVER_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE],
  [LISTOU_INTERNACOES_DO_PACIENTE]: [LISTAR_INTERNACOES_DO_PACIENTE],
  [ERRO_AO_LISTAR_INTERNACOES_DO_PACIENTE]: [LISTAR_INTERNACOES_DO_PACIENTE],
  [ADICIONOU_INTERNACAO_DO_PACIENTE]: [ADICIONAR_INTERNACAO_DO_PACIENTE],
  [ERRO_AO_ADICIONAR_INTERNACAO_DO_PACIENTE]: [ADICIONAR_INTERNACAO_DO_PACIENTE],
  [ALTEROU_INTERNACAO_DO_PACIENTE]: [ALTERAR_INTERNACAO_DO_PACIENTE],
  [ERRO_AO_ALTERAR_INTERNACAO_DO_PACIENTE]: [ALTERAR_INTERNACAO_DO_PACIENTE],
  [REMOVEU_INTERNACAO_DO_PACIENTE]: [REMOVER_INTERNACAO_DO_PACIENTE],
  [ERRO_AO_REMOVER_INTERNACAO_DO_PACIENTE]: [REMOVER_INTERNACAO_DO_PACIENTE],
  [LISTOU_IVCFS_DO_PACIENTE]: [LISTAR_IVCFS_DO_PACIENTE],
  [ERRO_AO_LISTAR_IVCFS_DO_PACIENTE]: [LISTAR_IVCFS_DO_PACIENTE],
  [ADICIONOU_IVCF_DO_PACIENTE]: [ADICIONAR_IVCF_DO_PACIENTE],
  [ERRO_AO_ADICIONAR_IVCF_DO_PACIENTE]: [ADICIONAR_IVCF_DO_PACIENTE],
  [ALTEROU_IVCF_DO_PACIENTE]: [ALTERAR_IVCF_DO_PACIENTE],
  [ERRO_AO_ALTERAR_IVCF_DO_PACIENTE]: [ALTERAR_IVCF_DO_PACIENTE],
  [REMOVEU_IVCF_DO_PACIENTE]: [REMOVER_IVCF_DO_PACIENTE],
  [ERRO_AO_REMOVER_IVCF_DO_PACIENTE]: [REMOVER_IVCF_DO_PACIENTE],
  [LISTOU_NIVEIS_DE_SAUDE_RELATIVA]: [LISTAR_NIVEIS_DE_SAUDE_RELATIVA],
  [ERRO_AO_LISTAR_NIVEIS_DE_SAUDE_RELATIVA]: [LISTAR_NIVEIS_DE_SAUDE_RELATIVA],
  [LISTOU_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE]: [LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE],
  [ERRO_AO_LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE]: [LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE],
  [ADICIONOU_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE]: [ADICIONAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE],
  [ERRO_AO_ADICIONAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE]: [ADICIONAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE],
  [ALTEROU_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE]: [ALTERAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE],
  [ERRO_AO_ALTERAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE]: [ALTERAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE],
  [REMOVEU_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE]: [REMOVER_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE],
  [ERRO_AO_REMOVER_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE]: [REMOVER_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE],
  [LISTOU_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE]: [LISTAR_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE],
  [ERRO_AO_LISTAR_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE]: [LISTAR_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE],
  [ADICIONOU_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE]: [ADICIONAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE],
  [ERRO_AO_ADICIONAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE]: [ADICIONAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE],
  [ALTEROU_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE]: [ALTERAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE],
  [ERRO_AO_ALTERAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE]: [ALTERAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE],
  [REMOVEU_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE]: [REMOVER_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE],
  [ERRO_AO_REMOVER_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE]: [REMOVER_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE],
  [LISTOU_CASPS_16_DO_PACIENTE]: [LISTAR_CASPS_16_DO_PACIENTE],
  [ERRO_AO_LISTAR_CASPS_16_DO_PACIENTE]: [LISTAR_CASPS_16_DO_PACIENTE],
  [ADICIONOU_CASP_16_DO_PACIENTE]: [ADICIONAR_CASP_16_DO_PACIENTE],
  [ERRO_AO_ADICIONAR_CASP_16_DO_PACIENTE]: [ADICIONAR_CASP_16_DO_PACIENTE],
  [ALTEROU_CASP_16_DO_PACIENTE]: [ALTERAR_CASP_16_DO_PACIENTE],
  [ERRO_AO_ALTERAR_CASP_16_DO_PACIENTE]: [ALTERAR_CASP_16_DO_PACIENTE],
  [REMOVEU_CASP_16_DO_PACIENTE]: [REMOVER_CASP_16_DO_PACIENTE],
  [ERRO_AO_REMOVER_CASP_16_DO_PACIENTE]: [REMOVER_CASP_16_DO_PACIENTE],
  [LISTOU_AVALIACOES_DA_SOLIDAO_DO_PACIENTE]: [LISTAR_AVALIACOES_DA_SOLIDAO_DO_PACIENTE],
  [ERRO_AO_LISTAR_AVALIACOES_DA_SOLIDAO_DO_PACIENTE]: [LISTAR_AVALIACOES_DA_SOLIDAO_DO_PACIENTE],
  [ADICIONOU_AVALIACAO_DA_SOLIDAO_DO_PACIENTE]: [ADICIONAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE],
  [ERRO_AO_ADICIONAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE]: [ADICIONAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE],
  [ALTEROU_AVALIACAO_DA_SOLIDAO_DO_PACIENTE]: [ALTERAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE],
  [ERRO_AO_ALTERAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE]: [ALTERAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE],
  [REMOVEU_AVALIACAO_DA_SOLIDAO_DO_PACIENTE]: [REMOVER_AVALIACAO_DA_SOLIDAO_DO_PACIENTE],
  [ERRO_AO_REMOVER_AVALIACAO_DA_SOLIDAO_DO_PACIENTE]: [REMOVER_AVALIACAO_DA_SOLIDAO_DO_PACIENTE],
  [LISTOU_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO]: [LISTAR_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO],
  [ERRO_AO_LISTAR_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO]: [LISTAR_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO],
  [RECUPEROU_AVALIACAO]: [RECUPERAR_AVALIACAO],
  [ERRO_AO_RECUPERAR_AVALIACAO]: [RECUPERAR_AVALIACAO],
  [RESPONDEU_AVALIACAO]: [RESPONDER_AVALIACAO],
  [ERRO_AO_RESPONDER_AVALIACAO]: [RESPONDER_AVALIACAO],
  [AUTENTICOU_MEDICO_NA_MEMED]: [AUTENTICAR_MEDICO_NA_MEMED],
  [ERRO_AO_AUTENTICAR_MEDICO_NA_MEMED]: [AUTENTICAR_MEDICO_NA_MEMED],
  [INTEGROU_NA_MEMED]: [INTEGRAR_NA_MEMED],
  [RECUPEROU_PRESCRICAO_PDF_NA_MEMED]: [RECUPERAR_PRESCRICAO_PDF_NA_MEMED],
  [ERRO_AO_RECUPERAR_PRESCRICAO_PDF_NA_MEMED]: [RECUPERAR_PRESCRICAO_PDF_NA_MEMED],
  [RECUPEROU_HISTORICO_PRESCRICAO_NA_MEMED]: [RECUPERAR_HISTORICO_PRESCRICAO_NA_MEMED],
  [ERRO_AO_RECUPERAR_HISTORICO_PRESCRICAO_NA_MEMED]: [RECUPERAR_HISTORICO_PRESCRICAO_NA_MEMED],
  [REMOVEU_PRESCRICAO_NA_MEMED]: [REMOVER_PRESCRICAO_NA_MEMED],
  [ERRO_AO_REMOVER_PRESCRICAO_NA_MEMED]: [REMOVER_PRESCRICAO_NA_MEMED],
  [LEU_CERTIFICADOS_DIGITAIS_FISICOS]: [LER_CERTIFICADOS_DIGITAIS_FISICOS],
  [ERRO_AO_LER_CERTIFICADOS_DIGITAIS_FISICOS]: [LER_CERTIFICADOS_DIGITAIS_FISICOS],
  [INICIOU_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO]: [INICIAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO],
  [ERRO_AO_INICIAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO]: [INICIAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO],
  [RECUPEROU_CERTIFICADOS_EM_NUVEM_DO_USUARIO]: [RECUPERAR_CERTIFICADOS_EM_NUVEM_DO_USUARIO],
  [ERRO_AO_RECUPERAR_CERTIFICADOS_EM_NUVEM_DO_USUARIO]: [RECUPERAR_CERTIFICADOS_EM_NUVEM_DO_USUARIO],
  [RECUPEROU_URL_AUTENTICACAO_DO_CERTIFICADO_EM_NUVEM]: [RECUPERAR_URL_AUTENTICACAO_DO_CERTIFICADO_EM_NUVEM],
  [ERRO_AO_RECUPERAR_URL_AUTENTICACAO_DO_CERTIFICADO_EM_NUVEM]: [RECUPERAR_URL_AUTENTICACAO_DO_CERTIFICADO_EM_NUVEM],
  [LISTOU_ATENDIMENTOS_NAO_ASSINADOS]: [LISTAR_ATENDIMENTOS_NAO_ASSINADOS],
  [ERRO_AO_LISTAR_ATENDIMENTOS_NAO_ASSINADOS]: [LISTAR_ATENDIMENTOS_NAO_ASSINADOS],
  [FINALIZOU_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO]: [FINALIZAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO],
  [ERRO_AO_FINALIZAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO]: [FINALIZAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO],
  [ASSINOU_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM]: [ASSINAR_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM],
  [ERRO_AO_ASSINAR_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM]: [ASSINAR_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM],
  [INICIOU_SESSAO_COM_CERTIFICADO_EM_NUVEM]: [INICIAR_SESSAO_COM_CERTIFICADO_EM_NUVEM],
  [ERRO_AO_INICIAR_SESSAO_COM_CERTIFICADO_EM_NUVEM]: [INICIAR_SESSAO_COM_CERTIFICADO_EM_NUVEM],
  [RECUPEROU_SESSAO_COM_CERTIFICADO_EM_NUVEM]: [RECUPERAR_SESSAO_COM_CERTIFICADO_EM_NUVEM],
  [ERRO_AO_RECUPERAR_SESSAO_COM_CERTIFICADO_EM_NUVEM]: [RECUPERAR_SESSAO_COM_CERTIFICADO_EM_NUVEM],
  [LISTOU_TIPOS_DE_ATENDIMENTO_PARA_O_NPS]: [LISTAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS],
  [ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS]: [LISTAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS],
  [LISTOU_PROFISSOES_PARA_O_NPS]: [LISTAR_PROFISSOES_PARA_O_NPS],
  [ERRO_AO_LISTAR_PROFISSOES_PARA_O_NPS]: [LISTAR_PROFISSOES_PARA_O_NPS],
  [HABILITOU_TIPOS_DE_ATENDIMENTO_PARA_O_NPS]: [HABILITAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS],
  [ERRO_AO_HABILITAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS]: [HABILITAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS],
  [DESABILITOU_TIPO_DE_ATENDIMENTO_PARA_O_NPS]: [DESABILITAR_TIPO_DE_ATENDIMENTO_PARA_O_NPS],
  [ERRO_AO_DESABILITAR_TIPO_DE_ATENDIMENTO_PARA_O_NPS]: [DESABILITAR_TIPO_DE_ATENDIMENTO_PARA_O_NPS],
  [HABILITOU_PROFISSOES_PARA_O_NPS]: [HABILITAR_PROFISSOES_PARA_O_NPS],
  [ERRO_AO_HABILITAR_PROFISSOES_PARA_O_NPS]: [HABILITAR_PROFISSOES_PARA_O_NPS],
  [DESABILITOU_PROFISSAO_PARA_O_NPS]: [DESABILITAR_PROFISSAO_PARA_O_NPS],
  [ERRO_AO_DESABILITAR_PROFISSAO_PARA_O_NPS]: [DESABILITAR_PROFISSAO_PARA_O_NPS],
  [LISTOU_AVALIACOES_RESPONDIDAS]: [LISTAR_AVALIACOES_RESPONDIDAS],
  [ERRO_AO_LISTAR_AVALIACOES_RESPONDIDAS]: [LISTAR_AVALIACOES_RESPONDIDAS],
  [ALTEROU_STATUS_DE_UMA_RESPOSTA]: [ALTERAR_STATUS_DE_UMA_RESPOSTA],
  [ERRO_AO_ALTERAR_STATUS_DE_UMA_RESPOSTA]: [ALTERAR_STATUS_DE_UMA_RESPOSTA],
  [RECUPEROU_AVALIACAO_RESPONDIDA]: [RECUPERAR_AVALIACAO_RESPONDIDA],
  [ERRO_AO_RECUPERAR_AVALIACAO_RESPONDIDA]: [RECUPERAR_AVALIACAO_RESPONDIDA],
  [LISTOU_PACIENTES_DO_NPS]: [LISTAR_PACIENTES_DO_NPS],
  [ERRO_AO_LISTAR_PACIENTES_DO_NPS]: [LISTAR_PACIENTES_DO_NPS],
  [LISTOU_PROFISSIONAIS_DO_NPS]: [LISTAR_PROFISSIONAIS_DO_NPS],
  [ERRO_AO_LISTAR_PROFISSIONAIS_DO_NPS]: [LISTAR_PROFISSIONAIS_DO_NPS],
  [LISTOU_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]: [LISTAR_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE],
  [ERRO_AO_LISTAR_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]: [LISTAR_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE],
  [ADICIONOU_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]: [ADICIONAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE],
  [ERRO_AO_ADICIONAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]: [ADICIONAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE],
  [ALTEROU_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]: [ALTERAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE],
  [ERRO_AO_ALTERAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]: [ALTERAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE],
  [EXCLUIU_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]: [EXCLUIR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE],
  [ERRO_AO_EXCLUIR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]: [EXCLUIR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE],
  [ALTEROU_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]: [ALTERAR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE],
  [ERRO_AO_ALTERAR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]: [ALTERAR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE],
  [ERRO_AO_EXCLUIR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]: [EXCLUIR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE],
  [EXCLUIU_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]: [EXCLUIR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE],
  [IMPORTOU_PACIENTES]: [IMPORTAR_PACIENTES],
  [ERRO_AO_IMPORTAR_PACIENTES]: [IMPORTAR_PACIENTES],
  [RECUPEROU_ENDERECO_DA_UNIDADE_POR_CEP]: [RECUPERAR_ENDERECO_DA_UNIDADE_POR_CEP],
  [ERRO_AO_RECUPERAR_ENDERECO_DA_UNIDADE_POR_CEP]: [RECUPERAR_ENDERECO_DA_UNIDADE_POR_CEP],
  [ADICIONOU_CONSULTA_EXTERNA_DO_PACIENTE]: [ADICIONAR_CONSULTA_EXTERNA_DO_PACIENTE],
  [ERRO_AO_ADICIONAR_CONSULTA_EXTERNA_DO_PACIENTE]: [ADICIONAR_CONSULTA_EXTERNA_DO_PACIENTE],
  [ALTEROU_CONSULTA_EXTERNA_DO_PACIENTE]: [ALTERAR_CONSULTA_EXTERNA_DO_PACIENTE],
  [ERRO_AO_ALTERAR_CONSULTA_EXTERNA_DO_PACIENTE]: [ALTERAR_CONSULTA_EXTERNA_DO_PACIENTE],
  [LISTOU_CONSULTAS_EXTERNAS_DO_PACIENTE]: [LISTAR_CONSULTAS_EXTERNAS_DO_PACIENTE],
  [ERRO_AO_LISTAR_CONSULTAS_EXTERNAS_DO_PACIENTE]: [LISTAR_CONSULTAS_EXTERNAS_DO_PACIENTE],
  [REMOVEU_CONSULTA_EXTERNA_DO_PACIENTE]: [REMOVER_CONSULTA_EXTERNA_DO_PACIENTE],
  [ERRO_AO_REMOVER_CONSULTA_EXTERNA_DO_PACIENTE]: [REMOVER_CONSULTA_EXTERNA_DO_PACIENTE],
  [LISTOU_TIPOS_DE_CONSULTA_EXTERNA]: [LISTAR_TIPOS_DE_CONSULTA_EXTERNA],
  [ERRO_AO_LISTAR_TIPOS_DE_CONSULTA_EXTERNA]: [LISTAR_TIPOS_DE_CONSULTA_EXTERNA],
  [MARCOU_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA]: [MARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA],
  [ERRO_AO_MARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA]: [MARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA],
  [DESMARCOU_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA]: [DESMARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA],
  [ERRO_AO_DESMARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA]: [DESMARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA],
  [DEFINIU_TEXTO_DA_TAREFA]: [DEFINIR_TEXTO_DA_TAREFA],
  [ERRO_AO_DEFINIR_TEXTO_DA_TAREFA]: [DEFINIR_TEXTO_DA_TAREFA],
  [ADICIONOU_PRE_CADASTRO_DO_PACIENTE_DA_TAREFA]: [ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DA_TAREFA],
  [ERRO_AO_ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DA_TAREFA]: [ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DA_TAREFA],
  [LISTOU_PROFISSOES_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS]: [LISTAR_PROFISSOES_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS],
  [ERRO_AO_LISTAR_PROFISSOES_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS]: [LISTAR_PROFISSOES_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS],
  [FEZ_DOWNLOAD_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS]: [FAZER_DOWNLOAD_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS],
  [ERRO_AO_FAZER_DOWNLOAD_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS]: [FAZER_DOWNLOAD_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS],
  [DEFINIU_TEXTO_LONGO_DA_TAREFA]: [DEFINIR_TEXTO_LONGO_DA_TAREFA],
  [ERRO_AO_DEFINIR_TEXTO_LONGO_DA_TAREFA]: [DEFINIR_TEXTO_LONGO_DA_TAREFA],
  [DEFINIU_NUMERO_INTEIRO_DA_TAREFA]: [DEFINIR_NUMERO_INTEIRO_DA_TAREFA],
  [ERRO_AO_DEFINIR_NUMERO_INTEIRO_DA_TAREFA]: [DEFINIR_NUMERO_INTEIRO_DA_TAREFA],
  [DEFINIU_MOMENTO_DA_TAREFA]: [DEFINIR_MOMENTO_DA_TAREFA],
  [ERRO_AO_DEFINIR_MOMENTO_DA_TAREFA]: [DEFINIR_MOMENTO_DA_TAREFA],
  [LISTOU_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE]: [LISTAR_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE],
  [ERRO_AO_LISTAR_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE]: [LISTAR_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE],
  [ADICIONOU_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE]: [ADICIONAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE],
  [ERRO_AO_ADICIONAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE]: [ADICIONAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE],
  [ALTEROU_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE]: [ALTERAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE],
  [ERRO_AO_ALTERAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE]: [ALTERAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE],
  [REMOVEU_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE]: [REMOVER_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE],
  [ERRO_AO_REMOVER_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE]: [REMOVER_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE],
  [LISTOU_MOTIVOS_DE_BLOQUEIO_DO_LOGIN]: [LISTAR_MOTIVOS_DE_BLOQUEIO_DO_LOGIN],
  [ERRO_AO_LISTAR_MOTIVOS_DE_BLOQUEIO_DO_LOGIN]: [LISTAR_MOTIVOS_DE_BLOQUEIO_DO_LOGIN],
  [LISTOU_USUARIOS_BLOQUEADOS]: [LISTAR_USUARIOS_BLOQUEADOS],
  [ERRO_AO_LISTAR_USUARIOS_BLOQUEADOS]: [LISTAR_USUARIOS_BLOQUEADOS],
  [ADICIONOU_CONVENIO_DO_PACIENTE]: [ADICIONAR_CONVENIO_DO_PACIENTE],
  [ERRO_AO_ADICIONAR_CONVENIO_DO_PACIENTE]: [ADICIONAR_CONVENIO_DO_PACIENTE],
  [ALTEROU_CONVENIO_DO_PACIENTE]: [ALTERAR_CONVENIO_DO_PACIENTE],
  [ERRO_AO_ALTERAR_CONVENIO_DO_PACIENTE]: [ALTERAR_CONVENIO_DO_PACIENTE],
  [REMOVEU_CONVENIO_DO_PACIENTE]: [REMOVER_CONVENIO_DO_PACIENTE],
  [ERRO_AO_REMOVER_CONVENIO_DO_PACIENTE]: [REMOVER_CONVENIO_DO_PACIENTE],
  [RECUPEROU_CONVENIO_DO_PACIENTE]: [RECUPERAR_CONVENIO_DO_PACIENTE],
  [ERRO_AO_RECUPERAR_CONVENIO_DO_PACIENTE]: [RECUPERAR_CONVENIO_DO_PACIENTE],
  [LISTOU_CONVENIOS_DO_PACIENTE]: [LISTAR_CONVENIOS_DO_PACIENTE],
  [ERRO_AO_LISTAR_CONVENIOS_DO_PACIENTE]: [LISTAR_CONVENIOS_DO_PACIENTE],
  [LISTOU_MOTIVOS_DE_CANCELAMENTO_DO_CONVENIO]: [LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_CONVENIO],
  [ERRO_AO_LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_CONVENIO]: [LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_CONVENIO],
  [FEZ_DOWNLOAD_DO_EXAME_DO_ATENDIMENTO]: [FAZER_DOWNLOAD_DO_EXAME_DO_ATENDIMENTO],
  [ERRO_AO_FAZER_DOWNLOAD_DO_EXAME_DO_ATENDIMENTO]: [FAZER_DOWNLOAD_DO_EXAME_DO_ATENDIMENTO],
  [FEZ_DOWNLOAD_DO_ANEXO_DO_ATENDIMENTO]: [FAZER_DOWNLOAD_DO_ANEXO_DO_ATENDIMENTO],
  [ERRO_AO_FAZER_DOWNLOAD_DO_ANEXO_DO_ATENDIMENTO]: [FAZER_DOWNLOAD_DO_ANEXO_DO_ATENDIMENTO],
  [DEFINIU_DATA_DA_TAREFA]: [DEFINIR_DATA_DA_TAREFA],
  [ERRO_AO_DEFINIR_DATA_DA_TAREFA]: [DEFINIR_DATA_DA_TAREFA],
  [DEFINIU_PRAZO_DA_TAREFA]: [DEFINIR_PRAZO_DA_TAREFA],
  [ERRO_AO_DEFINIR_PRAZO_DA_TAREFA]: [DEFINIR_PRAZO_DA_TAREFA],
  [ALTEROU_CONFIGURACAO_DO_USUARIO]: [ALTERAR_CONFIGURACAO_DO_USUARIO],
  [ERRO_AO_ALTERAR_CONFIGURACAO_DO_USUARIO]: [ALTERAR_CONFIGURACAO_DO_USUARIO],
  [RECUPEROU_CONFIGURACOES_VIGENTES_DO_USUARIO_LOGADO]: [RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO_LOGADO],
  [ERRO_AO_RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO_LOGADO]: [RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO_LOGADO],
  [RECUPEROU_CONFIGURACOES_DO_USUARIO_LOGADO]: [RECUPERAR_CONFIGURACOES_DO_USUARIO_LOGADO],
  [ERRO_AO_RECUPERAR_CONFIGURACOES_DO_USUARIO_LOGADO]: [RECUPERAR_CONFIGURACOES_DO_USUARIO_LOGADO],
  [RECUPEROU_CONFIGURACOES_VIGENTES_DO_USUARIO]: [RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO],
  [ERRO_AO_RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO]: [RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO],
  [RECUPEROU_CONFIGURACOES_DO_USUARIO]: [RECUPERAR_CONFIGURACOES_DO_USUARIO],
  [ERRO_AO_RECUPERAR_CONFIGURACOES_DO_USUARIO]: [RECUPERAR_CONFIGURACOES_DO_USUARIO],
  [LISTOU_DURACOES_DAS_DORES]: [LISTAR_DURACOES_DAS_DORES],
  [ERRO_AO_LISTAR_DURACOES_DAS_DORES]: [LISTAR_DURACOES_DAS_DORES],
  [LISTOU_INTENSIDADES_DAS_DORES]: [LISTAR_INTENSIDADES_DAS_DORES],
  [ERRO_AO_LISTAR_INTENSIDADES_DAS_DORES]: [LISTAR_INTENSIDADES_DAS_DORES],
  [LISTOU_LOCAIS_DAS_DORES]: [LISTAR_LOCAIS_DAS_DORES],
  [ERRO_AO_LISTAR_LOCAIS_DAS_DORES]: [LISTAR_LOCAIS_DAS_DORES],
  [LISTOU_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER]: [LISTAR_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER],
  [ERRO_AO_LISTAR_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER]: [LISTAR_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER],
  [LISTOU_PACIENTES_DOS_FILTROS_DO_TAREFAS]: [LISTAR_PACIENTES_DOS_FILTROS_DO_TAREFAS],
  [ERRO_AO_LISTAR_PACIENTES_DOS_FILTROS_DO_TAREFAS]: [LISTAR_PACIENTES_DOS_FILTROS_DO_TAREFAS],
  [ERRO_AO_DEFINIR_PRAZO_DA_TAREFA]: [DEFINIR_PRAZO_DA_TAREFA],
  [LISTOU_ESPECIALIDADES_MEDICAS_INTERNAS]: [LISTAR_ESPECIALIDADES_MEDICAS_INTERNAS],
  [ERRO_AO_LISTAR_ESPECIALIDADES_MEDICAS_INTERNAS]: [LISTAR_ESPECIALIDADES_MEDICAS_INTERNAS],
  [ADICIONOU_ESPECIALIDADE_MEDICA_INTERNA]: [ADICIONAR_ESPECIALIDADE_MEDICA_INTERNA],
  [ERRO_AO_ADICIONAR_ESPECIALIDADE_MEDICA_INTERNA]: [ADICIONAR_ESPECIALIDADE_MEDICA_INTERNA],
  [REMOVEU_ESPECIALIDADE_MEDICA_INTERNA]: [REMOVER_ESPECIALIDADE_MEDICA_INTERNA],
  [ERRO_AO_REMOVER_ESPECIALIDADE_MEDICA_INTERNA]: [REMOVER_ESPECIALIDADE_MEDICA_INTERNA],
  [LISTOU_CAMPOS_EXTERNOS_DO_PACIENTE]: [LISTAR_CAMPOS_EXTERNOS_DO_PACIENTE],
  [ERRO_AO_LISTAR_CAMPOS_EXTERNOS_DO_PACIENTE]: [LISTAR_CAMPOS_EXTERNOS_DO_PACIENTE],
  [ADICIONOU_CAMPO_EXTERNO_DO_PACIENTE]: [ADICIONAR_CAMPO_EXTERNO_DO_PACIENTE],
  [ERRO_AO_ADICIONAR_CAMPO_EXTERNO_DO_PACIENTE]: [ADICIONAR_CAMPO_EXTERNO_DO_PACIENTE],
  [RECUPEROU_CAMPO_EXTERNO_DO_PACIENTE]: [RECUPERAR_CAMPO_EXTERNO_DO_PACIENTE],
  [ERRO_AO_RECUPERAR_CAMPO_EXTERNO_DO_PACIENTE]: [RECUPERAR_CAMPO_EXTERNO_DO_PACIENTE],
  [ALTEROU_CAMPO_EXTERNO_DO_PACIENTE]: [ALTERAR_CAMPO_EXTERNO_DO_PACIENTE],
  [ERRO_AO_ALTERAR_CAMPO_EXTERNO_DO_PACIENTE]: [ALTERAR_CAMPO_EXTERNO_DO_PACIENTE],
  [REMOVEU_CAMPO_EXTERNO_DO_PACIENTE]: [REMOVER_CAMPO_EXTERNO_DO_PACIENTE],
  [ERRO_AO_REMOVER_CAMPO_EXTERNO_DO_PACIENTE]: [REMOVER_CAMPO_EXTERNO_DO_PACIENTE],
  [LISTOU_TIPOS_DE_UNIDADE]: [LISTAR_TIPOS_DE_UNIDADE],
  [ERRO_AO_LISTAR_TIPOS_DE_UNIDADE]: [LISTAR_TIPOS_DE_UNIDADE],
  [ADICIONOU_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE]: [ADICIONAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE],
  [ERRO_AO_ADICIONAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE]: [ADICIONAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE],
  [ALTEROU_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE]: [ALTERAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE],
  [ERRO_AO_ALTERAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE]: [ALTERAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE],
  [REMOVEU_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE]: [REMOVER_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE],
  [ERRO_AO_REMOVER_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE]: [REMOVER_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE],
  [LISTOU_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE]: [LISTAR_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE],
  [ERRO_AO_LISTAR_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE]: [LISTAR_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE],
  [LISTOU_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO]: [LISTAR_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO],
  [ERRO_AO_LISTAR_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO]: [LISTAR_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO],
  [ADICIONOU_CELULA]: [ADICIONAR_CELULA],
  [ALTEROU_CELULA]: [ALTERAR_CELULA],
  [ERRO_AO_ADICIONAR_CELULA]: [ADICIONAR_CELULA],
  [ERRO_AO_ALTERAR_CELULA]: [ALTERAR_CELULA],
  [ERRO_AO_LISTAR_CELULAS]: [LISTAR_CELULAS],
  [ERRO_AO_RECUPERAR_CELULA_PELO_IDENTIFICADOR]: [RECUPERAR_CELULA_PELO_IDENTIFICADOR],
  [ERRO_AO_REMOVER_CELULA]: [REMOVER_CELULA],
  [ERRO_AO_LISTAR_CELULAS_DO_CADASTRO]: [LISTAR_CELULAS_DO_CADASTRO],
  [LISTOU_CELULAS]: [LISTAR_CELULAS],
  [LISTOU_CELULAS_DO_CADASTRO]: [LISTAR_CELULAS_DO_CADASTRO],
  [RECUPEROU_CELULA_PELO_IDENTIFICADOR]: [RECUPERAR_CELULA_PELO_IDENTIFICADOR],
  [REMOVEU_CELULA]: [REMOVER_CELULA],
  [LISTOU_CELULAS_DO_RELATORIO_DE_PACIENTES]: [LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES],
  [ERRO_AO_LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES]: [LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES],
  [LISTOU_CELULAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA]: [LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA],
  [ERRO_AO_LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA]: [LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA],
  [LISTOU_FABRICANTES]: [LISTAR_FABRICANTES],
  [LISTOU_FABRICANTES_DO_CADASTRO]: [LISTAR_FABRICANTES_DO_CADASTRO],
  [ERRO_AO_LISTAR_FABRICANTES]: [LISTAR_FABRICANTES],
  [ERRO_AO_LISTAR_FABRICANTES_DO_CADASTRO]: [LISTAR_FABRICANTES_DO_CADASTRO],
  [RECUPEROU_FABRICANTE_PELO_IDENTIFICADOR]: [RECUPERAR_FABRICANTE_PELO_IDENTIFICADOR],
  [ERRO_AO_RECUPERAR_FABRICANTE_PELO_IDENTIFICADOR]: [RECUPERAR_FABRICANTE_PELO_IDENTIFICADOR],
  [ALTEROU_FABRICANTE]: [ALTERAR_FABRICANTE],
  [ERRO_AO_ALTERAR_FABRICANTE]: [ALTERAR_FABRICANTE],
  [ADICIONOU_FABRICANTE]: [ADICIONAR_FABRICANTE],
  [ERRO_AO_ADICIONAR_FABRICANTE]: [ADICIONAR_FABRICANTE],
  [REMOVEU_FABRICANTE]: [REMOVER_FABRICANTE],
  [ERRO_AO_REMOVER_FABRICANTE]: [REMOVER_FABRICANTE],
  [LISTOU_LOCAIS_DA_APLICACAO]: [LISTAR_LOCAIS_DA_APLICACAO],
  [ERRO_AO_LISTAR_LOCAIS_DA_APLICACAO]: [LISTAR_LOCAIS_DA_APLICACAO],
  [LISTOU_ESTADOS_DA_MEMORIA_ATUAL]: [LISTAR_ESTADOS_DA_MEMORIA_ATUAL],
  [ERRO_AO_LISTAR_ESTADOS_DA_MEMORIA_ATUAL]: [LISTAR_ESTADOS_DA_MEMORIA_ATUAL],
  [LISTOU_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS]: [LISTAR_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS],
  [ERRO_AO_LISTAR_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS]: [LISTAR_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS],
  [LISTOU_MINI_MENTAIS]: [LISTAR_MINI_MENTAIS],
  [ERRO_AO_LISTAR_MINI_MENTAIS]: [LISTAR_MINI_MENTAIS],
  [ADICIONOU_MINI_MENTAL]: [ADICIONAR_MINI_MENTAL],
  [ERRO_AO_ADICIONAR_MINI_MENTAL]: [ADICIONAR_MINI_MENTAL],
  [ALTEROU_MINI_MENTAL]: [ALTERAR_MINI_MENTAL],
  [ERRO_AO_ALTERAR_MINI_MENTAL]: [ALTERAR_MINI_MENTAL],
  [REMOVEU_MINI_MENTAL]: [REMOVER_MINI_MENTAL],
  [ERRO_AO_REMOVER_MINI_MENTAL]: [REMOVER_MINI_MENTAL],
  [LISTOU_PROXIMOS_AGENDAMENTOS_MEDICOS_DO_PACIENTE]: [LISTAR_PROXIMOS_AGENDAMENTOS_MEDICOS_DO_PACIENTE],
  [ERRO_AO_LISTAR_PROXIMOS_AGENDAMENTOS_MEDICOS_DO_PACIENTE]: [LISTAR_PROXIMOS_AGENDAMENTOS_MEDICOS_DO_PACIENTE],
  [ADICIONOU_INTERPRETACAO_MEDICA_DO_MINI_MENTAL]: [ADICIONAR_INTERPRETACAO_MEDICA_DO_MINI_MENTAL],
  [ERRO_AO_ADICIONAR_INTERPRETACAO_MEDICA_DO_MINI_MENTAL]: [ADICIONAR_INTERPRETACAO_MEDICA_DO_MINI_MENTAL],
  [REMOVEU_INTERPRETACAO_MEDICA_DO_MINI_MENTAL]: [REMOVER_INTERPRETACAO_MEDICA_DO_MINI_MENTAL],
  [ERRO_AO_REMOVER_INTERPRETACAO_MEDICA_DO_MINI_MENTAL]: [REMOVER_INTERPRETACAO_MEDICA_DO_MINI_MENTAL],
  [LISTOU_CIDS10]: [LISTAR_CIDS10],
  [ERRO_AO_LISTAR_CIDS10]: [LISTAR_CIDS10],
  [LISTOU_RESPOSTAS_DO_PHQ9]: [LISTAR_RESPOSTAS_DO_PHQ9],
  [ERRO_AO_LISTAR_RESPOSTAS_DO_PHQ9]: [LISTAR_RESPOSTAS_DO_PHQ9],
  [LISTOU_PHQS9]: [LISTAR_PHQS9],
  [ERRO_AO_LISTAR_PHQS9]: [LISTAR_PHQS9],
  [ADICIONOU_PHQ9]: [ADICIONAR_PHQ9],
  [ERRO_AO_ADICIONAR_PHQ9]: [ADICIONAR_PHQ9],
  [ALTEROU_PHQ9]: [ALTERAR_PHQ9],
  [ERRO_AO_ALTERAR_PHQ9]: [ALTERAR_PHQ9],
  [REMOVEU_PHQ9]: [REMOVER_PHQ9],
  [ERRO_AO_REMOVER_PHQ9]: [REMOVER_PHQ9],
  [LISTOU_SALAS_DA_UNIDADE]: [LISTAR_SALAS_DA_UNIDADE],
  [ERRO_AO_LISTAR_SALAS_DA_UNIDADE]: [LISTAR_SALAS_DA_UNIDADE],
  [ADICIONOU_SALA_NA_UNIDADE]: [ADICIONAR_SALA_NA_UNIDADE],
  [ERRO_AO_ADICIONAR_SALA_NA_UNIDADE]: [ADICIONAR_SALA_NA_UNIDADE],
  [ALTEROU_SALA_DA_UNIDADE]: [ALTERAR_SALA_DA_UNIDADE],
  [ERRO_AO_ALTERAR_SALA_DA_UNIDADE]: [ALTERAR_SALA_DA_UNIDADE],
  [REMOVEU_SALA_DA_UNIDADE]: [REMOVER_SALA_DA_UNIDADE],
  [ERRO_AO_REMOVER_SALA_DA_UNIDADE]: [REMOVER_SALA_DA_UNIDADE],
  [RECUPEROU_SALA_DA_UNIDADE_PELO_IDENTIFICADOR]: [RECUPERAR_SALA_DA_UNIDADE_PELO_IDENTIFICADOR],
  [ERRO_AO_RECUPERAR_SALA_DA_UNIDADE_PELO_IDENTIFICADOR]: [RECUPERAR_SALA_DA_UNIDADE_PELO_IDENTIFICADOR],
  [ADICIONOU_MEDICAMENTO_DO_PACIENTE]: [ADICIONAR_MEDICAMENTO_DO_PACIENTE],
  [ERRO_AO_ADICIONAR_MEDICAMENTO_DO_PACIENTE]: [ADICIONAR_MEDICAMENTO_DO_PACIENTE],
  [ALTEROU_MEDICAMENTO_DO_PACIENTE]: [ALTERAR_MEDICAMENTO_DO_PACIENTE],
  [ERRO_AO_ALTERAR_MEDICAMENTO_DO_PACIENTE]: [ALTERAR_MEDICAMENTO_DO_PACIENTE],
  [ALTEROU_STATUS_DO_MEDICAMENTO_DO_PACIENTE]: [ALTERAR_STATUS_DO_MEDICAMENTO_DO_PACIENTE],
  [ERRO_AO_ALTERAR_STATUS_DO_MEDICAMENTO_DO_PACIENTE]: [ALTERAR_STATUS_DO_MEDICAMENTO_DO_PACIENTE],
  [LISTOU_MEDICAMENTOS_DO_PACIENTE]: [LISTAR_MEDICAMENTOS_DO_PACIENTE],
  [ERRO_AO_LISTAR_MEDICAMENTOS_DO_PACIENTE]: [LISTAR_MEDICAMENTOS_DO_PACIENTE],
  [LISTOU_SETORES]: [LISTAR_SETORES],
  [ERRO_AO_LISTAR_SETORES]: [LISTAR_SETORES],
  [RECUPEROU_SETOR_PELO_IDENTIFICADOR]: [RECUPERAR_SETOR_PELO_IDENTIFICADOR],
  [ERRO_AO_RECUPERAR_SETOR_PELO_IDENTIFICADOR]: [RECUPERAR_SETOR_PELO_IDENTIFICADOR],
  [ALTEROU_SETOR]: [ALTERAR_SETOR],
  [ERRO_AO_ALTERAR_SETOR]: [ALTERAR_SETOR],
  [ADICIONOU_SETOR]: [ADICIONAR_SETOR],
  [ERRO_AO_ADICIONAR_SETOR]: [ADICIONAR_SETOR],
  [REMOVEU_SETOR]: [REMOVER_SETOR],
  [ERRO_AO_REMOVER_SETOR]: [REMOVER_SETOR],
  [LISTOU_SETORES_RESPONSAVEIS_DO_TAREFAS]: [LISTAR_SETORES_RESPONSAVEIS_DO_TAREFAS],
  [ERRO_AO_LISTAR_SETORES_RESPONSAVEIS_DO_TAREFAS]: [LISTAR_SETORES_RESPONSAVEIS_DO_TAREFAS],
  [DEFINIU_SETOR_RESPONSAVEL_DA_TAREFA]: [DEFINIR_SETOR_RESPONSAVEL_DA_TAREFA],
  [ERRO_AO_DEFINIR_SETOR_RESPONSAVEL_DA_TAREFA]: [DEFINIR_SETOR_RESPONSAVEL_DA_TAREFA],
  [ALTEROU_POSICAO_DOS_MEDICAMENTOS_DO_PACIENTE]: [ALTERAR_POSICAO_DOS_MEDICAMENTOS_DO_PACIENTE],
  [ERRO_AO_ALTERAR_POSICAO_DOS_MEDICAMENTOS_DO_PACIENTE]: [ALTERAR_POSICAO_DOS_MEDICAMENTOS_DO_PACIENTE],
  [ADICIONOU_DIAGNOSTICO_DO_PACIENTE]: [ADICIONAR_DIAGNOSTICO_DO_PACIENTE],
  [ERRO_AO_ADICIONAR_DIAGNOSTICO_DO_PACIENTE]: [ADICIONAR_DIAGNOSTICO_DO_PACIENTE],
  [ALTEROU_STATUS_DO_DIAGNOSTICO_DO_PACIENTE]: [ALTERAR_STATUS_DO_DIAGNOSTICO_DO_PACIENTE],
  [ERRO_AO_ALTERAR_STATUS_DO_DIAGNOSTICO_DO_PACIENTE]: [ALTERAR_STATUS_DO_DIAGNOSTICO_DO_PACIENTE],
  [LISTOU_DIAGNOSTICOS_DO_PACIENTE]: [LISTAR_DIAGNOSTICOS_DO_PACIENTE],
  [ERRO_AO_LISTAR_DIAGNOSTICOS_DO_PACIENTE]: [LISTAR_DIAGNOSTICOS_DO_PACIENTE],
  [ALTEROU_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE]: [ALTERAR_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE],
  [ERRO_AO_ALTERAR_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE]: [ALTERAR_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE],
  [ADICIONOU_FERIADO_TRABALHADO_DO_PROFISSIONAL]: [ADICIONAR_FERIADO_TRABALHADO_DO_PROFISSIONAL],
  [ERRO_AO_ADICIONAR_FERIADO_TRABALHADO_DO_PROFISSIONAL]: [ADICIONAR_FERIADO_TRABALHADO_DO_PROFISSIONAL],
  [REMOVEU_FERIADO_TRABALHADO_DO_PROFISSIONAL]: [REMOVER_FERIADO_TRABALHADO_DO_PROFISSIONAL],
  [ERRO_AO_REMOVER_FERIADO_TRABALHADO_DO_PROFISSIONAL]: [REMOVER_FERIADO_TRABALHADO_DO_PROFISSIONAL],
  [LISTOU_FERIADOS_TRABALHADOS_DO_PROFISSIONAL]: [LISTAR_FERIADOS_TRABALHADOS_DO_PROFISSIONAL],
  [ERRO_AO_LISTAR_FERIADOS_TRABALHADOS_DO_PROFISSIONAL]: [LISTAR_FERIADOS_TRABALHADOS_DO_PROFISSIONAL],
  [LISTOU_PEDIDOS_DE_CONTATO]: [LISTAR_PEDIDOS_DE_CONTATO],
  [ERRO_AO_LISTAR_PEDIDOS_DE_CONTATO]: [LISTAR_PEDIDOS_DE_CONTATO],
  [ADICIONOU_PEDIDO_DE_CONTATO]: [ADICIONAR_PEDIDO_DE_CONTATO],
  [ERRO_AO_ADICIONAR_PEDIDO_DE_CONTATO]: [ADICIONAR_PEDIDO_DE_CONTATO],
  [RECUPEROU_PEDIDO_DE_CONTATO_PELO_IDENTIFICADOR]: [RECUPERAR_PEDIDO_DE_CONTATO_PELO_IDENTIFICADOR],
  [ERRO_AO_RECUPERAR_PEDIDO_DE_CONTATO_PELO_IDENTIFICADOR]: [RECUPERAR_PEDIDO_DE_CONTATO_PELO_IDENTIFICADOR],
  [ALTEROU_PEDIDO_DE_CONTATO]: [ALTERAR_PEDIDO_DE_CONTATO],
  [ERRO_AO_ALTERAR_PEDIDO_DE_CONTATO]: [ALTERAR_PEDIDO_DE_CONTATO],
  [REMOVEU_PEDIDO_DE_CONTATO]: [REMOVER_PEDIDO_DE_CONTATO],
  [ERRO_AO_REMOVER_PEDIDO_DE_CONTATO]: [REMOVER_PEDIDO_DE_CONTATO],
  [LISTOU_CIDADES_PARA_O_PEDIDO_DE_CONTATO]: [LISTAR_CIDADES_PARA_O_PEDIDO_DE_CONTATO],
  [ERRO_AO_LISTAR_CIDADES_PARA_O_PEDIDO_DE_CONTATO]: [LISTAR_CIDADES_PARA_O_PEDIDO_DE_CONTATO],
  [LISTOU_PLANOS_DE_SAUDE_PARA_O_PEDIDO_DE_CONTATO]: [LISTAR_PLANOS_DE_SAUDE_PARA_O_PEDIDO_DE_CONTATO],
  [ERRO_AO_LISTAR_PLANOS_DE_SAUDE_PARA_O_PEDIDO_DE_CONTATO]: [LISTAR_PLANOS_DE_SAUDE_PARA_O_PEDIDO_DE_CONTATO],
}

export default (state = estadoInicial, action) => {
  if (acoesDeInicio.find(x => x === action.type)) {
    return {
      ...state,
      [action.type]: iniciarOperacao(action.type)
    }
  }

  const acaoInicial = acoesDeTermino[action.type];

  if (acaoInicial) {
    let estadoNovo = { ...state }

    delete estadoNovo[acaoInicial]

    return estadoNovo
  }

  return state
}