import React, { memo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { abrirTeleconsultaNoProntuario } from '../../../../acoes/prontuario/teleconsulta'
import './style.css'

function VideoConsulta({ identificadorDoAtendimento, ehRemotoPorVideoENaoTemTeleconsulta = false }) {
  const dispatch = useDispatch()  
  
  const [descricao, setDescricao] = useState('Iniciar Vídeo Consulta')
  
  function handleAbrir() {
    dispatch(abrirTeleconsultaNoProntuario(identificadorDoAtendimento))
    setDescricao('Reabrir a Vídeo Consulta')    
  }  

  return (
    <r-cell span={4} span-md='row' span-lg='row'>
      <button type='button' className={`button --primary w-sm-100`} disabled={ehRemotoPorVideoENaoTemTeleconsulta} onClick={handleAbrir}>{descricao}</button>      
    </r-cell>
  )
}

export default memo(VideoConsulta)