import React from 'react'
import { Link } from 'react-router-dom'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import { ListCheck, PieChart } from '../../design/icones'

export default function Atalhos() {
  return (
    <div className='dashboard principal'>
      <div className='container'>
        <div className='centraliza-dashboard'>
          <Permissao permissoes={[permissoes.LISTAR_PACIENTES]}>
            <Link to={`/cadastros/pacientes`} className='dashboard-item'>
              <i className='dashboard-item-icon --br icon icon-pacientes'></i>
              <p>Pacientes</p>
            </Link>
          </Permissao>
          <Permissao permissoes={[permissoes.LISTAR_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE]}>
            <Link to={`/agendamentos/agenda`} className='dashboard-item'>
              <i className='dashboard-item-icon --bl icon icon-agenda-profissional'></i>
              <p>Agenda Profissional</p>
            </Link>
          </Permissao>
          <Permissao permissoes={[permissoes.ADICIONAR_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE]}>
            <Link to={`/agendamentos/novo-agendamento`} className='dashboard-item'>
              <i className='dashboard-item-icon --tl icon icon-agenda-novo'></i>
              <p>Novo Agendamento</p>
            </Link>
          </Permissao>
          <Permissao permissoes={[permissoes.CONFIRMAR_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE]}>
            <Link to={`/agendamentos/confirmar-agendamento`} className='dashboard-item'>
              <i className='dashboard-item-icon --tr icon icon-confirmacao'></i>
              <p>Confirmar Agendamento</p>
            </Link>
          </Permissao>
          <Permissao permissoes={[permissoes.CONFIRMAR_CHEGADA_DO_PACIENTE]}>
            <Link to={`/agendamentos/informar-chegada-do-paciente`} className='dashboard-item'>
              <i className='dashboard-item-icon --tr icon icon-agenda'></i>
              <p>Informar Chegada</p>
              <p>do Paciente</p>
            </Link>
          </Permissao>
          <Permissao permissoes={[permissoes.LISTAR_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE]}>
            <Link to={`/atendimentos/sala-de-espera`} className='dashboard-item'>
              <i className='dashboard-item-icon --cl icon icon-sala'></i>
              <p>Sala de Espera</p>
            </Link>
          </Permissao>
          <Permissao permissoes={[permissoes.LISTAR_RELATORIOS_DO_POWER_BI, permissoes.VISUALIZAR_RELATORIO_DO_POWER_BI]}>
            <Link to={`/relatorios/dashboards`} className='dashboard-item'>
              <div className='dashboard-item-icon --cl icon'><PieChart size={42} /></div>
              <p>Dashboards</p>
            </Link>
          </Permissao>
          <Permissao permissoes={[permissoes.LISTAR_TAREFAS]}>
            <Link to={`/tarefas`} className='dashboard-item'>
              <div className='dashboard-item-icon --cl icon'><ListCheck size={42} /></div>
              <p>Tarefas</p>
            </Link>
          </Permissao>
        </div>
      </div>
    </div>
  )
}