import React, { useState, useEffect, useMemo } from 'react'
import { Chart } from 'react-google-charts'
import { formatarDataEHoraParaFormatoLocal, formatarDataParaFormatoUniversal } from '../../../../bibliotecas/data'
import { Formulario as FormularioDoFormik, DatePicker } from '../../../formik/formulario'
import Spinner from '../../../spinner'
import moment from 'moment'
import tabelaVazia from '../../../../design/img/empty-data.svg'
import { LISTAR_ATENDIMENTOS_FINALIZADOS } from '../../../../acoes/tipos'

function ordenarProfissoes(a) {
  if (a.profissao.toUpperCase() === 'MÉDICO(A)') {
    return -1
  }

  return 0
}

export default function TimelineDoPaciente({ historico = [] }) {
  const historicoReduzido = useMemo(() =>
    historico.length > 0 ?
      historico.map(x => ({
        profissao: x.usuario.profissao ? x.usuario.profissao.nome : 'SEM PROFISSÃO',
        inicio: new Date(x.inicio),
        fim: new Date(x.fim),
        tipo: x.tipo.nome,
        profissional: x.usuario.nome
      }))
      : [],
    [historico])

  const [historicoFiltrado, setHistoricoFiltrado] = useState([])

  useEffect(() => {
    if (historicoReduzido.length > 0) {
      setHistoricoFiltrado(historicoReduzido.sort(ordenarProfissoes))
    }
  }, [historicoReduzido])

  const aplicarFiltro = formulario => {
    const inicio = formatarDataParaFormatoUniversal(formulario.inicio)
    const fim = formatarDataParaFormatoUniversal(formulario.fim)

    const formatar = data => {
      const dataFormatada = formatarDataParaFormatoUniversal(data)
      const inicioFormatado = moment(inicio)
      const fimFormatado = moment(fim)

      return moment(dataFormatada).isBetween(inicioFormatado, fimFormatado, undefined, '[]')
    }

    setHistoricoFiltrado(historicoReduzido.filter(x => formatar(x.inicio)))
  }

  const renderizarCasoVazio = mensagem => {
    return (
      <div className='table-items__result'>
        <button className='icon-tabela-vazia'>
          <img src={tabelaVazia} alt='tabelaVazia' />
        </button>
        <p>{mensagem}</p>
      </div>
    )
  }

  return (
    <Spinner operacoes={[LISTAR_ATENDIMENTOS_FINALIZADOS]}>
      {({ processando }) => (
        processando ? <div className='table-items-expanded is-loading abas-qgcc'></div> :
          historico.length === 0 ? renderizarCasoVazio('O paciente não possui atendimentos.') :
            <>
              <FormularioDoFormik
                acao={aplicarFiltro}
                valoresIniciais={{
                  inicio: historico[historico.length - 1].inicio,
                  fim: historico[0].inicio
                }}
              >
                {({ values, initialValues }) => (
                  <r-grid columns-md={6} columns-lg={6} class='mb-2'>
                    <r-cell span={4} span-md={2} span-lg='7-8'>
                      <DatePicker
                        nome='inicio'
                        tabIndex={1}
                        minDate={moment(initialValues.inicio).toDate()}
                        maxDate={moment(values.fim).toDate()}
                        titulo='Início'
                      />
                    </r-cell>
                    <r-cell span={4} span-md={2} span-lg='9-10'>
                      <DatePicker
                        nome='fim'
                        tabIndex={2}
                        minDate={moment(values.inicio).toDate()}
                        maxDate={moment(initialValues.fim).toDate()}
                        titulo='Fim'
                      />
                    </r-cell>
                    <r-cell span={4} span-md={2} span-lg='11-12'>
                      <button type='submit' className='button --primary mt-24'>Filtrar</button>
                    </r-cell>
                  </r-grid>
                )}
              </FormularioDoFormik>
              {historicoFiltrado.length > 0 ?
                <RenderizarTimeline dados={historicoFiltrado} />
                : renderizarCasoVazio('O paciente não possui atendimentos com os filtros selecionados.')}
            </>
      )}
    </Spinner>
  )
}

function RenderizarTimeline({ dados }) {
  function renderizarTooltip(atendimento) {
    const formatarDuracao = () => {
      const diferenca = moment(atendimento.fim).diff(moment(atendimento.inicio))
      const duracao = moment.duration(diferenca)
      const horas = duracao.hours()
      const minutos = duracao.minutes()
      const segundos = duracao.seconds()

      return `${horas}h${minutos}min${segundos}s`
    }

    return (
      `<div class='tooltip-timeline'>
      <h2>${atendimento.tipo}</h2>
      <p>${atendimento.profissional}${atendimento.profissao ? ' - ' + atendimento.profissao : ''}</p>  
      <p>${formatarDataEHoraParaFormatoLocal(atendimento.inicio)}</p>
      <p>Duração: ${formatarDuracao()}</p>
    </div>`
    )
  }

  const atendimentos = [
    [
      { type: 'string', id: 'profissao' },
      { type: 'string', id: 'bar-label' },
      { type: 'string', role: 'tooltip', p: { html: true } },
      { type: 'date', id: 'inicio' },
      { type: 'date', id: 'fim' },
    ]
  ]

  for (let i = 0; i < dados.length; i++) {
    const atendimento = [
      dados[i].profissao,
      '',
      renderizarTooltip(dados[i]),
      dados[i].inicio,
      dados[i].fim
    ]

    atendimentos.push(atendimento)
  }

  const profissoes = dados.map(x => x.profissao)
  const alturaDaTimeline = (profissoes.filter((profissao, index) => profissoes.indexOf(profissao) === index).length) * 41 + 50

  return (
    <Chart
      width='100%'
      height='100%'
      chartType='Timeline'
      data={atendimentos}
      chartLanguage='PT-BR'
      className='timeline'
      options={{
        allowHtml: true,
        tooltip: { isHtml: true },
        timeline: {
          rowLabelStyle: {
            fontName: 'Roboto',
            fontSize: 14,
            letterSpacing: 'normal',
            color: '#393042'
          },
        },
        hAxis: { format: 'MM/yy' },
        height: alturaDaTimeline
      }}
      rootProps={{ 'data-testid': '1' }}
    />
  )
}