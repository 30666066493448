import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import Spinner from '../../spinner'
import { Input, Select, DatePicker, Telefone, Formulario as Form } from '../../formik/formulario'
import {
  ADICIONAR_PRE_CADASTRO_DO_PACIENTE
} from '../../../acoes/tipos'

export default function FormularioPreCadastroDoPaciente(props) {
  const {
    adicionar,
    listarSexos,
    nomeDoPaciente,
    fechar,
    sexos,
  } = props

  useEffect(() => { listarSexos() }, [listarSexos])

  return (
    <Spinner operacoes={[ADICIONAR_PRE_CADASTRO_DO_PACIENTE]}>
      <Form
        reinicializar={true}
        valoresIniciais={{
          nome: nomeDoPaciente ? nomeDoPaciente : '',
          nomeDaMae: '',
          telefone: '',
          sexo: '',
          dataDeNascimento: '',
          observacoes: '',
        }}
        acao={adicionar}
        esquemaDeValidacoes={Yup.object().shape({
          nome: Yup.string().required('Obrigatório'),
          nomeDaMae: Yup.string().required('Obrigatório'),
          telefone: Yup.string().required('Obrigatório').ehUmNumeroDeTelefoneValido('Telefone inválido. Ex.: 99 9999-9999'),
          sexo: Yup.string().required('Obrigatório'),
          dataDeNascimento: Yup.string().nullable().required('Obrigatório'),
        })}
      >
        <fieldset>
          <h2 className='form-title'>Pré-Cadastro de Paciente</h2>
          <r-grid columns-md={6} columns-lg={12}>
            <r-cell span={4} span-md={12} span-lg={12} class='form-item'>
              <p className='form-item__choice'>Caso queira fazer o cadastro completo do paciente, acesse o link
                <strong><Link to={`/cadastros/pacientes/novo`} target='_blank'>{' '}[Cadastro do Paciente]</Link>.</strong>
              </p>
            </r-cell>
            <r-cell span={4} span-md={6} span-lg={12}>
              <Input
                nome='nome'
                tabIndex={1}
                titulo='Nome *'
              />
            </r-cell>
            <r-cell span={4} span-md={6} span-lg={12}>
              <Input
                nome='nomeDaMae'
                tabIndex={2}
                titulo='Nome da Mãe *'
              />
            </r-cell>
            <r-cell span={4} span-md={2} span-lg={4}>
              <Select
                nome='sexo'
                tabIndex={3}
                titulo='Sexo *'
                itens={sexos}
                campoCodigo='codigo'
                campoDescricao='nome'
              />
            </r-cell>
            <r-cell span={4} span-md={2} span-lg={4}>
              <DatePicker
                nome='dataDeNascimento'
                tabIndex={4}
                titulo='Data de Nascimento *'
                maxDate={new Date()}
              />
            </r-cell>
            <r-cell span={4} span-md={2} span-lg={4}>
              <Telefone
                nome='telefone'
                tabIndex={5}
                titulo='Telefone *'
              />
            </r-cell>
            <r-cell span={4} span-md={12} span-lg={12}>
              <Input
                as='textarea'
                nome='observacoes'
                tabIndex={6}
                titulo='Observações'
              />
            </r-cell>
          </r-grid>
          <div className='buttons-modal'>
            <button type='button' onClick={() => fechar()} className='button --light'>Sair</button>
            <button type='submit' className='button --primary'>Adicionar Pré-Cadastro</button>
          </div>
        </fieldset>
      </Form>
    </Spinner>
  )
}