import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { iniciarAtendimento } from '../../../acoes/prontuario/atendimentos'
import '../style.css'

export default function NotificacaoAtendimentoSemAgendamento(props) {
  const { fechar, notificacao } = props
  const { metaDados } = notificacao

  const dispatch = useDispatch()

  const sim = (agendamento) => {
    dispatch(iniciarAtendimento(metaDados.paciente, { tipo: metaDados.tipoDoAtendimento, versao: metaDados.versaoDoAtendimento, agendamento: agendamento }))
    fechar()
  }

  const nao = () => {
    dispatch(iniciarAtendimento(metaDados.paciente, { tipo: metaDados.tipoDoAtendimento, versao: metaDados.versaoDoAtendimento, semAgendamento: true }))
    fechar()
  }

  const todosAgendamentosDoPacienteHoje = useMemo(() => {
    const agendamentos = Object.keys(metaDados)
      .filter(key => key.startsWith('agendamento_'))
      .map(key => metaDados[key])

    const horarios = Object.keys(metaDados)
      .filter(key => key.startsWith('horario_'))
      .map(key => metaDados[key])

    const resultado = agendamentos.map((agendamento, index) => {
      return { agendamento, horario: horarios[index] }
    })

    return resultado;
  }, [metaDados]);

  const unicoAgendamento = todosAgendamentosDoPacienteHoje.length === 1
  const mensagem = unicoAgendamento ?
    `O atendimento do paciente "${metaDados.nomePaciente}" está relacionado ao agendamento das "${todosAgendamentosDoPacienteHoje[0].horario}"?` :
    `O atendimento do paciente "${metaDados.nomePaciente}" está relacionado ao agendamento para algum dos seguintes horários?`

  return (
    <div>
      <span>{mensagem}</span>
      <div>
        <br />
        {todosAgendamentosDoPacienteHoje.map((a) => (
          <React.Fragment key={a.agendamento}>
            <button type='button' className='button button-light-select-horario-agendamento' onClick={() => sim(a.agendamento)}>{unicoAgendamento ? 'Sim' : a.horario}</button> {' '}
          </React.Fragment>
        ))}
        <button type='button' className='button button-light-select-horario-agendamento' onClick={nao}>Não. É um atendimento não agendado.</button>
      </div>
    </div>
  )
}