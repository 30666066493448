//sistema
export const INICIAR_SISTEMA = 'INICIAR_SISTEMA'

//notificacoes
export const ADICIONAR_NOTIFICACAO = 'ADICIONAR_NOTIFICACAO'
export const ADICIONAR_NOTIFICACOES = 'ADICIONAR_NOTIFICACOES'
export const ADICIONAR_NOTIFICACOES_DA_API = 'ADICIONAR_NOTIFICACOES_DA_API'
export const REMOVER_NOTIFICACAO = 'REMOVER_NOTIFICACAO'
export const REMOVER_NOTIFICACOES = 'REMOVER_NOTIFICACOES'

// pacientes
export const RECUPERAR_ENDERECO_DO_PACIENTE_POR_CEP = 'RECUPERAR_ENDERECO_DO_PACIENTE_POR_CEP'
export const RECUPEROU_ENDERECO_DO_PACIENTE_POR_CEP = 'RECUPEROU_ENDERECO_DO_PACIENTE_POR_CEP'
export const ERRO_AO_RECUPERAR_ENDERECO_DO_PACIENTE_POR_CEP = 'ERRO_AO_RECUPERAR_ENDERECO_DO_PACIENTE_POR_CEP'
export const ADICIONAR_PACIENTE = 'ADICIONAR_PACIENTE'
export const ADICIONOU_PACIENTE = 'ADICIONOU_PACIENTE'
export const ERRO_AO_ADICIONAR_PACIENTE = 'ERRO_AO_ADICIONAR_PACIENTE'
export const ADICIONAR_FOTO_DO_PACIENTE = 'ADICIONAR_FOTO_DO_PACIENTE'
export const ADICIONOU_FOTO_DO_PACIENTE = 'ADICIONOU_FOTO_DO_PACIENTE'
export const ERRO_AO_ADICIONAR_FOTO_DO_PACIENTE = 'ERRO_AO_ADICIONAR_FOTO_DO_PACIENTE'
export const REMOVER_FOTO_DO_PACIENTE = 'REMOVER_FOTO_DO_PACIENTE'
export const REMOVEU_FOTO_DO_PACIENTE = 'REMOVEU_FOTO_DO_PACIENTE'
export const RECUPERAR_PACIENTE_POR_IDENTIFICADOR = 'RECUPERAR_PACIENTE_POR_IDENTIFICADOR'
export const RECUPEROU_PACIENTE_POR_IDENTIFICADOR = 'RECUPEROU_PACIENTE_POR_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_PACIENTE_POR_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_PACIENTE_POR_IDENTIFICADOR'
export const RECUPERAR_FOTO_DO_PACIENTE_POR_IDENTIFICADOR = 'RECUPERAR_FOTO_DO_PACIENTE_POR_IDENTIFICADOR'
export const RECUPEROU_FOTO_DO_PACIENTE_POR_IDENTIFICADOR = 'RECUPEROU_FOTO_DO_PACIENTE_POR_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_FOTO_DO_PACIENTE_POR_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_FOTO_DO_PACIENTE_POR_IDENTIFICADOR'
export const SALVAR_PACIENTE = 'SALVAR_PACIENTE'
export const SALVOU_PACIENTE = 'SALVOU_PACIENTE'
export const ERRO_AO_SALVAR_PACIENTE = 'ERRO_AO_SALVAR_PACIENTE'
export const LISTAR_PACIENTES = 'LISTAR_PACIENTES'
export const LISTOU_PACIENTES = 'LISTOU_PACIENTES'
export const ERRO_AO_LISTAR_PACIENTES = 'ERRO_AO_LISTAR_PACIENTES'
export const ALTERAR_TERMO_DE_PACIENTES = 'ALTERAR_TERMO_DE_PACIENTES'
export const LIMPAR_PACIENTE_ATUAL = 'LIMPAR_PACIENTE_ATUAL'
export const SELECIONAR_ABA_ATIVA_DO_CADASTRO_PACIENTE = 'SELECIONAR_ABA_ATIVA_DO_CADASTRO_PACIENTE'
export const ADICIONAR_TAG_NO_PACIENTE = 'ADICIONAR_TAG_NO_PACIENTE'
export const ADICIONOU_TAG_NO_PACIENTE = 'ADICIONOU_TAG_NO_PACIENTE'
export const ERRO_AO_ADICIONAR_TAG_NO_PACIENTE = 'ERRO_AO_ADICIONAR_TAG_NO_PACIENTE'
export const REMOVER_TAG_DO_PACIENTE = 'REMOVER_TAG_DO_PACIENTE'
export const REMOVEU_TAG_DO_PACIENTE = 'REMOVEU_TAG_DO_PACIENTE'
export const ERRO_AO_REMOVER_TAG_DO_PACIENTE = 'ERRO_AO_REMOVER_TAG_DO_PACIENTE'
export const DESABILITAR_CAMPOS_DE_INFORMACAO_DO_PACIENTE = 'DESABILITAR_CAMPOS_DE_INFORMACAO_DO_PACIENTE'
export const RECUPERAR_PACIENTE_POR_IDENTIFICADOR_PUBLICO = 'RECUPERAR_PACIENTE_POR_IDENTIFICADOR_PUBLICO'
export const RECUPEROU_PACIENTE_POR_IDENTIFICADOR_PUBLICO = 'RECUPEROU_PACIENTE_POR_IDENTIFICADOR_PUBLICO'
export const ERRO_AO_RECUPERAR_PACIENTE_POR_IDENTIFICADOR_PUBLICO = 'ERRO_AO_RECUPERAR_PACIENTE_POR_IDENTIFICADOR_PUBLICO'

//enfermeiros do paciente
export const LISTAR_ENFERMEIROS_DO_PACIENTE = 'LISTAR_ENFERMEIROS_DO_PACIENTE'
export const LISTOU_ENFERMEIROS_DO_PACIENTE = 'LISTOU_ENFERMEIROS_DO_PACIENTE'
export const ERRO_AO_LISTAR_ENFERMEIROS_DO_PACIENTE = 'ERRO_AO_LISTAR_ENFERMEIROS_DO_PACIENTE'

// medicos do paciente
export const LISTAR_MEDICOS_DO_PACIENTE = 'LISTAR_MEDICOS_DO_PACIENTE'
export const LISTOU_MEDICOS_DO_PACIENTE = 'LISTOU_MEDICOS_DO_PACIENTE'
export const ERRO_AO_LISTAR_MEDICOS_DO_PACIENTE = 'ERRO_AO_LISTAR_MEDICOS_DO_PACIENTE'

// psicologos do paciente
export const LISTAR_PSICOLOGOS_DO_PACIENTE = 'LISTAR_PSICOLOGOS_DO_PACIENTE'
export const LISTOU_PSICOLOGOS_DO_PACIENTE = 'LISTOU_PSICOLOGOS_DO_PACIENTE'
export const ERRO_AO_LISTAR_PSICOLOGOS_DO_PACIENTE = 'ERRO_AO_LISTAR_PSICOLOGOS_DO_PACIENTE'

// consultores do paciente
export const LISTAR_CONSULTORES_DO_PACIENTE = 'LISTAR_CONSULTORES_DO_PACIENTE'
export const LISTOU_CONSULTORES_DO_PACIENTE = 'LISTOU_CONSULTORES_DO_PACIENTE'
export const ERRO_AO_LISTAR_CONSULTORES_DO_PACIENTE = 'ERRO_AO_LISTAR_CONSULTORES_DO_PACIENTE'

// operadoras do paciente
export const LISTAR_OPERADORAS_DO_PACIENTE = 'LISTAR_OPERADORAS_DO_PACIENTE'
export const LISTOU_OPERADORAS_DO_PACIENTE = 'LISTOU_OPERADORAS_DO_PACIENTE'
export const ERRO_AO_LISTAR_OPERADORAS_DO_PACIENTE = 'ERRO_AO_LISTAR_OPERADORAS_DO_PACIENTE'

//programas do paciente
export const ADICIONAR_PROGRAMA_DO_PACIENTE = 'ADICIONAR_PROGRAMA_DO_PACIENTE'
export const ADICIONOU_PROGRAMA_DO_PACIENTE = 'ADICIONOU_PROGRAMA_DO_PACIENTE'
export const ERRO_AO_ADICIONAR_PROGRAMA_DO_PACIENTE = 'ERRO_AO_ADICIONAR_PROGRAMA_DO_PACIENTE'
export const ALTERAR_PROGRAMA_DO_PACIENTE = 'ALTERAR_PROGRAMA_DO_PACIENTE'
export const ALTEROU_PROGRAMA_DO_PACIENTE = 'ALTEROU_PROGRAMA_DO_PACIENTE'
export const ERRO_AO_ALTERAR_PROGRAMA_DO_PACIENTE = 'ERRO_AO_ALTERAR_PROGRAMA_DO_PACIENTE'
export const LISTAR_PROGRAMAS_DO_PACIENTE = 'LISTAR_PROGRAMAS_DO_PACIENTE'
export const LISTOU_PROGRAMAS_DO_PACIENTE = 'LISTOU_PROGRAMAS_DO_PACIENTE'
export const ERRO_AO_LISTAR_PROGRAMAS_DO_PACIENTE = 'ERRO_AO_LISTAR_PROGRAMAS_DO_PACIENTE'
export const REMOVER_PROGRAMA_DO_PACIENTE = 'REMOVER_PROGRAMA_DO_PACIENTE'
export const REMOVEU_PROGRAMA_DO_PACIENTE = 'REMOVEU_PROGRAMA_DO_PACIENTE'
export const ERRO_AO_REMOVER_PROGRAMA_DO_PACIENTE = 'ERRO_AO_REMOVER_PROGRAMA_DO_PACIENTE'
export const RECUPERAR_PROGRAMA_DO_PACIENTE = 'RECUPERAR_PROGRAMA_DO_PACIENTE'
export const RECUPEROU_PROGRAMA_DO_PACIENTE = 'RECUPEROU_PROGRAMA_DO_PACIENTE'
export const ERRO_AO_RECUPERAR_PROGRAMA_DO_PACIENTE = 'ERRO_AO_RECUPERAR_PROGRAMA_DO_PACIENTE'

//convenios do paciente
export const ADICIONAR_CONVENIO_DO_PACIENTE = 'ADICIONAR_CONVENIO_DO_PACIENTE'
export const ADICIONOU_CONVENIO_DO_PACIENTE = 'ADICIONOU_CONVENIO_DO_PACIENTE'
export const ERRO_AO_ADICIONAR_CONVENIO_DO_PACIENTE = 'ERRO_AO_ADICIONAR_CONVENIO_DO_PACIENTE'
export const ALTERAR_CONVENIO_DO_PACIENTE = 'ALTERAR_CONVENIO_DO_PACIENTE'
export const ALTEROU_CONVENIO_DO_PACIENTE = 'ALTEROU_CONVENIO_DO_PACIENTE'
export const ERRO_AO_ALTERAR_CONVENIO_DO_PACIENTE = 'ERRO_AO_ALTERAR_CONVENIO_DO_PACIENTE'
export const LISTAR_CONVENIOS_DO_PACIENTE = 'LISTAR_CONVENIOS_DO_PACIENTE'
export const LISTOU_CONVENIOS_DO_PACIENTE = 'LISTOU_CONVENIOS_DO_PACIENTE'
export const ERRO_AO_LISTAR_CONVENIOS_DO_PACIENTE = 'ERRO_AO_LISTAR_CONVENIOS_DO_PACIENTE'
export const REMOVER_CONVENIO_DO_PACIENTE = 'REMOVER_CONVENIO_DO_PACIENTE'
export const REMOVEU_CONVENIO_DO_PACIENTE = 'REMOVEU_CONVENIO_DO_PACIENTE'
export const ERRO_AO_REMOVER_CONVENIO_DO_PACIENTE = 'ERRO_AO_REMOVER_CONVENIO_DO_PACIENTE'
export const RECUPERAR_CONVENIO_DO_PACIENTE = 'RECUPERAR_CONVENIO_DO_PACIENTE'
export const RECUPEROU_CONVENIO_DO_PACIENTE = 'RECUPEROU_CONVENIO_DO_PACIENTE'
export const ERRO_AO_RECUPERAR_CONVENIO_DO_PACIENTE = 'ERRO_AO_RECUPERAR_CONVENIO_DO_PACIENTE'

//sexo
export const LISTAR_SEXOS = 'LISTAR_SEXOS'
export const LISTOU_SEXOS = 'LISTOU_SEXOS'
export const ERRO_AO_LISTAR_SEXOS = 'ERRO_AO_LISTAR_SEXOS'

//tratamento
export const LISTAR_TRATAMENTOS = 'LISTAR_TRATAMENTOS'
export const LISTOU_TRATAMENTOS = 'LISTOU_TRATAMENTOS'
export const ERRO_AO_LISTAR_TRATAMENTOS = 'ERRO_AO_LISTAR_TRATAMENTOS'

//tipo de repeticao de serie da agenda
export const LISTAR_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA = 'LISTAR_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA'
export const LISTOU_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA = 'LISTOU_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA'
export const ERRO_AO_LISTAR_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA = 'ERRO_AO_LISTAR_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA'

//prontuario
export const RECUPERAR_CABECALHO_DO_PRONTUARIO = 'RECUPERAR_CABECALHO_DO_PRONTUARIO'
export const RECUPEROU_CABECALHO_DO_PRONTUARIO = 'RECUPEROU_CABECALHO_DO_PRONTUARIO'
export const ERRO_AO_RECUPERAR_CABECALHO_DO_PRONTUARIO = 'ERRO_AO_RECUPERAR_CABECALHO_DO_PRONTUARIO'
export const RECUPERAR_FOTO_DO_PACIENTE_DO_PRONTUARIO = 'RECUPERAR_FOTO_DO_PACIENTE_DO_PRONTUARIO'
export const RECUPEROU_FOTO_DO_PACIENTE_DO_PRONTUARIO = 'RECUPEROU_FOTO_DO_PACIENTE_DO_PRONTUARIO'
export const ERRO_AO_RECUPERAR_FOTO_DO_PACIENTE_DO_PRONTUARIO = 'ERRO_AO_RECUPERAR_FOTO_DO_PACIENTE_DO_PRONTUARIO'
export const INICIAR_PRONTUARIO = 'INICIAR_PRONTUARIO'
export const ALTERAR_STATUS_DE_VACINA_DO_PRONTUARIO_DO_PACIENTE = 'ALTERAR_STATUS_DE_VACINA_DO_PRONTUARIO_DO_PACIENTE'
export const DESFAZER_REMOCAO_DA_VACINA_DO_PRONTUARIO_DO_PACIENTE = 'DESFAZER_REMOCAO_DA_VACINA_DO_PRONTUARIO_DO_PACIENTE'
export const REMOVER_QUEDA_DO_PRONTUARIO_DO_PACIENTE = 'REMOVER_QUEDA_DO_PRONTUARIO_DO_PACIENTE'
export const DESFAZER_REMOCAO_DA_QUEDA_DO_PRONTUARIO_DO_PACIENTE = 'DESFAZER_REMOCAO_DA_QUEDA_DO_PRONTUARIO_DO_PACIENTE'
export const REMOVER_INTERNACAO_DO_PRONTUARIO_DO_PACIENTE = 'REMOVER_INTERNACAO_DO_PRONTUARIO_DO_PACIENTE'
export const DESFAZER_REMOCAO_DA_INTERNACAO_DO_PRONTUARIO_DO_PACIENTE = 'DESFAZER_REMOCAO_DA_INTERNACAO_DO_PRONTUARIO_DO_PACIENTE'
export const REMOVER_IDA_AO_PRONTO_ATENDIMENTO_DO_PRONTUARIO_DO_PACIENTE = 'REMOVER_IDA_AO_PRONTO_ATENDIMENTO_DO_PRONTUARIO_DO_PACIENTE'
export const DESFAZER_REMOCAO_DA_IDA_AO_PRONTO_ATENDIMENTO_DO_PRONTUARIO_DO_PACIENTE = 'DESFAZER_REMOCAO_DA_IDA_AO_PRONTO_ATENDIMENTO_DO_PRONTUARIO_DO_PACIENTE'
export const REMOVER_INFECCAO_DO_TRATO_URINARIO_DO_PRONTUARIO_DO_PACIENTE = 'REMOVER_INFECCAO_DO_TRATO_URINARIO_DO_PRONTUARIO_DO_PACIENTE'
export const DESFAZER_REMOCAO_DA_INFECCAO_DO_TRATO_URINARIO_DO_PRONTUARIO_DO_PACIENTE = 'DESFAZER_REMOCAO_DA_INFECCAO_DO_TRATO_URINARIO_DO_PRONTUARIO_DO_PACIENTE'
export const FAZER_DOWNLOAD_DO_ARQUIVO_DE_ATENDIMENTO = 'FAZER_DOWNLOAD_DO_ARQUIVO_DE_ATENDIMENTO'
export const FEZ_DOWNLOAD_DO_ARQUIVO_DE_ATENDIMENTO = 'FEZ_DOWNLOAD_DO_ARQUIVO_DE_ATENDIMENTO'
export const ERRO_AO_FAZER_DOWNLOAD_DO_ARQUIVO_DE_ATENDIMENTO = 'ERRO_AO_FAZER_DOWNLOAD_DO_ARQUIVO_DE_ATENDIMENTO'
export const ADICIONAR_TAG_NO_PACIENTE_DO_PRONTUARIO = 'ADICIONAR_TAG_NO_PACIENTE_DO_PRONTUARIO'
export const ADICIONOU_TAG_NO_PACIENTE_DO_PRONTUARIO = 'ADICIONOU_TAG_NO_PACIENTE_DO_PRONTUARIO'
export const ERRO_AO_ADICIONAR_TAG_NO_PACIENTE_DO_PRONTUARIO = 'ERRO_AO_ADICIONAR_TAG_NO_PACIENTE_DO_PRONTUARIO'
export const REMOVER_TAG_DO_PACIENTE_DO_PRONTUARIO = 'REMOVER_TAG_DO_PACIENTE_DO_PRONTUARIO'
export const REMOVEU_TAG_DO_PACIENTE_DO_PRONTUARIO = 'REMOVEU_TAG_DO_PACIENTE_DO_PRONTUARIO'
export const ERRO_AO_REMOVER_TAG_DO_PACIENTE_DO_PRONTUARIO = 'ERRO_AO_REMOVER_TAG_DO_PACIENTE_DO_PRONTUARIO'
export const LISTAR_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE = 'LISTAR_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE'
export const LISTOU_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE = 'LISTOU_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE'
export const ERRO_AO_LISTAR_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE = 'ERRO_AO_LISTAR_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE'
export const ALTERAR_STATUS_DE_IDA_AO_PRONTO_ATENDIMENTO_DO_PRONTUARIO_DO_PACIENTE = 'ALTERAR_STATUS_DE_IDA_AO_PRONTO_ATENDIMENTO_DO_PRONTUARIO_DO_PACIENTE'
export const LISTOU_DIAGNOSTICOS_PARA_O_PRONTUARIO = 'LISTOU_DIAGNOSTICOS_PARA_O_PRONTUARIO'
export const ERRO_AO_LISTAR_DIAGNOSTICOS_PARA_O_PRONTUARIO = 'ERRO_AO_LISTAR_DIAGNOSTICOS_PARA_O_PRONTUARIO'
export const LISTAR_DIAGNOSTICOS_PARA_O_PRONTUARIO = 'LISTAR_DIAGNOSTICOS_PARA_O_PRONTUARIO'
export const SELECIONAR_ATENDIMENTO_DA_TIMELINE = 'SELECIONAR_ATENDIMENTO_DA_TIMELINE'
export const ALTERAR_TERMO_DA_TIMELINE = 'ALTERAR_TERMO_DA_TIMELINE'
export const ADICIONAR_VACINA_DO_PACIENTE = 'ADICIONAR_VACINA_DO_PACIENTE'
export const ADICIONOU_VACINA_DO_PACIENTE = 'ADICIONOU_VACINA_DO_PACIENTE'
export const ERRO_AO_ADICIONAR_VACINA_DO_PACIENTE = 'ERRO_AO_ADICIONAR_VACINA_DO_PACIENTE'
export const ALTERAR_VACINA_DO_PACIENTE = 'ALTERAR_VACINA_DO_PACIENTE'
export const ALTEROU_VACINA_DO_PACIENTE = 'ALTEROU_VACINA_DO_PACIENTE'
export const ERRO_AO_ALTERAR_VACINA_DO_PACIENTE = 'ERRO_AO_ALTERAR_VACINA_DO_PACIENTE'
export const LISTAR_VACINAS_DO_PACIENTE = 'LISTAR_VACINAS_DO_PACIENTE'
export const LISTOU_VACINAS_DO_PACIENTE = 'LISTOU_VACINAS_DO_PACIENTE'
export const ERRO_AO_LISTAR_VACINAS_DO_PACIENTE = 'ERRO_AO_LISTAR_VACINAS_DO_PACIENTE'
export const REMOVER_VACINA_DO_PACIENTE = 'REMOVER_VACINA_DO_PACIENTE'
export const REMOVEU_VACINA_DO_PACIENTE = 'REMOVEU_VACINA_DO_PACIENTE'
export const ERRO_AO_REMOVER_VACINA_DO_PACIENTE = 'ERRO_AO_REMOVER_VACINA_DO_PACIENTE'
export const ADICIONAR_QUEDA_DO_PACIENTE = 'ADICIONAR_QUEDA_DO_PACIENTE'
export const ADICIONOU_QUEDA_DO_PACIENTE = 'ADICIONOU_QUEDA_DO_PACIENTE'
export const ERRO_AO_ADICIONAR_QUEDA_DO_PACIENTE = 'ERRO_AO_ADICIONAR_QUEDA_DO_PACIENTE'
export const ALTERAR_QUEDA_DO_PACIENTE = 'ALTERAR_QUEDA_DO_PACIENTE'
export const ALTEROU_QUEDA_DO_PACIENTE = 'ALTEROU_QUEDA_DO_PACIENTE'
export const ERRO_AO_ALTERAR_QUEDA_DO_PACIENTE = 'ERRO_AO_ALTERAR_QUEDA_DO_PACIENTE'
export const LISTAR_QUEDAS_DO_PACIENTE = 'LISTAR_QUEDAS_DO_PACIENTE'
export const LISTOU_QUEDAS_DO_PACIENTE = 'LISTOU_QUEDAS_DO_PACIENTE'
export const ERRO_AO_LISTAR_QUEDAS_DO_PACIENTE = 'ERRO_AO_LISTAR_QUEDAS_DO_PACIENTE'
export const REMOVER_QUEDA_DO_PACIENTE = 'REMOVER_QUEDA_DO_PACIENTE'
export const REMOVEU_QUEDA_DO_PACIENTE = 'REMOVEU_QUEDA_DO_PACIENTE'
export const ERRO_AO_REMOVER_QUEDA_DO_PACIENTE = 'ERRO_AO_REMOVER_QUEDA_DO_PACIENTE'
export const ADICIONAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE = 'ADICIONAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE'
export const ADICIONOU_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE = 'ADICIONOU_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE'
export const ERRO_AO_ADICIONAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE = 'ERRO_AO_ADICIONAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE'
export const ALTERAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE = 'ALTERAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE'
export const ALTEROU_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE = 'ALTEROU_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE'
export const ERRO_AO_ALTERAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE = 'ERRO_AO_ALTERAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE'
export const LISTAR_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE = 'LISTAR_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE'
export const LISTOU_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE = 'LISTOU_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE'
export const ERRO_AO_LISTAR_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE = 'ERRO_AO_LISTAR_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE'
export const REMOVER_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE = 'REMOVER_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE'
export const REMOVEU_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE = 'REMOVEU_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE'
export const ERRO_AO_REMOVER_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE = 'ERRO_AO_REMOVER_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE'
export const ADICIONAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE = 'ADICIONAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE'
export const ADICIONOU_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE = 'ADICIONOU_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE'
export const ERRO_AO_ADICIONAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE = 'ERRO_AO_ADICIONAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE'
export const ALTERAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE = 'ALTERAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE'
export const ALTEROU_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE = 'ALTEROU_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE'
export const ERRO_AO_ALTERAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE = 'ERRO_AO_ALTERAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE'
export const LISTAR_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE = 'LISTAR_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE'
export const LISTOU_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE = 'LISTOU_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE'
export const ERRO_AO_LISTAR_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE = 'ERRO_AO_LISTAR_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE'
export const REMOVER_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE = 'REMOVER_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE'
export const REMOVEU_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE = 'REMOVEU_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE'
export const ERRO_AO_REMOVER_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE = 'ERRO_AO_REMOVER_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE'
export const LISTAR_INTERNACOES_DO_PACIENTE = 'LISTAR_INTERNACOES_DO_PACIENTE'
export const LISTOU_INTERNACOES_DO_PACIENTE = 'LISTOU_INTERNACOES_DO_PACIENTE'
export const ERRO_AO_LISTAR_INTERNACOES_DO_PACIENTE = 'ERRO_AO_LISTAR_INTERNACOES_DO_PACIENTE'
export const ADICIONAR_INTERNACAO_DO_PACIENTE = 'ADICIONAR_INTERNACAO_DO_PACIENTE'
export const ADICIONOU_INTERNACAO_DO_PACIENTE = 'ADICIONOU_INTERNACAO_DO_PACIENTE'
export const ERRO_AO_ADICIONAR_INTERNACAO_DO_PACIENTE = 'ERRO_AO_ADICIONAR_INTERNACAO_DO_PACIENTE'
export const ALTERAR_INTERNACAO_DO_PACIENTE = 'ALTERAR_INTERNACAO_DO_PACIENTE'
export const ALTEROU_INTERNACAO_DO_PACIENTE = 'ALTEROU_INTERNACAO_DO_PACIENTE'
export const ERRO_AO_ALTERAR_INTERNACAO_DO_PACIENTE = 'ERRO_AO_ALTERAR_INTERNACAO_DO_PACIENTE'
export const REMOVER_INTERNACAO_DO_PACIENTE = 'REMOVER_INTERNACAO_DO_PACIENTE'
export const REMOVEU_INTERNACAO_DO_PACIENTE = 'REMOVEU_INTERNACAO_DO_PACIENTE'
export const ERRO_AO_REMOVER_INTERNACAO_DO_PACIENTE = 'ERRO_AO_REMOVER_INTERNACAO_DO_PACIENTE'
export const LISTAR_IVCFS_DO_PACIENTE = 'LISTAR_IVCFS_DO_PACIENTE'
export const LISTOU_IVCFS_DO_PACIENTE = 'LISTOU_IVCFS_DO_PACIENTE'
export const ERRO_AO_LISTAR_IVCFS_DO_PACIENTE = 'ERRO_AO_LISTAR_IVCFS_DO_PACIENTE'
export const ADICIONAR_IVCF_DO_PACIENTE = 'ADICIONAR_IVCF_DO_PACIENTE'
export const ADICIONOU_IVCF_DO_PACIENTE = 'ADICIONOU_IVCF_DO_PACIENTE'
export const ERRO_AO_ADICIONAR_IVCF_DO_PACIENTE = 'ERRO_AO_ADICIONAR_IVCF_DO_PACIENTE'
export const ALTERAR_IVCF_DO_PACIENTE = 'ALTERAR_IVCF_DO_PACIENTE'
export const ALTEROU_IVCF_DO_PACIENTE = 'ALTEROU_IVCF_DO_PACIENTE'
export const ERRO_AO_ALTERAR_IVCF_DO_PACIENTE = 'ERRO_AO_ALTERAR_IVCF_DO_PACIENTE'
export const REMOVER_IVCF_DO_PACIENTE = 'REMOVER_IVCF_DO_PACIENTE'
export const REMOVEU_IVCF_DO_PACIENTE = 'REMOVEU_IVCF_DO_PACIENTE'
export const ERRO_AO_REMOVER_IVCF_DO_PACIENTE = 'ERRO_AO_REMOVER_IVCF_DO_PACIENTE'
export const LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE = 'LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE'
export const LISTOU_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE = 'LISTOU_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE'
export const ERRO_AO_LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE = 'ERRO_AO_LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE'
export const ADICIONAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE = 'ADICIONAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE'
export const ADICIONOU_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE = 'ADICIONOU_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE'
export const ERRO_AO_ADICIONAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE = 'ERRO_AO_ADICIONAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE'
export const ALTERAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE = 'ALTERAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE'
export const ALTEROU_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE = 'ALTEROU_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE'
export const ERRO_AO_ALTERAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE = 'ERRO_AO_ALTERAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE'
export const REMOVER_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE = 'REMOVER_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE'
export const REMOVEU_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE = 'REMOVEU_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE'
export const ERRO_AO_REMOVER_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE = 'ERRO_AO_REMOVER_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE'
export const LISTAR_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE = 'LISTAR_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE'
export const LISTOU_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE = 'LISTOU_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE'
export const ERRO_AO_LISTAR_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE = 'ERRO_AO_LISTAR_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE'
export const ADICIONAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE = 'ADICIONAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE'
export const ADICIONOU_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE = 'ADICIONOU_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE'
export const ERRO_AO_ADICIONAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE = 'ERRO_AO_ADICIONAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE'
export const ALTERAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE = 'ALTERAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE'
export const ALTEROU_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE = 'ALTEROU_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE'
export const ERRO_AO_ALTERAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE = 'ERRO_AO_ALTERAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE'
export const REMOVER_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE = 'REMOVER_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE'
export const REMOVEU_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE = 'REMOVEU_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE'
export const ERRO_AO_REMOVER_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE = 'ERRO_AO_REMOVER_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE'
export const LISTAR_CASPS_16_DO_PACIENTE = 'LISTAR_CASPS_16_DO_PACIENTE'
export const LISTOU_CASPS_16_DO_PACIENTE = 'LISTOU_CASPS_16_DO_PACIENTE'
export const ERRO_AO_LISTAR_CASPS_16_DO_PACIENTE = 'ERRO_AO_LISTAR_CASPS_16_DO_PACIENTE'
export const ADICIONAR_CASP_16_DO_PACIENTE = 'ADICIONAR_CASP_16_DO_PACIENTE'
export const ADICIONOU_CASP_16_DO_PACIENTE = 'ADICIONOU_CASP_16_DO_PACIENTE'
export const ERRO_AO_ADICIONAR_CASP_16_DO_PACIENTE = 'ERRO_AO_ADICIONAR_CASP_16_DO_PACIENTE'
export const ALTERAR_CASP_16_DO_PACIENTE = 'ALTERAR_CASP_16_DO_PACIENTE'
export const ALTEROU_CASP_16_DO_PACIENTE = 'ALTEROU_CASP_16_DO_PACIENTE'
export const ERRO_AO_ALTERAR_CASP_16_DO_PACIENTE = 'ERRO_AO_ALTERAR_CASP_16_DO_PACIENTE'
export const REMOVER_CASP_16_DO_PACIENTE = 'REMOVER_CASP_16_DO_PACIENTE'
export const REMOVEU_CASP_16_DO_PACIENTE = 'REMOVEU_CASP_16_DO_PACIENTE'
export const ERRO_AO_REMOVER_CASP_16_DO_PACIENTE = 'ERRO_AO_REMOVER_CASP_16_DO_PACIENTE'
export const LISTAR_AVALIACOES_DA_SOLIDAO_DO_PACIENTE = 'LISTAR_AVALIACOES_DA_SOLIDAO_DO_PACIENTE'
export const LISTOU_AVALIACOES_DA_SOLIDAO_DO_PACIENTE = 'LISTOU_AVALIACOES_DA_SOLIDAO_DO_PACIENTE'
export const ERRO_AO_LISTAR_AVALIACOES_DA_SOLIDAO_DO_PACIENTE = 'ERRO_AO_LISTAR_AVALIACOES_DA_SOLIDAO_DO_PACIENTE'
export const ADICIONAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE = 'ADICIONAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE'
export const ADICIONOU_AVALIACAO_DA_SOLIDAO_DO_PACIENTE = 'ADICIONOU_AVALIACAO_DA_SOLIDAO_DO_PACIENTE'
export const ERRO_AO_ADICIONAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE = 'ERRO_AO_ADICIONAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE'
export const ALTERAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE = 'ALTERAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE'
export const ALTEROU_AVALIACAO_DA_SOLIDAO_DO_PACIENTE = 'ALTEROU_AVALIACAO_DA_SOLIDAO_DO_PACIENTE'
export const ERRO_AO_ALTERAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE = 'ERRO_AO_ALTERAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE'
export const REMOVER_AVALIACAO_DA_SOLIDAO_DO_PACIENTE = 'REMOVER_AVALIACAO_DA_SOLIDAO_DO_PACIENTE'
export const REMOVEU_AVALIACAO_DA_SOLIDAO_DO_PACIENTE = 'REMOVEU_AVALIACAO_DA_SOLIDAO_DO_PACIENTE'
export const ERRO_AO_REMOVER_AVALIACAO_DA_SOLIDAO_DO_PACIENTE = 'ERRO_AO_REMOVER_AVALIACAO_DA_SOLIDAO_DO_PACIENTE'
export const LISTAR_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO = 'LISTAR_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO'
export const LISTOU_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO = 'LISTOU_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO'
export const ERRO_AO_LISTAR_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO = 'ERRO_AO_LISTAR_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO'
export const ADICIONAR_CONSULTA_EXTERNA_DO_PACIENTE = 'ADICIONAR_CONSULTA_EXTERNA_DO_PACIENTE'
export const ADICIONOU_CONSULTA_EXTERNA_DO_PACIENTE = 'ADICIONOU_CONSULTA_EXTERNA_DO_PACIENTE'
export const ERRO_AO_ADICIONAR_CONSULTA_EXTERNA_DO_PACIENTE = 'ERRO_AO_ADICIONAR_CONSULTA_EXTERNA_DO_PACIENTE'
export const ALTERAR_CONSULTA_EXTERNA_DO_PACIENTE = 'ALTERAR_CONSULTA_EXTERNA_DO_PACIENTE'
export const ALTEROU_CONSULTA_EXTERNA_DO_PACIENTE = 'ALTEROU_CONSULTA_EXTERNA_DO_PACIENTE'
export const ERRO_AO_ALTERAR_CONSULTA_EXTERNA_DO_PACIENTE = 'ERRO_AO_ALTERAR_CONSULTA_EXTERNA_DO_PACIENTE'
export const LISTAR_CONSULTAS_EXTERNAS_DO_PACIENTE = 'LISTAR_CONSULTAS_EXTERNAS_DO_PACIENTE'
export const LISTOU_CONSULTAS_EXTERNAS_DO_PACIENTE = 'LISTOU_CONSULTAS_EXTERNAS_DO_PACIENTE'
export const ERRO_AO_LISTAR_CONSULTAS_EXTERNAS_DO_PACIENTE = 'ERRO_AO_LISTAR_CONSULTAS_EXTERNAS_DO_PACIENTE'
export const REMOVER_CONSULTA_EXTERNA_DO_PACIENTE = 'REMOVER_CONSULTA_EXTERNA_DO_PACIENTE'
export const REMOVEU_CONSULTA_EXTERNA_DO_PACIENTE = 'REMOVEU_CONSULTA_EXTERNA_DO_PACIENTE'
export const ERRO_AO_REMOVER_CONSULTA_EXTERNA_DO_PACIENTE = 'ERRO_AO_REMOVER_CONSULTA_EXTERNA_DO_PACIENTE'
export const LISTAR_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE = 'LISTAR_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE'
export const LISTOU_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE = 'LISTOU_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE'
export const ERRO_AO_LISTAR_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE = 'ERRO_AO_LISTAR_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE'
export const ADICIONAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE = 'ADICIONAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE'
export const ADICIONOU_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE = 'ADICIONOU_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE'
export const ERRO_AO_ADICIONAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE = 'ERRO_AO_ADICIONAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE'
export const ALTERAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE = 'ALTERAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE'
export const ALTEROU_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE = 'ALTEROU_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE'
export const ERRO_AO_ALTERAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE = 'ERRO_AO_ALTERAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE'
export const REMOVER_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE = 'REMOVER_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE'
export const REMOVEU_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE = 'REMOVEU_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE'
export const ERRO_AO_REMOVER_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE = 'ERRO_AO_REMOVER_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE'
export const ADICIONAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE = 'ADICIONAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE'
export const ADICIONOU_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE = 'ADICIONOU_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE'
export const ERRO_AO_ADICIONAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE = 'ERRO_AO_ADICIONAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE'
export const ALTERAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE = 'ALTERAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE'
export const ALTEROU_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE = 'ALTEROU_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE'
export const ERRO_AO_ALTERAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE = 'ERRO_AO_ALTERAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE'
export const REMOVER_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE = 'REMOVER_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE'
export const REMOVEU_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE = 'REMOVEU_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE'
export const ERRO_AO_REMOVER_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE = 'ERRO_AO_REMOVER_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE'
export const LISTAR_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE = 'LISTAR_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE'
export const LISTOU_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE = 'LISTOU_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE'
export const ERRO_AO_LISTAR_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE = 'ERRO_AO_LISTAR_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE'

// teleconsulta
export const ABRIR_TELECONSULTA_NO_PRONTUARIO = 'ABRIR_TELECONSULTA_NO_PRONTUARIO'
export const FECHAR_TELECONSULTA_NO_PRONTUARIO = 'FECHAR_TELECONSULTA_NO_PRONTUARIO'

//diagnosticos
export const LISTAR_DIAGNOSTICOS = 'LISTAR_DIAGNOSTICOS'
export const LISTOU_DIAGNOSTICOS = 'LISTOU_DIAGNOSTICOS'
export const ERRO_AO_LISTAR_DIAGNOSTICOS = 'ERRO_AO_LISTAR_DIAGNOSTICOS'
export const ADICIONOU_DIAGNOSTICO = 'ADICIONOU_DIAGNOSTICO'
export const ERRO_AO_ADICIONAR_DIAGNOSTICO = 'ERRO_AO_ADICIONAR_DIAGNOSTICO'
export const ADICIONAR_DIAGNOSTICO = 'ADICIONAR_DIAGNOSTICO'
export const RECUPEROU_DIAGNOSTICO_PELO_IDENTIFICADOR = 'RECUPEROU_DIAGNOSTICO_PELO_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_DIAGNOSTICO_PELO_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_DIAGNOSTICO_PELO_IDENTIFICADOR'
export const RECUPERAR_DIAGNOSTICO_PELO_IDENTIFICADOR = 'RECUPERAR_DIAGNOSTICO_PELO_IDENTIFICADOR'
export const ALTEROU_DIAGNOSTICO = 'ALTEROU_DIAGNOSTICO'
export const ERRO_AO_ALTERAR_DIAGNOSTICO = 'ERRO_AO_ALTERAR_DIAGNOSTICO'
export const ALTERAR_DIAGNOSTICO = 'ALTERAR_DIAGNOSTICO'
export const REMOVEU_DIAGNOSTICO = 'REMOVEU_DIAGNOSTICO'
export const ERRO_AO_REMOVER_DIAGNOSTICO = 'ERRO_AO_REMOVER_DIAGNOSTICO'
export const REMOVER_DIAGNOSTICO = 'REMOVER_DIAGNOSTICO'


//graus de relações
export const LISTAR_GRAUS_DE_RELACOES = 'LISTAR_GRAUS_DE_RELACOES'
export const LISTOU_GRAUS_DE_RELACOES = 'LISTOU_GRAUS_DE_RELACOES'
export const ERRO_AO_LISTAR_GRAUS_DE_RELACOES = 'ERRO_AO_LISTAR_GRAUS_DE_RELACOES'

//medicamentos do prontuario
export const LISTAR_MEDICAMENTOS_DO_PRONTUARIO = 'LISTAR_MEDICAMENTOS_DO_PRONTUARIO'
export const LISTOU_MEDICAMENTOS_DO_PRONTUARIO = 'LISTOU_MEDICAMENTOS_DO_PRONTUARIO'
export const ERRO_AO_LISTAR_MEDICAMENTOS_DO_PRONTUARIO = 'ERRO_AO_LISTAR_MEDICAMENTOS_DO_PRONTUARIO'
export const ALTERAR_TERMO_DOS_MEDICAMENTOS_DO_PRONTUARIO = 'ALTERAR_TERMO_DOS_MEDICAMENTOS_DO_PRONTUARIO'

//doses 
export const LISTAR_DOSES = 'LISTAR_DOSES'
export const LISTOU_DOSES = 'LISTOU_DOSES'
export const ERRO_AO_LISTAR_DOSES = 'ERRO_AO_LISTAR_DOSES'

//posologias
export const LISTAR_POSOLOGIAS = 'LISTAR_POSOLOGIAS'
export const LISTOU_POSOLOGIAS = 'LISTOU_POSOLOGIAS'
export const ERRO_AO_LISTAR_POSOLOGIAS = 'ERRO_AO_LISTAR_POSOLOGIAS'

//operadoras
export const LISTAR_OPERADORAS = 'LISTAR_OPERADORAS'
export const LISTOU_OPERADORAS = 'LISTOU_OPERADORAS'
export const ERRO_AO_LISTAR_OPERADORAS = 'ERRO_AO_LISTAR_OPERADORAS'
export const ADICIONOU_OPERADORA = 'ADICIONOU_OPERADORA'
export const ERRO_AO_ADICIONAR_OPERADORA = 'ERRO_AO_ADICIONAR_OPERADORA'
export const ADICIONAR_OPERADORA = 'ADICIONAR_OPERADORA'
export const RECUPEROU_OPERADORA_PELO_IDENTIFICADOR = 'RECUPEROU_OPERADORA_PELO_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_OPERADORA_PELO_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_OPERADORA_PELO_IDENTIFICADOR'
export const RECUPERAR_OPERADORA_PELO_IDENTIFICADOR = 'RECUPERAR_OPERADORA_PELO_IDENTIFICADOR'
export const ALTEROU_OPERADORA = 'ALTEROU_OPERADORA'
export const ERRO_AO_ALTERAR_OPERADORA = 'ERRO_AO_ALTERAR_OPERADORA'
export const ALTERAR_OPERADORA = 'ALTERAR_OPERADORA'
export const REMOVEU_OPERADORA = 'REMOVEU_OPERADORA'
export const ERRO_AO_REMOVER_OPERADORA = 'ERRO_AO_REMOVER_OPERADORA'
export const REMOVER_OPERADORA = 'REMOVER_OPERADORA'
export const LISTOU_OPERADORAS_DO_CADASTRO = 'LISTOU_OPERADORAS_DO_CADASTRO'
export const LISTAR_OPERADORAS_DO_CADASTRO = 'LISTAR_OPERADORAS_DO_CADASTRO'
export const ERRO_AO_LISTAR_OPERADORAS_DO_CADASTRO = 'ERRO_AO_LISTAR_OPERADORAS_DO_CADASTRO'

//unidades federativas
export const LISTAR_UNIDADES_FEDERATIVAS = 'LISTAR_UNIDADES_FEDERATIVAS'
export const LISTOU_UNIDADES_FEDERATIVAS = 'LISTOU_UNIDADES_FEDERATIVAS'
export const ERRO_AO_LISTAR_UNIDADES_FEDERATIVAS = 'ERRO_AO_LISTAR_UNIDADES_FEDERATIVAS'

//usuario
export const AUTENTICAR_USUARIO = 'AUTENTICAR_USUARIO'
export const REAUTENTICAR_USUARIO = 'REAUTENTICAR_USUARIO'
export const AUTENTICOU_USUARIO = 'AUTENTICOU_USUARIO'
export const NAO_AUTENTICOU_USUARIO = 'NAO_AUTENTICOU_USUARIO'
export const LOGOUT = 'LOGOUT'
export const ALTERAR_FILTROS_DE_USUARIOS = 'ALTERAR_FILTROS_DE_USUARIOS'
export const LISTAR_USUARIOS = 'LISTAR_USUARIOS'
export const LISTOU_USUARIOS = 'LISTOU_USUARIOS'
export const ERRO_AO_LISTAR_USUARIOS = 'ERRO_AO_LISTAR_USUARIOS'
export const ADICIONAR_USUARIO = 'ADICIONAR_USUARIO'
export const ADICIONOU_USUARIO = 'ADICIONOU_USUARIO'
export const ERRO_AO_ADICIONAR_USUARIO = 'ERRO_AO_ADICIONAR_USUARIO'
export const RECUPERAR_USUARIO_POR_IDENTIFICADOR = 'RECUPERAR_USUARIO_POR_IDENTIFICADOR'
export const RECUPEROU_USUARIO_POR_IDENTIFICADOR = 'RECUPEROU_USUARIO_POR_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_USUARIO_POR_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_USUARIO_POR_IDENTIFICADOR'
export const SALVAR_USUARIO = 'SALVAR_USUARIO'
export const SALVOU_USUARIO = 'SALVOU_USUARIO'
export const ERRO_AO_SALVAR_USUARIO = 'ERRO_AO_SALVAR_USUARIO'
export const RECUPERAR_VALIDADE_DO_TOKEN = 'RECUPERAR_VALIDADE_DO_TOKEN'
export const RECUPEROU_VALIDADE_DO_TOKEN = 'RECUPEROU_VALIDADE_DO_TOKEN'
export const ERRO_AO_RECUPERAR_VALIDADE_DO_TOKEN = 'ERRO_AO_RECUPERAR_VALIDADE_DO_TOKEN'
export const LISTAR_EMPRESAS_HABILITADAS_PARA_O_LOGIN = 'LISTAR_EMPRESAS_HABILITADAS_PARA_O_LOGIN'
export const LISTOU_EMPRESAS_HABILITADAS_PARA_O_LOGIN = 'LISTOU_EMPRESAS_HABILITADAS_PARA_O_LOGIN'
export const ERRO_AO_LISTAR_EMPRESAS_HABILITADAS_PARA_O_LOGIN = 'ERRO_AO_LISTAR_EMPRESAS_HABILITADAS_PARA_O_LOGIN'
export const LISTAR_EMPRESAS_HABILITADAS = 'LISTAR_EMPRESAS_HABILITADAS'
export const LISTOU_EMPRESAS_HABILITADAS = 'LISTOU_EMPRESAS_HABILITADAS'
export const ERRO_AO_LISTAR_EMPRESAS_HABILITADAS = 'ERRO_AO_LISTAR_EMPRESAS_HABILITADAS'
export const CANCELAR_LOGIN = 'CANCELAR_LOGIN'
export const RECUPERAR_LOGIN = 'RECUPERAR_LOGIN'
export const RECUPEROU_LOGIN = 'RECUPEROU_LOGIN'
export const ERRO_AO_RECUPERAR_LOGIN = 'ERRO_AO_RECUPERAR_LOGIN'
export const LISTAR_TIPOS_DE_ATENDIMENTO_DO_USUARIO_LOGADO = 'LISTAR_TIPOS_DE_ATENDIMENTO_DO_USUARIO_LOGADO'
export const LISTOU_TIPOS_DE_ATENDIMENTO_DO_USUARIO_LOGADO = 'LISTOU_TIPOS_DE_ATENDIMENTO_DO_USUARIO_LOGADO'
export const ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO_DO_USUARIO_LOGADO = 'ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO_DO_USUARIO_LOGADO'
export const ALTEROU_STATUS_DO_USUARIO = 'ALTEROU_STATUS_DO_USUARIO'
export const ERRO_AO_ALTERAR_STATUS_DO_USUARIO = 'ERRO_AO_ALTERAR_STATUS_DO_USUARIO'
export const ALTERAR_STATUS_DO_USUARIO = 'ALTERAR_STATUS_DO_USUARIO'
export const RECUPERAR_SENHA = 'RECUPERAR_SENHA'
export const RECUPEROU_SENHA = 'RECUPEROU_SENHA'
export const ERRO_AO_RECUPERAR_SENHA = 'ERRO_AO_RECUPERAR_SENHA'
export const REDEFINIR_SENHA = 'REDEFINIR_SENHA'
export const REDEFINIU_SENHA = 'REDEFINIU_SENHA'
export const ERRO_AO_REDEFINIR_SENHA = 'ERRO_AO_REDEFINIR_SENHA'
export const ALTERAR_UNIDADE_DO_LOGIN = 'ALTERAR_UNIDADE_DO_LOGIN'
export const ALTEROU_UNIDADE_DO_LOGIN = 'ALTEROU_UNIDADE_DO_LOGIN'
export const ERRO_AO_ALTERAR_UNIDADE_DO_LOGIN = 'ERRO_AO_ALTERAR_UNIDADE_DO_LOGIN'

//profissionais de saude
export const LISTAR_PROFISSIONAIS_DE_SAUDE = 'LISTAR_PROFISSIONAIS_DE_SAUDE'
export const LISTOU_PROFISSIONAIS_DE_SAUDE = 'LISTOU_PROFISSIONAIS_DE_SAUDE'
export const ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE = 'ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE'
export const ALTERAR_TERMO_DE_PROFISSIONAIS_DE_SAUDE = 'ALTERAR_TERMO_DE_PROFISSIONAIS_DE_SAUDE'
export const ADICIONAR_PACIENTE_NA_LISTA_DE_ESPERA = 'ADICIONAR_PACIENTE_NA_LISTA_DE_ESPERA'
export const ADICIONOU_PACIENTE_NA_LISTA_DE_ESPERA = 'ADICIONOU_PACIENTE_NA_LISTA_DE_ESPERA'
export const ERRO_AO_ADICIONAR_PACIENTE_NA_LISTA_DE_ESPERA = 'ERRO_AO_ADICIONAR_PACIENTE_NA_LISTA_DE_ESPERA'
export const REMOVER_PACIENTE_NA_LISTA_DE_ESPERA = 'REMOVER_PACIENTE_NA_LISTA_DE_ESPERA'
export const REMOVEU_PACIENTE_NA_LISTA_DE_ESPERA = 'REMOVEU_PACIENTE_NA_LISTA_DE_ESPERA'
export const ERRO_AO_REMOVER_PACIENTE_NA_LISTA_DE_ESPERA = 'ERRO_AO_REMOVER_PACIENTE_NA_LISTA_DE_ESPERA'
export const ALTERAR_PACIENTE_NA_LISTA_DE_ESPERA = 'ALTERAR_PACIENTE_NA_LISTA_DE_ESPERA'
export const ALTEROU_PACIENTE_NA_LISTA_DE_ESPERA = 'ALTEROU_PACIENTE_NA_LISTA_DE_ESPERA'
export const ERRO_AO_ALTERAR_PACIENTE_NA_LISTA_DE_ESPERA = 'ERRO_AO_ALTERAR_PACIENTE_NA_LISTA_DE_ESPERA'
export const SELECIONAR_PACIENTE_DA_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE = 'SELECIONAR_PACIENTE_DA_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE'
export const RECUPERAR_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE = 'RECUPERAR_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE'
export const RECUPEROU_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE = 'RECUPEROU_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE'
export const ERRO_AO_RECUPERAR_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE = 'ERRO_AO_RECUPERAR_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE'
export const ALTERAR_DADOS_SELECIONADOS_DO_AGENDAMENTO_NA_LISTA_DE_ESPERA = 'ALTERAR_DADOS_SELECIONADOS_DO_AGENDAMENTO_NA_LISTA_DE_ESPERA'
export const ALTEROU_DADOS_SELECIONADOS_DO_AGENDAMENTO_NA_LISTA_DE_ESPERA = 'ALTEROU_DADOS_SELECIONADOS_DO_AGENDAMENTO_NA_LISTA_DE_ESPERA'
export const ERRO_AO_ALTERAR_DADOS_SELECIONADOS_DO_AGENDAMENTO_NA_LISTA_DE_ESPERA = 'ERRO_AO_ALTERAR_DADOS_SELECIONADOS_DO_AGENDAMENTO_NA_LISTA_DE_ESPERA'

//secoes
export const ALTERAR_SECAO_DO_PRONTUARIO_SELECIONADA = 'ALTERAR_SECAO_DO_PRONTUARIO_SELECIONADA'

//atendimento
export const INICIAR_ATENDIMENTO = 'INICIAR_ATENDIMENTO'
export const INICIOU_ATENDIMENTO = 'INICIOU_ATENDIMENTO'
export const ERRO_AO_INICIAR_ATENDIMENTO = 'ERRO_AO_INICIAR_ATENDIMENTO'
export const LIMPAR_ATENDIMENTO = 'LIMPAR_ATENDIMENTO'
export const FINALIZAR_ATENDIMENTO = 'FINALIZAR_ATENDIMENTO'
export const FINALIZOU_ATENDIMENTO = 'FINALIZOU_ATENDIMENTO'
export const ERRO_AO_FINALIZAR_ATENDIMENTO = 'ERRO_AO_FINALIZAR_ATENDIMENTO'
export const CANCELAR_ATENDIMENTO = 'CANCELAR_ATENDIMENTO'
export const CANCELOU_ATENDIMENTO = 'CANCELOU_ATENDIMENTO'
export const ERRO_AO_CANCELAR_ATENDIMENTO = 'ERRO_AO_CANCELAR_ATENDIMENTO'
export const SALVAR_SECAO_DO_ATENDIMENTO = 'SALVAR_SECAO_DO_ATENDIMENTO'
export const SALVOU_SECAO_DO_ATENDIMENTO = 'SALVOU_SECAO_DO_ATENDIMENTO'
export const ERRO_AO_SALVAR_SECAO_DO_ATENDIMENTO = 'ERRO_AO_SALVAR_SECAO_DO_ATENDIMENTO'
export const RECUPERAR_ATENDIMENTO = 'RECUPERAR_ATENDIMENTO'
export const RECUPEROU_ATENDIMENTO = 'RECUPEROU_ATENDIMENTO'
export const ERRO_AO_RECUPERAR_ATENDIMENTO = 'ERRO_AO_RECUPERAR_ATENDIMENTO'
export const RECUPERAR_ATENDIMENTO_INICIADO = 'RECUPERAR_ATENDIMENTO_INICIADO'
export const RECUPEROU_ATENDIMENTO_INICIADO = 'RECUPEROU_ATENDIMENTO_INICIADO'
export const ERRO_AO_RECUPERAR_ATENDIMENTO_INICIADO = 'ERRO_AO_RECUPERAR_ATENDIMENTO_INICIADO'

//módulos do usuário
export const LISTAR_TIPOS_DE_ATENDIMENTO_DO_PRONTUARIO = 'LISTAR_TIPOS_DE_ATENDIMENTO_DO_PRONTUARIO'
export const LISTOU_TIPOS_DE_ATENDIMENTO_DO_PRONTUARIO = 'LISTOU_TIPOS_DE_ATENDIMENTO_DO_PRONTUARIO'
export const ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO_DO_PRONTUARIO = 'ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO_DO_PRONTUARIO'

//atendimentos finalizados do pacientes
export const LISTAR_ATENDIMENTOS_FINALIZADOS = 'LISTAR_ATENDIMENTOS_FINALIZADOS'
export const LISTOU_ATENDIMENTOS_FINALIZADOS = 'LISTOU_ATENDIMENTOS_FINALIZADOS'
export const ERRO_AO_LISTAR_ATENDIMENTOS_FINALIZADOS = 'ERRO_AO_LISTAR_ATENDIMENTOS_FINALIZADOS'
export const OCULTAR_DETALHES_DO_ATENDIMENTO = 'OCULTAR_DETALHES_DO_ATENDIMENTO'
export const ADICIONAR_INFORMACOES = 'ADICIONAR_INFORMACOES'
export const ADICIONOU_INFORMACOES = 'ADICIONOU_INFORMACOES'
export const ERRO_AO_ADICIONAR_INFORMACOES = 'ERRO_AO_ADICIONAR_INFORMACOES'
export const ALTERAR_TERMO_DOS_ATENDIMENTOS_FINALIZADOS = 'ALTERAR_TERMO_DOS_ATENDIMENTOS_FINALIZADOS'
export const CARREGAR_DETALHES_DO_ATENDIMENTO_FINALIZADO = 'CARREGAR_DETALHES_DO_ATENDIMENTO_FINALIZADO'
export const CARREGOU_DETALHES_DO_ATENDIMENTO_FINALIZADO = 'CARREGOU_DETALHES_DO_ATENDIMENTO_FINALIZADO'
export const ERRO_AO_CARREGAR_DETALHES_DO_ATENDIMENTO_FINALIZADO = 'ERRO_AO_CARREGAR_DETALHES_DO_ATENDIMENTO_FINALIZADO'
export const MARCAR_ATENDIMENTO_COMO_PRIVADO = 'MARCAR_ATENDIMENTO_COMO_PRIVADO'

//atendimentos iniciados pelo usuario
export const LISTAR_ATENDIMENTOS_INICIADOS = 'LISTAR_ATENDIMENTOS_INICIADOS'
export const LISTOU_ATENDIMENTOS_INICIADOS = 'LISTOU_ATENDIMENTOS_INICIADOS'
export const ERRO_AO_LISTAR_ATENDIMENTOS_INICIADOS = 'ERRO_AO_LISTAR_ATENDIMENTOS_INICIADOS'

//atendimentos finalizados e nao assinados pelo usuario
export const LISTAR_ATENDIMENTOS_NAO_ASSINADOS = 'LISTAR_ATENDIMENTOS_NAO_ASSINADOS'
export const LISTOU_ATENDIMENTOS_NAO_ASSINADOS = 'LISTOU_ATENDIMENTOS_NAO_ASSINADOS'
export const ERRO_AO_LISTAR_ATENDIMENTOS_NAO_ASSINADOS = 'ERRO_AO_LISTAR_ATENDIMENTOS_NAO_ASSINADOS'
export const ASSINAR_ATENDIMENTO_FINALIZADO = 'ASSINAR_ATENDIMENTO_FINALIZADO'

//arquivos exames do atendimento
export const FAZER_DOWNLOAD_DO_EXAME_DO_ATENDIMENTO = 'FAZER_DOWNLOAD_DO_EXAME_DO_ATENDIMENTO'
export const FEZ_DOWNLOAD_DO_EXAME_DO_ATENDIMENTO = 'FEZ_DOWNLOAD_DO_EXAME_DO_ATENDIMENTO'
export const ERRO_AO_FAZER_DOWNLOAD_DO_EXAME_DO_ATENDIMENTO = 'ERRO_AO_FAZER_DOWNLOAD_DO_EXAME_DO_ATENDIMENTO'
export const LISTAR_TIPOS_DE_EXAME = 'LISTAR_TIPOS_DE_EXAME'
export const LISTOU_TIPOS_DE_EXAME = 'LISTOU_TIPOS_DE_EXAME'
export const ERRO_AO_LISTAR_TIPOS_DE_EXAME = 'ERRO_AO_LISTAR_TIPOS_DE_EXAME'

//anexos do atendimento 
export const FAZER_DOWNLOAD_DO_ANEXO_DO_ATENDIMENTO = 'FAZER_DOWNLOAD_DO_ANEXO_DO_ATENDIMENTO'
export const FEZ_DOWNLOAD_DO_ANEXO_DO_ATENDIMENTO = 'FEZ_DOWNLOAD_DO_ANEXO_DO_ATENDIMENTO'
export const ERRO_AO_FAZER_DOWNLOAD_DO_ANEXO_DO_ATENDIMENTO = 'ERRO_AO_FAZER_DOWNLOAD_DO_ANEXO_DO_ATENDIMENTO'

//arquivos
export const ADICIONAR_ARQUIVO = 'ADICIONAR_ARQUIVO'
export const ADICIONOU_ARQUIVO = 'ADICIONOU_ARQUIVO'
export const ERRO_AO_ADICIONAR_ARQUIVO = 'ERRO_AO_ADICIONAR_ARQUIVO'

//tamanhos de paginas
export const LISTAR_TAMANHOS_DE_PAGINAS = 'LISTAR_TAMANHOS_DE_PAGINAS'
export const LISTOU_TAMANHOS_DE_PAGINAS = 'LISTOU_TAMANHOS_DE_PAGINAS'
export const ERRO_AO_LISTAR_TAMANHOS_DE_PAGINAS = 'ERRO_AO_LISTAR_TAMANHOS_DE_PAGINAS'

//download de prescrições
export const FAZER_DOWNLOAD_DA_PRESCRICAO = 'FAZER_DOWNLOAD_DA_PRESCRICAO'
export const FEZ_DOWNLOAD_DA_PRESCRICAO = 'FEZ_DOWNLOAD_DA_PRESCRICAO'
export const ERRO_AO_FAZER_DOWNLOAD_DA_PRESCRICAO = 'ERRO_AO_FAZER_DOWNLOAD_DA_PRESCRICAO'

//download de encaminhamentos
export const FAZER_DOWNLOAD_DO_ENCAMINHAMENTO = 'FAZER_DOWNLOAD_DO_ENCAMINHAMENTO'
export const FEZ_DOWNLOAD_DO_ENCAMINHAMENTO = 'FEZ_DOWNLOAD_DO_ENCAMINHAMENTO'
export const ERRO_AO_FAZER_DOWNLOAD_DO_ENCAMINHAMENTO = 'ERRO_AO_FAZER_DOWNLOAD_DO_ENCAMINHAMENTO'

//intervalo de execucao
export const ADICIONAR_FUNCAO = 'ADICIONAR_FUNCAO'
export const LIMPAR_FUNCOES = 'LIMPAR_FUNCOES'

//profissoes de saude
export const LISTAR_PROFISSOES = 'LISTAR_PROFISSOES'
export const LISTOU_PROFISSOES = 'LISTOU_PROFISSOES'
export const ERRO_AO_LISTAR_PROFISSOES = 'ERRO_AO_LISTAR_PROFISSOES'
export const LISTAR_PROFISSOES_MULTIDISCIPLINARES = 'LISTAR_PROFISSOES_MULTIDISCIPLINARES'
export const LISTOU_PROFISSOES_MULTIDISCIPLINARES = 'LISTOU_PROFISSOES_MULTIDISCIPLINARES'
export const ERRO_AO_LISTAR_PROFISSOES_MULTIDISCIPLINARES = 'ERRO_AO_LISTAR_PROFISSOES_MULTIDISCIPLINARES'
export const LISTAR_PROFISSOES_DO_CADASTRO = 'LISTAR_PROFISSOES_DO_CADASTRO'
export const LISTOU_PROFISSOES_DO_CADASTRO = 'LISTOU_PROFISSOES_DO_CADASTRO'
export const ERRO_AO_LISTAR_PROFISSOES_DO_CADASTRO = 'ERRO_AO_LISTAR_PROFISSOES_DO_CADASTRO'
export const ADICIONOU_PROFISSAO = 'ADICIONOU_PROFISSAO'
export const ERRO_AO_ADICIONAR_PROFISSAO = 'ERRO_AO_ADICIONAR_PROFISSAO'
export const ADICIONAR_PROFISSAO = 'ADICIONAR_PROFISSAO'
export const REMOVER_PROFISSAO = 'REMOVER_PROFISSAO'
export const REMOVEU_PROFISSAO = 'REMOVEU_PROFISSAO'
export const ERRO_AO_REMOVER_PROFISSAO = 'ERRO_AO_REMOVER_PROFISSAO'
export const ERRO_AO_ALTERAR_PROFISSAO = 'ERRO_AO_ALTERAR_PROFISSAO'
export const ALTERAR_PROFISSAO = 'ALTERAR_PROFISSAO'
export const ALTEROU_PROFISSAO = 'ALTEROU_PROFISSAO'
export const RECUPERAR_PROFISSAO_POR_IDENTIFICADOR = 'RECUPERAR_PROFISSAO_POR_IDENTIFICADOR'
export const RECUPEROU_PROFISSAO_POR_IDENTIFICADOR = 'RECUPEROU_PROFISSAO_POR_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_PROFISSAO_POR_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_PROFISSAO_POR_IDENTIFICADOR'
export const ADICIONAR_ESPECIALIDADE = 'ADICIONAR_ESPECIALIDADE'
export const ADICIONOU_ESPECIALIDADE = 'ADICIONOU_ESPECIALIDADE'
export const ERRO_AO_ADICIONAR_ESPECIALIDADE = 'ERRO_AO_ADICIONAR_ESPECIALIDADE'
export const REMOVER_ESPECIALIDADE = 'REMOVER_ESPECIALIDADE'
export const REMOVEU_ESPECIALIDADE = 'REMOVEU_ESPECIALIDADE'
export const ERRO_AO_REMOVER_ESPECIALIDADE = 'ERRO_AO_REMOVER_ESPECIALIDADE'
export const ALTERAR_ESPECIALIDADE = 'ALTERAR_ESPECIALIDADE'
export const ALTEROU_ESPECIALIDADE = 'ALTEROU_ESPECIALIDADE'
export const ERRO_AO_ALTERAR_ESPECIALIDADE = 'ERRO_AO_ALTERAR_ESPECIALIDADE'
export const RECUPERAR_ESPECIALIDADE_POR_IDENTIFICADOR = 'RECUPERAR_ESPECIALIDADE_POR_IDENTIFICADOR'
export const RECUPEROU_ESPECIALIDADE_POR_IDENTIFICADOR = 'RECUPEROU_ESPECIALIDADE_POR_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_ESPECIALIDADE_POR_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_ESPECIALIDADE_POR_IDENTIFICADOR'
export const LISTAR_ESPECIALIDADES = 'LISTAR_ESPECIALIDADES'
export const LISTOU_ESPECIALIDADES = 'LISTOU_ESPECIALIDADES'
export const ERRO_AO_LISTAR_ESPECIALIDADES = 'ERRO_AO_LISTAR_ESPECIALIDADES'
export const RECUPERAR_PROFISSAO_MEDICA = 'RECUPERAR_PROFISSAO_MEDICA'
export const RECUPEROU_PROFISSAO_MEDICA = 'RECUPEROU_PROFISSAO_MEDICA'
export const ERRO_AO_RECUPERAR_PROFISSAO_MEDICA = 'ERRO_AO_RECUPERAR_PROFISSAO_MEDICA'


// perfis
export const LISTAR_PERFIS = 'LISTAR_PERFIS'
export const LISTOU_PERFIS = 'LISTOU_PERFIS'
export const ERRO_AO_LISTAR_PERFIS = 'ERRO_AO_LISTAR_PERFIS'

// perfis do usuario
export const LISTAR_PERFIS_DO_USUARIO = 'LISTAR_PERFIS_DO_USUARIO'
export const LISTOU_PERFIS_DO_USUARIO = 'LISTOU_PERFIS_DO_USUARIO'
export const ERRO_AO_LISTAR_PERFIS_DO_USUARIO = 'ERRO_AO_LISTAR_PERFIS_DO_USUARIO'
export const SELECIONAR_PERFIL_DO_USUARIO_PARA_SER_REMOVIDO = 'SELECIONAR_PERFIL_DO_USUARIO_PARA_SER_REMOVIDO'
export const REMOVER_PERFIL_DO_USUARIO = 'REMOVER_PERFIL_DO_USUARIO'
export const REMOVEU_PERFIL_DO_USUARIO = 'REMOVEU_PERFIL_DO_USUARIO'
export const ERRO_AO_REMOVER_PERFIL_DO_USUARIO = 'ERRO_AO_REMOVER_PERFIL_DO_USUARIO'
export const ADICIONAR_PERFIL_DO_USUARIO = 'ADICIONAR_PERFIL_DO_USUARIO'
export const ADICIONOU_PERFIL_DO_USUARIO = 'ADICIONOU_PERFIL_DO_USUARIO'
export const ERRO_AO_ADICIONAR_PERFIL_DO_USUARIO = 'ERRO_AO_ADICIONAR_PERFIL_DO_USUARIO'
export const EXIBIR_POPUP_REMOVER_PERFIL_DO_USUARIO = 'EXIBIR_POPUP_REMOVER_PERFIL_DO_USUARIO'
export const OCULTAR_POPUP_REMOVER_PERFIL_DO_USUARIO = 'OCULTAR_POPUP_REMOVER_PERFIL_DO_USUARIO'
export const ALTERAR_SENHA_DO_USUARIO = 'ALTERAR_SENHA_DO_USUARIO'
export const ALTEROU_SENHA_DO_USUARIO = 'ALTEROU_SENHA_DO_USUARIO'
export const ERRO_AO_ALTERAR_SENHA_DO_USUARIO = 'ERRO_AO_ALTERAR_SENHA_DO_USUARIO'

//foto do usuario
export const ADICIONAR_FOTO_DO_USUARIO = 'ADICIONAR_FOTO_DO_USUARIO'
export const ADICIONOU_FOTO_DO_USUARIO = 'ADICIONOU_FOTO_DO_USUARIO'
export const ERRO_AO_ADICIONAR_FOTO_DO_USUARIO = 'ERRO_AO_ADICIONAR_FOTO_DO_USUARIO'
export const REMOVER_FOTO_DO_USUARIO = 'REMOVER_FOTO_DO_USUARIO'
export const REMOVEU_FOTO_DO_USUARIO = 'REMOVEU_FOTO_DO_USUARIO'
export const RECUPERAR_FOTO_DO_USUARIO_POR_IDENTIFICADOR = 'RECUPERAR_FOTO_DO_USUARIO_POR_IDENTIFICADOR'
export const RECUPEROU_FOTO_DO_USUARIO_POR_IDENTIFICADOR = 'RECUPEROU_FOTO_DO_USUARIO_POR_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_FOTO_DO_USUARIO_POR_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_FOTO_DO_USUARIO_POR_IDENTIFICADOR'
export const RECUPERAR_FOTO_DO_USUARIO_LOGADO_POR_IDENTIFICADOR = 'RECUPERAR_FOTO_DO_USUARIO_LOGADO_POR_IDENTIFICADOR'
export const RECUPEROU_FOTO_DO_USUARIO_LOGADO_POR_IDENTIFICADOR = 'RECUPEROU_FOTO_DO_USUARIO_LOGADO_POR_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_FOTO_DO_USUARIO_LOGADO_POR_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_FOTO_DO_USUARIO_LOGADO_POR_IDENTIFICADOR'
export const LIMPAR_USUARIO_ATUAL = 'LIMPAR_USUARIO_ATUAL'

//configurações da agenda
export const LISTAR_TIPOS_DE_AGENDAMENTO_DAS_CONFIGURACOES_DA_AGENDA = 'LISTAR_TIPOS_DE_AGENDAMENTO_DAS_CONFIGURACOES_DA_AGENDA'
export const LISTOU_TIPOS_DE_AGENDAMENTO_DAS_CONFIGURACOES_DA_AGENDA = 'LISTOU_TIPOS_DE_AGENDAMENTO_DAS_CONFIGURACOES_DA_AGENDA'
export const ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DAS_CONFIGURACOES_DA_AGENDA = 'ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DAS_CONFIGURACOES_DA_AGENDA'
export const LISTAR_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA = 'LISTAR_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA'
export const LISTOU_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA = 'LISTOU_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA'
export const ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA = 'ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA'
export const ADICIONAR_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL = 'ADICIONAR_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL'
export const ADICIONOU_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL = 'ADICIONOU_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL'
export const ERRO_AO_ADICIONAR_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL = 'ERRO_AO_ADICIONAR_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL'
export const REMOVER_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL = 'REMOVER_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL'
export const REMOVEU_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL = 'REMOVEU_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL'
export const ERRO_AO_REMOVER_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL = 'ERRO_AO_REMOVER_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL'

//medicos
export const LISTAR_MEDICOS = 'LISTAR_MEDICOS'
export const LISTOU_MEDICOS = 'LISTOU_MEDICOS'
export const ERRO_AO_LISTAR_MEDICOS = 'ERRO_AO_LISTAR_MEDICOS'

//enfermeiros
export const LISTAR_ENFERMEIROS = 'LISTAR_ENFERMEIROS'
export const LISTOU_ENFERMEIROS = 'LISTOU_ENFERMEIROS'
export const ERRO_AO_LISTAR_ENFERMEIROS = 'ERRO_AO_LISTAR_ENFERMEIROS'

//tipos de agendamento
export const ADICIONAR_TIPO_DE_AGENDAMENTO = 'ADICIONAR_TIPO_DE_AGENDAMENTO'
export const ADICIONOU_TIPO_DE_AGENDAMENTO = 'ADICIONOU_TIPO_DE_AGENDAMENTO'
export const ERRO_AO_ADICIONAR_TIPO_DE_AGENDAMENTO = 'ERRO_AO_ADICIONAR_TIPO_DE_AGENDAMENTO'
export const RECUPERAR_TIPO_DE_AGENDAMENTO_POR_IDENTIFICADOR = 'RECUPERAR_TIPO_DE_AGENDAMENTO_POR_IDENTIFICADOR'
export const RECUPEROU_TIPO_DE_AGENDAMENTO_POR_IDENTIFICADOR = 'RECUPEROU_TIPO_DE_AGENDAMENTO_POR_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_TIPO_DE_AGENDAMENTO_POR_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_TIPO_DE_AGENDAMENTO_POR_IDENTIFICADOR'
export const SALVAR_TIPO_DE_AGENDAMENTO = 'SALVAR_TIPO_DE_AGENDAMENTO'
export const SALVOU_TIPO_DE_AGENDAMENTO = 'SALVOU_TIPO_DE_AGENDAMENTO'
export const ERRO_AO_SALVAR_TIPO_DE_AGENDAMENTO = 'ERRO_AO_SALVAR_TIPO_DE_AGENDAMENTO'
export const LISTAR_TIPOS_DE_AGENDAMENTO = 'LISTAR_TIPOS_DE_AGENDAMENTO'
export const LISTOU_TIPOS_DE_AGENDAMENTO = 'LISTOU_TIPOS_DE_AGENDAMENTO'
export const ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO = 'ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO'
export const REMOVER_TIPO_DE_AGENDAMENTO = 'REMOVER_TIPO_DE_AGENDAMENTO'
export const REMOVEU_TIPO_DE_AGENDAMENTO = 'REMOVEU_TIPO_DE_AGENDAMENTO'
export const ERRO_AO_REMOVER_TIPO_DE_AGENDAMENTO = 'ERRO_AO_REMOVER_TIPO_DE_AGENDAMENTO'

//agenda
export const SELECIONAR_PROFISSIONAL_DA_AGENDA = 'SELECIONAR_PROFISSIONAL_DA_AGENDA'
export const EXIBIR_POPUP_ADICIONAR_AGENDAMENTO = 'EXIBIR_POPUP_ADICIONAR_AGENDAMENTO'
export const OCULTAR_POPUP_ADICIONAR_AGENDAMENTO = 'OCULTAR_POPUP_ADICIONAR_AGENDAMENTO'
export const RECUPERAR_AGENDA_SEMANAL_DO_PROFISSIONAL = 'RECUPERAR_AGENDA_SEMANAL_DO_PROFISSIONAL'
export const RECUPEROU_AGENDA_SEMANAL_DO_PROFISSIONAL = 'RECUPEROU_AGENDA_SEMANAL_DO_PROFISSIONAL'
export const ERRO_AO_RECUPERAR_AGENDA_SEMANAL_DO_PROFISSIONAL = 'ERRO_AO_RECUPERAR_AGENDA_SEMANAL_DO_PROFISSIONAL'
export const LISTAR_HORARIOS_LIVRES = 'LISTAR_HORARIOS_LIVRES'
export const LISTOU_HORARIOS_LIVRES = 'LISTOU_HORARIOS_LIVRES'
export const ERRO_AO_LISTAR_HORARIOS_LIVRES = 'ERRO_AO_LISTAR_HORARIOS_LIVRES'
export const ALTERAR_VISIBILIADE_DO_POPOVER_DE_HORARIOS_LIVRES = 'ALTERAR_VISIBILIADE_DO_POPOVER_DE_HORARIOS_LIVRES'
export const LIMPAR_HORARIOS_LIVRES = 'LIMPAR_HORARIOS_LIVRES'
export const LIMPAR_DIAS_DO_PROFISSIONAL_DA_AGENDA = 'LIMPAR_DIAS_DO_PROFISSIONAL_DA_AGENDA'
export const SELECIONAR_AGENDAMENTO_DA_AGENDA = 'SELECIONAR_AGENDAMENTO_DA_AGENDA'
export const LISTAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA = 'LISTAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA'
export const LISTOU_PROFISSIONAIS_DE_SAUDE_DA_AGENDA = 'LISTOU_PROFISSIONAIS_DE_SAUDE_DA_AGENDA'
export const ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA = 'ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA'
export const ALTERAR_TERMO_DE_PROFISSIONAIS_DE_SAUDE_DA_AGENDA = 'ALTERAR_TERMO_DE_PROFISSIONAIS_DE_SAUDE_DA_AGENDA'
export const FILTRAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA = 'FILTRAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA'
export const FILTROU_PROFISSIONAIS_DE_SAUDE_DA_AGENDA = 'FILTROU_PROFISSIONAIS_DE_SAUDE_DA_AGENDA'
export const ERRO_AO_FILTRAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA = 'ERRO_AO_FILTRAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA'
export const RECUPERAR_MINIATURA_DA_FOTO_DO_PROFISSIONAL_DE_SAUDE_DA_AGENDA = 'RECUPERAR_MINIATURA_DA_FOTO_DO_PROFISSIONAL_DE_SAUDE_DA_AGENDA'
export const RECUPEROU_MINIATURA_DA_FOTO_DO_PROFISSIONAL_DE_SAUDE_DA_AGENDA = 'RECUPEROU_MINIATURA_DA_FOTO_DO_PROFISSIONAL_DE_SAUDE_DA_AGENDA'
export const ERRO_AO_RECUPERAR_MINIATURA_DA_FOTO_DO_PROFISSIONAL_DE_SAUDE_DA_AGENDA = 'ERRO_AO_RECUPERAR_MINIATURA_DA_FOTO_DO_PROFISSIONAL_DE_SAUDE_DA_AGENDA'
export const EXIBIR_DETALHES_DO_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'EXIBIR_DETALHES_DO_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const OCULTAR_DETALHES_DO_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'OCULTAR_DETALHES_DO_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const EXIBIR_FORMULARIO_DE_ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'EXIBIR_FORMULARIO_DE_ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const OCULTAR_FORMULARIO_DE_ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'OCULTAR_FORMULARIO_DE_ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const SELECIONAR_UNIDADES_DA_AGENDA = 'SELECIONAR_UNIDADES_DA_AGENDA'

// feriados
export const ADICIONAR_FERIADO = 'ADICIONAR_FERIADO'
export const ADICIONOU_FERIADO = 'ADICIONOU_FERIADO'
export const ERRO_AO_ADICIONAR_FERIADO = 'ERRO_AO_ADICIONAR_FERIADO'
export const RECUPERAR_FERIADO_POR_IDENTIFICADOR = 'RECUPERAR_FERIADO_POR_IDENTIFICADOR'
export const RECUPEROU_FERIADO_POR_IDENTIFICADOR = 'RECUPEROU_FERIADO_POR_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_FERIADO_POR_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_FERIADO_POR_IDENTIFICADOR'
export const SALVAR_FERIADO = 'SALVAR_FERIADO'
export const SALVOU_FERIADO = 'SALVOU_FERIADO'
export const ERRO_AO_SALVAR_FERIADO = 'ERRO_AO_SALVAR_FERIADO'
export const SALVAR_SERIE_DE_FERIADOS = 'SALVAR_SERIE_DE_FERIADOS'
export const SALVOU_SERIE_DE_FERIADOS = 'SALVOU_SERIE_DE_FERIADOS'
export const ERRO_AO_SALVAR_SERIE_DE_FERIADOS = 'ERRO_AO_SALVAR_SERIE_DE_FERIADOS'
export const LISTAR_FERIADOS = 'LISTAR_FERIADOS'
export const LISTOU_FERIADOS = 'LISTOU_FERIADOS'
export const ERRO_AO_LISTAR_FERIADOS = 'ERRO_AO_LISTAR_FERIADOS'
export const LIMPAR_FERIADO_ATUAL = 'LIMPAR_FERIADO_ATUAL'
export const EXCLUIR_FERIADO = 'EXCLUIR_FERIADO'
export const EXCLUIU_FERIADO = 'EXCLUIU_FERIADO'
export const ERRO_AO_EXCLUIR_FERIADO = 'ERRO_AO_EXCLUIR_FERIADO'
export const EXCLUIR_SERIE_DE_FERIADOS = 'EXCLUIR_SERIE_DE_FERIADOS'
export const EXCLUIU_SERIE_DE_FERIADOS = 'EXCLUIU_SERIE_DE_FERIADOS'
export const ERRO_AO_EXCLUIR_SERIE_DE_FERIADOS = 'ERRO_AO_EXCLUIR_SERIE_DE_FERIADOS'
export const ALTERAR_TERMOS_FERIADOS = 'ALTERAR_TERMOS_FERIADOS'
export const ALTERAR_PAGINA_DE_FERIADOS = 'ALTERAR_PAGINA_DE_FERIADOS'

//foto dos profissionais de saude
export const RECUPERAR_MINIATURA_DA_FOTO_DO_PROFISSIONAL_DE_SAUDE_POR_IDENTIFICADOR = 'RECUPERAR_MINIATURA_DA_FOTO_DO_PROFISSIONAL_DE_SAUDE_POR_IDENTIFICADOR'
export const RECUPEROU_MINIATURA_DA_FOTO_DO_PROFISSIONAL_DE_SAUDE_POR_IDENTIFICADOR = 'RECUPEROU_MINIATURA_DA_FOTO_DO_PROFISSIONAL_DE_SAUDE_POR_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_MINIATURA_DA_FOTO_DO_PROFISSIONAL_DE_SAUDE_POR_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_MINIATURA_DA_FOTO_DO_PROFISSIONAL_DE_SAUDE_POR_IDENTIFICADOR'

//feriados da agenda do profissional de saude
export const LISTAR_FERIADOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'LISTAR_FERIADOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const LISTOU_FERIADOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'LISTOU_FERIADOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ERRO_AO_LISTAR_FERIADOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ERRO_AO_LISTAR_FERIADOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'

//agendamento
export const SELECIONAR_PACIENTE_DO_AGENDAMENTO = 'SELECIONAR_PACIENTE_DO_AGENDAMENTO'
export const IDENTIFICAR_PACIENTE_SEM_PROGRAMA_NO_AGENDAMENTO = 'IDENTIFICAR_PACIENTE_SEM_PROGRAMA_NO_AGENDAMENTO'
export const ADICIONAR_PROXIMOS_AGENDAMENTOS_DO_AGENDAMENTO = 'ADICIONAR_PROXIMOS_AGENDAMENTOS_DO_AGENDAMENTO'
export const LIMPAR_ALERTAS_DO_AGENDAMENTO = 'LIMPAR_ALERTAS_DO_AGENDAMENTO'
export const LIMPAR_DADOS_DO_PACIENTE_DO_AGENDAMENTO = 'LIMPAR_DADOS_DO_PACIENTE_DO_AGENDAMENTO'
export const LIMPAR_DADOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO = 'LIMPAR_DADOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO'
export const RECUPERAR_CONVENIOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO = 'RECUPERAR_CONVENIOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO'
export const RECUPEROU_CONVENIOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO = 'RECUPEROU_CONVENIOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO'
export const ERRO_AO_RECUPERAR_CONVENIOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO = 'ERRO_AO_RECUPERAR_CONVENIOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO'
export const RECUPERAR_PROGRAMAS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO = 'RECUPERAR_PROGRAMAS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO'
export const RECUPEROU_PROGRAMAS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO = 'RECUPEROU_PROGRAMAS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO'
export const ADICIONAR_AGENDAMENTO = 'ADICIONAR_AGENDAMENTO'
export const ADICIONOU_AGENDAMENTO = 'ADICIONOU_AGENDAMENTO'
export const ERRO_AO_ADICIONAR_AGENDAMENTO = 'ERRO_AO_ADICIONAR_AGENDAMENTO'
export const EXCLUIR_AGENDAMENTO = 'EXCLUIR_AGENDAMENTO'
export const EXCLUIU_AGENDAMENTO = 'EXCLUIU_AGENDAMENTO'
export const ERRO_AO_EXCLUIR_AGENDAMENTO = 'ERRO_AO_EXCLUIR_AGENDAMENTO'
export const CANCELAR_AGENDAMENTO = 'CANCELAR_AGENDAMENTO'
export const CANCELOU_AGENDAMENTO = 'CANCELOU_AGENDAMENTO'
export const ERRO_AO_CANCELAR_AGENDAMENTO = 'ERRO_AO_CANCELAR_AGENDAMENTO'
export const ALTERAR_DADOS_SELECIONADOS_DO_AGENDAMENTO = 'ALTERAR_DADOS_SELECIONADOS_DO_AGENDAMENTO'
export const LIMPAR_DADOS_SELECIONADOS_DO_AGENDAMENTO = 'LIMPAR_DADOS_SELECIONADOS_DO_AGENDAMENTO'
export const SELECIONAR_AGENDAMENTO_PARA_ALTERACAO = 'SELECIONAR_AGENDAMENTO_PARA_ALTERACAO'
export const LIMPAR_AGENDAMENTO_ATUAL = 'LIMPAR_AGENDAMENTO_ATUAL'
export const SALVAR_AGENDAMENTO = 'SALVAR_AGENDAMENTO'
export const SALVOU_AGENDAMENTO = 'SALVOU_AGENDAMENTO'
export const ERRO_AO_SALVAR_AGENDAMENTO = 'ERRO_AO_SALVAR_AGENDAMENTO'
export const CONFIRMAR_AGENDAMENTO = 'CONFIRMAR_AGENDAMENTO'
export const CONFIRMOU_AGENDAMENTO = 'CONFIRMOU_AGENDAMENTO'
export const ERRO_AO_CONFIRMAR_AGENDAMENTO = 'ERRO_AO_CONFIRMAR_AGENDAMENTO'
export const LISTAR_UNIDADES_DA_EMPRESA_DO_AGENDAMENTO = 'LISTAR_UNIDADES_DA_EMPRESA_DO_AGENDAMENTO'
export const LISTOU_UNIDADES_DA_EMPRESA_DO_AGENDAMENTO = 'LISTOU_UNIDADES_DA_EMPRESA_DO_AGENDAMENTO'
export const ERRO_AO_LISTAR_UNIDADES_DA_EMPRESA_DO_AGENDAMENTO = 'ERRO_AO_LISTAR_UNIDADES_DA_EMPRESA_DO_AGENDAMENTO'
export const ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO = 'ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO'
export const ALTEROU_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO = 'ALTEROU_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO'
export const ERRO_AO_ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO = 'ERRO_AO_ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO'
export const ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO = 'ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO'
export const ALTEROU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO = 'ALTEROU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO'
export const ERRO_AO_ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO = 'ERRO_AO_ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO'
export const EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO = 'EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO'
export const EXCLUIU_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO = 'EXCLUIU_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO'
export const ERRO_AO_EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO = 'ERRO_AO_EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO'
export const EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO = 'EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO'
export const EXCLUIU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO = 'EXCLUIU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO'
export const ERRO_AO_EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO = 'ERRO_AO_EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO'
export const ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO = 'ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO'
export const ALTEROU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO = 'ALTEROU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO'
export const ERRO_AO_ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO = 'ERRO_AO_ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO'
export const EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO = 'EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO'
export const ERRO_AO_EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO = 'ERRO_AO_EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO'
export const EXCLUIU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO = 'EXCLUIU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO'
export const ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO = 'ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO'
export const ALTEROU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO = 'ALTEROU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO'
export const ERRO_AO_ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO = 'ERRO_AO_ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO'
export const EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO = 'EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO'
export const EXCLUIU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO = 'EXCLUIU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO'
export const ERRO_AO_EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO = 'ERRO_AO_EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO'

export const RECUPERAR_AGENDAMENTO_PELO_IDENTIFICADOR = 'RECUPERAR_AGENDAMENTO_PELO_IDENTIFICADOR'
export const RECUPEROU_AGENDAMENTO_PELO_IDENTIFICADOR = 'RECUPEROU_AGENDAMENTO_PELO_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_AGENDAMENTO_PELO_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_AGENDAMENTO_PELO_IDENTIFICADOR'

//recorrencias do agendamento
export const LISTAR_RECORRENCIAS_DO_AGENDAMENTO = 'LISTAR_RECORRENCIAS_DO_AGENDAMENTO'
export const LISTOU_RECORRENCIAS_DO_AGENDAMENTO = 'LISTOU_RECORRENCIAS_DO_AGENDAMENTO'
export const ERRO_AO_LISTAR_RECORRENCIAS_DO_AGENDAMENTO = 'ERRO_AO_LISTAR_RECORRENCIAS_DO_AGENDAMENTO'

//calendario
export const ALTERAR_VISUALIZACAO_DO_CALENDARIO = 'ALTERAR_VISUALIZACAO_DO_CALENDARIO'
export const ALTERAR_DATA_DO_CALENDARIO = 'ALTERAR_DATA_DO_CALENDARIO'
export const LIMPAR_DADOS_DO_CALENDARIO = 'LIMPAR_DADOS_DO_CALENDARIO'

//status dos agendamentos
export const LISTAR_STATUS_DOS_AGENDAMENTOS = 'LISTAR_STATUS_DOS_AGENDAMENTOS'
export const LISTOU_STATUS_DOS_AGENDAMENTOS = 'LISTOU_STATUS_DOS_AGENDAMENTOS'
export const ERRO_AO_LISTAR_STATUS_DOS_AGENDAMENTOS = 'ERRO_AO_LISTAR_STATUS_DOS_AGENDAMENTOS'

//detalhes do agendamento
export const EXIBIR_DETALHES_DO_AGENDAMENTO = 'EXIBIR_DETALHES_DO_AGENDAMENTO'
export const OCULTAR_DETALHES_DO_AGENDAMENTO = 'OCULTAR_DETALHES_DO_AGENDAMENTO'

//foto do paciente do agendamento
export const RECUPERAR_MINIATURA_DA_FOTO_DO_PACIENTE_DO_AGENDAMENTO_POR_IDENTIFICADOR = 'RECUPERAR_MINIATURA_DA_FOTO_DO_PACIENTE_DO_AGENDAMENTO_POR_IDENTIFICADOR'
export const RECUPEROU_MINIATURA_DA_FOTO_DO_PACIENTE_DO_AGENDAMENTO_POR_IDENTIFICADOR = 'RECUPEROU_MINIATURA_DA_FOTO_DO_PACIENTE_DO_AGENDAMENTO_POR_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_MINIATURA_DA_FOTO_DO_PACIENTE_DO_AGENDAMENTO_POR_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_MINIATURA_DA_FOTO_DO_PACIENTE_DO_AGENDAMENTO_POR_IDENTIFICADOR'

//motivos de cancelamento dos agendamentos
export const LISTAR_MOTIVOS_DE_CANCELAMENTO_DOS_AGENDAMENTOS = 'LISTAR_MOTIVOS_DE_CANCELAMENTO_DOS_AGENDAMENTOS'
export const LISTOU_MOTIVOS_DE_CANCELAMENTO_DOS_AGENDAMENTOS = 'LISTOU_MOTIVOS_DE_CANCELAMENTO_DOS_AGENDAMENTOS'
export const ERRO_AO_LISTAR_MOTIVOS_DE_CANCELAMENTO_DOS_AGENDAMENTOS = 'ERRO_AO_LISTAR_MOTIVOS_DE_CANCELAMENTO_DOS_AGENDAMENTOS'

//pacientes do agendamento
export const LISTAR_PACIENTES_DO_AGENDAMENTO = 'LISTAR_PACIENTES_DO_AGENDAMENTO'
export const LISTOU_PACIENTES_DO_AGENDAMENTO = 'LISTOU_PACIENTES_DO_AGENDAMENTO'
export const ERRO_AO_LISTAR_PACIENTES_DO_AGENDAMENTO = 'ERRO_AO_LISTAR_PACIENTES_DO_AGENDAMENTO'

//tipos de agendamento do agendamento
export const LISTAR_TIPOS_DE_AGENDAMENTO_DO_AGENDAMENTO = 'LISTAR_TIPOS_DE_AGENDAMENTO_DO_AGENDAMENTO'
export const LISTOU_TIPOS_DE_AGENDAMENTO_DO_AGENDAMENTO = 'LISTOU_TIPOS_DE_AGENDAMENTO_DO_AGENDAMENTO'
export const ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DO_AGENDAMENTO = 'ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DO_AGENDAMENTO'

//confirmacao de agendamento
export const LISTAR_AGENDAMENTOS_PARA_CONFIRMACAO_DE_AGENDAMENTO = 'LISTAR_AGENDAMENTOS_PARA_CONFIRMACAO_DE_AGENDAMENTO'
export const LISTOU_AGENDAMENTOS_PARA_CONFIRMACAO_DE_AGENDAMENTO = 'LISTOU_AGENDAMENTOS_PARA_CONFIRMACAO_DE_AGENDAMENTO'
export const ERRO_AO_LISTAR_AGENDAMENTOS_PARA_CONFIRMACAO_DE_AGENDAMENTO = 'ERRO_AO_LISTAR_AGENDAMENTOS_PARA_CONFIRMACAO_DE_AGENDAMENTO'

//pesquisa de agendamentos
export const LISTAR_AGENDAMENTOS = 'LISTAR_AGENDAMENTOS'
export const LISTOU_AGENDAMENTOS = 'LISTOU_AGENDAMENTOS'
export const ERRO_AO_LISTAR_AGENDAMENTOS = 'ERRO_AO_LISTAR_AGENDAMENTOS'
export const LISTAR_TIPOS_DE_AGENDAMENTO_DA_PESQUISA = 'LISTAR_TIPOS_DE_AGENDAMENTO_DA_PESQUISA'
export const LISTOU_TIPOS_DE_AGENDAMENTO_DA_PESQUISA = 'LISTOU_TIPOS_DE_AGENDAMENTO_DA_PESQUISA'
export const ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DA_PESQUISA = 'ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DA_PESQUISA'

//agendamentos do dia
export const LISTAR_AGENDAMENTOS_DO_DIA = 'LISTAR_AGENDAMENTOS_DO_DIA'
export const LISTOU_AGENDAMENTOS_DO_DIA = 'LISTOU_AGENDAMENTOS_DO_DIA'
export const ERRO_AO_LISTAR_AGENDAMENTOS_DO_DIA = 'ERRO_AO_LISTAR_AGENDAMENTOS_DO_DIA'
export const CONFIRMAR_CHEGADA_DO_PACIENTE = 'CONFIRMAR_CHEGADA_DO_PACIENTE'
export const CONFIRMOU_CHEGADA_DO_PACIENTE = 'CONFIRMOU_CHEGADA_DO_PACIENTE'
export const ERRO_AO_CONFIRMAR_CHEGADA_DO_PACIENTE = 'ERRO_AO_CONFIRMAR_CHEGADA_DO_PACIENTE'
export const INFORMAR_SAIDA_DO_PACIENTE = 'INFORMAR_SAIDA_DO_PACIENTE'
export const INFORMOU_SAIDA_DO_PAICENTE = 'INFORMOU_SAIDA_DO_PAICENTE'
export const ERRO_AO_INFORMAR_SAIDA_DO_PACIENTE = 'ERRO_AO_INFORMAR_SAIDA_DO_PACIENTE'

//pre cadastro de pacientes
export const EXIBIR_POPUP_PRE_CADASTRO_DO_PACIENTES = 'EXIBIR_POPUP_PRE_CADASTRO_DO_PACIENTES'
export const OCULTAR_POPUP_PRE_CADASTRO_DO_PACIENTES = 'OCULTAR_POPUP_PRE_CADASTRO_DE_PACIENTES'
export const ADICIONAR_PRE_CADASTRO_DO_PACIENTE = 'ADICIONAR_PRE_CADASTRO_DO_PACIENTE'
export const ADICIONOU_PRE_CADASTRO_DO_PACIENTE = 'ADICIONOU_PRE_CADASTRO_DO_PACIENTE'
export const ERRO_AO_ADICIONAR_PRE_CADASTRO_DO_PACIENTE = 'ERRO_AO_ADICIONAR_PRE_CADASTRO_DO_PACIENTE'
export const CARREGAR_NOME_DO_PACIENTE_DO_PRE_CADASTRO = 'CARREGAR_NOME_DO_PACIENTE_DO_PRE_CADASTRO'

//motivos para não ter sido atendido
export const LISTAR_MOTIVOS_PARA_NAO_TER_SIDO_ATENDIDO = 'LISTAR_MOTIVOS_PARA_NAO_TER_SIDO_ATENDIDO'
export const LISTOU_MOTIVOS_PARA_NAO_TER_SIDO_ATENDIDO = 'LISTOU_MOTIVOS_PARA_NAO_TER_SIDO_ATENDIDO'
export const ERRO_AO_LISTAR_MOTIVOS_PARA_NAO_TER_SIDO_ATENDIDO = 'ERRO_AO_LISTAR_MOTIVOS_PARA_NAO_TER_SIDO_ATENDIDO'

//motivos de bloqueio da agenda
export const LISTAR_MOTIVOS_DE_BLOQUEIO_DA_AGENDA = 'LISTAR_MOTIVOS_DE_BLOQUEIO_DA_AGENDA'
export const LISTOU_MOTIVOS_DE_BLOQUEIO_DA_AGENDA = 'LISTOU_MOTIVOS_DE_BLOQUEIO_DA_AGENDA'
export const ERRO_AO_LISTAR_MOTIVOS_DE_BLOQUEIO_DA_AGENDA = 'ERRO_AO_LISTAR_MOTIVOS_DE_BLOQUEIO_DA_AGENDA'

//agendamentos aguardando atendimento
export const LISTAR_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO = 'LISTAR_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO'
export const LISTOU_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO = 'LISTOU_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO'
export const ERRO_AO_LISTAR_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO = 'ERRO_AO_LISTAR_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO'
export const REGISTRAR_RELOGIO_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO = 'REGISTRAR_RELOGIO_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO'
export const ATUALIZAR_LISTA_DOS_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO = 'ATUALIZAR_LISTA_DOS_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO'
export const ATUALIZOU_LISTA_DOS_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO = 'ATUALIZOU_LISTA_DOS_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO'
export const ERRO_AO_ATUALIZAR_LISTA_DOS_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO = 'ERRO_AO_ATUALIZAR_LISTA_DOS_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO'

//instruções para agendamento
export const LISTAR_INSTRUCOES_PARA_AGENDAMENTO = 'LISTAR_INSTRUCOES_PARA_AGENDAMENTO'
export const LISTOU_INSTRUCOES_PARA_AGENDAMENTO = 'LISTOU_INSTRUCOES_PARA_AGENDAMENTO'
export const ERRO_AO_LISTAR_INSTRUCOES_PARA_AGENDAMENTO = 'ERRO_AO_LISTAR_INSTRUCOES_PARA_AGENDAMENTO'
export const ADICIONAR_INSTRUCOES_PARA_AGENDAMENTO = 'ADICIONAR_INSTRUCOES_PARA_AGENDAMENTO'
export const ADICIONOU_INSTRUCOES_PARA_AGENDAMENTO = 'ADICIONOU_INSTRUCOES_PARA_AGENDAMENTO'
export const ERRO_AO_ADICIONAR_INSTRUCOES_PARA_AGENDAMENTO = 'ERRO_AO_ADICIONAR_INSTRUCOES_PARA_AGENDAMENTO'
export const ALTERAR_INSTRUCOES_PARA_AGENDAMENTO = 'ALTERAR_INSTRUCOES_PARA_AGENDAMENTO'
export const ALTEROU_INSTRUCOES_PARA_AGENDAMENTO = 'ALTEROU_INSTRUCOES_PARA_AGENDAMENTO'
export const ERRO_AO_ALTERAR_INSTRUCOES_PARA_AGENDAMENTO = 'ERRO_AO_ALTERAR_INSTRUCOES_PARA_AGENDAMENTO'
export const REMOVER_INSTRUCOES_PARA_AGENDAMENTO = 'REMOVER_INSTRUCOES_PARA_AGENDAMENTO'
export const REMOVEU_INSTRUCOES_PARA_AGENDAMENTO = 'REMOVEU_INSTRUCOES_PARA_AGENDAMENTO'
export const ERRO_AO_REMOVER_INSTRUCOES_PARA_AGENDAMENTO = 'ERRO_AO_REMOVER_INSTRUCOES_PARA_AGENDAMENTO'

//motivos de cancelamentos dos programas
export const LISTAR_MOTIVOS_DE_SAIDA_DOS_PROGRAMAS = 'LISTAR_MOTIVOS_DE_SAIDA_DOS_PROGRAMAS'
export const LISTOU_MOTIVOS_DE_SAIDA_DOS_PROGRAMAS = 'LISTOU_MOTIVOS_DE_SAIDA_DOS_PROGRAMAS'
export const ERRO_AO_LISTAR_MOTIVOS_DE_SAIDA_DOS_PROGRAMAS = 'ERRO_AO_LISTAR_MOTIVOS_DE_SAIDA_DOS_PROGRAMAS'

//motivos de cancelamentos do convenio
export const LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_CONVENIO = 'LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_CONVENIO'
export const LISTOU_MOTIVOS_DE_CANCELAMENTO_DO_CONVENIO = 'LISTOU_MOTIVOS_DE_CANCELAMENTO_DO_CONVENIO'
export const ERRO_AO_LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_CONVENIO = 'ERRO_AO_LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_CONVENIO'

//horarios da agenda do profissional de saude
export const LISTAR_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'LISTAR_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const LISTOU_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'LISTOU_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ERRO_AO_LISTAR_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ERRO_AO_LISTAR_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ALTERAR_FILTROS_DAS_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ALTERAR_FILTROS_DAS_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ADICIONAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ADICIONAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ADICIONOU_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ADICIONOU_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ERRO_AO_ADICIONAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ERRO_AO_ADICIONAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ALTERAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ALTERAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ALTEROU_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ALTEROU_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ERRO_AO_ALTERAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ERRO_AO_ALTERAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const EXCLUIR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'EXCLUIR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const EXCLUIU_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'EXCLUIU_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ERRO_AO_EXCLUIR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ERRO_AO_EXCLUIR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ALTERAR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ALTERAR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ALTEROU_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ALTEROU_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ERRO_AO_ALTERAR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ERRO_AO_ALTERAR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const EXCLUIR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'EXCLUIR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ERRO_AO_EXCLUIR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ERRO_AO_EXCLUIR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const EXCLUIU_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'EXCLUIU_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'

//bloqueios na agenda do profissional de saude
export const LISTAR_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'LISTAR_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const LISTOU_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'LISTOU_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ERRO_AO_LISTAR_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ERRO_AO_LISTAR_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const EXIBIR_FORMULARIO_ADICIONAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'EXIBIR_ADICIONAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const OCULTAR_FORMULARIO_ADICIONAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'OCULTAR_ADICIONAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ADICIONAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ADICIONAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ADICIONOU_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ADICIONOU_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ERRO_AO_ADICIONAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ERRO_AO_ADICIONAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const SELECIONAR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'SELECIONAR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ALTEROU_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ALTEROU_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ERRO_AO_ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ERRO_AO_ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ALTEROU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ALTEROU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ERRO_AO_ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ERRO_AO_ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const EXCLUIU_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'EXCLUIU_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ERRO_AO_EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ERRO_AO_EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ERRO_AO_EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ERRO_AO_EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const EXCLUIU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'EXCLUIU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ALTERAR_FILTROS_DOS_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ALTERAR_FILTROS_DOS_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'

//instrucoes de agendamentos do profissional de saude
export const LISTAR_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE = 'LISTAR_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE'
export const LISTOU_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE = 'LISTOU_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE'
export const ERRO_AO_LISTAR_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE = 'ERRO_AO_LISTAR_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE'
export const ALTERAR_FILTROS_DAS_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE = 'ALTERAR_FILTROS_DAS_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE'
export const SELECIONAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE = 'SELECIONAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE'
export const EXIBIR_FORMULARIO_ADICIONAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE = 'EXIBIR_ADICIONAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE'
export const OCULTAR_FORMULARIO_ADICIONAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE = 'OCULTAR_ADICIONAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE'
export const ADICIONAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE = 'ADICIONAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE'
export const ADICIONOU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE = 'ADICIONOU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE'
export const ERRO_AO_ADICIONAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE = 'ERRO_AO_ADICIONAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE'
export const ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE = 'ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE'
export const ALTEROU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE = 'ALTEROU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE'
export const ERRO_AO_ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE = 'ERRO_AO_ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE'
export const EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE = 'EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE'
export const EXCLUIU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE = 'EXCLUIU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE'
export const ERRO_AO_EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE = 'ERRO_AO_EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE'
export const EXIBIR_DETALHES_DA_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE = 'EXIBIR_DETALHES_DA_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE'
export const OCULTAR_DETALHES_DA_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE = 'OCULTAR_DETALHES_DA_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE'
export const EXIBIR_FORMULARIO_DE_ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE = 'EXIBIR_FORMULARIO_DE_ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE'
export const ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE = 'ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE'
export const ALTEROU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE = 'ALTEROU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE'
export const ERRO_AO_ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE = 'ERRO_AO_ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE'
export const EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE = 'EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE'
export const ERRO_AO_EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE = 'ERRO_AO_EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE'
export const EXCLUIU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE = 'EXCLUIU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE'

//vacinas
export const LISTAR_VACINAS = 'LISTAR_VACINAS'
export const LISTOU_VACINAS = 'LISTOU_VACINAS'
export const ERRO_AO_LISTAR_VACINAS = 'ERRO_AO_LISTAR_VACINAS'

//locais de quedas
export const LISTAR_LOCAIS_DE_QUEDAS = 'LISTAR_LOCAIS_DE_QUEDAS'
export const LISTOU_LOCAIS_DE_QUEDAS = 'LISTOU_LOCAIS_DE_QUEDAS'
export const ERRO_AO_LISTAR_LOCAIS_DE_QUEDAS = 'ERRO_AO_LISTAR_LOCAIS_DE_QUEDAS'

//motivos de internacao
export const LISTAR_MOTIVOS_DE_INTERNACAO = 'LISTAR_MOTIVOS_DE_INTERNACAO'
export const LISTOU_MOTIVOS_DE_INTERNACAO = 'LISTOU_MOTIVOS_DE_INTERNACAO'
export const ERRO_AO_LISTAR_MOTIVOS_DE_INTERNACAO = 'ERRO_AO_LISTAR_MOTIVOS_DE_INTERNACAO'
export const LISTAR_MOTIVOS_DE_INTERNACAO_DO_CADASTRO = 'LISTAR_MOTIVOS_DE_INTERNACAO_DO_CADASTRO'
export const LISTOU_MOTIVOS_DE_INTERNACAO_DO_CADASTRO = 'LISTOU_MOTIVOS_DE_INTERNACAO_DO_CADASTRO'
export const ERRO_AO_LISTAR_MOTIVOS_DE_INTERNACAO_DO_CADASTRO = 'ERRO_AO_LISTAR_MOTIVOS_DE_INTERNACAO_DO_CADASTRO'
export const ADICIONAR_MOTIVO_DE_INTERNACAO = 'ADICIONAR_MOTIVO_DE_INTERNACAO'
export const ADICIONOU_MOTIVO_DE_INTERNACAO = 'ADICIONOU_MOTIVO_DE_INTERNACAO'
export const ERRO_AO_ADICIONAR_MOTIVO_DE_INTERNACAO = 'ERRO_AO_ADICIONAR_MOTIVO_DE_INTERNACAO'
export const RECUPERAR_MOTIVO_DE_INTERNACAO_PELO_IDENTIFICADOR = 'RECUPERAR_MOTIVO_DE_INTERNACAO_PELO_IDENTIFICADOR'
export const RECUPEROU_MOTIVO_DE_INTERNACAO_PELO_IDENTIFICADOR = 'RECUPEROU_MOTIVO_DE_INTERNACAO_PELO_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_MOTIVO_DE_INTERNACAO_PELO_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_MOTIVO_DE_INTERNACAO_PELO_IDENTIFICADOR'
export const ALTERAR_MOTIVO_DE_INTERNACAO = 'ALTERAR_MOTIVO_DE_INTERNACAO'
export const ALTEROU_MOTIVO_DE_INTERNACAO = 'ALTEROU_MOTIVO_DE_INTERNACAO'
export const ERRO_AO_ALTERAR_MOTIVO_DE_INTERNACAO = 'ERRO_AO_ALTERAR_MOTIVO_DE_INTERNACAO'
export const REMOVER_MOTIVO_DE_INTERNACAO = 'REMOVER_MOTIVO_DE_INTERNACAO'
export const REMOVEU_MOTIVO_DE_INTERNACAO = 'REMOVEU_MOTIVO_DE_INTERNACAO'
export const ERRO_AO_REMOVER_MOTIVO_DE_INTERNACAO = 'ERRO_AO_REMOVER_MOTIVO_DE_INTERNACAO'

//status de internacoes
export const LISTAR_STATUS_DE_INTERNACOES = 'LISTAR_STATUS_DE_INTERNACOES'
export const LISTOU_STATUS_DE_INTERNACOES = 'LISTOU_STATUS_DE_INTERNACOES'
export const ERRO_AO_LISTAR_STATUS_DE_INTERNACOES = 'ERRO_AO_LISTAR_STATUS_DE_INTERNACOES'

//estabelecimentos de saude
export const LISTAR_ESTABELECIMENTOS_DE_SAUDE = 'LISTAR_ESTABELECIMENTOS_DE_SAUDE'
export const LISTOU_ESTABELECIMENTOS_DE_SAUDE = 'LISTOU_ESTABELECIMENTOS_DE_SAUDE'
export const ERRO_AO_LISTAR_ESTABELECIMENTOS_DE_SAUDE = 'ERRO_AO_LISTAR_ESTABELECIMENTOS_DE_SAUDE'
export const LISTAR_ESTABELECIMENTOS_DE_SAUDE_DO_CADASTRO = 'LISTAR_ESTABELECIMENTOS_DE_SAUDE_DO_CADASTRO'
export const LISTOU_ESTABELECIMENTOS_DE_SAUDE_DO_CADASTRO = 'LISTOU_ESTABELECIMENTOS_DE_SAUDE_DO_CADASTRO'
export const ERRO_AO_LISTAR_ESTABELECIMENTOS_DE_SAUDE_DO_CADASTRO = 'ERRO_AO_LISTAR_ESTABELECIMENTOS_DE_SAUDE_DO_CADASTRO'
export const ADICIONAR_ESTABELECIMENTO_DE_SAUDE = 'ADICIONAR_ESTABELECIMENTO_DE_SAUDE'
export const ADICIONOU_ESTABELECIMENTO_DE_SAUDE = 'ADICIONOU_ESTABELECIMENTO_DE_SAUDE'
export const ERRO_AO_ADICIONAR_ESTABELECIMENTO_DE_SAUDE = 'ERRO_AO_ADICIONAR_ESTABELECIMENTO_DE_SAUDE'
export const RECUPERAR_ESTABELECIMENTO_DE_SAUDE_PELO_IDENTIFICADOR = 'RECUPERAR_ESTABELECIMENTO_DE_SAUDE_PELO_IDENTIFICADOR'
export const RECUPEROU_ESTABELECIMENTO_DE_SAUDE_PELO_IDENTIFICADOR = 'RECUPEROU_ESTABELECIMENTO_DE_SAUDE_PELO_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_ESTABELECIMENTO_DE_SAUDE_PELO_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_ESTABELECIMENTO_DE_SAUDE_PELO_IDENTIFICADOR'
export const ALTERAR_ESTABELECIMENTO_DE_SAUDE = 'ALTERAR_ESTABELECIMENTO_DE_SAUDE'
export const ALTEROU_ESTABELECIMENTO_DE_SAUDE = 'ALTEROU_ESTABELECIMENTO_DE_SAUDE'
export const ERRO_AO_ALTERAR_ESTABELECIMENTO_DE_SAUDE = 'ERRO_AO_ALTERAR_ESTABELECIMENTO_DE_SAUDE'
export const REMOVER_ESTABELECIMENTO_DE_SAUDE = 'REMOVER_ESTABELECIMENTO_DE_SAUDE'
export const REMOVEU_ESTABELECIMENTO_DE_SAUDE = 'REMOVEU_ESTABELECIMENTO_DE_SAUDE'
export const ERRO_AO_REMOVER_ESTABELECIMENTO_DE_SAUDE = 'ERRO_AO_REMOVER_ESTABELECIMENTO_DE_SAUDE'

//motivos de ida ao pronto atendimento
export const LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO = 'LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO'
export const LISTOU_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO = 'LISTOU_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO'
export const ERRO_AO_LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO = 'ERRO_AO_LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO'
export const LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO_DO_CADASTRO = 'LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO_DO_CADASTRO'
export const LISTOU_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO_DO_CADASTRO = 'LISTOU_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO_DO_CADASTRO'
export const ERRO_AO_LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO_DO_CADASTRO = 'ERRO_AO_LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO_DO_CADASTRO'
export const ADICIONAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO = 'ADICIONAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO'
export const ADICIONOU_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO = 'ADICIONOU_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO'
export const ERRO_AO_ADICIONAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO = 'ERRO_AO_ADICIONAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO'
export const RECUPERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO_PELO_IDENTIFICADOR = 'RECUPERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO_PELO_IDENTIFICADOR'
export const RECUPEROU_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO_PELO_IDENTIFICADOR = 'RECUPEROU_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO_PELO_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO_PELO_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO_PELO_IDENTIFICADOR'
export const ALTERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO = 'ALTERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO'
export const ALTEROU_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO = 'ALTEROU_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO'
export const ERRO_AO_ALTERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO = 'ERRO_AO_ALTERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO'
export const REMOVER_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO = 'REMOVER_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO'
export const REMOVEU_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO = 'REMOVEU_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO'
export const ERRO_AO_REMOVER_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO = 'ERRO_AO_REMOVER_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO'

//status de ida ao pronto atendimento
export const LISTAR_STATUS_DE_IDA_AO_PRONTO_ATENDIMENTO = 'LISTAR_STATUS_DE_IDA_AO_PRONTO_ATENDIMENTO'
export const LISTOU_STATUS_DE_IDA_AO_PRONTO_ATENDIMENTO = 'LISTOU_STATUS_DE_IDA_AO_PRONTO_ATENDIMENTO'
export const ERRO_AO_LISTAR_STATUS_DE_IDA_AO_PRONTO_ATENDIMENTO = 'ERRO_AO_LISTAR_STATUS_DE_IDA_AO_PRONTO_ATENDIMENTO'

//antibioticos
export const LISTAR_ANTIBIOTICOS = 'LISTAR_ANTIBIOTICOS'
export const LISTOU_ANTIBIOTICOS = 'LISTOU_ANTIBIOTICOS'
export const ERRO_AO_LISTAR_ANTIBIOTICOS = 'ERRO_AO_LISTAR_ANTIBIOTICOS'

//microrganismos
export const LISTAR_MICRORGANISMOS = 'LISTAR_MICRORGANISMOS'
export const LISTOU_MICRORGANISMOS = 'LISTOU_MICRORGANISMOS'
export const ERRO_AO_LISTAR_MICRORGANISMOS = 'ERRO_AO_LISTAR_MICRORGANISMOS'

//sensibilidades de microrganismos a antibioticos
export const LISTAR_SENSIBILIDADES_DE_MICRORGANISMOS_A_ANTIBIOTICOS = 'LISTAR_SENSIBILIDADES_DE_MICRORGANISMOS_A_ANTIBIOTICOS'
export const LISTOU_SENSIBILIDADES_DE_MICRORGANISMOS_A_ANTIBIOTICOS = 'LISTOU_SENSIBILIDADES_DE_MICRORGANISMOS_A_ANTIBIOTICOS'
export const ERRO_AO_LISTAR_SENSIBILIDADES_DE_MICRORGANISMOS_A_ANTIBIOTICOS = 'ERRO_AO_LISTAR_SENSIBILIDADES_DE_MICRORGANISMOS_A_ANTIBIOTICOS'

//hipoteses diagnosticas do trato urinario
export const LISTAR_HIPOTESES_DIAGNOSTICAS_DO_TRATO_URINARIO = 'LISTAR_HIPOTESES_DIAGNOSTICAS_DO_TRATO_URINARIO'
export const LISTOU_HIPOTESES_DIAGNOSTICAS_DO_TRATO_URINARIO = 'LISTOU_HIPOTESES_DIAGNOSTICAS_DO_TRATO_URINARIO'
export const ERRO_AO_LISTAR_HIPOTESES_DIAGNOSTICAS_DO_TRATO_URINARIO = 'ERRO_AO_LISTAR_HIPOTESES_DIAGNOSTICAS_DO_TRATO_URINARIO'

//sumario de alta
export const ADICIONAR_SUMARIO_DE_ALTA_DA_INTERNACAO = 'ADICIONAR_SUMARIO_DE_ALTA_DA_INTERNACAO'
export const ADICIONOU_SUMARIO_DE_ALTA_DA_INTERNACAO = 'ADICIONOU_SUMARIO_DE_ALTA_DA_INTERNACAO'
export const ERRO_AO_ADICIONAR_SUMARIO_DE_ALTA_DA_INTERNACAO = 'ERRO_AO_ADICIONAR_SUMARIO_DE_ALTA_DA_INTERNACAO'
export const FAZER_DOWNLOAD_DO_SUMARIO_DE_ALTA_DA_INTERNACAO = 'FAZER_DOWNLOAD_DO_SUMARIO_DE_ALTA_DA_INTERNACAO'
export const FEZ_DOWNLOAD_DO_SUMARIO_DE_ALTA_DA_INTERNACAO = 'FEZ_DOWNLOAD_DO_SUMARIO_DE_ALTA_DA_INTERNACAO'
export const ERRO_AO_FAZER_DOWNLOAD_DO_SUMARIO_DE_ALTA_DA_INTERNACAO = 'ERRO_AO_FAZER_DOWNLOAD_DO_SUMARIO_DE_ALTA_DA_INTERNACAO'

//tamanhos de paginas
export const LISTAR_ORIENTACOES_DE_PAGINAS = 'LISTAR_ORIENTACOES_DE_PAGINAS'
export const LISTOU_ORIENTACOES_DE_PAGINAS = 'LISTOU_ORIENTACOES_DE_PAGINAS'
export const ERRO_AO_LISTAR_ORIENTACOES_DE_PAGINAS = 'ERRO_AO_LISTAR_ORIENTACOES_DE_PAGINAS'

//empresa
export const RECUPERAR_EMPRESA = 'RECUPERAR_EMPRESA'
export const RECUPEROU_EMPRESA = 'RECUPEROU_EMPRESA'
export const ERRO_AO_RECUPERAR_EMPRESA = 'ERRO_AO_RECUPERAR_EMPRESA'
export const SALVAR_EMPRESA = 'SALVAR_EMPRESA'
export const SALVOU_EMPRESA = 'SALVOU_EMPRESA'
export const ERRO_AO_SALVAR_EMPRESA = 'ERRO_AO_SALVAR_EMPRESA'

//foto da empresa logada
export const ADICIONAR_FOTO_DA_EMPRESA = 'ADICIONAR_FOTO_DA_EMPRESA'
export const ADICIONOU_FOTO_DA_EMPRESA = 'ADICIONOU_FOTO_DA_EMPRESA'
export const ERRO_AO_ADICIONAR_FOTO_DA_EMPRESA = 'ERRO_AO_ADICIONAR_FOTO_DA_EMPRESA'
export const REMOVER_FOTO_DA_EMPRESA = 'REMOVER_FOTO_DA_EMPRESA'
export const REMOVEU_FOTO_DA_EMPRESA = 'REMOVEU_FOTO_DA_EMPRESA'
export const RECUPERAR_FOTO_DA_EMPRESA_LOGADA = 'RECUPERAR_FOTO_DA_EMPRESA_LOGADA'
export const RECUPEROU_FOTO_DA_EMPRESA_LOGADA = 'RECUPEROU_FOTO_DA_EMPRESA_LOGADA'
export const ERRO_AO_RECUPERAR_FOTO_DA_EMPRESA_LOGADA = 'ERRO_AO_RECUPERAR_FOTO_DA_EMPRESA_LOGADA'

//foto da empresa
export const RECUPERAR_FOTO_DA_EMPRESA_POR_IDENTIFICADOR_PUBLICO = 'RECUPERAR_FOTO_DA_EMPRESA_POR_IDENTIFICADOR_PUBLICO'
export const RECUPEROU_FOTO_DA_EMPRESA_POR_IDENTIFICADOR_PUBLICO = 'RECUPEROU_FOTO_DA_EMPRESA_POR_IDENTIFICADOR_PUBLICO'
export const ERRO_AO_RECUPERAR_FOTO_DA_EMPRESA_POR_IDENTIFICADOR_PUBLICO = 'ERRO_AO_RECUPERAR_FOTO_DA_EMPRESA_POR_IDENTIFICADOR_PUBLICO'

//unidades da empresa
export const ADICIONAR_UNIDADE_DA_EMPRESA = 'ADICIONAR_UNIDADE_DA_EMPRESA'
export const ADICIONOU_UNIDADE_DA_EMPRESA = 'ADICIONOU_UNIDADE_DA_EMPRESA'
export const ERRO_AO_ADICIONAR_UNIDADE_DA_EMPRESA = 'ERRO_AO_ADICIONAR_UNIDADE_DA_EMPRESA'
export const SALVAR_UNIDADE_DA_EMPRESA = 'SALVAR_UNIDADE_DA_EMPRESA'
export const SALVOU_UNIDADE_DA_EMPRESA = 'SALVOU_UNIDADE_DA_EMPRESA'
export const ERRO_AO_SALVAR_UNIDADE_DA_EMPRESA = 'ERRO_AO_SALVAR_UNIDADE_DA_EMPRESA'
export const LISTAR_UNIDADES_DA_EMPRESA = 'LISTAR_UNIDADES_DA_EMPRESA'
export const LISTOU_UNIDADES_DA_EMPRESA = 'LISTOU_UNIDADES_DA_EMPRESA'
export const ERRO_AO_LISTAR_UNIDADES_DA_EMPRESA = 'ERRO_AO_LISTAR_UNIDADES_DA_EMPRESA'
export const REMOVER_UNIDADE_DA_EMPRESA = 'REMOVER_UNIDADE_DA_EMPRESA'
export const REMOVEU_UNIDADE_DA_EMPRESA = 'REMOVEU_UNIDADE_DA_EMPRESA'
export const ERRO_AO_REMOVER_UNIDADE_DA_EMPRESA = 'ERRO_AO_REMOVER_UNIDADE_DA_EMPRESA'
export const ATIVAR_UNIDADE_DA_EMPRESA = 'ATIVAR_UNIDADE_DA_EMPRESA'
export const ATIVOU_UNIDADE_DA_EMPRESA = 'ATIVOU_UNIDADE_DA_EMPRESA'
export const ERRO_AO_ATIVAR_UNIDADE_DA_EMPRESA = 'ERRO_AO_ATIVAR_UNIDADE_DA_EMPRESA'
export const DESATIVAR_UNIDADE_DA_EMPRESA = 'DESATIVAR_UNIDADE_DA_EMPRESA'
export const DESATIVOU_UNIDADE_DA_EMPRESA = 'DESATIVOU_UNIDADE_DA_EMPRESA'
export const ERRO_AO_DESATIVAR_UNIDADE_DA_EMPRESA = 'ERRO_AO_DESATIVAR_UNIDADE_DA_EMPRESA'
export const RECUPERAR_ENDERECO_DA_UNIDADE_POR_CEP = 'RECUPERAR_ENDERECO_DA_UNIDADE_POR_CEP'
export const RECUPEROU_ENDERECO_DA_UNIDADE_POR_CEP = 'RECUPEROU_ENDERECO_DA_UNIDADE_POR_CEP'
export const ERRO_AO_RECUPERAR_ENDERECO_DA_UNIDADE_POR_CEP = 'ERRO_AO_RECUPERAR_ENDERECO_DA_UNIDADE_POR_CEP'
export const SELECIONAR_UNIDADE = 'SELECIONAR_UNIDADE'

//unidades do usuario
export const LISTAR_UNIDADES_DO_USUARIO = 'LISTAR_UNIDADES_DO_USUARIO'
export const LISTOU_UNIDADES_DO_USUARIO = 'LISTOU_UNIDADES_DO_USUARIO'
export const ERRO_AO_LISTAR_UNIDADES_DO_USUARIO = 'ERRO_AO_LISTAR_UNIDADES_DO_USUARIO'
export const ADICIONAR_UNIDADE_DO_USUARIO = 'ADICIONAR_UNIDADE_DO_USUARIO'
export const ADICIONOU_UNIDADE_DO_USUARIO = 'ADICIONOU_UNIDADE_DO_USUARIO'
export const ERRO_AO_ADICIONAR_UNIDADE_DO_USUARIO = 'ERRO_AO_ADICIONAR_UNIDADE_DO_USUARIO'
export const REMOVER_UNIDADE_DO_USUARIO = 'REMOVER_UNIDADE_DO_USUARIO'
export const REMOVEU_UNIDADE_DO_USUARIO = 'REMOVEU_UNIDADE_DO_USUARIO'
export const ERRO_AO_REMOVER_UNIDADE_DO_USUARIO = 'ERRO_AO_REMOVER_UNIDADE_DO_USUARIO'
export const LISTAR_UNIDADES_DA_EMPRESA_DO_USUARIO = 'LISTAR_UNIDADES_DA_EMPRESA_DO_USUARIO'
export const LISTOU_UNIDADES_DA_EMPRESA_DO_USUARIO = 'LISTOU_UNIDADES_DA_EMPRESA_DO_USUARIO'
export const ERRO_AO_LISTAR_UNIDADES_DA_EMPRESA_DO_USUARIO = 'ERRO_AO_LISTAR_UNIDADES_DA_EMPRESA_DO_USUARIO'
export const SELECIONAR_UNIDADE_DA_EMPRESA_DO_USUARIO = 'SELECIONAR_UNIDADE_DA_EMPRESA_DO_USUARIO'

//tipos de atendimento do usuário
export const LISTAR_TIPOS_DE_ATENDIMENTO = 'LISTAR_TIPOS_DE_ATENDIMENTO'
export const LISTOU_TIPOS_DE_ATENDIMENTO = 'LISTOU_TIPOS_DE_ATENDIMENTO'
export const ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO = 'ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO'
export const LISTAR_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO = 'LISTAR_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO'
export const LISTOU_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO = 'LISTOU_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO'
export const ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO = 'ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO'
export const HABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO = 'HABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO'
export const HABILITOU_TIPO_DE_ATENDIMENTO_DO_USUARIO = 'HABILITOU_TIPO_DE_ATENDIMENTO_DO_USUARIO'
export const ERRO_AO_HABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO = 'ERRO_AO_HABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO'
export const DESABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO = 'DESABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO'
export const DESABILITOU_TIPO_DE_ATENDIMENTO_DO_USUARIO = 'DESABILITOU_TIPO_DE_ATENDIMENTO_DO_USUARIO'
export const ERRO_AO_DESABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO = 'ERRO_AO_DESABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO'

//tipos de horários da agenda do usuário
export const LISTAR_TIPOS_DE_HORARIOS = 'LISTAR_TIPOS_DE_HORARIOS'
export const LISTOU_TIPOS_DE_HORARIOS = 'LISTOU_TIPOS_DE_HORARIOS'
export const ERRO_AO_LISTAR_TIPOS_DE_HORARIOS = 'ERRO_AO_LISTAR_TIPOS_DE_HORARIOS'

//configurações do usuário
export const ALTERAR_CONFIGURACAO_DO_USUARIO = 'ALTERAR_CONFIGURACAO_DO_USUARIO'
export const ALTEROU_CONFIGURACAO_DO_USUARIO = 'ALTEROU_CONFIGURACAO_DO_USUARIO'
export const ERRO_AO_ALTERAR_CONFIGURACAO_DO_USUARIO = 'ERRO_AO_ALTERAR_CONFIGURACAO_DO_USUARIO'
export const RECUPERAR_CONFIGURACOES_DO_USUARIO_LOGADO = 'RECUPERAR_CONFIGURACOES_DO_USUARIO_LOGADO'
export const RECUPEROU_CONFIGURACOES_DO_USUARIO_LOGADO = 'RECUPEROU_CONFIGURACOES_DO_USUARIO_LOGADO'
export const ERRO_AO_RECUPERAR_CONFIGURACOES_DO_USUARIO_LOGADO = 'ERRO_AO_RECUPERAR_CONFIGURACOES_DO_USUARIO_LOGADO'
export const RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO_LOGADO = 'RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO_LOGADO'
export const RECUPEROU_CONFIGURACOES_VIGENTES_DO_USUARIO_LOGADO = 'RECUPEROU_CONFIGURACOES_VIGENTES_DO_USUARIO_LOGADO'
export const ERRO_AO_RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO_LOGADO = 'ERRO_AO_RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO_LOGADO'
export const RECUPERAR_CONFIGURACOES_DO_USUARIO = 'RECUPERAR_CONFIGURACOES_DO_USUARIO'
export const RECUPEROU_CONFIGURACOES_DO_USUARIO = 'RECUPEROU_CONFIGURACOES_DO_USUARIO'
export const ERRO_AO_RECUPERAR_CONFIGURACOES_DO_USUARIO = 'ERRO_AO_RECUPERAR_CONFIGURACOES_DO_USUARIO'
export const RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO = 'RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO'
export const RECUPEROU_CONFIGURACOES_VIGENTES_DO_USUARIO = 'RECUPEROU_CONFIGURACOES_VIGENTES_DO_USUARIO'
export const ERRO_AO_RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO = 'ERRO_AO_RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO'

//unidades do usuario logado
export const LISTAR_UNIDADES_DO_USUARIO_LOGADO = 'LISTAR_UNIDADES_DO_USUARIO_LOGADO'
export const LISTOU_UNIDADES_DO_USUARIO_LOGADO = 'LISTOU_UNIDADES_DO_USUARIO_LOGADO'
export const ERRO_AO_LISTAR_UNIDADES_DO_USUARIO_LOGADO = 'ERRO_AO_LISTAR_UNIDADES_DO_USUARIO_LOGADO'

//unidades do usuario do horario da agenda
export const LISTAR_UNIDADES_DO_USUARIO_DO_HORARIO_DA_AGENDA = 'LISTAR_UNIDADES_DO_USUARIO_DO_HORARIO_DA_AGENDA'
export const LISTOU_UNIDADES_DO_USUARIO_DO_HORARIO_DA_AGENDA = 'LISTOU_UNIDADES_DO_USUARIO_DO_HORARIO_DA_AGENDA'
export const ERRO_AO_LISTAR_UNIDADES_DO_USUARIO_DO_HORARIO_DA_AGENDA = 'ERRO_AO_LISTAR_UNIDADES_DO_USUARIO_DO_HORARIO_DA_AGENDA'

//tipos de internacao
export const LISTAR_TIPOS_DE_INTERNACAO = 'LISTAR_TIPOS_DE_INTERNACAO'
export const LISTOU_TIPOS_DE_INTERNACAO = 'LISTOU_TIPOS_DE_INTERNACAO'
export const ERRO_AO_LISTAR_TIPOS_DE_INTERNACAO = 'ERRO_AO_LISTAR_TIPOS_DE_INTERNACAO'

//resumo de internacoes
export const GERAR_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO = 'GERAR_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO'
export const GEROU_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO = 'GEROU_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO'
export const ERRO_AO_GERAR_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO = 'ERRO_AO_GERAR_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO'
export const GERAR_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO = 'GERAR_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO'
export const GEROU_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO = 'GEROU_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO'
export const ERRO_AO_GERAR_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO = 'ERRO_AO_GERAR_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO'
export const LISTAR_PROGRAMAS_DO_RELATORIO_RESUMO_DE_INTERNACOES = 'LISTAR_PROGRAMAS_DO_RELATORIO_RESUMO_DE_INTERNACOES'
export const LISTOU_PROGRAMAS_DO_RELATORIO_RESUMO_DE_INTERNACOES = 'LISTOU_PROGRAMAS_DO_RELATORIO_RESUMO_DE_INTERNACOES'
export const ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_RESUMO_DE_INTERNACOES = 'ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_RESUMO_DE_INTERNACOES'
export const SELECIONAR_OPERADORA_DO_RELATORIO_RESUMO_DE_INTERNACOES = 'SELECIONAR_OPERADORA_DO_RELATORIO_RESUMO_DE_INTERNACOES'
export const FAZER_DOWNLOAD_DE_INTERNACOES_POR_FRATURA_DE_FEMUR = 'FAZER_DOWNLOAD_DE_INTERNACOES_POR_FRATURA_DE_FEMUR'
export const FEZ_DOWNLOAD_DE_INTERNACOES_POR_FRATURA_DE_FEMUR = 'FEZ_DOWNLOAD_DE_INTERNACOES_POR_FRATURA_DE_FEMUR'
export const ERRO_AO_FAZER_DOWNLOAD_DE_INTERNACOES_POR_FRATURA_DE_FEMUR = 'ERRO_AO_FAZER_DOWNLOAD_DE_INTERNACOES_POR_FRATURA_DE_FEMUR'

//relatorio de idas ao pronto atendimento
export const LISTAR_PROGRAMAS_DO_RELATORIO_DE_IDAS_AO_PRONTO_ATENDIMENTO = 'LISTAR_PROGRAMAS_DO_RELATORIO_DE_IDAS_AO_PRONTO_ATENDIMENTO'
export const LISTOU_PROGRAMAS_DO_RELATORIO_DE_IDAS_AO_PRONTO_ATENDIMENTO = 'LISTOU_PROGRAMAS_DO_RELATORIO_DE_IDAS_AO_PRONTO_ATENDIMENTO'
export const ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_IDAS_AO_PRONTO_ATENDIMENTO = 'ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_IDAS_AO_PRONTO_ATENDIMENTO'
export const SELECIONAR_OPERADORA_DO_RELATORIO_DE_IDAS_AO_PRONTO_ATENDIMENTO = 'SELECIONAR_OPERADORA_DO_RELATORIO_DE_IDAS_AO_PRONTO_ATENDIMENTO'
export const FAZER_DOWNLOAD_DE_IDAS_AO_PRONTO_ATENDIMENTO = 'FAZER_DOWNLOAD_DE_IDAS_AO_PRONTO_ATENDIMENTO'
export const FEZ_DOWNLOAD_DE_IDAS_AO_PRONTO_ATENDIMENTO = 'FEZ_DOWNLOAD_DE_IDAS_AO_PRONTO_ATENDIMENTO'
export const ERRO_AO_FAZER_DOWNLOAD_DE_IDAS_AO_PRONTO_ATENDIMENTO = 'ERRO_AO_FAZER_DOWNLOAD_DE_IDAS_AO_PRONTO_ATENDIMENTO'
export const ALTERAR_FILTROS_DO_RELATORIO_DE_IDAS_AO_PRONTO_ATENDIMENTO = 'ALTERAR_FILTROS_DO_RELATORIO_DE_IDAS_AO_PRONTO_ATENDIMENTO'

//relatorio de internacoes
export const LISTAR_PROGRAMAS_DO_RELATORIO_DE_INTERNACOES = 'LISTAR_PROGRAMAS_DO_RELATORIO_DE_INTERNACOES'
export const LISTOU_PROGRAMAS_DO_RELATORIO_DE_INTERNACOES = 'LISTOU_PROGRAMAS_DO_RELATORIO_DE_INTERNACOES'
export const ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_INTERNACOES = 'ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_INTERNACOES'
export const SELECIONAR_OPERADORA_DO_RELATORIO_DE_INTERNACOES = 'SELECIONAR_OPERADORA_DO_RELATORIO_DE_INTERNACOES'
export const FAZER_DOWNLOAD_DE_INTERNACOES = 'FAZER_DOWNLOAD_DE_INTERNACOES'
export const FEZ_DOWNLOAD_DE_INTERNACOES = 'FEZ_DOWNLOAD_DE_INTERNACOES'
export const ERRO_AO_FAZER_DOWNLOAD_DE_INTERNACOES = 'ERRO_AO_FAZER_DOWNLOAD_DE_INTERNACOES'
export const ALTERAR_FILTROS_DO_RELATORIO_DE_INTERNACOES = 'ALTERAR_FILTROS_DO_RELATORIO_DE_INTERNACOES'

//relatorio de reinternacoes
export const LISTAR_PROGRAMAS_DO_RELATORIO_DE_REINTERNACOES = 'LISTAR_PROGRAMAS_DO_RELATORIO_DE_REINTERNACOES'
export const LISTOU_PROGRAMAS_DO_RELATORIO_DE_REINTERNACOES = 'LISTOU_PROGRAMAS_DO_RELATORIO_DE_REINTERNACOES'
export const ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_REINTERNACOES = 'ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_REINTERNACOES'
export const SELECIONAR_OPERADORA_DO_RELATORIO_DE_REINTERNACOES = 'SELECIONAR_OPERADORA_DO_RELATORIO_DE_REINTERNACOES'
export const FAZER_DOWNLOAD_DE_REINTERNACOES = 'FAZER_DOWNLOAD_DE_REINTERNACOES'
export const FEZ_DOWNLOAD_DE_REINTERNACOES = 'FEZ_DOWNLOAD_DE_REINTERNACOES'
export const ERRO_AO_FAZER_DOWNLOAD_DE_REINTERNACOES = 'ERRO_AO_FAZER_DOWNLOAD_DE_REINTERNACOES'
export const ALTERAR_FILTROS_DO_RELATORIO_DE_REINTERNACOES = 'ALTERAR_FILTROS_DO_RELATORIO_DE_REINTERNACOES'

//relatorio de pacientes
export const LISTAR_OPERADORAS_DO_RELATORIO_DE_PACIENTES = 'LISTAR_OPERADORAS_DO_RELATORIO_DE_PACIENTES'
export const LISTOU_OPERADORAS_DO_RELATORIO_DE_PACIENTES = 'LISTOU_OPERADORAS_DO_RELATORIO_DE_PACIENTES'
export const ERRO_AO_LISTAR_OPERADORAS_DO_RELATORIO_DE_PACIENTES = 'ERRO_AO_LISTAR_OPERADORAS_DO_RELATORIO_DE_PACIENTES'
export const SELECIONAR_OPERADORA_DO_RELATORIO_DE_PACIENTES = 'SELECIONAR_OPERADORA_DO_RELATORIO_DE_PACIENTES'
export const LISTOU_MEDICOS_DO_RELATORIO_DE_PACIENTES = 'LISTOU_MEDICOS_DO_RELATORIO_DE_PACIENTES'
export const ERRO_AO_LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES = 'ERRO_AO_LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES'
export const LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES = 'LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES'
export const LISTOU_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES = 'LISTOU_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES'
export const ERRO_AO_LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES = 'ERRO_AO_LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES'
export const LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES = 'LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES'
export const LIMPAR_FILTROS_DO_RELATORIO_DE_PACIENTES = 'LIMPAR_FILTROS_DO_RELATORIO_DE_PACIENTES'
export const FAZER_DOWNLOAD_DE_PACIENTES = 'FAZER_DOWNLOAD_DE_PACIENTES'
export const FEZ_DOWNLOAD_DE_PACIENTES = 'FEZ_DOWNLOAD_DE_PACIENTES'
export const ERRO_AO_FAZER_DOWNLOAD_DE_PACIENTES = 'ERRO_AO_FAZER_DOWNLOAD_DE_PACIENTES'
export const LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES = 'LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES'
export const LISTOU_PROGRAMAS_DO_RELATORIO_DE_PACIENTES = 'LISTOU_PROGRAMAS_DO_RELATORIO_DE_PACIENTES'
export const ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES = 'ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES'
export const LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES = 'LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES'
export const LISTOU_CELULAS_DO_RELATORIO_DE_PACIENTES = 'LISTOU_CELULAS_DO_RELATORIO_DE_PACIENTES'
export const ERRO_AO_LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES = 'ERRO_AO_LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES'

//relatorio de pacientes do programa
export const LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA = 'LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA'
export const LISTOU_PROGRAMAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA = 'LISTOU_PROGRAMAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA'
export const ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA = 'ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA'
export const LISTOU_MEDICOS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA = 'LISTOU_MEDICOS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA'
export const ERRO_AO_LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA = 'ERRO_AO_LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA'
export const LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA = 'LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA'
export const LISTOU_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA = 'LISTOU_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA'
export const ERRO_AO_LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA = 'ERRO_AO_LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA'
export const LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA = 'LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA'
export const LIMPAR_FILTROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA = 'LIMPAR_FILTROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA'
export const FAZER_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA = 'FAZER_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA'
export const FEZ_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA = 'FEZ_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA'
export const ERRO_AO_FAZER_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA = 'ERRO_AO_FAZER_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA'
export const LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA = 'LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA'
export const LISTOU_CELULAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA = 'LISTOU_CELULAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA'
export const ERRO_AO_LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA = 'ERRO_AO_LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA'

//vias de administracao de medicamentos
export const LISTAR_VIAS_DE_ADMINISTRACAO_DE_MEDICAMENTOS = 'LISTAR_VIAS_DE_ADMINISTRACAO_DE_MEDICAMENTOS'
export const LISTOU_VIAS_DE_ADMINISTRACAO_DE_MEDICAMENTOS = 'LISTOU_VIAS_DE_ADMINISTRACAO_DE_MEDICAMENTOS'
export const ERRO_AO_LISTAR_VIAS_DE_ADMINISTRACAO_DE_MEDICAMENTOS = 'ERRO_AO_LISTAR_VIAS_DE_ADMINISTRACAO_DE_MEDICAMENTOS'

// relatorio de atendimentos
export const LISTAR_PROGRAMAS_DO_RELATORIO_DE_ATENDIMENTOS = 'LISTAR_PROGRAMAS_DO_RELATORIO_DE_ATENDIMENTOS'
export const LISTOU_PROGRAMAS_DO_RELATORIO_DE_ATENDIMENTOS = 'LISTOU_PROGRAMAS_DO_RELATORIO_DE_ATENDIMENTOS'
export const ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_ATENDIMENTOS = 'ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_ATENDIMENTOS'
export const SELECIONAR_OPERADORA_DO_RELATORIO_DE_ATENDIMENTOS = 'SELECIONAR_OPERADORA_DO_RELATORIO_DE_ATENDIMENTOS'
export const FEZ_DOWNLOAD_DE_ATENDIMENTOS = 'FEZ_DOWNLOAD_DE_ATENDIMENTOS'
export const ERRO_AO_FAZER_DOWNLOAD_DE_ATENDIMENTOS = 'ERRO_AO_FAZER_DOWNLOAD_DE_ATENDIMENTOS'
export const FAZER_DOWNLOAD_DE_ATENDIMENTOS = 'FAZER_DOWNLOAD_DE_ATENDIMENTOS'

//arquivos adicionais do atendimento
export const FAZER_DOWNLOAD_DO_ARQUIVO_ADICIONAL_DO_ATENDIMENTO = 'FAZER_DOWNLOAD_DO_ARQUIVO_ADICIONAL_DO_ATENDIMENTO'
export const FEZ_DOWNLOAD_DO_ARQUIVO_ADICIONAL_DO_ATENDIMENTO = 'FEZ_DOWNLOAD_DO_ARQUIVO_ADICIONAL_DO_ATENDIMENTO'
export const ERRO_AO_FAZER_DOWNLOAD_DO_ARQUIVO_ADICIONAL_DO_ATENDIMENTO = 'ERRO_AO_FAZER_DOWNLOAD_DO_ARQUIVO_ADICIONAL_DO_ATENDIMENTO'

//arquivos de video consulta 
export const FAZER_DOWNLOAD_DO_ARQUIVO_DE_VIDEO_CONSULTA = 'FAZER_DOWNLOAD_DO_ARQUIVO_DE_VIDEO_CONSULTA'
export const FEZ_DOWNLOAD_DO_ARQUIVO_DE_VIDEO_CONSULTA = 'FEZ_DOWNLOAD_DO_ARQUIVO_DE_VIDEO_CONSULTA'
export const ERRO_AO_FAZER_DOWNLOAD_DO_ARQUIVO_DE_VIDEO_CONSULTA = 'ERRO_AO_FAZER_DOWNLOAD_DO_ARQUIVO_DE_VIDEO_CONSULTA'

//tags do paciente
export const LISTAR_TAGS_DE_PACIENTES = 'LISTAR_TAGS_DE_PACIENTES'
export const LISTOU_TAGS_DE_PACIENTES = 'LISTOU_TAGS_DE_PACIENTES'
export const ERRO_AO_LISTAR_TAGS_DE_PACIENTES = 'ERRO_AO_LISTAR_TAGS_DE_PACIENTES'

//relatorio de idas ao pronto atendimento
export const LISTAR_PROFISSOES_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS = 'LISTAR_PROFISSOES_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS'
export const LISTOU_PROFISSOES_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS = 'LISTOU_PROFISSOES_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS'
export const ERRO_AO_LISTAR_PROFISSOES_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS = 'ERRO_AO_LISTAR_PROFISSOES_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS'
export const FAZER_DOWNLOAD_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS = 'FAZER_DOWNLOAD_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS'
export const FEZ_DOWNLOAD_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS = 'FEZ_DOWNLOAD_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS'
export const ERRO_AO_FAZER_DOWNLOAD_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS = 'ERRO_AO_FAZER_DOWNLOAD_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS'

// importacao de pacientes
export const IMPORTAR_PACIENTES = 'IMPORTAR_PACIENTES'
export const IMPORTOU_PACIENTES = 'IMPORTOU_PACIENTES'
export const ERRO_AO_IMPORTAR_PACIENTES = 'ERRO_AO_IMPORTAR_PACIENTES'
export const LIMPAR_IMPORTACAO_DE_PACIENTES = 'LIMPAR_IMPORTACAO_DE_PACIENTES'

//medicamentos
export const LISTAR_MEDICAMENTOS = 'LISTAR_MEDICAMENTOS'
export const LISTOU_MEDICAMENTOS = 'LISTOU_MEDICAMENTOS'
export const ERRO_AO_LISTAR_MEDICAMENTOS = 'ERRO_AO_LISTAR_MEDICAMENTOS'
export const ADICIONAR_MEDICAMENTO = 'ADICIONAR_MEDICAMENTO'
export const ADICIONOU_MEDICAMENTO = 'ADICIONOU_MEDICAMENTO'
export const ERRO_AO_ADICIONAR_MEDICAMENTO = 'ERRO_AO_ADICIONAR_MEDICAMENTO'
export const REMOVER_MEDICAMENTO = 'REMOVER_MEDICAMENTO'
export const REMOVEU_MEDICAMENTO = 'REMOVEU_MEDICAMENTO'
export const ERRO_AO_REMOVER_MEDICAMENTO = 'ERRO_AO_REMOVER_MEDICAMENTO'
export const ALTERAR_MEDICAMENTO = 'ALTERAR_MEDICAMENTO'
export const ALTEROU_MEDICAMENTO = 'ALTEROU_MEDICAMENTO'
export const ERRO_AO_ALTERAR_MEDICAMENTO = 'ERRO_AO_ALTERAR_MEDICAMENTO'
export const LISTAR_CONCENTRACOES_DO_MEDICAMENTO = 'LISTAR_CONCENTRACOES_DO_MEDICAMENTO'
export const LISTOU_CONCENTRACOES_DO_MEDICAMENTO = 'LISTOU_CONCENTRACOES_DO_MEDICAMENTO'
export const ERRO_AO_LISTAR_CONCENTRACOES_DO_MEDICAMENTO = 'ERRO_AO_LISTAR_CONCENTRACOES_DO_MEDICAMENTO'
export const ADICIONAR_CONCENTRACAO_DO_MEDICAMENTO = 'ADICIONAR_CONCENTRACAO_DO_MEDICAMENTO'
export const ADICIONOU_CONCENTRACAO_DO_MEDICAMENTO = 'ADICIONOU_CONCENTRACAO_DO_MEDICAMENTO'
export const ERRO_AO_ADICIONAR_CONCENTRACAO_DO_MEDICAMENTO = 'ERRO_AO_ADICIONAR_CONCENTRACAO_DO_MEDICAMENTO'
export const REMOVER_CONCENTRACAO_DO_MEDICAMENTO = 'REMOVER_CONCENTRACAO_DO_MEDICAMENTO'
export const REMOVEU_CONCENTRACAO_DO_MEDICAMENTO = 'REMOVEU_CONCENTRACAO_DO_MEDICAMENTO'
export const ERRO_AO_REMOVER_CONCENTRACAO_DO_MEDICAMENTO = 'ERRO_AO_REMOVER_CONCENTRACAO_DO_MEDICAMENTO'
export const ALTERAR_CONCENTRACAO_DO_MEDICAMENTO = 'ALTERAR_CONCENTRACAO_DO_MEDICAMENTO'
export const ALTEROU_CONCENTRACAO_DO_MEDICAMENTO = 'ALTEROU_CONCENTRACAO_DO_MEDICAMENTO'
export const ERRO_AO_ALTERAR_CONCENTRACAO_DO_MEDICAMENTO = 'ERRO_AO_ALTERAR_CONCENTRACAO_DO_MEDICAMENTO'
export const RECUPERAR_MEDICAMENTO_POR_IDENTIFICADOR = 'RECUPERAR_MEDICAMENTO_POR_IDENTIFICADOR'
export const RECUPEROU_MEDICAMENTO_POR_IDENTIFICADOR = 'RECUPEROU_MEDICAMENTO_POR_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_MEDICAMENTO_POR_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_MEDICAMENTO_POR_IDENTIFICADOR'
export const ALTERAR_STATUS_DO_MEDICAMENTO = 'ALTERAR_STATUS_DO_MEDICAMENTO'
export const ALTEROU_STATUS_DO_MEDICAMENTO = 'ALTEROU_STATUS_DO_MEDICAMENTO'
export const ERRO_AO_ALTERAR_STATUS_DO_MEDICAMENTO = 'ERRO_AO_ALTERAR_STATUS_DO_MEDICAMENTO'

//imagens
export const REMOVER_IMAGEM_DA_MEMORIA_POR_IDENTIFICADOR = 'LIMPAR_IMAGEM_DA_MEMORIA_POR_IDENTIFICADOR'

//niveis de saude relativa
export const LISTAR_NIVEIS_DE_SAUDE_RELATIVA = 'LISTAR_NIVEIS_DE_SAUDE_RELATIVA'
export const LISTOU_NIVEIS_DE_SAUDE_RELATIVA = 'LISTOU_NIVEIS_DE_SAUDE_RELATIVA'
export const ERRO_AO_LISTAR_NIVEIS_DE_SAUDE_RELATIVA = 'ERRO_AO_LISTAR_NIVEIS_DE_SAUDE_RELATIVA'

//verificação de rede
export const ALTERAR_STATUS_DE_CONEXAO_DE_REDE = 'ALTERAR_STATUS_DE_CONEXAO_DE_REDE'
export const ALTEROU_STATUS_DE_CONEXAO_DE_REDE = 'ALTEROU_STATUS_DE_CONEXAO_DE_REDE'

//wizard de novo agendamento
export const SELECIONAR_PACIENTE_DO_WIZARD_DE_AGENDAMENTO = 'SELECIONAR_PACIENTE_DO_WIZARD_DE_AGENDAMENTO'
export const SELECIONOU_PACIENTE_DO_WIZARD_DE_AGENDAMENTO = 'SELECIONOU_PACIENTE_DO_WIZARD_DE_AGENDAMENTO'
export const ERRO_AO_SELECIONAR_PACIENTE_DO_WIZARD_DE_AGENDAMENTO = 'ERRO_AO_SELECIONAR_PACIENTE_DO_WIZARD_DE_AGENDAMENTO'
export const RECUPERAR_CONVENIOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO = 'RECUPERAR_CONVENIOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO'
export const RECUPEROU_CONVENIOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO = 'RECUPEROU_CONVENIOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO'
export const ERRO_AO_RECUPERAR_CONVENIOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO = 'ERRO_AO_RECUPERAR_CONVENIOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO'
export const ADICIONAR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO = 'ADICIONAR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO'
export const ADICIONOU_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO = 'ADICIONOU_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO'
export const ERRO_AO_ADICIONAR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO = 'ERRO_AO_ADICIONAR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO'
export const EXCLUIR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO = 'EXCLUIR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO'
export const EXCLUIU_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO = 'EXCLUIU_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO'
export const ERRO_AO_EXCLUIR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO = 'ERRO_AO_EXCLUIR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO'
export const SELECIONAR_PROFISSIONAL_DO_WIZARD_DE_AGENDAMENTO = 'SELECIONAR_PROFISSIONAL_DO_WIZARD_DE_AGENDAMENTO'
export const SELECIONAR_CONVENIO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO = 'SELECIONAR_CONVENIO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO'
export const SELECIONAR_TIPO_DO_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO = 'SELECIONAR_TIPO_DO_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO'
export const LISTAR_UNIDADES_DA_EMPRESA_DO_WIZARD_DE_AGENDAMENTO = 'LISTAR_UNIDADES_DA_EMPRESA_DO_WIZARD_DE_AGENDAMENTO'
export const LISTOU_UNIDADES_DA_EMPRESA_DO_WIZARD_DE_AGENDAMENTO = 'LISTOU_UNIDADES_DA_EMPRESA_DO_WIZARD_DE_AGENDAMENTO'
export const ERRO_AO_LISTAR_UNIDADES_DA_EMPRESA_DO_WIZARD_DE_AGENDAMENTO = 'ERRO_AO_LISTAR_UNIDADES_DA_EMPRESA_DO_WIZARD_DE_AGENDAMENTO'
export const LISTAR_PROFISSIONAIS_DE_SAUDE_DO_WIZARD_DE_AGENDAMENTO = 'LISTAR_PROFISSIONAIS_DE_SAUDE_DO_WIZARD_DE_AGENDAMENTO'
export const LISTOU_PROFISSIONAIS_DE_SAUDE_DO_WIZARD_DE_AGENDAMENTO = 'LISTOU_PROFISSIONAIS_DE_SAUDE_DO_WIZARD_DE_AGENDAMENTO'
export const ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE_DO_WIZARD_DE_AGENDAMENTO = 'ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE_DO_WIZARD_DE_AGENDAMENTO'
export const LISTAR_PROFISSOES_DO_WIZARD_DE_AGENDAMENTO = 'LISTAR_PROFISSOES_DO_WIZARD_DE_AGENDAMENTO'
export const LISTOU_PROFISSOES_DO_WIZARD_DE_AGENDAMENTO = 'LISTOU_PROFISSOES_DO_WIZARD_DE_AGENDAMENTO'
export const ERRO_AO_LISTAR_PROFISSOES_DO_WIZARD_DE_AGENDAMENTO = 'ERRO_AO_LISTAR_PROFISSOES_DO_WIZARD_DE_AGENDAMENTO'
export const LISTAR_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO = 'LISTAR_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO'
export const LISTOU_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO = 'LISTOU_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO'
export const ERRO_AO_LISTAR_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO = 'ERRO_AO_LISTAR_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO'
export const ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO = 'ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO'
export const ADICIONOU_PRE_CADASTRO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO = 'ADICIONOU_PRE_CADASTRO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO'
export const ERRO_AO_ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO = 'ERRO_AO_ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO'
export const LISTAR_TIPOS_DE_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO = 'LISTAR_TIPOS_DE_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO'
export const LISTOU_TIPOS_DE_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO = 'LISTOU_TIPOS_DE_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO'
export const ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO = 'ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO'
export const LISTAR_PACIENTES_DO_WIZARD_DE_AGENDAMENTO = 'LISTAR_PACIENTES_DO_WIZARD_DE_AGENDAMENTO'
export const LISTOU_PACIENTES_DO_WIZARD_DE_AGENDAMENTO = 'LISTOU_PACIENTES_DO_WIZARD_DE_AGENDAMENTO'
export const ERRO_AO_LISTAR_PACIENTES_DO_WIZARD_DE_AGENDAMENTO = 'ERRO_AO_LISTAR_PACIENTES_DO_WIZARD_DE_AGENDAMENTO'
export const SELECIONAR_CONTATOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO = 'SELECIONAR_CONTATOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO'
export const SELECIONAR_ESPECIALIDADE_DO_PROFISSIONAL_DO_WIZARD_DE_AGENDAMENTO = 'SELECIONAR_ESPECIALIDADE_DO_PROFISSIONAL_DO_WIZARD_DE_AGENDAMENTO'
export const SELECIONAR_PROFISSAO_DO_WIZARD_DE_AGENDAMENTO = 'SELECIONAR_PROFISSAO_DO_WIZARD_DE_AGENDAMENTO'
export const LIMPAR_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO = 'LIMPAR_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO'
export const LIMPAR_DADOS_DO_WIZARD_DE_AGENDAMENTO = 'LIMPAR_DADOS_DO_WIZARD_DE_AGENDAMENTO'
export const LIMPAR_DADOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO = 'LIMPAR_DADOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO'

//recuperar número de versao
export const RECUPERAR_NUMERO_DE_VERSAO = 'RECUPERAR_NUMERO_DE_VERSAO'
export const RECUPEROU_NUMERO_DE_VERSAO = 'RECUPEROU_NUMERO_DE_VERSAO'
export const ERRO_AO_RECUPERAR_NUMERO_DE_VERSAO = 'ERRO_AO_RECUPERAR_NUMERO_DE_VERSAO'

//programas
export const LISTAR_PROGRAMAS = 'LISTAR_PROGRAMAS'
export const LISTOU_PROGRAMAS = 'LISTOU_PROGRAMAS'
export const ERRO_AO_LISTAR_PROGRAMAS = 'ERRO_AO_LISTAR_PROGRAMAS'
export const ADICIONOU_PROGRAMA = 'ADICIONOU_PROGRAMA'
export const ERRO_AO_ADICIONAR_PROGRAMA = 'ERRO_AO_ADICIONAR_PROGRAMA'
export const ADICIONAR_PROGRAMA = 'ADICIONAR_PROGRAMA'
export const RECUPEROU_PROGRAMA_PELO_IDENTIFICADOR = 'RECUPEROU_PROGRAMA_PELO_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_PROGRAMA_PELO_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_PROGRAMA_PELO_IDENTIFICADOR'
export const RECUPERAR_PROGRAMA_PELO_IDENTIFICADOR = 'RECUPERAR_PROGRAMA_PELO_IDENTIFICADOR'
export const ALTEROU_PROGRAMA = 'ALTEROU_PROGRAMA'
export const ERRO_AO_ALTERAR_PROGRAMA = 'ERRO_AO_ALTERAR_PROGRAMA'
export const ALTERAR_PROGRAMA = 'ALTERAR_PROGRAMA'
export const REMOVEU_PROGRAMA = 'REMOVEU_PROGRAMA'
export const ERRO_AO_REMOVER_PROGRAMA = 'ERRO_AO_REMOVER_PROGRAMA'
export const REMOVER_PROGRAMA = 'REMOVER_PROGRAMA'

//associacoes
export const LISTAR_ASSOCIACOES = 'LISTAR_ASSOCIACOES'
export const LISTOU_ASSOCIACOES = 'LISTOU_ASSOCIACOES'
export const ERRO_AO_LISTAR_ASSOCIACOES = 'ERRO_AO_LISTAR_ASSOCIACOES'
export const ADICIONOU_ASSOCIACAO = 'ADICIONOU_ASSOCIACAO'
export const ERRO_AO_ADICIONAR_ASSOCIACAO = 'ERRO_AO_ADICIONAR_ASSOCIACAO'
export const ADICIONAR_ASSOCIACAO = 'ADICIONAR_ASSOCIACAO'
export const RECUPEROU_ASSOCIACAO_PELO_IDENTIFICADOR = 'RECUPEROU_ASSOCIACAO_PELO_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_ASSOCIACAO_PELO_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_ASSOCIACAO_PELO_IDENTIFICADOR'
export const RECUPERAR_ASSOCIACAO_PELO_IDENTIFICADOR = 'RECUPERAR_ASSOCIACAO_PELO_IDENTIFICADOR'
export const ALTEROU_ASSOCIACAO = 'ALTEROU_ASSOCIACAO'
export const ERRO_AO_ALTERAR_ASSOCIACAO = 'ERRO_AO_ALTERAR_ASSOCIACAO'
export const ALTERAR_ASSOCIACAO = 'ALTERAR_ASSOCIACAO'
export const REMOVEU_ASSOCIACAO = 'REMOVEU_ASSOCIACAO'
export const ERRO_AO_REMOVER_ASSOCIACAO = 'ERRO_AO_REMOVER_ASSOCIACAO'
export const REMOVER_ASSOCIACAO = 'REMOVER_ASSOCIACAO'
export const LISTOU_ASSOCIACOES_DO_CADASTRO = 'LISTOU_ASSOCIACOES_DO_CADASTRO'
export const LISTAR_ASSOCIACOES_DO_CADASTRO = 'LISTAR_ASSOCIACOES_DO_CADASTRO'
export const ERRO_AO_LISTAR_ASSOCIACOES_DO_CADASTRO = 'ERRO_AO_LISTAR_ASSOCIACOES_DO_CADASTRO'

//profissionais externos
export const LISTAR_PROFISSIONAIS_EXTERNOS = 'LISTAR_PROFISSIONAIS_EXTERNOS'
export const LISTOU_PROFISSIONAIS_EXTERNOS = 'LISTOU_PROFISSIONAIS_EXTERNOS'
export const ERRO_AO_LISTAR_PROFISSIONAIS_EXTERNOS = 'ERRO_AO_LISTAR_PROFISSIONAIS_EXTERNOS'
export const ADICIONOU_PROFISSIONAL_EXTERNO = 'ADICIONOU_PROFISSIONAL_EXTERNO'
export const ERRO_AO_ADICIONAR_PROFISSIONAL_EXTERNO = 'ERRO_AO_ADICIONAR_PROFISSIONAL_EXTERNO'
export const ADICIONAR_PROFISSIONAL_EXTERNO = 'ADICIONAR_PROFISSIONAL_EXTERNO'
export const RECUPEROU_PROFISSIONAL_EXTERNO_PELO_IDENTIFICADOR = 'RECUPEROU_PROFISSIONAL_EXTERNO_PELO_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_PROFISSIONAL_EXTERNO_PELO_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_PROFISSIONAL_EXTERNO_PELO_IDENTIFICADOR'
export const RECUPERAR_PROFISSIONAL_EXTERNO_PELO_IDENTIFICADOR = 'RECUPERAR_PROFISSIONAL_EXTERNO_PELO_IDENTIFICADOR'
export const ALTEROU_PROFISSIONAL_EXTERNO = 'ALTEROU_PROFISSIONAL_EXTERNO'
export const ERRO_AO_ALTERAR_PROFISSIONAL_EXTERNO = 'ERRO_AO_ALTERAR_PROFISSIONAL_EXTERNO'
export const ALTERAR_PROFISSIONAL_EXTERNO = 'ALTERAR_PROFISSIONAL_EXTERNO'
export const REMOVEU_PROFISSIONAL_EXTERNO = 'REMOVEU_PROFISSIONAL_EXTERNO'
export const ERRO_AO_REMOVER_PROFISSIONAL_EXTERNO = 'ERRO_AO_REMOVER_PROFISSIONAL_EXTERNO'
export const REMOVER_PROFISSIONAL_EXTERNO = 'REMOVER_PROFISSIONAL_EXTERNO'

//encaminhamentos
export const LISTAR_ENCAMINHAMENTOS = 'LISTAR_ENCAMINHAMENTOS'
export const LISTOU_ENCAMINHAMENTOS = 'LISTOU_ENCAMINHAMENTOS'
export const ERRO_AO_LISTAR_ENCAMINHAMENTOS = 'ERRO_AO_LISTAR_ENCAMINHAMENTOS'
export const LISTAR_STATUS_DE_ENCAMINHAMENTO = 'LISTAR_STATUS_DE_ENCAMINHAMENTO'
export const LISTOU_STATUS_DE_ENCAMINHAMENTO = 'LISTOU_STATUS_DE_ENCAMINHAMENTO'
export const ERRO_AO_LISTAR_STATUS_DE_ENCAMINHAMENTO = 'ERRO_AO_LISTAR_STATUS_DE_ENCAMINHAMENTO'
export const LISTAR_TIPOS_DE_ENCAMINHAMENTO = 'LISTAR_TIPOS_DE_ENCAMINHAMENTO'
export const LISTOU_TIPOS_DE_ENCAMINHAMENTO = 'LISTOU_TIPOS_DE_ENCAMINHAMENTO'
export const ERRO_AO_LISTAR_TIPOS_DE_ENCAMINHAMENTO = 'ERRO_AO_LISTAR_TIPOS_DE_ENCAMINHAMENTO'
export const ALTERAR_STATUS_DO_ENCAMINHAMENTO = 'ALTERAR_STATUS_DO_ENCAMINHAMENTO'
export const ALTEROU_STATUS_DO_ENCAMINHAMENTO = 'ALTEROU_STATUS_DO_ENCAMINHAMENTO'
export const ERRO_AO_ALTERAR_STATUS_DO_ENCAMINHAMENTO = 'ERRO_AO_ALTERAR_STATUS_DO_ENCAMINHAMENTO'

//tipos de atendimento
export const RECUPERAR_VERSAO_DO_TIPO_DE_ATENDIMENTO = 'RECUPERAR_VERSAO_DO_TIPO_DE_ATENDIMENTO'
export const RECUPEROU_VERSAO_DO_TIPO_DE_ATENDIMENTO = 'RECUPEROU_VERSAO_DO_TIPO_DE_ATENDIMENTO'
export const ERRO_AO_RECUPERAR_VERSAO_DO_TIPO_DE_ATENDIMENTO = 'ERRO_AO_RECUPERAR_VERSAO_DO_TIPO_DE_ATENDIMENTO'
export const RECUPERAR_TIPO_DE_ATENDIMENTO_POR_IDENTIFICADOR = 'RECUPERAR_TIPO_DE_ATENDIMENTO_POR_IDENTIFICADOR'
export const RECUPEROU_TIPO_DE_ATENDIMENTO_POR_IDENTIFICADOR = 'RECUPEROU_TIPO_DE_ATENDIMENTO_POR_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_TIPO_DE_ATENDIMENTO_POR_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_TIPO_DE_ATENDIMENTO_POR_IDENTIFICADOR'

//grupos de cuidado
export const LISTAR_GRUPOS_DE_CUIDADO = 'LISTAR_GRUPOS_DE_CUIDADO'
export const LISTOU_GRUPOS_DE_CUIDADO = 'LISTOU_GRUPOS_DE_CUIDADO'
export const ERRO_AO_LISTAR_GRUPOS_DE_CUIDADO = 'ERRO_AO_LISTAR_GRUPOS_DE_CUIDADO'
export const ADICIONOU_GRUPO_DE_CUIDADO = 'ADICIONOU_GRUPO_DE_CUIDADO'
export const ERRO_AO_ADICIONAR_GRUPO_DE_CUIDADO = 'ERRO_AO_ADICIONAR_GRUPO_DE_CUIDADO'
export const ADICIONAR_GRUPO_DE_CUIDADO = 'ADICIONAR_GRUPO_DE_CUIDADO'
export const RECUPEROU_GRUPO_DE_CUIDADO_PELO_IDENTIFICADOR = 'RECUPEROU_GRUPO_DE_CUIDADO_PELO_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_GRUPO_DE_CUIDADO_PELO_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_GRUPO_DE_CUIDADO_PELO_IDENTIFICADOR'
export const RECUPERAR_GRUPO_DE_CUIDADO_PELO_IDENTIFICADOR = 'RECUPERAR_GRUPO_DE_CUIDADO_PELO_IDENTIFICADOR'
export const ALTEROU_GRUPO_DE_CUIDADO = 'ALTEROU_GRUPO_DE_CUIDADO'
export const ERRO_AO_ALTERAR_GRUPO_DE_CUIDADO = 'ERRO_AO_ALTERAR_GRUPO_DE_CUIDADO'
export const ALTERAR_GRUPO_DE_CUIDADO = 'ALTERAR_GRUPO_DE_CUIDADO'
export const REMOVEU_GRUPO_DE_CUIDADO = 'REMOVEU_GRUPO_DE_CUIDADO'
export const ERRO_AO_REMOVER_GRUPO_DE_CUIDADO = 'ERRO_AO_REMOVER_GRUPO_DE_CUIDADO'
export const REMOVER_GRUPO_DE_CUIDADO = 'REMOVER_GRUPO_DE_CUIDADO'
export const LISTOU_GRUPOS_DE_CUIDADO_DO_CADASTRO = 'LISTOU_GRUPOS_DE_CUIDADO_DO_CADASTRO'
export const LISTAR_GRUPOS_DE_CUIDADO_DO_CADASTRO = 'LISTAR_GRUPOS_DE_CUIDADO_DO_CADASTRO'
export const ERRO_AO_LISTAR_GRUPOS_DE_CUIDADO_DO_CADASTRO = 'ERRO_AO_LISTAR_GRUPOS_DE_CUIDADO_DO_CADASTRO'
export const ALTEROU_STATUS_DO_GRUPO_DE_CUIDADO = 'ALTEROU_STATUS_DO_GRUPO_DE_CUIDADO'
export const ALTERAR_STATUS_DO_GRUPO_DE_CUIDADO = 'ALTERAR_STATUS_DO_GRUPO_DE_CUIDADO'
export const ERRO_AO_ALTERAR_STATUS_DO_GRUPO_DE_CUIDADO = 'ERRO_AO_ALTERAR_STATUS_DO_GRUPO_DE_CUIDADO'

//escala zarit
export const LISTAR_FREQUENCIAS_NA_ESCALA_ZARIT = 'LISTAR_FREQUENCIAS_NA_ESCALA_ZARIT'
export const LISTOU_FREQUENCIAS_NA_ESCALA_ZARIT = 'LISTOU_FREQUENCIAS_NA_ESCALA_ZARIT'
export const ERRO_AO_LISTAR_FREQUENCIAS_NA_ESCALA_ZARIT = 'ERRO_AO_LISTAR_FREQUENCIAS_NA_ESCALA_ZARIT'
export const LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_NA_ESCALA_ZARIT = 'LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_NA_ESCALA_ZARIT'
export const LISTOU_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_NA_ESCALA_ZARIT = 'LISTOU_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_NA_ESCALA_ZARIT'
export const ERRO_AO_LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_NA_ESCALA_ZARIT = 'ERRO_AO_LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_NA_ESCALA_ZARIT'

//sorologias
export const LISTAR_RESULTADOS_HBSAG = 'LISTAR_RESULTADOS_HBSAG'
export const LISTOU_RESULTADOS_HBSAG = 'LISTOU_RESULTADOS_HBSAG'
export const ERRO_AO_LISTAR_RESULTADOS_HBSAG = 'ERRO_AO_LISTAR_RESULTADOS_HBSAG'
export const LISTAR_RESULTADOS_ANTI_HCV = 'LISTAR_RESULTADOS_ANTI_HCV'
export const LISTOU_RESULTADOS_ANTI_HCV = 'LISTOU_RESULTADOS_ANTI_HCV'
export const ERRO_AO_LISTAR_RESULTADOS_ANTI_HCV = 'ERRO_AO_LISTAR_RESULTADOS_ANTI_HCV'
export const LISTAR_RESULTADOS_ANTI_HIV = 'LISTAR_RESULTADOS_ANTI_HIV'
export const LISTOU_RESULTADOS_ANTI_HIV = 'LISTOU_RESULTADOS_ANTI_HIV'
export const ERRO_AO_LISTAR_RESULTADOS_ANTI_HIV = 'ERRO_AO_LISTAR_RESULTADOS_ANTI_HIV'
export const LISTAR_RESULTADOS_ANTI_HBC = 'LISTAR_RESULTADOS_ANTI_HBC'
export const LISTOU_RESULTADOS_ANTI_HBC = 'LISTOU_RESULTADOS_ANTI_HBC'
export const ERRO_AO_LISTAR_RESULTADOS_ANTI_HBC = 'ERRO_AO_LISTAR_RESULTADOS_ANTI_HBC'

//unidades de calcio ionico
export const LISTAR_UNIDADES_DE_CALCIO_IONICO = 'LISTAR_UNIDADES_DE_CALCIO_IONICO'
export const LISTOU_UNIDADES_DE_CALCIO_IONICO = 'LISTOU_UNIDADES_DE_CALCIO_IONICO'
export const ERRO_AO_LISTAR_UNIDADES_DE_CALCIO_IONICO = 'ERRO_AO_LISTAR_UNIDADES_DE_CALCIO_IONICO'

//casp16
export const LISTAR_RESPOSTAS_DO_CASP16 = 'LISTAR_RESPOSTAS_DO_CASP16'
export const LISTOU_RESPOSTAS_DO_CASP16 = 'LISTOU_RESPOSTAS_DO_CASP16'
export const ERRO_AO_LISTAR_RESPOSTAS_DO_CASP16 = 'ERRO_AO_LISTAR_RESPOSTAS_DO_CASP16'

//planos de cuidado
export const LISTAR_PLANOS_DE_CUIDADO = 'LISTAR_PLANOS_DE_CUIDADO'
export const LISTOU_PLANOS_DE_CUIDADO = 'LISTOU_PLANOS_DE_CUIDADO'
export const ERRO_AO_LISTAR_PLANOS_DE_CUIDADO = 'ERRO_AO_LISTAR_PLANOS_DE_CUIDADO'
export const ADICIONOU_PLANO_DE_CUIDADO = 'ADICIONOU_PLANO_DE_CUIDADO'
export const ERRO_AO_ADICIONAR_PLANO_DE_CUIDADO = 'ERRO_AO_ADICIONAR_PLANO_DE_CUIDADO'
export const ADICIONAR_PLANO_DE_CUIDADO = 'ADICIONAR_PLANO_DE_CUIDADO'
export const RECUPEROU_PLANO_DE_CUIDADO_PELO_IDENTIFICADOR = 'RECUPEROU_PLANO_DE_CUIDADO_PELO_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_PLANO_DE_CUIDADO_PELO_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_PLANO_DE_CUIDADO_PELO_IDENTIFICADOR'
export const RECUPERAR_PLANO_DE_CUIDADO_PELO_IDENTIFICADOR = 'RECUPERAR_PLANO_DE_CUIDADO_PELO_IDENTIFICADOR'
export const ALTEROU_PLANO_DE_CUIDADO = 'ALTEROU_PLANO_DE_CUIDADO'
export const ERRO_AO_ALTERAR_PLANO_DE_CUIDADO = 'ERRO_AO_ALTERAR_PLANO_DE_CUIDADO'
export const ALTERAR_PLANO_DE_CUIDADO = 'ALTERAR_PLANO_DE_CUIDADO'
export const REMOVEU_PLANO_DE_CUIDADO = 'REMOVEU_PLANO_DE_CUIDADO'
export const ERRO_AO_REMOVER_PLANO_DE_CUIDADO = 'ERRO_AO_REMOVER_PLANO_DE_CUIDADO'
export const REMOVER_PLANO_DE_CUIDADO = 'REMOVER_PLANO_DE_CUIDADO'

//planos de cuidado do paciente
export const ADICIONAR_PLANO_DE_CUIDADO_DO_PACIENTE = 'ADICIONAR_PLANO_DE_CUIDADO_DO_PACIENTE'
export const ADICIONOU_PLANO_DE_CUIDADO_DO_PACIENTE = 'ADICIONOU_PLANO_DE_CUIDADO_DO_PACIENTE'
export const ERRO_AO_ADICIONAR_PLANO_DE_CUIDADO_DO_PACIENTE = 'ERRO_AO_ADICIONAR_PLANO_DE_CUIDADO_DO_PACIENTE'
export const ALTERAR_PLANO_DE_CUIDADO_DO_PACIENTE = 'ALTERAR_PLANO_DE_CUIDADO_DO_PACIENTE'
export const ALTEROU_PLANO_DE_CUIDADO_DO_PACIENTE = 'ALTEROU_PLANO_DE_CUIDADO_DO_PACIENTE'
export const ERRO_AO_ALTERAR_PLANO_DE_CUIDADO_DO_PACIENTE = 'ERRO_AO_ALTERAR_PLANO_DE_CUIDADO_DO_PACIENTE'
export const LISTAR_PLANOS_DE_CUIDADO_DO_PACIENTE = 'LISTAR_PLANOS_DE_CUIDADO_DO_PACIENTE'
export const LISTOU_PLANOS_DE_CUIDADO_DO_PACIENTE = 'LISTOU_PLANOS_DE_CUIDADO_DO_PACIENTE'
export const ERRO_AO_LISTAR_PLANOS_DE_CUIDADO_DO_PACIENTE = 'ERRO_AO_LISTAR_PLANOS_DE_CUIDADO_DO_PACIENTE'
export const REMOVER_PLANO_DE_CUIDADO_DO_PACIENTE = 'REMOVER_PLANO_DE_CUIDADO_DO_PACIENTE'
export const REMOVEU_PLANO_DE_CUIDADO_DO_PACIENTE = 'REMOVEU_PLANO_DE_CUIDADO_DO_PACIENTE'
export const ERRO_AO_REMOVER_PLANO_DE_CUIDADO_DO_PACIENTE = 'ERRO_AO_REMOVER_PLANO_DE_CUIDADO_DO_PACIENTE'
export const RECUPERAR_PLANO_DE_CUIDADO_DO_PACIENTE = 'RECUPERAR_PLANO_DE_CUIDADO_DO_PACIENTE'
export const RECUPEROU_PLANO_DE_CUIDADO_DO_PACIENTE = 'RECUPEROU_PLANO_DE_CUIDADO_DO_PACIENTE'
export const ERRO_AO_RECUPERAR_PLANO_DE_CUIDADO_DO_PACIENTE = 'ERRO_AO_RECUPERAR_PLANO_DE_CUIDADO_DO_PACIENTE'
export const LISTAR_MOTIVOS_DE_SAIDA_DOS_PLANOS_DE_CUIDADO = 'LISTAR_MOTIVOS_DE_SAIDA_DOS_PLANOS_DE_CUIDADO'
export const LISTOU_MOTIVOS_DE_SAIDA_DOS_PLANOS_DE_CUIDADO = 'LISTOU_MOTIVOS_DE_SAIDA_DOS_PLANOS_DE_CUIDADO'
export const ERRO_AO_LISTAR_MOTIVOS_DE_SAIDA_DOS_PLANOS_DE_CUIDADO = 'ERRO_AO_LISTAR_MOTIVOS_DE_SAIDA_DOS_PLANOS_DE_CUIDADO'

//estados civis
export const LISTAR_ESTADOS_CIVIS = 'LISTAR_ESTADOS_CIVIS'
export const LISTOU_ESTADOS_CIVIS = 'LISTOU_ESTADOS_CIVIS'
export const ERRO_AO_LISTAR_ESTADOS_CIVIS = 'ERRO_AO_LISTAR_ESTADOS_CIVIS'

//orientações
export const LISTAR_ORIENTACOES = 'LISTAR_ORIENTACOES'
export const LISTOU_ORIENTACOES = 'LISTOU_ORIENTACOES'
export const ERRO_AO_LISTAR_ORIENTACOES = 'ERRO_AO_LISTAR_ORIENTACOES'
export const ADICIONOU_ORIENTACAO = 'ADICIONOU_ORIENTACAO'
export const ERRO_AO_ADICIONAR_ORIENTACAO = 'ERRO_AO_ADICIONAR_ORIENTACAO'
export const ADICIONAR_ORIENTACAO = 'ADICIONAR_ORIENTACAO'
export const RECUPEROU_ORIENTACAO_PELO_IDENTIFICADOR = 'RECUPEROU_ORIENTACAO_PELO_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_ORIENTACAO_PELO_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_ORIENTACAO_PELO_IDENTIFICADOR'
export const RECUPERAR_ORIENTACAO_PELO_IDENTIFICADOR = 'RECUPERAR_ORIENTACAO_PELO_IDENTIFICADOR'
export const ALTEROU_ORIENTACAO = 'ALTEROU_ORIENTACAO'
export const ERRO_AO_ALTERAR_ORIENTACAO = 'ERRO_AO_ALTERAR_ORIENTACAO'
export const ALTERAR_ORIENTACAO = 'ALTERAR_ORIENTACAO'
export const REMOVEU_ORIENTACAO = 'REMOVEU_ORIENTACAO'
export const ERRO_AO_REMOVER_ORIENTACAO = 'ERRO_AO_REMOVER_ORIENTACAO'
export const REMOVER_ORIENTACAO = 'REMOVER_ORIENTACAO'
export const FAZER_DOWNLOAD_DO_ARQUIVO_DA_ORIENTACAO = 'FAZER_DOWNLOAD_DO_ARQUIVO_DA_ORIENTACAO'
export const FEZ_DOWNLOAD_DO_ARQUIVO_DA_ORIENTACAO = 'FEZ_DOWNLOAD_DO_ARQUIVO_DA_ORIENTACAO'
export const ERRO_AO_FAZER_DOWNLOAD_DO_ARQUIVO_DA_ORIENTACAO = 'ERRO_AO_FAZER_DOWNLOAD_DO_ARQUIVO_DA_ORIENTACAO'
//integracao power bi
export const RECUPERAR_TOKEN_DOS_RELATORIOS_DO_POWERBI = 'RECUPERAR_TOKEN_DOS_RELATORIOS_DO_POWERBI'
export const RECUPEROU_TOKEN_DOS_RELATORIOS_DO_POWERBI = 'RECUPEROU_TOKEN_DOS_RELATORIOS_DO_POWERBI'
export const ERRO_AO_RECUPERAR_TOKEN_DOS_RELATORIOS_DO_POWERBI = 'ERRO_AO_RECUPERAR_TOKEN_DOS_RELATORIOS_DO_POWERBI'
export const LISTAR_RELATORIOS_DO_POWERBI = 'LISTAR_RELATORIOS_DO_POWERBI'
export const LISTOU_RELATORIOS_DO_POWERBI = 'LISTOU_RELATORIOS_DO_POWERBI'
export const ERRO_AO_LISTAR_RELATORIOS_DO_POWERBI = 'ERRO_AO_LISTAR_RELATORIOS_DO_POWERBI'

// sugestoes - tokens - templates
export const ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE_DE_SUGESTAO = 'ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE_DE_SUGESTAO'
export const LISTAR_PROFISSIONAIS_DE_SAUDE_DE_SUGESTAO = 'LISTAR_PROFISSIONAIS_DE_SAUDE_DE_SUGESTAO'
export const LISTOU_PROFISSIONAIS_DE_SAUDE_DE_SUGESTAO = 'LISTOU_PROFISSIONAIS_DE_SAUDE_DE_SUGESTAO'
export const ERRO_AO_LISTAR_SUGESTOES_DE_PACIENTE = 'ERRO_AO_LISTAR_SUGESTOES_DE_PACIENTE'
export const LISTAR_SUGESTOES_DE_PACIENTE = 'LISTAR_SUGESTOES_DE_PACIENTE'
export const LISTOU_SUGESTOES_DE_PACIENTE = 'LISTOU_SUGESTOES_DE_PACIENTE'
export const ERRO_AO_LISTAR_TOKENS_DE_SUGESTAO = 'ERRO_AO_LISTAR_TOKENS_DE_SUGESTAO'
export const LISTAR_TOKENS_DE_SUGESTAO = 'LISTAR_TOKENS_DE_SUGESTAO'
export const LISTOU_TOKENS_DE_SUGESTAO = 'LISTOU_TOKENS_DE_SUGESTAO'
export const ERRO_AO_LISTAR_TOKENS_DE_EXEMPLO = 'ERRO_AO_LISTAR_TOKENS_DE_EXEMPLO'
export const LISTAR_TOKENS_DE_EXEMPLO = 'LISTAR_TOKENS_DE_EXEMPLO'
export const LISTOU_TOKENS_DE_EXEMPLO = 'LISTOU_TOKENS_DE_EXEMPLO'
export const ERRO_AO_LISTAR_COMPONENTES_COM_TEMPLATE = 'ERRO_AO_LISTAR_COMPONENTES_COM_TEMPLATE'
export const LISTAR_COMPONENTES_COM_TEMPLATE = 'LISTAR_COMPONENTES_COM_TEMPLATE'
export const LISTOU_COMPONENTES_COM_TEMPLATE = 'LISTOU_COMPONENTES_COM_TEMPLATE'
export const ADICIONAR_TEMPLATE = 'ADICIONAR_TEMPLATE'
export const ADICIONOU_TEMPLATE = 'ADICIONOU_TEMPLATE'
export const ERRO_AO_ADICIONAR_TEMPLATE = 'ERRO_AO_ADICIONAR_TEMPLATE'
export const RECUPERAR_TEMPLATE_POR_IDENTIFICADOR = 'RECUPERAR_TEMPLATE_POR_IDENTIFICADOR'
export const RECUPEROU_TEMPLATE_POR_IDENTIFICADOR = 'RECUPEROU_TEMPLATE_POR_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_TEMPLATE_POR_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_TEMPLATE_POR_IDENTIFICADOR'
export const SALVAR_TEMPLATE = 'SALVAR_TEMPLATE'
export const SALVOU_TEMPLATE = 'SALVOU_TEMPLATE'
export const ERRO_AO_SALVAR_TEMPLATE = 'ERRO_AO_SALVAR_TEMPLATE'
export const LISTAR_TEMPLATES = 'LISTAR_TEMPLATES'
export const LISTOU_TEMPLATES = 'LISTOU_TEMPLATES'
export const ERRO_AO_LISTAR_TEMPLATES = 'ERRO_AO_LISTAR_TEMPLATES'
export const EXCLUIR_TEMPLATE = 'EXCLUIR_TEMPLATE'
export const EXCLUIU_TEMPLATE = 'EXCLUIU_TEMPLATE'
export const ERRO_AO_EXCLUIR_TEMPLATE = 'ERRO_AO_EXCLUIR_TEMPLATE'
export const LISTAR_TEMPLATES_DO_PRONTUARIO = 'LISTAR_TEMPLATES_DO_PRONTUARIO'
export const LISTOU_TEMPLATES_DO_PRONTUARIO = 'LISTOU_TEMPLATES_DO_PRONTUARIO'
export const ERRO_AO_LISTAR_TEMPLATES_DO_PRONTUARIO = 'ERRO_AO_LISTAR_TEMPLATES_DO_PRONTUARIO'
export const ADICIONAR_TEMPLATE_DO_PRONTUARIO = 'ADICIONAR_TEMPLATE_DO_PRONTUARIO'
export const ADICIONOU_TEMPLATE_DO_PRONTUARIO = 'ADICIONOU_TEMPLATE_DO_PRONTUARIO'
export const ERRO_AO_ADICIONAR_TEMPLATE_DO_PRONTUARIO = 'ERRO_AO_ADICIONAR_TEMPLATE_DO_PRONTUARIO'

//proprietarios de telefones
export const LISTAR_PROPRIETARIOS_DE_TELEFONES = 'LISTAR_PROPRIETARIOS_DE_TELEFONES'
export const LISTOU_PROPRIETARIOS_DE_TELEFONES = 'LISTOU_PROPRIETARIOS_DE_TELEFONES'
export const ERRO_AO_LISTAR_PROPRIETARIOS_DE_TELEFONES = 'ERRO_AO_LISTAR_PROPRIETARIOS_DE_TELEFONES'

//tarefas
export const AUTENTICAR_USUARIO_NO_FIRESTORE = 'AUTENTICAR_USUARIO_NO_FIRESTORE'
export const AUTENTICOU_USUARIO_NO_FIRESTORE = 'AUTENTICOU_USUARIO_NO_FIRESTORE'
export const ERRO_AO_AUTENTICAR_USUARIO_NO_FIRESTORE = 'ERRO_AO_AUTENTICAR_USUARIO_NO_FIRESTORE'
export const REGISTRAR_FIRESTORE_DE_TAREFAS = 'REGISTRAR_FIRESTORE_DE_TAREFAS'
export const REGISTROU_FIRESTORE_DE_TAREFAS = 'REGISTROU_FIRESTORE_DE_TAREFAS'
export const ERRO_AO_REGISTRAR_FIRESTORE_DE_TAREFAS = 'ERRO_AO_REGISTRAR_FIRESTORE_DE_TAREFAS'
export const REGISTRAR_FIRESTORE_DE_TAREFAS_DO_PACIENTE = 'REGISTRAR_FIRESTORE_DE_TAREFAS_DO_PACIENTE'
export const REGISTROU_FIRESTORE_DE_TAREFAS_DO_PACIENTE = 'REGISTROU_FIRESTORE_DE_TAREFAS_DO_PACIENTE'
export const ERRO_AO_REGISTRAR_FIRESTORE_DE_TAREFAS_DO_PACIENTE = 'ERRO_AO_REGISTRAR_FIRESTORE_DE_TAREFAS_DO_PACIENTE'
export const REGISTRAR_FIRESTORE_DE_TAREFAS_DO_USUARIO = 'REGISTRAR_FIRESTORE_DE_TAREFAS_DO_USUARIO'
export const REGISTROU_FIRESTORE_DE_TAREFAS_DO_USUARIO = 'REGISTROU_FIRESTORE_DE_TAREFAS_DO_USUARIO'
export const ERRO_AO_REGISTRAR_FIRESTORE_DE_TAREFAS_DO_USUARIO = 'ERRO_AO_REGISTRAR_FIRESTORE_DE_TAREFAS_DO_USUARIO'
export const ATUALIZAR_TAREFAS = 'ATUALIZAR_TAREFAS'
export const ATUALIZAR_TAREFAS_DO_PACIENTE = 'ATUALIZAR_TAREFAS_DO_PACIENTE'
export const ATUALIZAR_TAREFAS_DO_USUARIO = 'ATUALIZAR_TAREFAS_DO_USUARIO'
export const LISTAR_TIPOS_DE_TAREFA = 'LISTAR_TIPOS_DE_TAREFA'
export const LISTOU_TIPOS_DE_TAREFA = 'LISTOU_TIPOS_DE_TAREFA'
export const ERRO_AO_LISTAR_TIPOS_DE_TAREFA = 'ERRO_AO_LISTAR_TIPOS_DE_TAREFA'
export const CRIAR_TAREFA = 'CRIAR_TAREFA'
export const CRIOU_TAREFA = 'CRIOU_TAREFA'
export const ERRO_AO_CRIAR_TAREFA = 'ERRO_AO_CRIAR_TAREFA'
export const CRIAR_TAREFA_DO_PACIENTE = 'CRIAR_TAREFA_DO_PACIENTE'
export const CRIOU_TAREFA_DO_PACIENTE = 'CRIOU_TAREFA_DO_PACIENTE'
export const ERRO_AO_CRIAR_TAREFA_DO_PACIENTE = 'ERRO_AO_CRIAR_TAREFA_DO_PACIENTE'
export const REGISTRAR_FIRESTORE_DA_TAREFA_ATUAL = 'REGISTRAR_FIRESTORE_DA_TAREFA_ATUAL'
export const REGISTROU_FIRESTORE_DA_TAREFA_ATUAL = 'REGISTROU_FIRESTORE_DA_TAREFA_ATUAL'
export const ERRO_AO_REGISTRAR_FIRESTORE_DA_TAREFA_ATUAL = 'ERRO_AO_REGISTRAR_FIRESTORE_DA_TAREFA_ATUAL'
export const ATUALIZAR_TAREFA_ATUAL = 'ATUALIZAR_TAREFA_ATUAL'
export const LIMPAR_TAREFA_ATUAL = 'LIMPAR_TAREFA_ATUAL'

//tarefas - atividades
export const REGISTRAR_FIRESTORE_DE_ATIVIDADES_DA_TAREFA = 'REGISTRAR_FIRESTORE_DE_ATIVIDADES_DA_TAREFA'
export const REGISTROU_FIRESTORE_DE_ATIVIDADES_DA_TAREFA = 'REGISTROU_FIRESTORE_DE_ATIVIDADES_DA_TAREFA'
export const ERRO_AO_REGISTRAR_FIRESTORE_DE_ATIVIDADES_DA_TAREFA = 'ERRO_AO_REGISTRAR_FIRESTORE_DE_ATIVIDADES_DA_TAREFA'
export const ATUALIZAR_ATIVIDADES_DA_TAREFA = 'ATUALIZAR_ATIVIDADES_DA_TAREFA'

//tarefas - telefone de contato
export const DEFINIR_TELEFONE_DE_CONTATO_DA_TAREFA = 'DEFINIR_TELEFONE_DE_CONTATO_DA_TAREFA'
export const DEFINIU_TELEFONE_DE_CONTATO_DA_TAREFA = 'DEFINIU_TELEFONE_DE_CONTATO_DA_TAREFA'
export const ERRO_AO_DEFINIR_TELEFONE_DE_CONTATO_DA_TAREFA = 'ERRO_AO_DEFINIR_TELEFONE_DE_CONTATO_DA_TAREFA'

//tarefas - descrição
export const DEFINIR_DESCRICAO_DA_TAREFA = 'DEFINIR_DESCRICAO_DA_TAREFA'
export const DEFINIU_DESCRICAO_DA_TAREFA = 'DEFINIU_DESCRICAO_DA_TAREFA'
export const ERRO_AO_DEFINIR_DESCRICAO_DA_TAREFA = 'ERRO_AO_DEFINIR_DESCRICAO_DA_TAREFA'

//tarefas - paciente
export const DEFINIR_PACIENTE_DA_TAREFA = 'DEFINIR_PACIENTE_DA_TAREFA'
export const DEFINIU_PACIENTE_DA_TAREFA = 'DEFINIU_PACIENTE_DA_TAREFA'
export const ERRO_AO_DEFINIR_PACIENTE_DA_TAREFA = 'ERRO_AO_DEFINIR_PACIENTE_DA_TAREFA'
export const LISTAR_PACIENTES_DO_TAREFAS = 'LISTAR_PACIENTES_DO_TAREFAS'
export const LISTOU_PACIENTES_DO_TAREFAS = 'LISTOU_PACIENTES_DO_TAREFAS'
export const ERRO_AO_LISTAR_PACIENTES_DO_TAREFAS = 'ERRO_AO_LISTAR_PACIENTES_DO_TAREFAS'
export const ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DA_TAREFA = 'ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DA_TAREFA'
export const ADICIONOU_PRE_CADASTRO_DO_PACIENTE_DA_TAREFA = 'ADICIONOU_PRE_CADASTRO_DO_PACIENTE_DA_TAREFA'
export const ERRO_AO_ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DA_TAREFA = 'ERRO_AO_ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DA_TAREFA'

//tarefas - categoria
export const DEFINIR_CATEGORIA_DA_TAREFA = 'DEFINIR_CATEGORIA_DA_TAREFA'
export const DEFINIU_CATEGORIA_DA_TAREFA = 'DEFINIU_CATEGORIA_DA_TAREFA'
export const ERRO_AO_DEFINIR_CATEGORIA_DA_TAREFA = 'ERRO_AO_DEFINIR_CATEGORIA_DA_TAREFA'
export const LISTAR_CATEGORIAS_DO_TAREFAS = 'LISTAR_CATEGORIAS_DO_TAREFAS'
export const LISTOU_CATEGORIAS_DO_TAREFAS = 'LISTOU_CATEGORIAS_DO_TAREFAS'
export const ERRO_AO_LISTAR_CATEGORIAS_DO_TAREFAS = 'ERRO_AO_LISTAR_CATEGORIAS_DO_TAREFAS'

//tarefas - urgência
export const DEFINIR_URGENCIA_DA_TAREFA = 'DEFINIR_URGENCIA_DA_TAREFA'
export const DEFINIU_URGENCIA_DA_TAREFA = 'DEFINIU_URGENCIA_DA_TAREFA'
export const ERRO_AO_DEFINIR_URGENCIA_DA_TAREFA = 'ERRO_AO_DEFINIR_URGENCIA_DA_TAREFA'
export const LISTAR_URGENCIAS_DO_TAREFAS = 'LISTAR_URGENCIAS_DO_TAREFAS'
export const LISTOU_URGENCIAS_DO_TAREFAS = 'LISTOU_URGENCIAS_DO_TAREFAS'
export const ERRO_AO_LISTAR_URGENCIAS_DO_TAREFAS = 'ERRO_AO_LISTAR_URGENCIAS_DO_TAREFAS'

//tarefas - motivos de cancelamento
export const DEFINIR_MOTIVO_DO_CANCELAMENTO_DA_TAREFA = 'DEFINIR_MOTIVO_DO_CANCELAMENTO_DA_TAREFA'
export const DEFINIU_MOTIVO_DO_CANCELAMENTO_DA_TAREFA = 'DEFINIU_MOTIVO_DO_CANCELAMENTO_DA_TAREFA'
export const ERRO_AO_DEFINIR_MOTIVO_DO_CANCELAMENTO_DA_TAREFA = 'ERRO_AO_DEFINIR_MOTIVO_DO_CANCELAMENTO_DA_TAREFA'
export const LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_TAREFAS = 'LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_TAREFAS'
export const LISTOU_MOTIVOS_DE_CANCELAMENTO_DO_TAREFAS = 'LISTOU_MOTIVOS_DE_CANCELAMENTO_DO_TAREFAS'
export const ERRO_AO_LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_TAREFAS = 'ERRO_AO_LISTAR_MOTIVOS_DE_CANCELAMENTO_DA_TAREFA'

//tarefas - profissionais responsaveis
export const DEFINIR_PROFISSIONAL_RESPONSAVEL_DA_TAREFA = 'DEFINIR_PROFISSIONAL_RESPONSAVEL_DA_TAREFA'
export const DEFINIU_PROFISSIONAL_RESPONSAVEL_DA_TAREFA = 'DEFINIU_PROFISSIONAL_RESPONSAVEL_DA_TAREFA'
export const ERRO_AO_DEFINIR_PROFISSIONAL_RESPONSAVEL_DA_TAREFA = 'ERRO_AO_DEFINIR_PROFISSIONAL_RESPONSAVEL_DA_TAREFA'
export const LISTAR_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS = 'LISTAR_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS'
export const LISTOU_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS = 'LISTOU_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS'
export const ERRO_AO_LISTAR_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS = 'ERRO_AO_LISTAR_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS'

//tarefas - comentários
export const ADICIONAR_COMENTARIO_DA_TAREFA = 'ADICIONAR_COMENTARIO_DA_TAREFA'
export const ADICIONOU_COMENTARIO_DA_TAREFA = 'ADICIONOU_COMENTARIO_DA_TAREFA'
export const ERRO_AO_ADICIONAR_COMENTARIO_DA_TAREFA = 'ERRO_AO_ADICIONAR_COMENTARIO_DA_TAREFA'
export const ALTERAR_COMENTARIO_DA_TAREFA = 'ALTERAR_COMENTARIO_DA_TAREFA'
export const ALTEROU_COMENTARIO_DA_TAREFA = 'ALTEROU_COMENTARIO_DA_TAREFA'
export const ERRO_AO_ALTERAR_COMENTARIO_DA_TAREFA = 'ERRO_AO_ALTERAR_COMENTARIO_DA_TAREFA'
export const REMOVER_COMENTARIO_DA_TAREFA = 'REMOVER_COMENTARIO_DA_TAREFA'
export const REMOVEU_COMENTARIO_DA_TAREFA = 'REMOVEU_COMENTARIO_DA_TAREFA'
export const ERRO_AO_REMOVER_COMENTARIO_DA_TAREFA = 'ERRO_AO_REMOVER_COMENTARIO_DA_TAREFA'

//tarefas - status
export const ALTERAR_STATUS_DA_TAREFA = 'ALTERAR_STATUS_DA_TAREFA'
export const ALTEROU_STATUS_DA_TAREFA = 'ALTEROU_STATUS_DA_TAREFA'
export const ERRO_AO_ALTERAR_STATUS_DA_TAREFA = 'ERRO_AO_ALTERAR_STATUS_DA_TAREFA'

//tarefas - filtros
export const DEFINIR_TIPO_DA_TAREFA_ATUAL = 'DEFINIR_TIPO_DA_TAREFA_ATUAL'
export const DEFINIR_DASHBOARD_DA_TAREFA_ATUAL = 'DEFINIR_DASHBOARD_DA_TAREFA_ATUAL'
export const DEFINIR_ABA_ATUAL = 'DEFINIR_ABA_ATUAL'
export const ALTERAR_FILTROS_DO_TAREFAS = 'ALTERAR_FILTROS_DO_TAREFAS'
export const ALTERAR_TERMO_DO_FILTRO_DE_PACIENTES_DO_TAREFAS = 'ALTERAR_TERMO_DO_FILTRO_DE_PACIENTES_DO_TAREFAS'
export const LISTAR_PACIENTES_DOS_FILTROS_DO_TAREFAS = 'LISTAR_PACIENTES_DOS_FILTROS_DO_TAREFAS'
export const LISTOU_PACIENTES_DOS_FILTROS_DO_TAREFAS = 'LISTOU_PACIENTES_DOS_FILTROS_DO_TAREFAS'
export const ERRO_AO_LISTAR_PACIENTES_DOS_FILTROS_DO_TAREFAS = 'ERRO_AO_LISTAR_PACIENTES_DOS_FILTROS_DO_TAREFAS'

//tarefas - lista de opções múltiplas
export const MARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA = 'MARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA'
export const MARCOU_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA = 'MARCOU_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA'
export const ERRO_AO_MARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA = 'ERRO_AO_MARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA'
export const DESMARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA = 'DESMARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA'
export const DESMARCOU_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA = 'DESMARCOU_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA'
export const ERRO_AO_DESMARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA = 'ERRO_AO_DESMARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA'

//tarefas - texto
export const DEFINIR_TEXTO_DA_TAREFA = 'DEFINIR_TEXTO_DA_TAREFA'
export const DEFINIU_TEXTO_DA_TAREFA = 'DEFINIU_TEXTO_DA_TAREFA'
export const ERRO_AO_DEFINIR_TEXTO_DA_TAREFA = 'ERRO_AO_DEFINIR_TEXTO_DA_TAREFA'

//tarefas - texto longo
export const DEFINIR_TEXTO_LONGO_DA_TAREFA = 'DEFINIR_TEXTO_LONGO_DA_TAREFA'
export const DEFINIU_TEXTO_LONGO_DA_TAREFA = 'DEFINIU_TEXTO_LONGO_DA_TAREFA'
export const ERRO_AO_DEFINIR_TEXTO_LONGO_DA_TAREFA = 'ERRO_AO_DEFINIR_TEXTO_LONGO_DA_TAREFA'

//tarefas - número inteiro
export const DEFINIR_NUMERO_INTEIRO_DA_TAREFA = 'DEFINIR_NUMERO_INTEIRO_DA_TAREFA'
export const DEFINIU_NUMERO_INTEIRO_DA_TAREFA = 'DEFINIU_NUMERO_INTEIRO_DA_TAREFA'
export const ERRO_AO_DEFINIR_NUMERO_INTEIRO_DA_TAREFA = 'ERRO_AO_DEFINIR_NUMERO_INTEIRO_DA_TAREFA'

//tarefas - momento
export const DEFINIR_MOMENTO_DA_TAREFA = 'DEFINIR_MOMENTO_DA_TAREFA'
export const DEFINIU_MOMENTO_DA_TAREFA = 'DEFINIU_MOMENTO_DA_TAREFA'
export const ERRO_AO_DEFINIR_MOMENTO_DA_TAREFA = 'ERRO_AO_DEFINIR_MOMENTO_DA_TAREFA'

//tarefas - data
export const DEFINIR_DATA_DA_TAREFA = 'DEFINIR_DATA_DA_TAREFA'
export const DEFINIU_DATA_DA_TAREFA = 'DEFINIU_DATA_DA_TAREFA'
export const ERRO_AO_DEFINIR_DATA_DA_TAREFA = 'ERRO_AO_DEFINIR_DATA_DA_TAREFA'

//tarefas - prazo
export const DEFINIR_PRAZO_DA_TAREFA = 'DEFINIR_PRAZO_DA_TAREFA'
export const DEFINIU_PRAZO_DA_TAREFA = 'DEFINIU_PRAZO_DA_TAREFA'
export const ERRO_AO_DEFINIR_PRAZO_DA_TAREFA = 'ERRO_AO_DEFINIR_PRAZO_DA_TAREFA'

//tarefas - setores responsaveis
export const DEFINIR_SETOR_RESPONSAVEL_DA_TAREFA = 'DEFINIR_SETOR_RESPONSAVEL_DA_TAREFA'
export const DEFINIU_SETOR_RESPONSAVEL_DA_TAREFA = 'DEFINIU_SETOR_RESPONSAVEL_DA_TAREFA'
export const ERRO_AO_DEFINIR_SETOR_RESPONSAVEL_DA_TAREFA = 'ERRO_AO_DEFINIR_SETOR_RESPONSAVEL_DA_TAREFA'
export const LISTAR_SETORES_RESPONSAVEIS_DO_TAREFAS = 'LISTAR_SETORES_RESPONSAVEIS_DO_TAREFAS'
export const LISTOU_SETORES_RESPONSAVEIS_DO_TAREFAS = 'LISTOU_SETORES_RESPONSAVEIS_DO_TAREFAS'
export const ERRO_AO_LISTAR_SETORES_RESPONSAVEIS_DO_TAREFAS = 'ERRO_AO_LISTAR_SETORES_RESPONSAVEIS_DO_TAREFAS'

//teleconsulta
export const RECUPERAR_TELECONSULTA_DO_AGENDAMENTO = 'RECUPERAR_TELECONSULTA_DO_AGENDAMENTO'
export const RECUPEROU_TELECONSULTA_DO_AGENDAMENTO = 'RECUPEROU_TELECONSULTA_DO_AGENDAMENTO'
export const ERRO_AO_RECUPERAR_TELECONSULTA_DO_AGENDAMENTO = 'ERRO_AO_RECUPERAR_TELECONSULTA_DO_AGENDAMENTO'
export const RECUPERAR_TELECONSULTA_DO_ATENDIMENTO = 'RECUPERAR_TELECONSULTA_DO_ATENDIMENTO'
export const RECUPEROU_TELECONSULTA_DO_ATENDIMENTO = 'RECUPEROU_TELECONSULTA_DO_ATENDIMENTO'
export const ERRO_AO_RECUPERAR_TELECONSULTA_DO_ATENDIMENTO = 'ERRO_AO_RECUPERAR_TELECONSULTA_DO_ATENDIMENTO'

//nps
export const RECUPERAR_AVALIACAO = 'RECUPERAR_AVALIACAO'
export const RECUPEROU_AVALIACAO = 'RECUPEROU_AVALIACAO'
export const ERRO_AO_RECUPERAR_AVALIACAO = 'ERRO_AO_RECUPERAR_AVALIACAO'
export const RESPONDER_AVALIACAO = 'RESPONDER_AVALIACAO'
export const RESPONDEU_AVALIACAO = 'RESPONDEU_AVALIACAO'
export const ERRO_AO_RESPONDER_AVALIACAO = 'ERRO_AO_RESPONDER_AVALIACAO'
export const LISTAR_PROFISSOES_PARA_O_NPS = 'LISTAR_PROFISSOES_PARA_O_NPS'
export const LISTOU_PROFISSOES_PARA_O_NPS = 'LISTOU_PROFISSOES_PARA_O_NPS'
export const ERRO_AO_LISTAR_PROFISSOES_PARA_O_NPS = 'ERRO_AO_LISTAR_PROFISSOES_PARA_O_NPS'
export const HABILITAR_PROFISSOES_PARA_O_NPS = 'HABILITAR_PROFISSOES_PARA_O_NPS'
export const HABILITOU_PROFISSOES_PARA_O_NPS = 'HABILITOU_PROFISSOES_PARA_O_NPS'
export const ERRO_AO_HABILITAR_PROFISSOES_PARA_O_NPS = 'ERRO_AO_HABILITAR_PROFISSOES_PARA_O_NPS'
export const DESABILITAR_PROFISSAO_PARA_O_NPS = 'DESABILITAR_PROFISSAO_PARA_O_NPS'
export const DESABILITOU_PROFISSAO_PARA_O_NPS = 'DESABILITOU_PROFISSAO_PARA_O_NPS'
export const ERRO_AO_DESABILITAR_PROFISSAO_PARA_O_NPS = 'ERRO_AO_DESABILITAR_PROFISSAO_PARA_O_NPS'
export const LISTAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS = 'LISTAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS'
export const LISTOU_TIPOS_DE_ATENDIMENTO_PARA_O_NPS = 'LISTOU_TIPOS_DE_ATENDIMENTO_PARA_O_NPS'
export const ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS = 'ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS'
export const HABILITAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS = 'HABILITAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS'
export const HABILITOU_TIPOS_DE_ATENDIMENTO_PARA_O_NPS = 'HABILITOU_TIPOS_DE_ATENDIMENTO_PARA_O_NPS'
export const ERRO_AO_HABILITAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS = 'ERRO_AO_HABILITAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS'
export const DESABILITAR_TIPO_DE_ATENDIMENTO_PARA_O_NPS = 'DESABILITAR_TIPO_DE_ATENDIMENTO_PARA_O_NPS'
export const DESABILITOU_TIPO_DE_ATENDIMENTO_PARA_O_NPS = 'DESABILITOU_TIPO_DE_ATENDIMENTO_PARA_O_NPS'
export const ERRO_AO_DESABILITAR_TIPO_DE_ATENDIMENTO_PARA_O_NPS = 'ERRO_AO_DESABILITAR_TIPO_DE_ATENDIMENTO_PARA_O_NPS'
export const LISTAR_AVALIACOES_RESPONDIDAS = 'LISTAR_AVALIACOES_RESPONDIDAS'
export const LISTOU_AVALIACOES_RESPONDIDAS = 'LISTOU_AVALIACOES_RESPONDIDAS'
export const ERRO_AO_LISTAR_AVALIACOES_RESPONDIDAS = 'ERRO_AO_LISTAR_AVALIACOES_RESPONDIDAS'
export const ALTERAR_STATUS_DE_UMA_RESPOSTA = 'ALTERAR_STATUS_DE_UMA_RESPOSTA'
export const ALTEROU_STATUS_DE_UMA_RESPOSTA = 'ALTEROU_STATUS_DE_UMA_RESPOSTA'
export const ERRO_AO_ALTERAR_STATUS_DE_UMA_RESPOSTA = 'ERRO_AO_ALTERAR_STATUS_DE_UMA_RESPOSTA'
export const RECUPERAR_AVALIACAO_RESPONDIDA = 'RECUPERAR_AVALIACAO_RESPONDIDA'
export const RECUPEROU_AVALIACAO_RESPONDIDA = 'RECUPEROU_AVALIACAO_RESPONDIDA'
export const ERRO_AO_RECUPERAR_AVALIACAO_RESPONDIDA = 'ERRO_AO_RECUPERAR_AVALIACAO_RESPONDIDA'
export const LISTAR_PACIENTES_DO_NPS = 'LISTAR_PACIENTES_DO_NPS'
export const LISTOU_PACIENTES_DO_NPS = 'LISTOU_PACIENTES_DO_NPS'
export const ERRO_AO_LISTAR_PACIENTES_DO_NPS = 'ERRO_AO_LISTAR_PACIENTES_DO_NPS'
export const LISTAR_PROFISSIONAIS_DO_NPS = 'LISTAR_PROFISSIONAIS_DO_NPS'
export const LISTOU_PROFISSIONAIS_DO_NPS = 'LISTOU_PROFISSIONAIS_DO_NPS'
export const ERRO_AO_LISTAR_PROFISSIONAIS_DO_NPS = 'ERRO_AO_LISTAR_PROFISSIONAIS_DO_NPS'

//indicadores do paciente
export const LISTAR_INDICADORES_DO_PACIENTE = 'LISTAR_INDICADORES_DO_PACIENTE'
export const LISTOU_INDICADORES_DO_PACIENTE = 'LISTOU_INDICADORES_DO_PACIENTE'
export const ERRO_AO_LISTAR_INDICADORES_DO_PACIENTE = 'ERRO_AO_LISTAR_INDICADORES_DO_PACIENTE'

// memed
export const AUTENTICAR_MEDICO_NA_MEMED = 'AUTENTICAR_MEDICO_NA_MEMED'
export const AUTENTICOU_MEDICO_NA_MEMED = 'AUTENTICOU_MEDICO_NA_MEMED'
export const ERRO_AO_AUTENTICAR_MEDICO_NA_MEMED = 'ERRO_AO_AUTENTICAR_MEDICO_NA_MEMED'
export const INTEGRAR_NA_MEMED = 'INTEGRAR_NA_MEMED'
export const INTEGROU_NA_MEMED = 'INTEGROU_NA_MEMED'
export const ERRO_AO_INTEGRAR_NA_MEMED = 'ERRO_AO_INTEGRAR_NA_MEMED'
export const RECUPERAR_PRESCRICAO_PDF_NA_MEMED = 'RECUPERAR_PRESCRICAO_PDF_NA_MEMED'
export const RECUPEROU_PRESCRICAO_PDF_NA_MEMED = 'RECUPEROU_PRESCRICAO_PDF_NA_MEMED'
export const ERRO_AO_RECUPERAR_PRESCRICAO_PDF_NA_MEMED = 'ERRO_AO_RECUPERAR_PRESCRICAO_PDF_NA_MEMED'
export const RECUPERAR_HISTORICO_PRESCRICAO_NA_MEMED = 'RECUPERAR_HISTORICO_PRESCRICAO_NA_MEMED'
export const RECUPEROU_HISTORICO_PRESCRICAO_NA_MEMED = 'RECUPEROU_HISTORICO_PRESCRICAO_NA_MEMED'
export const ERRO_AO_RECUPERAR_HISTORICO_PRESCRICAO_NA_MEMED = 'ERRO_AO_RECUPERAR_HISTORICO_PRESCRICAO_NA_MEMED'
export const REMOVER_PRESCRICAO_NA_MEMED = 'REMOVER_PRESCRICAO_NA_MEMED'
export const REMOVEU_PRESCRICAO_NA_MEMED = 'REMOVEU_PRESCRICAO_NA_MEMED'
export const ERRO_AO_REMOVER_PRESCRICAO_NA_MEMED = 'ERRO_AO_REMOVER_PRESCRICAO_NA_MEMED'
export const INCLUIR_PRESCRICAO_MEMED_NO_FORMULARIO = 'INCLUIR_PRESCRICAO_MEMED_NO_FORMULARIO'
export const EXCLUIR_PRESCRICAO_MEMED_NO_FORMULARIO = 'EXCLUIR_PRESCRICAO_MEMED_NO_FORMULARIO'
export const LIMPAR_PRESCRICAO_MEMED_NO_FORMULARIO = 'LIMPAR_PRESCRICAO_MEMED_NO_FORMULARIO'
export const LIMPAR_PRESCRICAO_MEMED = 'LIMPAR_PRESCRICAO_MEMED'
export const EXIBIR_PRESCRICAO_MEMED_DO_HISTORICO = 'EXIBIR_PRESCRICAO_MEMED_DO_HISTORICO'

// lacuna - certificado digital
export const LER_CERTIFICADOS_DIGITAIS_FISICOS = 'LER_CERTIFICADOS_DIGITAIS_FISICOS'
export const LEU_CERTIFICADOS_DIGITAIS_FISICOS = 'LEU_CERTIFICADOS_DIGITAIS_FISICOS'
export const ERRO_AO_LER_CERTIFICADOS_DIGITAIS_FISICOS = 'ERRO_AO_LER_CERTIFICADOS_DIGITAIS_FISICOS'
export const SELECIONAR_CERTIFICADO_DIGITAL = 'SELECIONAR_CERTIFICADO_DIGITAL'
export const INICIAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO = 'INICIAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO'
export const INICIOU_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO = 'INICIOU_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO'
export const ERRO_AO_INICIAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO = 'ERRO_AO_INICIAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO'
export const ASSINAR_ATENDIMENTO_COM_CERTIFICADO_FISICO = 'ASSINAR_ATENDIMENTO_COM_CERTIFICADO_FISICO'
export const FINALIZAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO = 'FINALIZAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO'
export const FINALIZOU_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO = 'FINALIZOU_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO'
export const ERRO_AO_FINALIZAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO = 'ERRO_AO_FINALIZAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO'
export const RECUPERAR_CERTIFICADOS_EM_NUVEM_DO_USUARIO = 'RECUPERAR_CERTIFICADOS_EM_NUVEM_DO_USUARIO'
export const RECUPEROU_CERTIFICADOS_EM_NUVEM_DO_USUARIO = 'RECUPEROU_CERTIFICADOS_EM_NUVEM_DO_USUARIO'
export const ERRO_AO_RECUPERAR_CERTIFICADOS_EM_NUVEM_DO_USUARIO = 'ERRO_AO_RECUPERAR_CERTIFICADOS_EM_NUVEM_DO_USUARIO'
export const RECUPERAR_URL_AUTENTICACAO_DO_CERTIFICADO_EM_NUVEM = 'RECUPERAR_URL_AUTENTICACAO_DO_CERTIFICADO_EM_NUVEM'
export const RECUPEROU_URL_AUTENTICACAO_DO_CERTIFICADO_EM_NUVEM = 'RECUPEROU_URL_AUTENTICACAO_DO_CERTIFICADO_EM_NUVEM'
export const ERRO_AO_RECUPERAR_URL_AUTENTICACAO_DO_CERTIFICADO_EM_NUVEM = 'ERRO_AO_RECUPERAR_URL_AUTENTICACAO_DO_CERTIFICADO_EM_NUVEM'
export const INICIAR_SESSAO_COM_CERTIFICADO_EM_NUVEM = 'INICIAR_SESSAO_COM_CERTIFICADO_EM_NUVEM'
export const INICIOU_SESSAO_COM_CERTIFICADO_EM_NUVEM = 'INICIOU_SESSAO_COM_CERTIFICADO_EM_NUVEM'
export const ERRO_AO_INICIAR_SESSAO_COM_CERTIFICADO_EM_NUVEM = 'ERRO_AO_INICIAR_SESSAO_COM_CERTIFICADO_EM_NUVEM'
export const RECUPERAR_SESSAO_COM_CERTIFICADO_EM_NUVEM = 'RECUPERAR_SESSAO_COM_CERTIFICADO_EM_NUVEM'
export const RECUPEROU_SESSAO_COM_CERTIFICADO_EM_NUVEM = 'RECUPEROU_SESSAO_COM_CERTIFICADO_EM_NUVEM'
export const ERRO_AO_RECUPERAR_SESSAO_COM_CERTIFICADO_EM_NUVEM = 'ERRO_AO_RECUPERAR_SESSAO_COM_CERTIFICADO_EM_NUVEM'
export const ASSINAR_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM = 'ASSINAR_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM'
export const ASSINOU_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM = 'ASSINOU_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM'
export const ERRO_AO_ASSINAR_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM = 'ERRO_AO_ASSINAR_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM'
export const SINALIZAR_ATENDIMENTO_ASSINADO_COM_CERTIFICADO_EM_NUVEM = 'SINALIZAR_ATENDIMENTO_ASSINADO_COM_CERTIFICADO_EM_NUVEM'

//tipos de consulta externa
export const LISTAR_TIPOS_DE_CONSULTA_EXTERNA = 'LISTAR_TIPOS_DE_CONSULTA_EXTERNA'
export const LISTOU_TIPOS_DE_CONSULTA_EXTERNA = 'LISTOU_TIPOS_DE_CONSULTA_EXTERNA'
export const ERRO_AO_LISTAR_TIPOS_DE_CONSULTA_EXTERNA = 'ERRO_AO_LISTAR_TIPOS_DE_CONSULTA_EXTERNA'

//fusos horarios
export const LISTAR_FUSOS_HORARIOS = 'LISTAR_FUSOS_HORARIOS'
export const LISTOU_FUSOS_HORARIOS = 'LISTOU_FUSOS_HORARIOS'
export const ERRO_AO_LISTAR_FUSOS_HORARIOS = 'ERRO_AO_LISTAR_FUSOS_HORARIOS'

//motivos de bloqueio do login
export const LISTAR_MOTIVOS_DE_BLOQUEIO_DO_LOGIN = 'LISTAR_MOTIVOS_DE_BLOQUEIO_DO_LOGIN'
export const LISTOU_MOTIVOS_DE_BLOQUEIO_DO_LOGIN = 'LISTOU_MOTIVOS_DE_BLOQUEIO_DO_LOGIN'
export const ERRO_AO_LISTAR_MOTIVOS_DE_BLOQUEIO_DO_LOGIN = 'ERRO_AO_LISTAR_MOTIVOS_DE_BLOQUEIO_DO_LOGIN'

//usuarios bloqueados
export const LISTAR_USUARIOS_BLOQUEADOS = 'LISTAR_USUARIOS_BLOQUEADOS'
export const LISTOU_USUARIOS_BLOQUEADOS = 'LISTOU_USUARIOS_BLOQUEADOS'
export const ERRO_AO_LISTAR_USUARIOS_BLOQUEADOS = 'ERRO_AO_LISTAR_MOTIVOS_DE_BLOQUEIO_DO_LOGIN'

//desbloquear login do usuario
export const DESBLOQUEAR_LOGIN_DO_USUARIO = 'DESBLOQUEAR_LOGIN_DO_USUARIO'
export const DESBLOQUEOU_LOGIN_DO_USUARIO = 'DESBLOQUEOU_LOGIN_DO_USUARIO'
export const ERRO_AO_DESBLOQUEAR_LOGIN_DO_USUARIO = 'ERRO_AO_DESBLOQUEAR_LOGIN_DO_USUARIO'

//duracoes das dores
export const LISTAR_DURACOES_DAS_DORES = 'LISTAR_DURACOES_DAS_DORES'
export const LISTOU_DURACOES_DAS_DORES = 'LISTOU_DURACOES_DAS_DORES'
export const ERRO_AO_LISTAR_DURACOES_DAS_DORES = 'ERRO_AO_LISTAR_DURACOES_DAS_DORES'

//intensidades das dores 
export const LISTAR_INTENSIDADES_DAS_DORES = 'LISTAR_INTENSIDADES_DAS_DORES'
export const LISTOU_INTENSIDADES_DAS_DORES = 'LISTOU_INTENSIDADES_DAS_DORES'
export const ERRO_AO_LISTAR_INTENSIDADES_DAS_DORES = 'ERRO_AO_LISTAR_INTENSIDADES_DAS_DORES'

//locais das dores
export const LISTAR_LOCAIS_DAS_DORES = 'LISTAR_LOCAIS_DAS_DORES'
export const LISTOU_LOCAIS_DAS_DORES = 'LISTOU_LOCAIS_DAS_DORES'
export const ERRO_AO_LISTAR_LOCAIS_DAS_DORES = 'ERRO_AO_LISTAR_LOCAIS_DAS_DORES'

//tipos de atividade fisica de lazer
export const LISTAR_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER = 'LISTAR_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER'
export const LISTOU_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER = 'LISTOU_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER'
export const ERRO_AO_LISTAR_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER = 'ERRO_AO_LISTAR_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER'


// especialidades médicas internas
export const LISTAR_ESPECIALIDADES_MEDICAS_INTERNAS = 'LISTAR_ESPECIALIDADES_MEDICAS_INTERNAS'
export const LISTOU_ESPECIALIDADES_MEDICAS_INTERNAS = 'LISTOU_ESPECIALIDADES_MEDICAS_INTERNAS'
export const ERRO_AO_LISTAR_ESPECIALIDADES_MEDICAS_INTERNAS = 'ERRO_AO_LISTAR_ESPECIALIDADES_MEDICAS_INTERNAS'
export const ADICIONAR_ESPECIALIDADE_MEDICA_INTERNA = 'ADICIONAR_ESPECIALIDADE_MEDICA_INTERNA'
export const ADICIONOU_ESPECIALIDADE_MEDICA_INTERNA = 'ADICIONOU_ESPECIALIDADE_MEDICA_INTERNA'
export const ERRO_AO_ADICIONAR_ESPECIALIDADE_MEDICA_INTERNA = 'ERRO_AO_ADICIONAR_ESPECIALIDADE_MEDICA_INTERNA'
export const REMOVER_ESPECIALIDADE_MEDICA_INTERNA = 'REMOVER_ESPECIALIDADE_MEDICA_INTERNA'
export const REMOVEU_ESPECIALIDADE_MEDICA_INTERNA = 'REMOVEU_ESPECIALIDADE_MEDICA_INTERNA'
export const ERRO_AO_REMOVER_ESPECIALIDADE_MEDICA_INTERNA = 'ERRO_AO_REMOVER_ESPECIALIDADE_MEDICA_INTERNA'

//campos externos do paciente
export const ADICIONAR_CAMPO_EXTERNO_DO_PACIENTE = 'ADICIONAR_CAMPO_EXTERNO_DO_PACIENTE'
export const ADICIONOU_CAMPO_EXTERNO_DO_PACIENTE = 'ADICIONOU_CAMPO_EXTERNO_DO_PACIENTE'
export const ERRO_AO_ADICIONAR_CAMPO_EXTERNO_DO_PACIENTE = 'ERRO_AO_ADICIONAR_CAMPO_EXTERNO_DO_PACIENTE'
export const ALTERAR_CAMPO_EXTERNO_DO_PACIENTE = 'ALTERAR_CAMPO_EXTERNO_DO_PACIENTE'
export const ALTEROU_CAMPO_EXTERNO_DO_PACIENTE = 'ALTEROU_CAMPO_EXTERNO_DO_PACIENTE'
export const ERRO_AO_ALTERAR_CAMPO_EXTERNO_DO_PACIENTE = 'ERRO_AO_ALTERAR_CAMPO_EXTERNO_DO_PACIENTE'
export const LISTAR_CAMPOS_EXTERNOS_DO_PACIENTE = 'LISTAR_CAMPOS_EXTERNOS_DO_PACIENTE'
export const LISTOU_CAMPOS_EXTERNOS_DO_PACIENTE = 'LISTOU_CAMPOS_EXTERNOS_DO_PACIENTE'
export const ERRO_AO_LISTAR_CAMPOS_EXTERNOS_DO_PACIENTE = 'ERRO_AO_LISTAR_CAMPOS_EXTERNOS_DO_PACIENTE'
export const REMOVER_CAMPO_EXTERNO_DO_PACIENTE = 'REMOVER_CAMPO_EXTERNO_DO_PACIENTE'
export const REMOVEU_CAMPO_EXTERNO_DO_PACIENTE = 'REMOVEU_CAMPO_EXTERNO_DO_PACIENTE'
export const ERRO_AO_REMOVER_CAMPO_EXTERNO_DO_PACIENTE = 'ERRO_AO_REMOVER_CAMPO_EXTERNO_DO_PACIENTE'
export const RECUPERAR_CAMPO_EXTERNO_DO_PACIENTE = 'RECUPERAR_CAMPO_EXTERNO_DO_PACIENTE'
export const RECUPEROU_CAMPO_EXTERNO_DO_PACIENTE = 'RECUPEROU_CAMPO_EXTERNO_DO_PACIENTE'
export const ERRO_AO_RECUPERAR_CAMPO_EXTERNO_DO_PACIENTE = 'ERRO_AO_RECUPERAR_CAMPO_EXTERNO_DO_PACIENTE'

//tipos de unidade
export const LISTAR_TIPOS_DE_UNIDADE = 'LISTAR_TIPOS_DE_UNIDADE'
export const LISTOU_TIPOS_DE_UNIDADE = 'LISTOU_TIPOS_DE_UNIDADE'
export const ERRO_AO_LISTAR_TIPOS_DE_UNIDADE = 'ERRO_AO_LISTAR_TIPOS_DE_UNIDADE'

//modalidades dos tipos de agendamento
export const LISTAR_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO = 'LISTAR_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO'
export const LISTOU_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO = 'LISTOU_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO'
export const ERRO_AO_LISTAR_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO = 'ERRO_AO_LISTAR_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO'

// células
export const LISTAR_CELULAS_DO_CADASTRO = 'LISTAR_CELULAS_DO_CADASTRO'
export const LISTOU_CELULAS_DO_CADASTRO = 'LISTOU_CELULAS_DO_CADASTRO'
export const ERRO_AO_LISTAR_CELULAS_DO_CADASTRO = 'ERRO_AO_LISTAR_CELULAS_DO_CADASTRO'
export const LISTAR_CELULAS = 'LISTAR_CELULAS'
export const LISTOU_CELULAS = 'LISTOU_CELULAS'
export const ERRO_AO_LISTAR_CELULAS = 'ERRO_AO_LISTAR_CELULAS'
export const ADICIONAR_CELULA = 'ADICIONAR_CELULA'
export const ADICIONOU_CELULA = 'ADICIONOU_CELULA'
export const ERRO_AO_ADICIONAR_CELULA = 'ERRO_AO_ADICIONAR_CELULA'
export const ALTERAR_CELULA = 'ALTERAR_CELULA'
export const ALTEROU_CELULA = 'ALTEROU_CELULA'
export const ERRO_AO_ALTERAR_CELULA = 'ERRO_AO_ALTERAR_CELULA'
export const REMOVER_CELULA = 'REMOVER_CELULAS'
export const REMOVEU_CELULA = 'REMOVEU_CELULA'
export const ERRO_AO_REMOVER_CELULA = 'ERROU_AO_REMOVER_CELULA'
export const RECUPERAR_CELULA_PELO_IDENTIFICADOR = 'RECUPERAR_CELULA_PELO_IDENTIFICADOR'
export const RECUPEROU_CELULA_PELO_IDENTIFICADOR = 'RECUPEROU_CELULA_PELO_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_CELULA_PELO_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_CELULA_PELO_IDENTIFICADOR'

// previsão de retorno
export const LISTAR_PREVISOES_DE_RETORNO = 'LISTAR_PREVISOES_DE_RETORNO'
export const LISTOU_PREVISOES_DE_RETORNO = 'LISTOU_PREVISOES_DE_RETORNO'
export const ERRO_AO_LISTAR_PREVISOES_DE_RETORNO = 'ERRO_AO_LISTAR_PREVISOES_DE_RETORNO'

//execuções do teste de fisioterapia
export const LISTAR_EXECUCOES_DO_TESTE_DE_FISIOTERAPIA = 'LISTAR_EXECUCOES_DO_TESTE_DE_FISIOTERAPIA'
export const LISTOU_EXECUCOES_DO_TESTE_DE_FISIOTERAPIA = 'LISTOU_EXECUCOES_DO_TESTE_DE_FISIOTERAPIA'
export const ERRO_AO_LISTAR_EXECUCOES_DO_TESTE_DE_FISIOTERAPIA = 'ERRO_AO_LISTAR_EXECUCOES_DO_TESTE_DE_FISIOTERAPIA'

//fabricantes
export const LISTAR_FABRICANTES = 'LISTAR_FABRICANTES'
export const LISTOU_FABRICANTES = 'LISTOU_FABRICANTES'
export const ERRO_AO_LISTAR_FABRICANTES = 'ERRO_AO_LISTAR_FABRICANTES'
export const LISTAR_FABRICANTES_DO_CADASTRO = 'LISTAR_FABRICANTES_DO_CADASTRO'
export const LISTOU_FABRICANTES_DO_CADASTRO = 'LISTOU_FABRICANTES_DO_CADASTRO'
export const ERRO_AO_LISTAR_FABRICANTES_DO_CADASTRO = 'ERRO_AO_LISTAR_FABRICANTES_DO_CADASTRO'
export const ADICIONAR_FABRICANTE = 'ADICIONAR_FABRICANTE'
export const ADICIONOU_FABRICANTE = 'ADICIONOU_FABRICANTE'
export const ERRO_AO_ADICIONAR_FABRICANTE = 'ERRO_AO_ADICIONAR_FABRICANTE'
export const RECUPERAR_FABRICANTE_PELO_IDENTIFICADOR = 'RECUPERAR_FABRICANTE_PELO_IDENTIFICADOR'
export const RECUPEROU_FABRICANTE_PELO_IDENTIFICADOR = 'RECUPEROU_FABRICANTE_PELO_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_FABRICANTE_PELO_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_FABRICANTE_PELO_IDENTIFICADOR'
export const ALTERAR_FABRICANTE = 'ALTERAR_FABRICANTE'
export const ALTEROU_FABRICANTE = 'ALTEROU_FABRICANTE'
export const ERRO_AO_ALTERAR_FABRICANTE = 'ERRO_AO_ALTERAR_FABRICANTE'
export const REMOVER_FABRICANTE = 'REMOVER_FABRICANTE'
export const REMOVEU_FABRICANTE = 'REMOVEU_FABRICANTE'
export const ERRO_AO_REMOVER_FABRICANTE = 'ERRO_AO_REMOVER_FABRICANTE'

//locais da aplicação
export const LISTAR_LOCAIS_DA_APLICACAO = 'LISTAR_LOCAIS_DA_APLICACAO'
export const LISTOU_LOCAIS_DA_APLICACAO = 'LISTOU_LOCAIS_DA_APLICACAO'
export const ERRO_AO_LISTAR_LOCAIS_DA_APLICACAO = 'ERRO_AO_LISTAR_LOCAIS_DA_APLICACAO'

//estados da memoria atual
export const LISTAR_ESTADOS_DA_MEMORIA_ATUAL = 'LISTAR_ESTADOS_DA_MEMORIA_ATUAL'
export const LISTOU_ESTADOS_DA_MEMORIA_ATUAL = 'LISTOU_ESTADOS_DA_MEMORIA_ATUAL'
export const ERRO_AO_LISTAR_ESTADOS_DA_MEMORIA_ATUAL = 'ERRO_AO_LISTAR_ESTADOS_DA_MEMORIA_ATUAL'

//estados da memoria comparado ha um ano atras
export const LISTAR_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS = 'LISTAR_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS'
export const LISTOU_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS = 'LISTOU_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS'
export const ERRO_AO_LISTAR_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS = 'ERRO_AO_LISTAR_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS'

//mini-mental
export const LISTAR_MINI_MENTAIS = 'LISTAR_MINI_MENTAIS'
export const LISTOU_MINI_MENTAIS = 'LISTOU_MINI_MENTAIS'
export const ERRO_AO_LISTAR_MINI_MENTAIS = 'ERRO_AO_LISTAR_MINI_MENTAIS'
export const ADICIONAR_MINI_MENTAL = 'ADICIONAR_MINI_MENTAL'
export const ADICIONOU_MINI_MENTAL = 'ADICIONOU_MINI_MENTAL'
export const ERRO_AO_ADICIONAR_MINI_MENTAL = 'ERRO_AO_ADICIONAR_MINI_MENTAL'
export const ALTERAR_MINI_MENTAL = 'ALTERAR_MINI_MENTAL'
export const ALTEROU_MINI_MENTAL = 'ALTEROU_MINI_MENTAL'
export const ERRO_AO_ALTERAR_MINI_MENTAL = 'ERRO_AO_ALTERAR_MINI_MENTAL'
export const REMOVER_MINI_MENTAL = 'REMOVER_MINI_MENTAL'
export const REMOVEU_MINI_MENTAL = 'REMOVEU_MINI_MENTAL'
export const ERRO_AO_REMOVER_MINI_MENTAL = 'ERRO_AO_REMOVER_MINI_MENTAL'
export const RECUPERAR_DESENHO_DO_MINI_MENTAL = 'RECUPERAR_DESENHO_DO_MINI_MENTAL'
export const RECUPEROU_DESENHO_DO_MINI_MENTAL = 'RECUPEROU_DESENHO_DO_MINI_MENTAL'
export const ERRO_AO_RECUPERAR_DESENHO_DO_MINI_MENTAL = 'ERRO_AO_RECUPERAR_DESENHO_DO_MINI_MENTAL'
export const ADICIONAR_INTERPRETACAO_MEDICA_DO_MINI_MENTAL = 'ADICIONAR_INTERPRETACAO_MEDICA_DO_MINI_MENTAL'
export const ADICIONOU_INTERPRETACAO_MEDICA_DO_MINI_MENTAL = 'ADICIONOU_INTERPRETACAO_MEDICA_DO_MINI_MENTAL'
export const ERRO_AO_ADICIONAR_INTERPRETACAO_MEDICA_DO_MINI_MENTAL = 'ERRO_AO_ADICIONAR_INTERPRETACAO_MEDICA_DO_MINI_MENTAL'
export const REMOVER_INTERPRETACAO_MEDICA_DO_MINI_MENTAL = 'REMOVER_INTERPRETACAO_MEDICA_DO_MINI_MENTAL'
export const REMOVEU_INTERPRETACAO_MEDICA_DO_MINI_MENTAL = 'REMOVEU_INTERPRETACAO_MEDICA_DO_MINI_MENTAL'
export const ERRO_AO_REMOVER_INTERPRETACAO_MEDICA_DO_MINI_MENTAL = 'ERRO_AO_REMOVER_INTERPRETACAO_MEDICA_DO_MINI_MENTAL'

//próximos agendamentos médicos do paciente
export const LISTAR_PROXIMOS_AGENDAMENTOS_MEDICOS_DO_PACIENTE = 'LISTAR_PROXIMOS_AGENDAMENTOS_MEDICOS_DO_PACIENTE'
export const LISTOU_PROXIMOS_AGENDAMENTOS_MEDICOS_DO_PACIENTE = 'LISTOU_PROXIMOS_AGENDAMENTOS_MEDICOS_DO_PACIENTE'
export const ERRO_AO_LISTAR_PROXIMOS_AGENDAMENTOS_MEDICOS_DO_PACIENTE = 'ERRO_AO_LISTAR_PROXIMOS_AGENDAMENTOS_MEDICOS_DO_PACIENTE'

//cids10
export const LISTAR_CIDS10 = 'LISTAR_CIDS10'
export const LISTOU_CIDS10 = 'LISTOU_CIDS10'
export const ERRO_AO_LISTAR_CIDS10 = 'ERRO_AO_LISTAR_CIDS10'

//respostas do phq9
export const LISTAR_RESPOSTAS_DO_PHQ9 = 'LISTAR_RESPOSTAS_DO_PHQ9'
export const LISTOU_RESPOSTAS_DO_PHQ9 = 'LISTOU_RESPOSTAS_DO_PHQ9'
export const ERRO_AO_LISTAR_RESPOSTAS_DO_PHQ9 = 'ERRO_AO_LISTAR_RESPOSTAS_DO_PHQ9'

//phq9
export const LISTAR_PHQS9 = 'LISTAR_PHQS9'
export const LISTOU_PHQS9 = 'LISTOU_PHQS9'
export const ERRO_AO_LISTAR_PHQS9 = 'ERRO_AO_LISTAR_PHQS9'
export const ADICIONAR_PHQ9 = 'ADICIONAR_PHQ9'
export const ADICIONOU_PHQ9 = 'ADICIONOU_PHQ9'
export const ERRO_AO_ADICIONAR_PHQ9 = 'ERRO_AO_ADICIONAR_PHQ9'
export const ALTERAR_PHQ9 = 'ALTERAR_PHQ9'
export const ALTEROU_PHQ9 = 'ALTEROU_PHQ9'
export const ERRO_AO_ALTERAR_PHQ9 = 'ERRO_AO_ALTERAR_PHQ9'
export const REMOVER_PHQ9 = 'REMOVER_PHQ9'
export const REMOVEU_PHQ9 = 'REMOVEU_PHQ9'
export const ERRO_AO_REMOVER_PHQ9 = 'ERRO_AO_REMOVER_PHQ9'

//salas da unidade
export const LISTAR_SALAS_DA_UNIDADE = 'LISTAR_SALAS_DA_UNIDADE'
export const LISTOU_SALAS_DA_UNIDADE = 'LISTOU_SALAS_DA_UNIDADE'
export const ERRO_AO_LISTAR_SALAS_DA_UNIDADE = 'ERRO_AO_LISTAR_SALAS_DA_UNIDADE'
export const ADICIONAR_SALA_NA_UNIDADE = 'ADICIONAR_SALA_NA_UNIDADE'
export const ADICIONOU_SALA_NA_UNIDADE = 'ADICIONOU_SALA_NA_UNIDADE'
export const ERRO_AO_ADICIONAR_SALA_NA_UNIDADE = 'ERRO_AO_ADICIONAR_SALA_NA_UNIDADE'
export const ALTERAR_SALA_DA_UNIDADE = 'ALTERAR_SALA_DA_UNIDADE'
export const ALTEROU_SALA_DA_UNIDADE = 'ALTEROU_SALA_DA_UNIDADE'
export const ERRO_AO_ALTERAR_SALA_DA_UNIDADE = 'ERRO_AO_ALTERAR_SALA_DA_UNIDADE'
export const REMOVER_SALA_DA_UNIDADE = 'REMOVER_SALA_DA_UNIDADE'
export const REMOVEU_SALA_DA_UNIDADE = 'REMOVEU_SALA_DA_UNIDADE'
export const ERRO_AO_REMOVER_SALA_DA_UNIDADE = 'ERRO_AO_REMOVER_SALA_DA_UNIDADE'
export const RECUPERAR_SALA_DA_UNIDADE_PELO_IDENTIFICADOR = 'RECUPERAR_SALA_DA_UNIDADE_PELO_IDENTIFICADOR'
export const RECUPEROU_SALA_DA_UNIDADE_PELO_IDENTIFICADOR = 'RECUPEROU_SALA_DA_UNIDADE_PELO_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_SALA_DA_UNIDADE_PELO_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_SALA_DA_UNIDADE_PELO_IDENTIFICADOR'
export const LISTAR_TIPOS_DE_SALA = 'LISTAR_TIPOS_DE_SALA'
export const LISTOU_TIPOS_DE_SALA = 'LISTOU_TIPOS_DE_SALA'
export const ERRO_AO_LISTAR_TIPOS_DE_SALA = 'ERRO_AO_LISTAR_TIPOS_DE_SALA'

//medicamentos
export const ADICIONAR_MEDICAMENTO_DO_PACIENTE = 'ADICIONAR_MEDICAMENTO_DO_PACIENTE'
export const ADICIONOU_MEDICAMENTO_DO_PACIENTE = 'ADICIONOU_MEDICAMENTO_DO_PACIENTE'
export const ERRO_AO_ADICIONAR_MEDICAMENTO_DO_PACIENTE = 'ERRO_AO_ADICIONAR_MEDICAMENTO_DO_PACIENTE'
export const ALTERAR_MEDICAMENTO_DO_PACIENTE = 'ALTERAR_MEDICAMENTO_DO_PACIENTE'
export const ALTEROU_MEDICAMENTO_DO_PACIENTE = 'ALTEROU_MEDICAMENTO_DO_PACIENTE'
export const ERRO_AO_ALTERAR_MEDICAMENTO_DO_PACIENTE = 'ERRO_AO_ALTERAR_MEDICAMENTO_DO_PACIENTE'
export const ALTERAR_STATUS_DO_MEDICAMENTO_DO_PACIENTE = 'ALTERAR_STATUS_DO_MEDICAMENTO_DO_PACIENTE'
export const ALTEROU_STATUS_DO_MEDICAMENTO_DO_PACIENTE = 'ALTEROU_STATUS_DO_MEDICAMENTO_DO_PACIENTE'
export const ERRO_AO_ALTERAR_STATUS_DO_MEDICAMENTO_DO_PACIENTE = 'ERRO_AO_ALTERAR_STATUS_DO_MEDICAMENTO_DO_PACIENTE'
export const LISTAR_MEDICAMENTOS_DO_PACIENTE = 'LISTAR_MEDICAMENTOS_DO_PACIENTE'
export const LISTOU_MEDICAMENTOS_DO_PACIENTE = 'LISTOU_MEDICAMENTOS_DO_PACIENTE'
export const ERRO_AO_LISTAR_MEDICAMENTOS_DO_PACIENTE = 'ERRO_AO_LISTAR_MEDICAMENTOS_DO_PACIENTE'
export const ALTERAR_POSICAO_DOS_MEDICAMENTOS_DO_PACIENTE = 'ALTERAR_POSICAO_DOS_MEDICAMENTOS_DO_PACIENTE'
export const ALTEROU_POSICAO_DOS_MEDICAMENTOS_DO_PACIENTE = 'ALTEROU_POSICAO_DOS_MEDICAMENTOS_DO_PACIENTE'
export const ERRO_AO_ALTERAR_POSICAO_DOS_MEDICAMENTOS_DO_PACIENTE = 'ERRO_AO_ALTERAR_POSICAO_DOS_MEDICAMENTOS_DO_PACIENTE'

//setores
export const LISTAR_SETORES = 'LISTAR_SETORES'
export const LISTOU_SETORES = 'LISTOU_SETORES'
export const ERRO_AO_LISTAR_SETORES = 'ERRO_AO_LISTAR_SETORES'
export const ADICIONAR_SETOR = 'ADICIONAR_SETOR'
export const ADICIONOU_SETOR = 'ADICIONOU_SETOR'
export const ERRO_AO_ADICIONAR_SETOR = 'ERRO_AO_ADICIONAR_SETOR'
export const RECUPERAR_SETOR_PELO_IDENTIFICADOR = 'RECUPERAR_SETOR_PELO_IDENTIFICADOR'
export const RECUPEROU_SETOR_PELO_IDENTIFICADOR = 'RECUPEROU_SETOR_PELO_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_SETOR_PELO_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_SETOR_PELO_IDENTIFICADOR'
export const ALTERAR_SETOR = 'ALTERAR_SETOR'
export const ALTEROU_SETOR = 'ALTEROU_SETOR'
export const ERRO_AO_ALTERAR_SETOR = 'ERRO_AO_ALTERAR_SETOR'
export const REMOVER_SETOR = 'REMOVER_SETOR'
export const REMOVEU_SETOR = 'REMOVEU_SETOR'
export const ERRO_AO_REMOVER_SETOR = 'ERRO_AO_REMOVER_SETOR'

//prioridade do encaminhamento
export const LISTAR_MOTIVOS_DE_PRIORIDADE = 'LISTAR_MOTIVOS_DE_PRIORIDADE'
export const LISTOU_MOTIVOS_DE_PRIORIDADE = 'LISTOU_MOTIVOS_DE_PRIORIDADE'
export const ERRO_AO_LISTAR_MOTIVOS_DE_PRIORIDADE = 'ERRO_AO_LISTAR_MOTIVOS_DE_PRIORIDADE'

//diagnosticos
export const ADICIONAR_DIAGNOSTICO_DO_PACIENTE = 'ADICIONAR_DIAGNOSTICO_DO_PACIENTE'
export const ADICIONOU_DIAGNOSTICO_DO_PACIENTE = 'ADICIONOU_DIAGNOSTICO_DO_PACIENTE'
export const ERRO_AO_ADICIONAR_DIAGNOSTICO_DO_PACIENTE = 'ERRO_AO_ADICIONAR_DIAGNOSTICO_DO_PACIENTE'
export const ALTERAR_STATUS_DO_DIAGNOSTICO_DO_PACIENTE = 'ALTERAR_STATUS_DO_DIAGNOSTICO_DO_PACIENTE'
export const ALTEROU_STATUS_DO_DIAGNOSTICO_DO_PACIENTE = 'ALTEROU_STATUS_DO_DIAGNOSTICO_DO_PACIENTE'
export const ERRO_AO_ALTERAR_STATUS_DO_DIAGNOSTICO_DO_PACIENTE = 'ERRO_AO_ALTERAR_STATUS_DO_DIAGNOSTICO_DO_PACIENTE'
export const LISTAR_DIAGNOSTICOS_DO_PACIENTE = 'LISTAR_DIAGNOSTICOS_DO_PACIENTE'
export const LISTOU_DIAGNOSTICOS_DO_PACIENTE = 'LISTOU_DIAGNOSTICOS_DO_PACIENTE'
export const ERRO_AO_LISTAR_DIAGNOSTICOS_DO_PACIENTE = 'ERRO_AO_LISTAR_DIAGNOSTICOS_DO_PACIENTE'
export const ALTERAR_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE = 'ALTERAR_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE'
export const ALTEROU_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE = 'ALTEROU_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE'
export const ERRO_AO_ALTERAR_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE = 'ERRO_AO_ALTERAR_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE'

//feriados trabalhados
export const ADICIONAR_FERIADO_TRABALHADO_DO_PROFISSIONAL = 'ADICIONAR_FERIADO_TRABALHADO_DO_PROFISSIONAL'
export const ADICIONOU_FERIADO_TRABALHADO_DO_PROFISSIONAL = 'ADICIONOU_FERIADO_TRABALHADO_DO_PROFISSIONAL'
export const ERRO_AO_ADICIONAR_FERIADO_TRABALHADO_DO_PROFISSIONAL = 'ERRO_AO_ADICIONAR_FERIADO_TRABALHADO_DO_PROFISSIONAL'
export const REMOVER_FERIADO_TRABALHADO_DO_PROFISSIONAL = 'REMOVER_FERIADO_TRABALHADO_DO_PROFISSIONAL'
export const REMOVEU_FERIADO_TRABALHADO_DO_PROFISSIONAL = 'REMOVEU_FERIADO_TRABALHADO_DO_PROFISSIONAL'
export const ERRO_AO_REMOVER_FERIADO_TRABALHADO_DO_PROFISSIONAL = 'ERRO_AO_REMOVER_FERIADO_TRABALHADO_DO_PROFISSIONAL'
export const LISTAR_FERIADOS_TRABALHADOS_DO_PROFISSIONAL = 'LISTAR_FERIADOS_TRABALHADOS_DO_PROFISSIONAL'
export const LISTOU_FERIADOS_TRABALHADOS_DO_PROFISSIONAL = 'LISTOU_FERIADOS_TRABALHADOS_DO_PROFISSIONAL'
export const ERRO_AO_LISTAR_FERIADOS_TRABALHADOS_DO_PROFISSIONAL = 'ERRO_AO_LISTAR_FERIADOS_TRABALHADOS_DO_PROFISSIONAL'

//pedidos de contato
export const LISTAR_PEDIDOS_DE_CONTATO = 'LISTAR_PEDIDOS_DE_CONTATO'
export const LISTOU_PEDIDOS_DE_CONTATO = 'LISTOU_PEDIDOS_DE_CONTATO'
export const ERRO_AO_LISTAR_PEDIDOS_DE_CONTATO = 'ERRO_AO_LISTAR_PEDIDOS_DE_CONTATO'
export const ADICIONAR_PEDIDO_DE_CONTATO = 'ADICIONAR_PEDIDO_DE_CONTATO'
export const ADICIONOU_PEDIDO_DE_CONTATO = 'ADICIONOU_PEDIDO_DE_CONTATO'
export const ERRO_AO_ADICIONAR_PEDIDO_DE_CONTATO = 'ERRO_AO_ADICIONAR_PEDIDO_DE_CONTATO'
export const RECUPERAR_PEDIDO_DE_CONTATO_PELO_IDENTIFICADOR = 'RECUPERAR_PEDIDO_DE_CONTATO_PELO_IDENTIFICADOR'
export const RECUPEROU_PEDIDO_DE_CONTATO_PELO_IDENTIFICADOR = 'RECUPEROU_PEDIDO_DE_CONTATO_PELO_IDENTIFICADOR'
export const ERRO_AO_RECUPERAR_PEDIDO_DE_CONTATO_PELO_IDENTIFICADOR = 'ERRO_AO_RECUPERAR_PEDIDO_DE_CONTATO_PELO_IDENTIFICADOR'
export const ALTERAR_PEDIDO_DE_CONTATO = 'ALTERAR_PEDIDO_DE_CONTATO'
export const ALTEROU_PEDIDO_DE_CONTATO = 'ALTEROU_PEDIDO_DE_CONTATO'
export const ERRO_AO_ALTERAR_PEDIDO_DE_CONTATO = 'ERRO_AO_ALTERAR_PEDIDO_DE_CONTATO'
export const REMOVER_PEDIDO_DE_CONTATO = 'REMOVER_PEDIDO_DE_CONTATO'
export const REMOVEU_PEDIDO_DE_CONTATO = 'REMOVEU_PEDIDO_DE_CONTATO'
export const ERRO_AO_REMOVER_PEDIDO_DE_CONTATO = 'ERRO_AO_REMOVER_PEDIDO_DE_CONTATO'
export const LISTAR_CIDADES_PARA_O_PEDIDO_DE_CONTATO = 'LISTAR_CIDADES_PARA_O_PEDIDO_DE_CONTATO'
export const LISTOU_CIDADES_PARA_O_PEDIDO_DE_CONTATO = 'LISTOU_CIDADES_PARA_O_PEDIDO_DE_CONTATO'
export const ERRO_AO_LISTAR_CIDADES_PARA_O_PEDIDO_DE_CONTATO = 'ERRO_AO_LISTAR_CIDADES_PARA_O_PEDIDO_DE_CONTATO'
export const LISTAR_PLANOS_DE_SAUDE_PARA_O_PEDIDO_DE_CONTATO = 'LISTAR_PLANOS_DE_SAUDE_PARA_O_PEDIDO_DE_CONTATO'
export const LISTOU_PLANOS_DE_SAUDE_PARA_O_PEDIDO_DE_CONTATO = 'LISTOU_PLANOS_DE_SAUDE_PARA_O_PEDIDO_DE_CONTATO'
export const ERRO_AO_LISTAR_PLANOS_DE_SAUDE_PARA_O_PEDIDO_DE_CONTATO = 'ERRO_AO_LISTAR_PLANOS_DE_SAUDE_PARA_O_PEDIDO_DE_CONTATO'