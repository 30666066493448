import React, { useEffect, useMemo } from 'react'
import { Formulario as FormularioDoFormik } from '../../formik/formulario'
import Spinner from '../../spinner/'
import { estahVazioOuNulo } from '../../../bibliotecas/texto'
import '../componentes/style.css'
import AcidoUrico from '../componentes/acido-urico'
import Albumina from '../componentes/albumina'
import Alerta from '../componentes/alerta'
import Altura from '../componentes/altura'
import Anamnese from '../componentes/anamnese'
import Anexos from '../componentes/anexos'
import AntiHbc from '../componentes/anti-hbc'
import AntiHbs from '../componentes/anti-hbs'
import AntiHcv from '../componentes/anti-hcv'
import AntiHiv from '../componentes/anti-hiv'
import Bicarbonato from '../componentes/bicarbonato'
import CalcioIonico from '../componentes/calcio-ionico'
import CalcioTotal from '../componentes/calcio-total'
import CircunferenciaDaPanturrilha from '../componentes/circunferencia-da-panturrilha'
import ColesterolTotal from '../componentes/colesterol-total'
import Conduta from '../componentes/conduta'
import Creatinina from '../componentes/creatinina'
import Encaminhamento from '../componentes/encaminhamento'
import ExameFisico from '../componentes/exame-fisico'
import Exames from '../componentes/exames'
import Ferritina from '../componentes/ferritina'
import ForcaDePreensaoPalmar from '../componentes/forca-de-preensao-palmar'
import Fosforo from '../componentes/fosforo'
import FrequenciaCardiaca from '../componentes/frequencia-cardiaca'
import Frutosamina from '../componentes/frutosamina'
import GlicemiaEmJejum from '../componentes/glicemia-em-jejum'
import GlicoHemoglobina from '../componentes/glico-hemoglobina'
import Hbsag from '../componentes/hbsag'
import Hdl from '../componentes/hdl'
import Hemoglobina from '../componentes/hemoglobina'
import IndiceDeSaturacaoDeTransferrina from '../componentes/indice-de-saturacao-de-transferrina'
import Informacao from '../componentes/informacao'
import Ldl from '../componentes/ldl'
import Orientacao from '../componentes/orientacao'
import Paratormonio from '../componentes/paratormonio'
import Passometro from '../componentes/passometro'
import Peso from '../componentes/peso'
import Potassio from '../componentes/potassio'
import Prescricao from '../componentes/prescricoes/prescricao'
import PrescricaoDaMemed from '../componentes/prescricoes/prescricao-da-memed'
import Prescricoes from '../componentes/prescricoes'
import PressaoArterial from '../componentes/pressao-arterial'
import PrevisaoDeRetorno from '../componentes/previsao-de-retorno'
import Proteinuria from '../componentes/proteinuria'
import RelacaoAlbuminaCreatinina from '../componentes/relacao-albumina-creatinina'
import RelacaoProteinaCreatinina from '../componentes/relacao-proteina-creatinina'
import ResultadoDeExame from '../componentes/resultado-de-exame'
import Rni from '../componentes/rni'
import Sodio from '../componentes/sodio'
import VideoConsulta from '../componentes/video-consulta'
import Titulo from '../componentes/titulo'
import DataDosExames from '../componentes/data-exames'
import Triglicerides from '../componentes/triglicerides'
import Tsh from '../componentes/tsh'
import Ureia from '../componentes/ureia'
import VelocidadeDaMarcha from '../componentes/velocidade-da-marcha'
import VitaminaD from '../componentes/vitamina-d'
import recuperarValoresIniciaisDaSecao from './recuperar-valores-iniciais-da-secao'
import verificarSecaoComExame from './verificar-secao-com-exame'
import Permissao from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import TempoDoAtendimento from '../tempo-do-atendimento'
import usePrescricao from '../componentes/prescricoes/usePrescricao'
import useAtalhos from './useAtalhos'

import {
  SALVAR_SECAO_DO_ATENDIMENTO,
  FAZER_DOWNLOAD_DO_ARQUIVO_DA_ORIENTACAO,
  ADICIONAR_ARQUIVO,
  REMOVER_PRESCRICAO_NA_MEMED,
  RECUPERAR_HISTORICO_PRESCRICAO_NA_MEMED,
  RECUPERAR_PRESCRICAO_PDF_NA_MEMED
} from '../../../acoes/tipos'

export default function Formulario(props) {
  const {
    salvar,
    recuperarPrescricaoPdfNaMemed,
    recuperarTeleconsultaDoAtendimento,
    componentes,
    atendimento,
    historicoDeCampos = {},
    secao,
    controlarDadosSalvos,
    setStatusDaSecao,
    statusDaSecao,
    id,
    tokensDeSugestao = {},
    abrirAdicionarTemplate,
    abrirFinalizarAtendimento,
    abrirCancelarAtendimento,
    paciente,
    ehRemotoPorVideoENaoTemTeleconsulta,
  } = props

  const {
    adicionarPrescricaoDoHistorico,
    limparPrescricaoDoHistorico,
    textoAdicionado,
    temPrescricao,
    temPrescricaoDaMemed
  } = usePrescricao()

  let CONTADOR_DE_EXAMES = -1
  const DATA_DO_EXAME_DA_SECAO = 'data_exame_' + secao.nome + '_'
  const MESMA_DATA_DO_EXAME_DA_SECAO = 'mesma_data_exame_' + secao.nome + '_'
  const TEM_PRESCRICAO = temPrescricao(componentes)
  const TEM_PRESCRICAO_DA_MEMED = temPrescricaoDaMemed(componentes)

  const fabricaDeComponentes = ({ tipo, metadados }, valores, setFieldValue) => {
    const nomeData = DATA_DO_EXAME_DA_SECAO + CONTADOR_DE_EXAMES
    const nomeMesmaData = MESMA_DATA_DO_EXAME_DA_SECAO + CONTADOR_DE_EXAMES
    const valorDataExame = valores[nomeData] === undefined ? null : valores[nomeData]

    switch (tipo) {
      case 'ACIDO_URICO':
        return <AcidoUrico key={tipo} metadados={metadados} valor={valores.acidoUrico} historico={historicoDeCampos.acido_urico} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'ALBUMINA':
        return <Albumina key={tipo} metadados={metadados} valor={valores.albumina} historico={historicoDeCampos.albumina} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'ALERTA':
        return <Alerta key={tipo} metadados={metadados} />
      case 'ALTURA':
        return <Altura key={tipo} metadados={metadados} historico={historicoDeCampos.altura} />
      case 'ANAMNESE':
        return <Anamnese key={tipo} metadados={metadados} tokensDeSugestao={tokensDeSugestao} setFieldValue={setFieldValue} abrirAdicionarTemplate={abrirAdicionarTemplate} statusDaSecao={statusDaSecao} />
      case 'ANEXOS':
        return <Anexos key={tipo} identificadorDoPaciente={atendimento.paciente.identificador} identificadorDoAtendimento={atendimento.identificador} metadados={metadados} valor={valores.anexos} />
      case 'ANTI_HBC':
        return <AntiHbc key={tipo} metadados={metadados} valor={valores.antiHbc} historico={historicoDeCampos.anti_hbc} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'ANTI_HBS':
        return <AntiHbs key={tipo} metadados={metadados} valor={valores.antiHbs} historico={historicoDeCampos.anti_hbs} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'ANTI_HCV':
        return <AntiHcv key={tipo} metadados={metadados} valor={valores.antiHcv} historico={historicoDeCampos.anti_hcv} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'ANTI_HIV':
        return <AntiHiv key={tipo} metadados={metadados} valor={valores.antiHiv} historico={historicoDeCampos.anti_hiv} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'BICARBONATO':
        return <Bicarbonato key={tipo} metadados={metadados} valor={valores.bicarbonato} historico={historicoDeCampos.bicarbonato} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'CALCIO_IONICO':
        return <CalcioIonico key={tipo} metadados={metadados} valor={valores.calcioIonico} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'CALCIO_TOTAL':
        return <CalcioTotal key={tipo} metadados={metadados} valor={valores.calcioTotal} historico={historicoDeCampos.calcio_total} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'CONDUTA':
        return <Conduta key={tipo} metadados={metadados} tokensDeSugestao={tokensDeSugestao} setFieldValue={setFieldValue} abrirAdicionarTemplate={abrirAdicionarTemplate} valor={valores.conduta} statusDaSecao={statusDaSecao} />
      case 'CIRCUNFERENCIA_DA_PANTURRILHA':
        return <CircunferenciaDaPanturrilha key={tipo} metadados={metadados} valor={valores.circunferenciaDaPanturrilha} historico={historicoDeCampos.circunferencia_da_panturrilha} />
      case 'COLESTEROL_TOTAL':
        return <ColesterolTotal key={tipo} metadados={metadados} valor={valores.colesterolTotal} historico={historicoDeCampos.colesterol_total} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'CREATININA':
        return <Creatinina key={tipo} metadados={metadados} valor={valores.creatinina} paciente={paciente} historico={historicoDeCampos.creatinina} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'ENCAMINHAMENTO':
        return <Encaminhamento key={tipo} identificadorDoPaciente={atendimento.paciente.identificador} identificadorDoAtendimento={atendimento.identificador} metadados={metadados} tokensDeSugestao={tokensDeSugestao} />
      case 'EXAME_FISICO':
        return <ExameFisico key={tipo} metadados={metadados} valor={valores.exameFisico} />
      case 'EXAMES':
        return <Exames key={tipo} metadados={metadados} valor={valores.exames} atendimento={atendimento} />
      case 'FERRITINA':
        return <Ferritina key={tipo} metadados={metadados} valor={valores.ferritina} historico={historicoDeCampos.ferritina} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'FORCA_DE_PREENSAO_PALMAR':
        return <ForcaDePreensaoPalmar key={tipo} metadados={metadados} valor={valores.forcaDePreensaoPalmar} historico={historicoDeCampos.forca_de_preensao_palmar} />
      case 'FOSFORO':
        return <Fosforo key={tipo} metadados={metadados} valor={valores.fosforo} historico={historicoDeCampos.fosforo} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'FREQUENCIA_CARDIACA':
        return <FrequenciaCardiaca key={tipo} metadados={metadados} />
      case 'FRUTOSAMINA':
        return <Frutosamina key={tipo} metadados={metadados} valor={valores.frutosamina} historico={historicoDeCampos.frutosamina} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'GLICEMIA_EM_JEJUM':
        return <GlicemiaEmJejum key={tipo} metadados={metadados} valor={valores.glicemiaEmJejum} historico={historicoDeCampos.glicemia_em_jejum} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'GLICO_HEMOGLOBINA':
        return <GlicoHemoglobina key={tipo} metadados={metadados} valor={valores.glicoHemoglobina} historico={historicoDeCampos.glico_hemoglobina} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'HBSAG':
        return <Hbsag key={tipo} metadados={metadados} valor={valores.hbsag} historico={historicoDeCampos.hbsag} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'HDL':
        return <Hdl key={tipo} metadados={metadados} valor={valores.hdl} historico={historicoDeCampos.hdl} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'HEMOGLOBINA':
        return <Hemoglobina key={tipo} metadados={metadados} valor={valores.hemoglobina} historico={historicoDeCampos.hemoglobina} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'INDICE_DE_SATURACAO_DE_TRANSFERRINA':
        return <IndiceDeSaturacaoDeTransferrina key={tipo} metadados={metadados} valor={valores.indiceDeSaturacaoDeTransferrina} historico={historicoDeCampos.indice_de_saturacao_de_transferrina} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'INFORMACAO':
        return <Informacao key={tipo} metadados={metadados} />
      case 'LDL':
        return <Ldl key={tipo} metadados={metadados} valor={valores.ldl} historico={historicoDeCampos.ldl} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'ORIENTACOES':
        return <Orientacao key={tipo} metadados={metadados} valor={valores.orientacoes} />
      case 'PARATORMONIO':
        return <Paratormonio key={tipo} metadados={metadados} valor={valores.paratormonio} historico={historicoDeCampos.paratormonio} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'PASSOMETRO':
        return <Passometro key={tipo} metadados={metadados} tokensDeSugestao={tokensDeSugestao} setFieldValue={setFieldValue} abrirAdicionarTemplate={abrirAdicionarTemplate} />
      case 'PESO':
        return <Peso key={tipo} metadados={metadados} valor={valores.peso} altura={valores.altura} historico={historicoDeCampos.peso} />
      case 'POTASSIO':
        return <Potassio key={tipo} metadados={metadados} valor={valores.potassio} historico={historicoDeCampos.potassio} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'PRESCRICOES':
      case 'PRESCRICOES_DA_MEMED':
        return (
          <Prescricoes
            key={`${tipo}_pai`}
            historico={historicoDeCampos.prescricoes}
            identificadorDoPaciente={atendimento.paciente.identificador}
            identificadorDoAtendimento={{ identificador: atendimento.identificador, identificadorPublico: atendimento.identificadorPublico }}
            adicionarPrescricaoDoHistorico={adicionarPrescricaoDoHistorico}
            limparPrescricaoDoHistorico={limparPrescricaoDoHistorico}
            temMemed={TEM_PRESCRICAO_DA_MEMED}
            temLifecode={TEM_PRESCRICAO}
            textoAdicionado={textoAdicionado}>
            {tipo === 'PRESCRICOES' && <Prescricao key={tipo} metadados={metadados} tokensDeSugestao={tokensDeSugestao} setFieldValue={setFieldValue} abrirAdicionarTemplate={abrirAdicionarTemplate} />}
            {tipo === 'PRESCRICOES_DA_MEMED' && <PrescricaoDaMemed key={tipo} />}

          </Prescricoes>
        )
      case 'PRESSAO_ARTERIAL':
        return <PressaoArterial key={tipo} metadados={metadados} historico={historicoDeCampos.pressao_arterial} valor={valores.pressaoArterial} />
      case 'PREVISAO_DE_RETORNO':
        return <PrevisaoDeRetorno key={tipo} metadados={metadados} />
      case 'PROTEINURIA_24_HORAS':
        return <Proteinuria key={tipo} metadados={metadados} valor={valores.proteinuria24Horas} historico={historicoDeCampos.proteinuria_24_horas} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'RELACAO_ALBUMINA_CREATININA':
        return <RelacaoAlbuminaCreatinina key={tipo} metadados={metadados} valor={valores.relacaoAlbuminaCreatinina} historico={historicoDeCampos.relacao_albumina_creatinina} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'RELACAO_PROTEINA_CREATININA':
        return <RelacaoProteinaCreatinina key={tipo} metadados={metadados} valor={valores.relacaoProteinaCreatinina} historico={historicoDeCampos.relacao_proteina_creatinina} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'RESULTADO_DE_EXAME':
        return <ResultadoDeExame key={tipo} metadados={metadados} valor={valores.resultadoDeExame} statusDaSecao={statusDaSecao} />
      case 'RNI':
        return <Rni key={tipo} metadados={metadados} valor={valores.rni} historico={historicoDeCampos.rni} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'SODIO':
        return <Sodio key={tipo} metadados={metadados} valor={valores.sodio} historico={historicoDeCampos.sodio} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'TITULO':
        return <Titulo key={`${tipo}_${metadados.texto}`} metadados={metadados} />
      case 'TRIGLICERIDES':
        return <Triglicerides key={tipo} metadados={metadados} valor={valores.triglicerides} historico={historicoDeCampos.triglicerides} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'TSH':
        return <Tsh key={tipo} metadados={metadados} valor={valores.tsh} historico={historicoDeCampos.tsh} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'UREIA':
        return <Ureia key={tipo} metadados={metadados} valor={valores.ureia} historico={historicoDeCampos.ureia} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'VIDEO_CONSULTA':
        return <VideoConsulta key={tipo} identificadorDoAtendimento={atendimento.identificadorPublico} ehRemotoPorVideoENaoTemTeleconsulta={ehRemotoPorVideoENaoTemTeleconsulta} />
      case 'VITAMINA_D':
        return <VitaminaD key={tipo} metadados={metadados} valor={valores.vitaminaD} historico={historicoDeCampos.vitamina_d} exibirDataExame={valores[nomeMesmaData]} dataExame={valorDataExame} setFieldValue={setFieldValue} />
      case 'VELOCIDADE_DA_MARCHA':
        return <VelocidadeDaMarcha key={tipo} metadados={metadados} valor={valores.velocidadeDaMarcha} historico={historicoDeCampos.velocidade_da_marcha} />
      default:
        return <div key={tipo}>{tipo}</div>
    }
  }

  const salvarSecao = async (atendimento, dados) => {
    let dadosDaSecao = {
      ...dados
    }

    delete dadosDaSecao.template_anamnese

    if (dados.prescricoes) {
      dadosDaSecao.prescricoes = await formatarPrescricoes(dados.prescricoes)
    }

    if (dados.prescricoesDaMemed) {
      dadosDaSecao.prescricoesDaMemed = await formatarPrescricoesDaMemed(dados.prescricoesDaMemed)
    }

    if (dados.exames) {
      dadosDaSecao.exames = formatarExames(dados.exames)
    }

    if (dados.anexos) {
      dadosDaSecao.anexos = formatarAnexos(dados.anexos)
    }

    if (dados.acidoUrico) {
      dadosDaSecao.acidoUrico = dados.acidoUrico.valor ? dados.acidoUrico : null
    }

    if (dados.albumina) {
      dadosDaSecao.albumina = dados.albumina.valor ? dados.albumina : null
    }

    if (dados.antiHbc) {
      dadosDaSecao.antiHbc = dados.antiHbc.valor ? dados.antiHbc : null
    }

    if (dados.antiHbs) {
      dadosDaSecao.antiHbs = dados.antiHbs.valor ? dados.antiHbs : null
    }

    if (dados.antiHcv) {
      dadosDaSecao.antiHcv = dados.antiHcv.valor ? dados.antiHcv : null
    }

    if (dados.antiHiv) {
      dadosDaSecao.antiHiv = dados.antiHiv.valor ? dados.antiHiv : null
    }

    if (dados.bicarbonato) {
      dadosDaSecao.bicarbonato = dados.bicarbonato.valor ? dados.bicarbonato : null
    }

    if (dados.calcioIonico) {
      dadosDaSecao.calcioIonico = dados.calcioIonico.valor ? dados.calcioIonico : null
    }

    if (dados.calcioTotal) {
      dadosDaSecao.calcioTotal = dados.calcioTotal.valor ? dados.calcioTotal : null
    }

    if (dados.colesterolTotal) {
      dadosDaSecao.colesterolTotal = dados.colesterolTotal.valor ? dados.colesterolTotal : null
    }

    if (dados.creatinina) {
      dadosDaSecao.creatinina = dados.creatinina.valor ? dados.creatinina : null
    }

    if (dados.ferritina) {
      dadosDaSecao.ferritina = dados.ferritina.valor ? dados.ferritina : null
    }

    if (dados.fosforo) {
      dadosDaSecao.fosforo = dados.fosforo.valor ? dados.fosforo : null
    }

    if (dados.frutosamina) {
      dadosDaSecao.frutosamina = dados.frutosamina.valor ? dados.frutosamina : null
    }

    if (dados.glicemiaEmJejum) {
      dadosDaSecao.glicemiaEmJejum = dados.glicemiaEmJejum.valor ? dados.glicemiaEmJejum : null
    }

    if (dados.glicoHemoglobina) {
      dadosDaSecao.glicoHemoglobina = dados.glicoHemoglobina.valor ? dados.glicoHemoglobina : null
    }

    if (dados.hbsag) {
      dadosDaSecao.hbsag = dados.hbsag.valor ? dados.hbsag : null
    }

    if (dados.hdl) {
      dadosDaSecao.hdl = dados.hdl.valor ? dados.hdl : null
    }

    if (dados.hemoglobina) {
      dadosDaSecao.hemoglobina = dados.hemoglobina.valor ? dados.hemoglobina : null
    }

    if (dados.indiceDeSaturacaoDeTransferrina) {
      dadosDaSecao.indiceDeSaturacaoDeTransferrina = dados.indiceDeSaturacaoDeTransferrina.valor ? dados.indiceDeSaturacaoDeTransferrina : null
    }

    if (dados.ldl) {
      dadosDaSecao.ldl = dados.ldl.valor ? dados.ldl : null
    }

    if (dados.paratormonio) {
      dadosDaSecao.paratormonio = dados.paratormonio.valor ? dados.paratormonio : null
    }

    if (dados.potassio) {
      dadosDaSecao.potassio = dados.potassio.valor ? dados.potassio : null
    }

    if (dados.previsaoDeRetorno) {
      dadosDaSecao.previsaoDeRetorno = dados.previsaoDeRetorno ? dados.previsaoDeRetorno : null
    }

    if (dados.proteinuria24Horas) {
      dadosDaSecao.proteinuria24Horas = dados.proteinuria24Horas.valor ? dados.proteinuria24Horas : null
    }

    if (dados.relacaoAlbuminaCreatinina) {
      dadosDaSecao.relacaoAlbuminaCreatinina = dados.relacaoAlbuminaCreatinina.valor ? dados.relacaoAlbuminaCreatinina : null
    }

    if (dados.relacaoProteinaCreatinina) {
      dadosDaSecao.relacaoProteinaCreatinina = dados.relacaoProteinaCreatinina.proteinas ? dados.relacaoProteinaCreatinina : null
    }

    if (dados.rni) {
      dadosDaSecao.rni = dados.rni.valor ? dados.rni : null
    }

    if (dados.sodio) {
      dadosDaSecao.sodio = dados.sodio.valor ? dados.sodio : null
    }

    if (dados.triglicerides) {
      dadosDaSecao.triglicerides = dados.triglicerides.valor ? dados.triglicerides : null
    }

    if (dados.tsh) {
      dadosDaSecao.tsh = dados.tsh.valor ? dados.tsh : null
    }

    if (dados.ureia) {
      dadosDaSecao.ureia = dados.ureia.valor ? dados.ureia : null
    }

    if (dados.vitaminaD) {
      dadosDaSecao.vitaminaD = dados.vitaminaD.valor ? dados.vitaminaD : null
    }

    const salvou = await salvar(atendimento, dadosDaSecao)
    if (salvou) {
      setStatusDaSecao(statusDaSecao.filter(x => x !== dados.secao))
    }

  }

  const formatarPrescricoes = async (prescricoes = []) => {
    if (prescricoes.length === 0) return []
    return prescricoes.filter(x => x && x.texto && !estahVazioOuNulo(x.texto))
  }

  const formatarPrescricoesDaMemed = async (prescricoes = []) => {
    if (prescricoes.length === 0) return []
    return await Promise.all(removePrescricoesDuplicadas(prescricoes).filter(x => x && x.texto).map(async (i) => asyncFunctionRecuperarPdfNaMemed(i)))
  }

  const asyncFunctionRecuperarPdfNaMemed = async (item) => {
    if (item.linkExterno) return item

    const pdf = await recuperarPrescricaoPdfNaMemed(null, item.identificadorExterno)
    return { ...item, linkExterno: pdf }
  }

  function removePrescricoesDuplicadas(prescricoes) {
    const prescricoesFiltradas = prescricoes.filter((prescricao, index, array) => {
      return array.findIndex((p) => p.identificadorExterno === prescricao.identificadorExterno) === index
    })

    return prescricoesFiltradas
  }

  const formatarExames = (exames = []) => {
    if (exames.length === 0) return []

    return exames.map(item => {
      let registro = {
        ...item,
        arquivos: item.arquivos.length > 0 ? item.arquivos.map(x => x.identificador) : [],
      }

      return registro
    })
  }

  const formatarAnexos = (anexos = []) => {
    if (anexos.length === 0) return []
    return anexos.map(item => item.identificador)
  }

  const possuiCamposParaSalvarNoFormulario = secao => {
    const CAMPOS_COM_ACAO_DE_SALVAR = ['TITULO']

    if (!secao.componentes) return false

    for (let i = 0; i < secao.componentes.length; i++) {
      const componente = secao.componentes[i]
      const possui = !CAMPOS_COM_ACAO_DE_SALVAR.find(x => x === componente.tipo.toUpperCase())

      if (possui) {
        return ({ [secao.nome]: true })
      }
    }

    return ({ [secao.nome]: false })
  }

  useEffect(() => {
    if (atendimento.identificadorPublico) {
      const timer = setTimeout(() => {
        recuperarTeleconsultaDoAtendimento(atendimento.identificadorPublico);
      }, 1000)

      return () => clearTimeout(timer);
    }
  }, [atendimento.identificadorPublico, recuperarTeleconsultaDoAtendimento]);


  const possuiBotaoSalvar = possuiCamposParaSalvarNoFormulario(secao)
  const valoresIniciais = useMemo(() => recuperarValoresIniciaisDaSecao(componentes, atendimento, secao), [componentes, atendimento, secao])

  useAtalhos(null, statusDaSecao)

  return (
    <Spinner operacoes={[
      SALVAR_SECAO_DO_ATENDIMENTO,
      FAZER_DOWNLOAD_DO_ARQUIVO_DA_ORIENTACAO,
      ADICIONAR_ARQUIVO,
      REMOVER_PRESCRICAO_NA_MEMED,
      RECUPERAR_HISTORICO_PRESCRICAO_NA_MEMED,
      RECUPERAR_PRESCRICAO_PDF_NA_MEMED
    ]}>
      <FormularioDoFormik
        className='formulario-de-atendimento mt-2 mb-4'
        reinicializar={true}
        valoresIniciais={valoresIniciais}
        acao={dados => salvarSecao(atendimento, dados)}
        controlarDadosSalvos={controlarDadosSalvos}
        id={id}
      >
        {({ values, setFieldValue }) => (
          <>
            <r-grid columns-md={6} columns-lg={12} class='align-items-stretch'>
              {componentes.map((x, index) => {
                CONTADOR_DE_EXAMES = index === 0 ? -1 : CONTADOR_DE_EXAMES
                const ehUmaSecaoComExame = verificarSecaoComExame(index, x, componentes)

                if (ehUmaSecaoComExame) {

                  CONTADOR_DE_EXAMES++
                  const nomeData = DATA_DO_EXAME_DA_SECAO + CONTADOR_DE_EXAMES
                  const nomeMesmaData = MESMA_DATA_DO_EXAME_DA_SECAO + CONTADOR_DE_EXAMES

                  return (
                    <React.Fragment key={x.tipo + '_' + index}>
                      {fabricaDeComponentes(x, values, setFieldValue)}
                      <DataDosExames valores={values} nomeData={nomeData} nomeMesmaData={nomeMesmaData} contadorDeExames={CONTADOR_DE_EXAMES} componentes={componentes} secao={secao} />
                    </React.Fragment>
                  )
                }
                return fabricaDeComponentes(x, values, setFieldValue)
              })}
            </r-grid>
            <r-cell span={4} span-md={2} span-lg={6} class='d-flex justify-content-end  mt-2 d-lg-none align-space-between'>
              {possuiBotaoSalvar[values.secao] &&
                <>
                  <Permissao permissoes={[permissoes.CANCELAR_ATENDIMENTO_INICIADO]}>
                    <button type='button' className='button --danger minw-auto' onClick={abrirCancelarAtendimento}>Cancelar</button>
                  </Permissao>
                  <button type='submit' id='salvar' className={`button --primary w-sm-100 ${!statusDaSecao.includes(values.secao) ? 'is-disabled' : ''}`}>Salvar</button>
                  <div
                    className={`assistence-end botao-finalizar-atendimento ${statusDaSecao.length > 0 ? 'is-disabled' : ''}`}
                    onClick={abrirFinalizarAtendimento}
                    id='finalizar'
                  >
                    <span className='assistence-end__label'>Finalizar</span>
                    <span className='assistence-end__time'>
                      <i className='icon icon-clock'></i>
                      {atendimento && <TempoDoAtendimento inicio={atendimento.inicio} />}
                    </span>
                  </div>
                </>
              }
            </r-cell>
          </>
        )}
      </FormularioDoFormik>
    </Spinner>
  )
}