import camposExternos from './campos-externos'
import convenios from './convenios'
import programasDoPaciente from './programas'
import tags from './tags'
import planosDeCuidado from './planos-de-cuidado'
import {
  ADICIONOU_PACIENTE,
  LISTOU_ASSOCIACOES,
  LISTOU_CELULAS,
  LISTOU_CONSULTORES_DO_PACIENTE,
  LISTOU_ENFERMEIROS_DO_PACIENTE,
  LISTOU_MEDICOS_DO_PACIENTE,
  LISTOU_MOTIVOS_DE_CANCELAMENTO_DO_CONVENIO,
  LISTOU_MOTIVOS_DE_SAIDA_DOS_PLANOS_DE_CUIDADO,
  LISTOU_MOTIVOS_DE_SAIDA_DOS_PROGRAMAS,
  LISTOU_OPERADORAS_DO_PACIENTE,
  LISTOU_PACIENTES,
  LISTOU_PSICOLOGOS_DO_PACIENTE,
  LISTOU_TAGS_DE_PACIENTES,
  RECUPEROU_PACIENTE_POR_IDENTIFICADOR,
  REMOVEU_FOTO_DO_PACIENTE,
  SALVOU_PACIENTE,
} from '../../acoes/tipos'

const estadoInicial = {
  consultores: [],
  enfermeiros: [],
  filtros: {
    obito: ['Ativo'],
    termo: '',
  },
  itemAtual: {
    convenios: [],
    programas: [],
    planosDeCuidado: [],
    tags: [],
    camposExternos: []
  },
  medicos: [],
  motivosDeCancelamentoDoConvenio: [],
  motivosDeSaidaDosProgramas: [],
  motivosDeSaidaDosPlanosDeCuidado: [],
  operadoras: [],
  ordenacao: { nome: 'nome', ordem: '+' },
  paginaDeDados: {},
  psicologos: [],
  tagsDePacientes: [],
  associacoes: [],
  celulas: []
}

const pacientes = (state, action) => {
  switch (action.type) {
    case ADICIONOU_PACIENTE: {
      return {
        ...state,
        itemAtual: action.paciente,
      }
    }

    case RECUPEROU_PACIENTE_POR_IDENTIFICADOR: {
      return {
        ...state,
        itemAtual: action.paciente,
      }
    }

    case SALVOU_PACIENTE: {
      return {
        ...state,
        itemAtual: action.paciente,
      }
    }

    case REMOVEU_FOTO_DO_PACIENTE: {
      const paciente = state.itemAtual?.identificador === action.identificador

      if (paciente) {
        return {
          ...state,
          itemAtual: {
            ...state.itemAtual,
            foto: null
          }
        }
      }

      return state
    }

    case LISTOU_PACIENTES: {
      const { filtros, ordenacao, paginaDeDados } = action

      return {
        ...state,
        filtros,
        ordenacao,
        paginaDeDados,
      }
    }

    case LISTOU_ENFERMEIROS_DO_PACIENTE: {
      return {
        ...state,
        enfermeiros: action.enfermeiros.map(x => ({...x, label: x.tratamento ? x.tratamento + ' ' + x.nome: x.nome})),
      }
    }

    case LISTOU_CONSULTORES_DO_PACIENTE: {
      return {
        ...state,
        consultores: action.consultores.map(x => ({...x, label: x.tratamento ? x.tratamento + ' ' + x.nome: x.nome})),
      }
    }

    case LISTOU_MEDICOS_DO_PACIENTE: {
      return {
        ...state,
        medicos: action.medicos.map(x => ({...x, label: x.tratamento ? x.tratamento + ' ' + x.nome: x.nome})),
      }
    }

    case LISTOU_PSICOLOGOS_DO_PACIENTE: {
      return {
        ...state,
        psicologos: action.psicologos.map(x => ({...x, label: x.tratamento ? x.tratamento + ' ' + x.nome: x.nome})),
      }
    }

    case LISTOU_TAGS_DE_PACIENTES: {
      return {
        ...state,
        tagsDePacientes: action.tagsDePacientes,
      }
    }

    case LISTOU_MOTIVOS_DE_CANCELAMENTO_DO_CONVENIO: {
      return {
        ...state,
        motivosDeCancelamentoDoConvenio: action.motivos,
      }
    }

    case LISTOU_MOTIVOS_DE_SAIDA_DOS_PROGRAMAS: {
      return {
        ...state,
        motivosDeSaidaDosProgramas: action.motivosDeSaidaDosProgramas,
      }
    }

    case LISTOU_MOTIVOS_DE_SAIDA_DOS_PLANOS_DE_CUIDADO: {
      return {
        ...state,
        motivosDeSaidaDosPlanosDeCuidado: action.motivosDeSaidaDosPlanosDeCuidado,
      }
    }

    case LISTOU_OPERADORAS_DO_PACIENTE: {
      return {
        ...state,
        operadoras: action.operadoras
      }
    }

    case LISTOU_ASSOCIACOES: {
      return {
        ...state,
        associacoes: action.associacoes
      }
    }

    case LISTOU_CELULAS: {
      return {
        ...state,
        celulas: action.celulas
      }
    }

    default: {
      return state
    }
  }
}

export default (state = estadoInicial, action) => {
  const dados = pacientes(state, action)

  return {
    ...dados,
    itemAtual: {
      ...dados.itemAtual,
      camposExternos: camposExternos(state.itemAtual.camposExternos, action),
      convenios: convenios(state.itemAtual.convenios, action),
      programas: programasDoPaciente(state.itemAtual.programas, action),
      planosDeCuidado: planosDeCuidado(state.itemAtual.planosDeCuidado, action),
      tags: tags(state.itemAtual.tags, action),
    }
  }
}