import React from 'react'
import { Link } from 'react-router-dom'
import { Formulario as FormularioDoFormik, Select, Telefone, Input, DatePicker } from '../../../formik/formulario'
import Spinner from '../../../spinner'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'

import {
  SELECIONAR_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO
} from '../../../../acoes/tipos'

export default function PreCadastroDePaciente({
  adicionarPreCadastroDoPaciente,
  listarPacientesDoAgendamento,
  nomeDoPaciente,
  fechar,
  ordem,
  selecionarPacienteDoAgendamento,
  sexos
}) {

  const adicionarPreCadastroDoPacienteInformado = async formulario => {
    const dados = {
      ativo: true,
      nome: formulario.nome,
      nomeDaMae: formulario.nomeDaMae,
      sexo: formulario.sexo,
      dataDeNascimento: formulario.dataDeNascimento,
      telefones: [{
        identificador: uuidv4(),
        proprietario: 'paciente',
        nome: '',
        grauDeRelacao: '',
        numero: formulario.telefone
      }],
      observacoes: formulario.observacoes,
      origemDosDados: 'prontuario',
      preCadastro: true,
    }

    const adicionou = await adicionarPreCadastroDoPaciente(dados)

    if (adicionou) {
      listarPacientesDoAgendamento({ termo: adicionou.nome }, 1, ordem, 50)
      selecionarPacienteDoAgendamento(adicionou.identificador)
      fechar()
    }
  }

  return (
    <Spinner operacoes={[ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO, SELECIONAR_PACIENTE_DO_WIZARD_DE_AGENDAMENTO]}>
      <FormularioDoFormik
        reinicializar={true}
        valoresIniciais={{
          nome: nomeDoPaciente ? nomeDoPaciente : '',
          nomeDaMae: '',
          telefone: '',
          sexo: '',
          dataDeNascimento: '',
          observacoes: '',
        }}
        acao={adicionarPreCadastroDoPacienteInformado}
        esquemaDeValidacoes={Yup.object().shape({
          nome: Yup.string().required('Obrigatório'),
          nomeDaMae: Yup.string().required('Obrigatório'),
          dataDeNascimento: Yup.string().required('Obrigatório').nullable(),
          sexo: Yup.string().required('Obrigatório'),
          telefone: Yup.string().ehUmNumeroDeTelefoneValido('Telefone inválido. Ex.: 99 9999-9999.').required('Obrigatório')
        })}
      >
        <fieldset>
          <h2 className='form-title'>Pré-Cadastro de Paciente</h2>
          <r-grid columns-md={12} columns-lg={12}>
            <r-cell span={4} span-md={12} span-lg={12} class='form-item'>
              <p className='form-item__choice'>Caso queira fazer o cadastro completo do paciente, acesse o link
                <strong>
                  <Link to={`/cadastros/pacientes/novo`} target='_blank'>{' '}[Cadastro do Paciente]</Link>.
                </strong>
              </p>
            </r-cell>
            <r-cell span={4} span-md={12} span-lg={12}>
              <Input
                nome='nome'
                tabIndex={1}
                titulo='Nome *'
              />
            </r-cell>
            <r-cell span={4} span-md={12} span-lg={12}>
              <Input
                nome='nomeDaMae'
                tabIndex={2}
                titulo='Nome da Mãe *'
              />
            </r-cell>
            <r-cell span={4} span-md={4} span-lg={4}>
              <Select
                nome='sexo'
                tabIndex={3}
                titulo='Sexo *'
                itens={sexos}
                campoCodigo='codigo'
                campoDescricao='nome'
              />
            </r-cell>
            <r-cell span={4} span-md={4} span-lg={4}>
              <DatePicker
                nome='dataDeNascimento'
                tabIndex={4}
                titulo='Data de Nascimento *'
                maxDate={new Date()}
              />
            </r-cell>
            <r-cell span={4} span-md={4} span-lg={4}>
              <Telefone
                nome='telefone'
                tabIndex={5}
                titulo='Telefone *'
              />
            </r-cell>
            <r-cell span={4} span-md={12} span-lg={12}>
              <Input
                as='textarea'
                nome='observacoes'
                tabIndex={6}
                titulo='Observações'
              />
            </r-cell>
          </r-grid>
          <div className='list-btn'>
            <button type='button' onClick={() => fechar()} className='button --light'>Cancelar</button>
            <button type='submit' className='button --primary'>Adicionar Pré-Cadastro</button>
          </div>
        </fieldset>
      </FormularioDoFormik>
    </Spinner>
  )
}