import React, { useState, useRef, useEffect } from 'react'
import moment from 'moment'
import Modal from 'react-modal'
import * as Yup from 'yup'
import Spinner from '../../spinner'
import { Contador, Opcoes, Input, Select, DatePicker, HourPicker, Checkbox, Telefone, Formulario as Form } from '../../formik/formulario'
import HorariosLivres from './horarios-livres'
import FormularioPreCadastroDoPaciente from './formulario-pre-cadastro-do-paciente'
import FormularioNovoBloqueio from '../../usuarios/agenda/bloqueio/formulario'
import useCliqueForaDoElemento from '../../../bibliotecas/clique-fora-do-elemento'
import { diferencaEmMinutosEntreDatas } from '../../../bibliotecas/data'
import Permissao, { usePossuiAsPermissoes } from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import NotificacaoQuandoOPacienteJaTemAgendamentosNosProximosMeses from './agendamentos-nos-proximos-meses'
import NotificacaoQuandoOPacienteNaoPossuiProgramaAtivo from './paciente-sem-programa'
import { WhatsApp } from '../../../design/icones'
import {
  ADICIONAR_AGENDAMENTO,
  LISTAR_PACIENTES_DO_AGENDAMENTO,
  LISTAR_GRAUS_DE_RELACOES,
  LISTAR_PROPRIETARIOS_DE_TELEFONES,
  LISTAR_RECORRENCIAS_DO_AGENDAMENTO,
  LISTAR_TIPOS_DE_AGENDAMENTO_DO_AGENDAMENTO,
  RECUPERAR_CONVENIOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO,
  SALVAR_AGENDAMENTO,
  LISTAR_TIPOS_DE_HORARIOS,
} from '../../../acoes/tipos'

const estiloDoModal = {
  content: {
    width: '1100px'
  }
}

export default function Formulario(props) {
  const {
    adicionarBloqueioNaAgendaDoProfissionalDeSaude,
    adicionarAgendamento,
    adicionarPreCadastroDoPaciente,
    convenios,
    dataBaseHorariosLivres,
    grausDeRelacoes,
    horariosLivres,
    identificadorDoProfissional,
    limparAlertasDoAgendamento,
    limparDadosDoPaciente,
    limparHorariosLivres,
    listarGrausDeRelacoes,
    listarHorariosLivres,
    listarProprietariosDeTelefones,
    listarSexos,
    listarTiposDeHorarios,
    match,
    motivosDeBloqueio,
    fechar,
    pacientes,
    pacienteEmEspera,
    pacienteSemPrograma,
    pesquisarPacientes,
    proprietariosDeTelefones,
    proximosAgendamentos,
    recorrenciasDoAgendamento,
    selecionarPaciente,
    sexos,
    tiposDeAgendamento,
    unidades = [],
    valoresIniciais
  } = props

  const referenciaDoHorariosLivres = useRef(null)
  const referenciaPopUpPreCadastroDoPaciente = useRef(null)

  const [pacientePreCadastrado, setPacientePreCadastrado] = useState('')
  const [valor, setValor] = useState('')
  const [exibirPopUpPreCadastroDoPaciente, setExibirPopUpPreCadastroDoPaciente] = useState(false)
  const [exibirPopUpNovoBloqueio, setExibirPopUpNovoBloqueio] = useState(false)
  const [exibirPopupHorariosLivres, setExibirPopupHorariosLivres] = useState(false)
  const [exibirSemana, setExibirSemana] = useState(false)
  const [salvando, setSalvando] = useState(false)
  const habilitarFuncoes = usePossuiAsPermissoes([permissoes.ADICIONAR_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE])

  useCliqueForaDoElemento(referenciaDoHorariosLivres, () => fecharPopupHorariosLivres())
  useCliqueForaDoElemento(referenciaPopUpPreCadastroDoPaciente, () => referenciaPopUpPreCadastroDoPaciente(false))

  useEffect(() => { listarGrausDeRelacoes() }, [listarGrausDeRelacoes])
  useEffect(() => { listarProprietariosDeTelefones() }, [listarProprietariosDeTelefones])
  useEffect(() => { listarTiposDeHorarios() }, [listarTiposDeHorarios])

  useEffect(() => {
    limparAlertasDoAgendamento()
  }, [limparAlertasDoAgendamento])

  const element = document.getElementById('lista-botoes')

  useEffect(() => {
    if (element && (proximosAgendamentos || pacienteSemPrograma)) {
      element.scrollIntoView(false)
    }

  }, [proximosAgendamentos, pacienteSemPrograma, element])

  const setDuracaoDoTipoDeAgendamento = (tipoDeAgendamento, setFieldValue) => {
    if (tipoDeAgendamento === '') {
      setFieldValue('duracaoDoAgendamento', '')

    } else {
      const { tempo } = tiposDeAgendamento.find(x => x.identificador === tipoDeAgendamento)
      const tokens = tempo.split(':')

      let minutos = tokens[0] * 60
      minutos += + tokens[1]

      setFieldValue('duracaoDoAgendamento', minutos)
    }
  }

  const adicionar = async formulario => {
    setSalvando(true)
    const confirmarOAgendamentoQuandoOPacienteNaoPossuiProgramaAtivo = !!pacienteSemPrograma
    const confirmarOAgendamentoQuandoOPacienteJaTemAgendamentosNosProximosMeses = !!proximosAgendamentos
    const sucesso = await adicionarAgendamento({
      ...formulario,
      confirmarOAgendamentoQuandoOPacienteNaoPossuiProgramaAtivo,
      confirmarOAgendamentoQuandoOPacienteJaTemAgendamentosNosProximosMeses
    })

    if (sucesso) {
      fechar()
    } else {
      setSalvando(false)
    }
  }

  const selecionarPacienteDaLista = async (identificador, setFieldValue) => {
    if (identificador) {
      const paciente = pacientes.find(x => x.identificador === identificador)
      setExibirSemana(false)
      selecionarPaciente(paciente)

    } else {
      setFieldValue('telefone1', '')
      setFieldValue('proprietarioDoTelefone1', '')
      setFieldValue('nomeDoTelefone1', '')
      setFieldValue('grauDeRelacaoDoTelefone1', '')
      setFieldValue('telefone1EhWhatsapp', false)
      setFieldValue('telefone2', '')
      setFieldValue('proprietarioDoTelefone2', '')
      setFieldValue('nomeDoTelefone2', '')
      setFieldValue('grauDeRelacaoDoTelefone2', '')
      setFieldValue('telefone2EhWhatsapp', false)
      setFieldValue('convenio', '')
      setFieldValue('email', '')
      setValor('')
      limparDadosDoPaciente()
    }

  }

  const listarHorariosLivresDoProfissional = (evento, tipoDeAgendamento, unidade) => {
    listarHorariosLivres(moment(), tipoDeAgendamento, unidade)
    setExibirPopupHorariosLivres(true)
    evento.preventDefault()
  }

  function aoAlterarRecorrencia(valor, setFieldValue) {
    setExibirSemana(valor === 'semanal')
    if (!valor || valor === 'nao_recorrente') {
      setFieldValue('numeroDeRepeticoes', '')
      setFieldValue('ateAData', '')
    }
  }

  const selecionarHorario = async (horario, unidadeDaEmpresa, setFieldValue) => {
    const { inicio, fim } = horario

    let minutos = diferencaEmMinutosEntreDatas(inicio, fim)

    setFieldValue('duracaoDoAgendamento', minutos)
    setFieldValue('horarioDoAgendamento', moment(horario.inicio).format('HH:mm'))
    setFieldValue('data', moment(horario.inicio).format('YYYY-MM-DD'))
    setFieldValue('unidadeDaEmpresa', unidadeDaEmpresa)

    setExibirPopupHorariosLivres(false)
    limparHorariosLivres()
  }

  const adicionarPreCadastro = async formulario => {
    const adicionou = await adicionarPreCadastroDoPaciente(formulario)
    setExibirPopUpPreCadastroDoPaciente(!adicionou)
  }

  const renderizarItemAutoCompletePaciente = ({ value, label, dataDeNascimento, medicoDeReferencia, unidadePreferencial }) => {
    function extrairNomeResumido(nome) {
      if (!nome) return ''
      if (nome.length <= 35) return nome

      const nomes = nome.split(' ')

      if (nomes.length === 1) {
        return nomes[0]
      }

      const primeiroNome = nomes[0]
      const segundoNome = nomes.length > 2 ? nomes[1] : ''
      const ultimoNome = nomes[nomes.length - 1]

      return `${primeiroNome} ${segundoNome} ${ultimoNome}`
    }

    return (
      <div key={value}>
        <div className='container-item-auto-complete-paciente'>
          <div className='nome'>
            <span>{label}</span>
          </div>
          <div className='data-de-nascimento'>
            <span>Nasc.: {moment(dataDeNascimento).format('DD/MM/YYYY')}</span>
          </div>
        </div>
        <div className={`segunda-linha-auto-complete-pacientes ${medicoDeReferencia ? 'possui-medico' : ''}`}>
          {medicoDeReferencia &&
            <div className='medico-do-paciente'>
              <span>Médico(a): {extrairNomeResumido(medicoDeReferencia.nome)}</span>
            </div>
          }
          {!medicoDeReferencia &&
            <div className='medico-do-paciente'>
              <span>Não possui médico de referência.</span>
            </div>
          }
          <div className='unidade-do-paciente'>
            {unidadePreferencial &&
              <span>Unidade: {unidadePreferencial.nome}</span>
            }
          </div>
        </div>
      </div>
    )
  }

  const pesquisarPacientesAutocomplete = async termo => {
    setPacientePreCadastrado(termo)
    setValor(termo)

    if (!termo || termo.length <= 2) return

    await pesquisarPacientes(termo)
  }

  const optionsPaciente = pacientes.map(x => ({
    value: x.identificador,
    label: x.nome,
    dataDeNascimento: x.dataDeNascimento,
    medicoDeReferencia: x.medicoDeReferencia,
    unidadePreferencial: x.unidadePreferencial
  }))

  function abrirPreCadastroDoPaciente(evento) {
    setExibirPopUpPreCadastroDoPaciente(true)
    evento.preventDefault()
  }

  function fecharPreCadastroDoPaciente() {
    setExibirPopUpPreCadastroDoPaciente(false)
  }

  function fecharPopupHorariosLivres() {
    setExibirPopupHorariosLivres(false)
    limparHorariosLivres()
  }

  function abrirNovoBloqueio(evento) {
    setExibirPopUpNovoBloqueio(true)
    evento.preventDefault()
  }

  function fecharNovoBloqueio() {
    setExibirPopUpNovoBloqueio(false)
  }

  const construirArrayDeSemana = dias => {
    let semana = []
    if (dias.domingo) {
      semana.push('Dom')
    }
    if (dias.segundaFeira) {
      semana.push('Seg')
    }
    if (dias.tercaFeira) {
      semana.push('Ter')
    }
    if (dias.quartaFeira) {
      semana.push('Qua')
    }
    if (dias.quintaFeira) {
      semana.push('Qui')
    }
    if (dias.sextaFeira) {
      semana.push('Sex')
    }
    if (dias.sabado) {
      semana.push('Sáb')
    }

    return semana
  }

  const diasDaSemana = valoresIniciais.recorrencia && valoresIniciais.recorrencia.tipo === 'Semanal' && construirArrayDeSemana(valoresIniciais.recorrencia)
  const unidadePreSelecionada = unidades.length === 1 ? unidades[0].identificador : valoresIniciais.unidadeDaEmpresa

  const limparCampoTipoDeAgendamentoDeAcordoComTipoDaUnidade = (unidade, tipo, setFieldValue) => {
    const tipoDaUnidade = unidades.find(x => x.identificador === unidade)?.tipo
    const modalidadeDoTipoDeAgendamento = tiposDeAgendamento.find(x => x.identificador === tipo)?.modalidade

    if (!tipoDaUnidade || !modalidadeDoTipoDeAgendamento) {
      setFieldValue('tipoDeAgendamento', '')
      return
    }

    if (tipoDaUnidade === 'Virtual' && modalidadeDoTipoDeAgendamento === 'Presencial') {
      setFieldValue('tipoDeAgendamento', '')
      return
    }
  }

  const filtrarTiposDeAgendamentoDeAcordoComTipoDaUnidadeETipoDeHorario = unidade => {
    const unidadeEncontrada = unidades.find(x => x.identificador === unidade)

    if (!unidadeEncontrada) return tiposDeAgendamento;

    return unidadeEncontrada.tipo === 'Virtual'
      ? tiposDeAgendamento.filter(x => x.modalidade !== 'Presencial')
      : tiposDeAgendamento
  }

  return (
    <>
      <Modal
        isOpen={exibirPopUpPreCadastroDoPaciente}
        style={estiloDoModal}
        contentLabel='Modal para Pre-Cadastro do Paciente'
        className='modal'
      >
        <FormularioPreCadastroDoPaciente
          adicionar={adicionarPreCadastro}
          listarSexos={listarSexos}
          match={match}
          nomeDoPaciente={pacientePreCadastrado || valor}
          fechar={fecharPreCadastroDoPaciente}
          sexos={sexos}
        />
      </Modal>
      <Modal
        isOpen={exibirPopUpNovoBloqueio}
        style={estiloDoModal}
        contentLabel='Modal para Adicionar Bloqueio'
        className='modal'
      >
        <FormularioNovoBloqueio
          adicionar={adicionarBloqueioNaAgendaDoProfissionalDeSaude}
          identificadorDoProfissional={identificadorDoProfissional}
          fechar={fecharNovoBloqueio}
          motivosDeBloqueio={motivosDeBloqueio}
        />
      </Modal>
      <Spinner operacoes={[LISTAR_PACIENTES_DO_AGENDAMENTO]}>
        {({ processando }) => (
          <Spinner operacoes={[
            RECUPERAR_CONVENIOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO,
            LISTAR_TIPOS_DE_AGENDAMENTO_DO_AGENDAMENTO,
            LISTAR_RECORRENCIAS_DO_AGENDAMENTO,
            LISTAR_GRAUS_DE_RELACOES,
            LISTAR_PROPRIETARIOS_DE_TELEFONES,
            ADICIONAR_AGENDAMENTO,
            SALVAR_AGENDAMENTO,
            LISTAR_TIPOS_DE_HORARIOS
          ]}>
            <Form
              reinicializar={true}
              valoresIniciais={{
                identificadorDoAgendamento: valoresIniciais.identificadorDoAgendamento || '',
                paciente: valoresIniciais.paciente.identificador || '',
                telefone1: valoresIniciais.telefone1 || '',
                proprietarioDoTelefone1: valoresIniciais.proprietarioDoTelefone1 || '',
                nomeDoTelefone1: valoresIniciais.nomeDoTelefone1 || '',
                grauDeRelacaoDoTelefone1: valoresIniciais.grauDeRelacaoDoTelefone1 || '',
                telefone1EhWhatsapp: valoresIniciais.telefone1EhWhatsapp || false,
                telefone2: valoresIniciais.telefone2 || '',
                proprietarioDoTelefone2: valoresIniciais.proprietarioDoTelefone2 || '',
                nomeDoTelefone2: valoresIniciais.nomeDoTelefone2 || '',
                grauDeRelacaoDoTelefone2: valoresIniciais.grauDeRelacaoDoTelefone2 || '',
                telefone2EhWhatsapp: valoresIniciais.telefone2EhWhatsapp || false,
                email: valoresIniciais.email || '',
                unidadeDaEmpresa: unidadePreSelecionada || '',
                tipoDeAgendamento: valoresIniciais.tipoDeAgendamento || '',
                convenio: valoresIniciais.convenio || '',
                data: valoresIniciais.data || '',
                horarioDoAgendamento: valoresIniciais.horarioDoAgendamento || '',
                duracaoDoAgendamento: valoresIniciais.duracaoDoAgendamento || '',
                recorrencia: valoresIniciais.recorrencia ? valoresIniciais.recorrencia.tipo === 'Diária' ? 'diaria' : valoresIniciais.recorrencia.tipo.toLowerCase() : 'nao_recorrente',
                ateAData: valoresIniciais.recorrencia && valoresIniciais.recorrencia.dataFinal ? valoresIniciais.recorrencia.dataFinal : '',
                numeroDeRepeticoes: valoresIniciais.recorrencia && valoresIniciais.recorrencia.numeroDeRepeticoes ? valoresIniciais.recorrencia.numeroDeRepeticoes : '',
                semana: diasDaSemana || [],
                observacoes: valoresIniciais.observacoes || '',
              }}
              acao={adicionar}
              esquemaDeValidacoes={Yup.object().shape({
                paciente: Yup.string().required('Obrigatório'),
                telefone1: Yup.string()
                  .when("telefone2", {
                    is: val => val === '' || val === undefined,
                    then: Yup.string().required('Obrigatório.')
                  })
                  .nullable()
                  .ehUmNumeroDeTelefoneValido('Telefone inválido. Ex.: 99 9999-9999'),
                proprietarioDoTelefone1: Yup.string()
                  .when("telefone2", {
                    is: val => val === '' || val === undefined,
                    then: Yup.string().required('Obrigatório.')
                  })
                  .nullable(),
                nomeDoTelefone1: Yup.string()
                  .when('proprietarioDoTelefone1', {
                    is: val => val !== 'Paciente' && val !== '' && val !== undefined,
                    then: Yup.string().required('Obrigatório.').min(3, 'O nome deve possuir no mínimo 3 caracteres.')
                  }),
                grauDeRelacaoDoTelefone1: Yup.string()
                  .when('proprietarioDoTelefone1', {
                    is: val => val !== 'Paciente' && val !== '' && val !== undefined,
                    then: Yup.string().required('Obrigatório.')
                  }),
                telefone2: Yup.string()
                  .nullable()
                  .ehUmNumeroDeTelefoneValido('Telefone inválido. Ex.: 99 9999-9999'),
                proprietarioDoTelefone2: Yup.string()
                  .when("telefone2", {
                    is: val => val !== '' && val !== undefined,
                    then: Yup.string().required('Obrigatório.')
                  })
                  .nullable(),
                nomeDoTelefone2: Yup.string()
                  .when('proprietarioDoTelefone2', {
                    is: val => val !== 'Paciente' && val !== '' && val !== undefined,
                    then: Yup.string().required('Obrigatório.').min(3, 'O nome deve possuir no mínimo 3 caracteres.')
                  }),
                grauDeRelacaoDoTelefone2: Yup.string()
                  .when('proprietarioDoTelefone2', {
                    is: val => val !== 'Paciente' && val !== '' && val !== undefined,
                    then: Yup.string().required('Obrigatório.')
                  }),
                email: Yup.string().ehUmEmailValido('E-mail deve ser válido.'),
                unidadeDaEmpresa: Yup.string().required('Obrigatório'),
                tipoDeAgendamento: Yup.string().required('Obrigatório'),
                data: Yup.string()
                  .nullable()
                  .required('Obrigatório'),
                horarioDoAgendamento: Yup.string()
                  .nullable()
                  .required('Obrigatório'),
                duracaoDoAgendamento: Yup.string().required('Obrigatório'),
                numeroDeRepeticoes: Yup.number()
                  .typeError('Dever ser numérico')
                  .min(0, 'Número de repetições deve ser um valor entre 1 e 99.')
                  .max(99, 'Número de reptições deve ser um valor entre 1 e 99.')
                  .positive('Deve ser positivo')
                  .integer('Deve ser inteiro'),
                ateAData: Yup.string()
                  .nullable()
                  .test(
                    'recorrencia_teste',
                    "'Até a Data' ou 'Repetições' deve ser informado",
                    function (value) {
                      const { recorrencia, numeroDeRepeticoes } = this.parent;
                      const sucesso = (recorrencia &&
                        recorrencia !== 'nao_recorrente' &&
                        !value &&
                        !numeroDeRepeticoes)
                      return !sucesso
                    }
                  ),
                semana: Yup.string()
                  .nullable()
                  .test(
                    'recorrencia_teste',
                    'Obrigatório',
                    function (value) {
                      const { recorrencia } = this.parent;
                      const sucesso = (recorrencia &&
                        recorrencia === 'semanal' &&
                        !value)
                      return !sucesso
                    }
                  ),
              })}
            >
              {({ values, setFieldValue, errors, touched }) => (
                <>
                  <fieldset>
                    <r-grid columns-md={6} columns-lg={12}>
                      <r-cell span={4} span-md={4} span-lg={8}>
                        <h2 className='form-title'>{valoresIniciais.identificadorDoAgendamento ? 'Alterar Agendamento' : 'Novo Agendamento'}</h2>
                      </r-cell>
                      {!valoresIniciais.identificadorDoAgendamento &&
                        <Permissao permissoes={[permissoes.ADICIONAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]}>
                          <r-cell span={4} span-md={2} span-lg={4} class='flex align-items-end'>
                            <button type='button' className={`bt-novo-bloqueio-agenda --primary w-100`} onClick={abrirNovoBloqueio}>
                              Novo Bloqueio
                            </button>
                          </r-cell>
                        </Permissao>
                      }
                    </r-grid>
                    <r-grid columns-md={6} columns-lg={12}>
                      <r-cell span={4} span-md={4} span-lg={8}>
                        <Select
                          nome='paciente'
                          tabIndex={1}
                          titulo='Paciente *'
                          itens={pacientes}
                          campoCodigo='identificador'
                          campoDescricao='nome'
                          formatOptionLabel={renderizarItemAutoCompletePaciente}
                          onChange={v => selecionarPacienteDaLista(v, setFieldValue)}
                          onInputChange={pesquisarPacientesAutocomplete}
                          options={optionsPaciente}
                          isLoading={processando}
                          inputValue={valoresIniciais.paciente.identificador ? '' : (pacientePreCadastrado || valor)}
                          filterOption={() => true}
                          onMenuClose={() => setValor(pacientePreCadastrado)}
                          onFocus={() => pesquisarPacientesAutocomplete(pacientePreCadastrado || valor)}
                          isDisabled={pacienteEmEspera || valoresIniciais.identificadorDoAgendamento}
                        />
                      </r-cell>
                      <r-cell span={4} span-md={2} span-lg={4} class='flex align-items-end'>
                        <button
                          type='button'
                          disabled={values.paciente}
                          className={`button-wizard ${errors.paciente && touched.paciente && 'has-error'} --plus --primary w-100`}
                          onClick={abrirPreCadastroDoPaciente}>
                          Adicionar
                        </button>
                      </r-cell>

                      <r-cell span={3} span-md={3} span-lg={3}>
                        <Select
                          nome='proprietarioDoTelefone1'
                          tabIndex={2}
                          titulo='Proprietário do telefone*'
                          itens={proprietariosDeTelefones}
                          campoCodigo='nome'
                          campoDescricao='nome'
                          onChange={() =>
                            setFieldValue('nomeDoTelefone1', '') &&
                            setFieldValue('grauDeRelacaoDoTelefone1', '')
                          }
                          isDisabled={!habilitarFuncoes}
                        />
                      </r-cell>
                      <r-cell span={3} span-md={3} span-lg={3}>
                        <Input
                          type='text'
                          nome='nomeDoTelefone1'
                          tabIndex={3}
                          titulo={values.proprietarioDoTelefone1 === 'Paciente' || !values.telefone1 ? 'Nome' : 'Nome *'}
                          disabled={values.proprietarioDoTelefone1 === 'Paciente' || !habilitarFuncoes} />
                      </r-cell>
                      <r-cell span={3} span-md={2} span-lg={3}>
                        <Select
                          nome='grauDeRelacaoDoTelefone1'
                          tabIndex={4}
                          titulo={values.proprietarioDoTelefone1 === 'Paciente' || !values.telefone1 ? 'Grau de Relação' : 'Grau de Relação *'}
                          itens={grausDeRelacoes}
                          campoCodigo='nome'
                          campoDescricao='nome'
                          isDisabled={values.proprietarioDoTelefone1 === 'Paciente' || !habilitarFuncoes}
                        />
                      </r-cell>
                      <r-cell span={2} span-md={3} span-lg={2}>
                        <Telefone
                          type='text'
                          nome='telefone1'
                          tabIndex={5}
                          titulo='Telefone 1*'
                        />
                      </r-cell>
                      <r-cell span={1} span-md='1' span-lg='1' title='Telefone é WhatsApp'>
                        <Checkbox
                          nome='telefone1EhWhatsapp'
                          tabIndex={6}
                          titulo={<WhatsApp size={24} className='mt-5n' />}
                          classname='mt-35'
                          title='Telefone é WhatsApp'
                          disabled={values.telefone1 === '' || values.telefone1 === undefined || !habilitarFuncoes}
                        />
                      </r-cell>

                      <r-cell span={3} span-md={3} span-lg={3}>
                        <Select
                          nome='proprietarioDoTelefone2'
                          tabIndex={7}
                          titulo={values.telefone2 ? 'Proprietário do telefone*' : 'Proprietário do telefone'}
                          itens={proprietariosDeTelefones}
                          campoCodigo='nome'
                          campoDescricao='nome'
                          onChange={() =>
                            setFieldValue('nomeDoTelefone2', '') &&
                            setFieldValue('grauDeRelacaoDoTelefone2', '')
                          }
                          isDisabled={!habilitarFuncoes}
                        />
                      </r-cell>
                      <r-cell span={3} span-md={3} span-lg={3}>
                        <Input
                          type='text'
                          nome='nomeDoTelefone2'
                          tabIndex={8}
                          titulo={values.proprietarioDoTelefone2 === 'Paciente' || !values.telefone2 ? 'Nome' : 'Nome *'}
                          disabled={values.proprietarioDoTelefone2 === 'Paciente' || !habilitarFuncoes}
                        />
                      </r-cell>
                      <r-cell span={3} span-md={2} span-lg={3}>
                        <Select
                          nome='grauDeRelacaoDoTelefone2'
                          tabIndex={9}
                          titulo={values.proprietarioDoTelefone2 === 'Paciente' || !values.telefone2 ? 'Grau de Relação' : 'Grau de Relação *'}
                          itens={grausDeRelacoes}
                          campoCodigo='nome'
                          campoDescricao='nome'
                          isDisabled={values.proprietarioDoTelefone2 === 'Paciente' || !habilitarFuncoes}
                        />
                      </r-cell>
                      <r-cell span={2} span-md={3} span-lg={2}>
                        <Telefone
                          type='text'
                          nome='telefone2'
                          tabIndex={10}
                          titulo='Telefone 2'
                        />
                      </r-cell>
                      <r-cell span={1} span-md='1' span-lg='1' title='Telefone é WhatsApp'>
                        <Checkbox
                          nome='telefone2EhWhatsapp'
                          tabIndex={11}
                          titulo={<WhatsApp size={24} className='mt-5n' />}
                          classname='mt-35'
                          title='Telefone é WhatsApp'
                          disabled={values.telefone2 === '' || values.telefone2 === undefined || !habilitarFuncoes}
                        />
                      </r-cell>
                      <r-cell span={4} span-md={6} span-lg={5}>
                        <Input
                          type='text'
                          nome='email'
                          tabIndex={12}
                          titulo='E-mail'
                        />
                      </r-cell>
                      <r-cell span={4} span-md={3} span-lg={3}>
                        <Select
                          nome='unidadeDaEmpresa'
                          tabIndex={13}
                          titulo='Unidade *'
                          itens={unidades}
                          campoCodigo='identificador'
                          campoDescricao='nome'
                          onChange={unidade => limparCampoTipoDeAgendamentoDeAcordoComTipoDaUnidade(unidade, values.tipoDeAgendamento, setFieldValue)}
                        />
                      </r-cell>
                      <r-cell span={4} span-md={3} span-lg={4}>
                        <Select
                          nome='tipoDeAgendamento'
                          tabIndex={14}
                          titulo='Tipo de Agendamento *'
                          itens={filtrarTiposDeAgendamentoDeAcordoComTipoDaUnidadeETipoDeHorario(values.unidadeDaEmpresa)}
                          campoCodigo='identificador'
                          campoDescricao='nome'
                          onChange={v => setDuracaoDoTipoDeAgendamento(v, setFieldValue)}
                        />
                      </r-cell>
                      <r-cell span={4} span-md={3} span-lg={4}>
                        <Select
                          nome='convenio'
                          tabIndex={15}
                          titulo='Convênio'
                          itens={convenios}
                          campoCodigo='identificador'
                          campoDescricao='descricao'
                        />
                      </r-cell>
                      <r-cell span={4} span-md={4} span-lg={2}>
                        <DatePicker
                          type='text'
                          nome='data'
                          tabIndex={16}
                          titulo='Data *'
                          minDate={moment().toDate()}
                        />
                      </r-cell>
                      <r-cell span={4} span-md={2} span-lg={2}>
                        <HourPicker
                          titulo='Horário *'
                          nome='horarioDoAgendamento'
                          tabIndex={17}
                        />
                      </r-cell>
                      <r-cell span={4} span-md={4} span-lg={2}>
                        <Contador
                          nome='duracaoDoAgendamento'
                          tabIndex={18}
                          titulo='Duração *'
                          range={5}
                          minimo={5}
                        />
                      </r-cell>

                      <r-cell span={4} span-md={2} span-lg={2} class='flex align-items-end'>
                        {exibirPopupHorariosLivres &&
                          <div className='box-hours-free active' ref={referenciaDoHorariosLivres}>
                            <HorariosLivres
                              dataBaseHorariosLivres={dataBaseHorariosLivres}
                              horariosLivres={horariosLivres}
                              listarHorariosLivres={listarHorariosLivres}
                              selecionarHorario={selecionarHorario}
                              setFieldValue={setFieldValue}
                              tipoDeAgendamento={tiposDeAgendamento.find(x => x.identificador === values.tipoDeAgendamento)}
                              unidadeDaEmpresaSelecionada={values.unidadeDaEmpresa}
                              fechar={() => fecharPopupHorariosLivres()}
                              unidades={unidades}
                            />
                            <button type='button' className='button-hours-free is-disabled --primary w-100'>Horários Livres</button>
                          </div>
                        }
                        {!exibirPopupHorariosLivres &&
                          <button
                            type='button'
                            disabled={!values.tipoDeAgendamento}
                            className={`button-hours-free ${((errors.data && touched.data) || (errors.horarioDoAgendamento && touched.horarioDoAgendamento)
                              || (errors.duracaoDoAgendamento && touched.duracaoDoAgendamento)) && 'has-error'} --primary w-100`}
                            onClick={(evento) => listarHorariosLivresDoProfissional(evento, values.tipoDeAgendamento, values.unidadeDaEmpresa)}>
                            Horários Livres
                          </button>
                        }
                      </r-cell>
                      <r-cell span={4} span-md={2} span-lg={4}>
                        <Select
                          nome='recorrencia'
                          tabIndex={19}
                          titulo='Recorrência'
                          itens={recorrenciasDoAgendamento}
                          campoCodigo='codigo'
                          campoDescricao='nome'
                          onChange={v => aoAlterarRecorrencia(v, setFieldValue)}
                        />
                      </r-cell>
                      <r-cell span={4} span-md={2} span-lg={2}>
                        <DatePicker
                          type='text'
                          nome='ateAData'
                          tabIndex={20}
                          titulo='Até a Data'
                          isDisabled={!values.recorrencia || values.recorrencia === 'nao_recorrente' || values.numeroDeRepeticoes}
                          minDate={moment().toDate()}
                        />
                      </r-cell>
                      <r-cell span={4} span-md={2} span-lg={2}>
                        <Input
                          type='text'
                          nome='numeroDeRepeticoes'
                          tabIndex={21}
                          titulo='Repetições'
                          disabled={!values.recorrencia || values.recorrencia === 'nao_recorrente' || values.ateAData}
                        />
                      </r-cell>
                      {(exibirSemana || values.recorrencia === 'semanal') &&
                        <r-cell span={4} span-md={6} span-lg={12}>
                          <Opcoes
                            titulo='Dia da Semana *'
                            nome='semana'
                            opcoes={['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']}
                          />
                        </r-cell>
                      }
                      <r-cell span={4} span-md='row' span-lg='row'>
                        <Input
                          as='textarea'
                          nome='observacoes'
                          tabIndex={22}
                          titulo='Observações'
                        />
                      </r-cell>
                    </r-grid>
                    <NotificacaoQuandoOPacienteNaoPossuiProgramaAtivo pacienteSemPrograma={pacienteSemPrograma} />
                    <NotificacaoQuandoOPacienteJaTemAgendamentosNosProximosMeses proximosAgendamentos={proximosAgendamentos} />
                    <div className='list-btn' id='lista-botoes'>
                      <button type='button' onClick={() => fechar()} className='button --light'>Cancelar</button>
                      <button type='submit' className={`button --primary ${salvando && 'is-loading'}`}>{(proximosAgendamentos || pacienteSemPrograma) ? 'Confirmar Novo Agendamento' : 'Salvar Agendamento'}</button>
                    </div>
                  </fieldset>
                </>
              )}
            </Form>
          </Spinner>
        )}
      </Spinner>
    </>
  )
}