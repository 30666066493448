
import React, { createContext, useContext } from 'react'
import { useDispatch } from 'react-redux'
import constantes from '../constantes'
import {
  excluirPrescricaoMemedNoFormulario,
  erroAoIntegrarNaMemed,
  incluirPrescricaoMemedNoFormulario,
  integrarNaMemed,
  integrouNaMemed,
  recuperarPrescricaoPdfNaMemed,
} from '../../acoes/prontuario/memed'

const MemedContext = createContext()

function MemedProvider({ children }) {
  const dispatch = useDispatch()

  const open = (module = 'plataforma.prescricao') => {
    window.MdHub.module.show(module)
  }

  const hide = (module = 'plataforma.prescricao') => {
    window.MdHub.module.hide(module)
  }

  const visualizarPrescricaoGerada = idPrescricaoMemed => {
    if (window.MdHub) {
      window.MdHub.command.send('plataforma.prescricao', 'viewPrescription', idPrescricaoMemed)
    }
  }

  const logOut = () => {
    //console.info('====== Memed Logout ======')
    if (window.MdHub) {
      try {
        // Parar os event listeners da Memed
        window.MdHub.server.unbindEvents()

        // Limpar o local storage do navegador web
        window.MdHub.command.send('plataforma.sdk', 'logout')

        // Remover os objetos principais da Memed
        // @ts-expect-error
        delete window.MdHub
        // @ts-expect-error
        delete window.MdSinapsePrescricao

      } catch (err) {
        console.error(err)
      }
    }
  }

  const definePacient = (pacient) => {
    return new Promise((resolve, reject) => {
      try {
        window.MdHub.command
          .send('plataforma.prescricao', 'setPaciente', {
            idExterno: pacient?.idExterno,
            cpf: pacient?.cpf,
            nome: pacient?.nome,
            telefone: pacient?.telefone,
            nome_mae: pacient?.nome_mae,
            data_nascimento: pacient?.data_nascimento,
            endereco: pacient?.endereco,
            cidade: pacient?.cidade,
          })
          .then(
            (response) => {
              resolve('')
            },
            error => {
              reject(error)
            }
          )
      } catch (err) {
        console.error(err)
      }
    })
  }

  const initEventsMemed = (tokens, paciente, fechar) => {
    try {
      dispatch(integrarNaMemed())

      window.MdSinapsePrescricao.event.add('core:moduleHide', (module) => {
        if (module.moduleName === 'plataforma.prescricao') {
          //console.info('====== Módulos da Memed encerrados ======')
          fechar()
        }
      })

      window.MdSinapsePrescricao.event.add('core:moduleInit', async function (module) {
        if (module.name === 'plataforma.prescricao') {
          //console.info('====== Módulos da Memed carregados ======')

          await window.MdHub.command.send('plataforma.prescricao', 'setFeatureToggle', {
            // Desativa a opção de excluir um paciente
            deletePatient: false,
            // Desabilita a opção de remover/trocar o paciente
            removePatient: false,
            // Esconde o formulário de confirmação dos dados do paciente para receituário de controle especial
            // caso a cidade e o endereço tenham sido definidos com o comando `setPaciente`
            editPatient: false,
            // Esconder botao X de fechar popup memed
            //buttonClose: false,
          })

          await window.MdHub.command.send('plataforma.prescricao', 'setAdditionalData', {
            atendimento: tokens.identificadorDoAtendimento,
            paciente: tokens.identificadorDoPaciente,
            usuario: tokens.identificadorDoUsuario,
            empresa: tokens.identificadorDaEmpresa,
            unidade: tokens.identificadorDaUnidade,
          })

          await window.MdHub.event.add('prescricaoImpressa', async prescriptionData => {
            // No objeto 'prescriptionData', é retornado as informações da prescrição gerada.
            // Implementar ações, callbacks, etc.     
            // console.info('====== Prescrição Memed gerada ======', prescriptionData)
            if (!prescriptionData.reimpressao) {

              let contagem = 0
              const prescricaoMemedArray = prescriptionData.prescricao.medicamentos.map((m, index) => {
                if (m.unit) contagem++

                return `
${m.unit ? `- ${contagem}.` : ''} ${m.nome} ${m.quantidade !== null ? '-' : ''} ${m.quantidade !== null && m.quantidade > 0 ? m.quantidade + ' ' + notNull(m.unit) : notNull(m.unit)}
     ${m.posologia.replaceAll('<br>', '\n     ').replaceAll('</p><p>', '\n     ').replaceAll('<p>', '').replaceAll('</p>', '')}${m.unit ? '\n' : ''}`
              })

              const prescricaoMemed = prescricaoMemedArray.join('')

              const pdf = await dispatch(recuperarPrescricaoPdfNaMemed(tokens.userToken, prescriptionData.prescricao.id.toString()))

              dispatch(incluirPrescricaoMemedNoFormulario({
                texto: prescricaoMemed,
                identificadorExterno: prescriptionData.prescricao.id.toString(),
                assinada: prescriptionData.prescricao.signed === 1 ? true : false,
                linkExterno: pdf,
                dadosDaMemed: JSON.stringify(prescriptionData.prescricao.medicamentos)
              }))

            }

          })

          // Escutando o evento de prescricaoExcluida.
          await window.MdHub.event.add('prescricaoExcluida', function (idPrescricao) {
            //console.info('====== Prescrição Memed excluida ======', idPrescricao)
            dispatch(excluirPrescricaoMemedNoFormulario(idPrescricao))
          });

          //console.info('====== Prescrição Memed -> define paciente ======', paciente)
          await definePacient(paciente)
          fechar() // fechar popup para evitar congelamento da tela do lifecode ao finalizar memed
          open()

          //console.info('====== Prescrição Memed -> integrou na memed ======')
          dispatch(integrouNaMemed())

        }
      })

    } catch (err) {
      console.error(err)
      dispatch(erroAoIntegrarNaMemed())
    }
  }

  const initMemed = (tokens, paciente, fechar) => {
    const script = document.createElement('script')
    // script.setAttribute('type', 'text/javascript')
    // script.setAttribute('data-color', colors.primary[80])
    // script.setAttribute('data-token', tokens.userToken)
    //script.setAttribute('data-container', 'memed-container')
    script.dataset.token = tokens.userToken
    script.src = `https://${constantes.urlDaApiMemed}/modulos/plataforma.sinapse-prescricao/build/sinapse-prescricao.min.js`
    script.onload = () => {
      initEventsMemed(tokens, paciente, fechar)
    }
    document.body.appendChild(script)
  }

  return <MemedContext.Provider value={{ logOut, open, hide, initMemed, visualizarPrescricaoGerada }}>{children}</MemedContext.Provider>
}

const useMemed = () => {
  const context = useContext(MemedContext)

  if (!context) {
    throw new Error('useMemed must be used within an MemedProvider')
  }

  return context
}

function notNull(valor) {
  if (!valor) return ''
  return valor
}

export { useMemed, MemedProvider }