import React from 'react'
import AcidoUrico from '../../componentes/acido-urico'
import Albumina from '../../componentes/albumina'
import Altura from '../../componentes/altura'
import Anamnese from '../../componentes/anamnese'
import AntiHbc from '../../componentes/anti-hbc'
import AntiHbs from '../../componentes/anti-hbs'
import AntiHcv from '../../componentes/anti-hcv'
import AntiHiv from '../../componentes/anti-hiv'
import Bicarbonato from '../../componentes/bicarbonato'
import CalcioIonico from '../../componentes/calcio-ionico'
import CalcioTotal from '../../componentes/calcio-total'
import CircunferenciaDaPanturrilha from '../../componentes/circunferencia-da-panturrilha'
import ColesterolTotal from '../../componentes/colesterol-total'
import Conduta from '../../componentes/conduta'
import Creatinina from '../../componentes/creatinina'
import Encaminhamentos from '../../componentes/encaminhamento/resumo'
import ExameFisico from '../../componentes/exame-fisico'
import Ferritina from '../../componentes/ferritina'
import ForcaDePreensaoPalmar from '../../componentes/forca-de-preensao-palmar'
import Fosforo from '../../componentes/fosforo'
import FrequenciaCardiaca from '../../componentes/frequencia-cardiaca'
import Frutosamina from '../../componentes/frutosamina'
import GlicemiaEmJejum from '../../componentes/glicemia-em-jejum'
import GlicoHemoglobina from '../../componentes/glico-hemoglobina'
import Hbsag from '../../componentes/hbsag'
import Hdl from '../../componentes/hdl'
import Hemoglobina from '../../componentes/hemoglobina'
import IndiceDeSaturacaoDeTransferrina from '../../componentes/indice-de-saturacao-de-transferrina'
import Ldl from '../../componentes/ldl'
import Orientacoes from '../../componentes/orientacao/resumo'
import Paratormonio from '../../componentes/paratormonio'
import Passometro from '../../componentes/passometro'
import Peso from '../../componentes/peso'
import Potassio from '../../componentes/potassio'
import PressaoArterial from '../../componentes/pressao-arterial'
import PrevisaoDeRetorno from '../../componentes/previsao-de-retorno'
import Proteinuria from '../../componentes/proteinuria'
import RelacaoAlbuminaCreatinina from '../../componentes/relacao-albumina-creatinina'
import RelacaoProteinaCreatinina from '../../componentes/relacao-proteina-creatinina'
import ResultadoDeExame from '../../componentes/resultado-de-exame'
import ResumoDeAnexos from '../../componentes/anexos/resumo'
import ResumoDeExames from '../../componentes/exames/resumo'
import ResumoDePrescricoes from '../../componentes/prescricoes/prescricao/resumo'
import ResumoDePrescricoesDaMemed from '../../componentes/prescricoes/prescricao-da-memed/resumo'
import ResumoDeVideoConferencias from '../../componentes/video-consulta-por-zoom/resumo'
import Rni from '../../componentes/rni'
import Sodio from '../../componentes/sodio'
import Titulo from '../../componentes/titulo'
import Triglicerides from '../../componentes/triglicerides'
import Tsh from '../../componentes/tsh'
import Ureia from '../../componentes/ureia'
import VelocidadeDaMarcha from '../../componentes/velocidade-da-marcha'
import VitaminaD from '../../componentes/vitamina-d'

export const renderizarComponente = (componente, atendimento, nome) => {
  const key = `${nome}_${componente.tipo}`
  switch (componente.tipo.toUpperCase()) {
    case 'ACIDO_URICO':
      return <AcidoUrico  {...componente} key={key} leitura={true} valor={atendimento.acidoUrico} />
    case 'ALBUMINA':
      return <Albumina {...componente} key={key} leitura={true} valor={atendimento.albumina} />
    case 'ALTURA':
      return <Altura {...componente} key={key} leitura={true} valor={atendimento.altura} />
    case 'ANAMNESE':
      return <Anamnese {...componente} key={key} leitura={true} valor={atendimento.anamnese} />
    case 'ANEXOS':
      return <ResumoDeAnexos {...componente} key={key} valor={atendimento.anexos || []} identificadorDoPaciente={atendimento.paciente.identificador} atendimento={atendimento} />
    case 'ANTI_HBC':
      return <AntiHbc {...componente} key={key} leitura={true} valor={atendimento.antiHbc} />
    case 'ANTI_HBS':
      return <AntiHbs {...componente} key={key} leitura={true} valor={atendimento.antiHbs} />
    case 'ANTI_HCV':
      return <AntiHcv {...componente} key={key} leitura={true} valor={atendimento.antiHcv} />
    case 'ANTI_HIV':
      return <AntiHiv {...componente} key={key} leitura={true} valor={atendimento.antiHiv} />
    case 'BICARBONATO':
      return <Bicarbonato {...componente} key={key} leitura={true} valor={atendimento.bicarbonato} />
    case 'CALCIO_IONICO':
      return <CalcioIonico  {...componente} key={key} leitura={true} valor={atendimento.calcioIonico} />
    case 'CALCIO_TOTAL':
      return <CalcioTotal  {...componente} key={key} leitura={true} valor={atendimento.calcioTotal} />
    case 'CONDUTA':
      return <Conduta {...componente} key={key} leitura={true} valor={atendimento.conduta} />
    case 'CIRCUNFERENCIA_DA_PANTURRILHA':
      return <CircunferenciaDaPanturrilha {...componente} key={key} leitura={true} valor={atendimento.circunferenciaDaPanturrilha} />
    case 'COLESTEROL_TOTAL':
      return <ColesterolTotal  {...componente} key={key} leitura={true} valor={atendimento.colesterolTotal} />
    case 'CREATININA':
      return <Creatinina  {...componente} key={key} leitura={true} valor={atendimento.creatinina} />
    case 'ENCAMINHAMENTO':
      return <Encaminhamentos  {...componente} key={key} valor={atendimento.encaminhamentos || []} />
    case 'EXAME_FISICO':
      return <ExameFisico {...componente} key={key} leitura={true} valor={atendimento.exameFisico} />
    case 'EXAMES':
      return <ResumoDeExames {...componente} key={key} valor={atendimento.exames} identificadorDoPaciente={atendimento.paciente.identificador} identificadorDoAtendimento={atendimento.identificador} />
    case 'FERRITINA':
      return <Ferritina  {...componente} key={key} leitura={true} valor={atendimento.ferritina} />
    case 'FORCA_DE_PREENSAO_PALMAR':
      return <ForcaDePreensaoPalmar {...componente} key={key} leitura={true} valor={atendimento.forcaDePreensaoPalmar} />
    case 'FOSFORO':
      return <Fosforo  {...componente} key={key} leitura={true} valor={atendimento.fosforo} />
    case 'FREQUENCIA_CARDIACA':
      return <FrequenciaCardiaca {...componente} key={key} leitura={true} valor={atendimento.frequenciaCardiaca} />
    case 'FRUTOSAMINA':
      return <Frutosamina {...componente} key={key} leitura={true} valor={atendimento.frutosamina} />
    case 'GLICEMIA_EM_JEJUM':
      return <GlicemiaEmJejum  {...componente} key={key} leitura={true} valor={atendimento.glicemiaEmJejum} />
    case 'GLICO_HEMOGLOBINA':
      return <GlicoHemoglobina {...componente} key={key} leitura={true} valor={atendimento.glicoHemoglobina} />
    case 'HBSAG':
      return <Hbsag {...componente} key={key} leitura={true} valor={atendimento.hbsag} />
    case 'HDL':
      return <Hdl {...componente} key={key} leitura={true} valor={atendimento.hdl} />
    case 'HEMOGLOBINA':
      return <Hemoglobina {...componente} key={key} leitura={true} valor={atendimento.hemoglobina} />
    case 'INDICE_DE_SATURACAO_DE_TRANSFERRINA':
      return <IndiceDeSaturacaoDeTransferrina {...componente} key={key} leitura={true} valor={atendimento.indiceDeSaturacaoDeTransferrina} />
    case 'LDL':
      return <Ldl  {...componente} key={key} leitura={true} valor={atendimento.ldl} />
    case 'ORIENTACOES':
      return <Orientacoes  {...componente} key={key} valor={atendimento.orientacoes || []} />
    case 'PARATORMONIO':
      return <Paratormonio {...componente} key={key} leitura={true} valor={atendimento.paratormonio} />
    case 'PASSOMETRO':
      return <Passometro {...componente} key={key} leitura={true} valor={atendimento.passometro} />
    case 'PESO':
      return <Peso {...componente} key={key} leitura={true} valor={atendimento.peso} />
    case 'POTASSIO':
      return <Potassio {...componente} key={key} leitura={true} valor={atendimento.potassio} />
    case 'PRESCRICOES':
      return <ResumoDePrescricoes {...componente} key={key} valor={atendimento.prescricoes || []} />
    case 'PRESCRICOES_DA_MEMED':
      return <ResumoDePrescricoesDaMemed {...componente} key={key} valor={atendimento.prescricoesDaMemed || []} />
    case 'PRESSAO_ARTERIAL':
      const { pressaoArterialSistolica, pressaoArterialDiastolica } = atendimento
      const valorDaPressaoArterial = pressaoArterialSistolica ? { pressaoArterialSistolica, pressaoArterialDiastolica } : null

      return <PressaoArterial {...componente} key={key} leitura={true} valor={valorDaPressaoArterial} />
    case 'PREVISAO_DE_RETORNO':
      return <PrevisaoDeRetorno {...componente} key={key} leitura={true} valor={atendimento.previsaoDeRetorno} />
    case 'PROTEINURIA_24_HORAS':
      return <Proteinuria {...componente} key={key} leitura={true} valor={atendimento.proteinuria24Horas} />
    case 'RELACAO_ALBUMINA_CREATININA':
      return <RelacaoAlbuminaCreatinina {...componente} key={key} leitura={true} valor={atendimento.relacaoAlbuminaCreatinina} />
    case 'RELACAO_PROTEINA_CREATININA':
      return <RelacaoProteinaCreatinina {...componente} key={key} leitura={true} valor={atendimento.relacaoProteinaCreatinina} />
    case 'RESULTADO_DE_EXAME':
      return <ResultadoDeExame {...componente} key={key} leitura={true} valor={atendimento.resultadoDeExame} />
    case 'RNI':
      return <Rni {...componente} key={key} leitura={true} valor={atendimento.rni} />
    case 'SODIO':
      return <Sodio {...componente} key={key} leitura={true} valor={atendimento.sodio} />
    case 'TITULO':
      if (componente.metadados.texto === 'Vídeo Consulta') return null
      return <Titulo {...componente} key={`${key}_${componente.metadados.texto}`} metadados={componente.metadados} />
    case 'TRIGLICERIDES':
      return <Triglicerides  {...componente} key={key} leitura={true} valor={atendimento.triglicerides} />
    case 'TSH':
      return <Tsh  {...componente} key={key} leitura={true} valor={atendimento.tsh} />
    case 'UREIA':
      return <Ureia {...componente} key={key} leitura={true} valor={atendimento.ureia} />
    case 'VELOCIDADE_DA_MARCHA':
      return <VelocidadeDaMarcha {...componente} key={key} leitura={true} valor={atendimento.velocidadeDaMarcha} />
    case 'VITAMINA_D':
      return <VitaminaD {...componente} key={key} leitura={true} valor={atendimento.vitaminaD} />
    case 'VIDEO_CONSULTA_COM_ZOOM':
      const { videoConferencias = [], lotesDeArquivosDeVideoConsulta = [] } = atendimento
      let valor = (videoConferencias.length > 0 ? videoConferencias : '') || (lotesDeArquivosDeVideoConsulta.length > 0 ? lotesDeArquivosDeVideoConsulta : '')
      if (videoConferencias.length === 0 && lotesDeArquivosDeVideoConsulta.length === 0) valor = null

      return <ResumoDeVideoConferencias {...componente} key={key} videoConferencias={videoConferencias || []} lotesDeArquivosDeVideoConsulta={lotesDeArquivosDeVideoConsulta || []} atendimento={atendimento} valor={valor} />
    default:
      break
  }
}

